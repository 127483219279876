import React from "react";
import PropTypes from "prop-types";

function DetailsPicture({ id, children }) {
  return (
    <div id={id} className="details-picture">
      {children}
    </div>
  );
}

DetailsPicture.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};

DetailsPicture.defaultProps = {
  id: "details-picture",
};

export default DetailsPicture;
