import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { NavDropdown, MenuItem } from "react-bootstrap";
import { withConfiguration, withTracking, trackingShape } from "../../context";
import CONFIGURATION from "../../configuration";
import { locationShape } from "../../shapes";
import { sentryUnmask } from "../../utils/sentryUtils";

export function NavigationAppSwitcher({
  configuration = {},
  location,
  tracking,
}) {
  const { applications = {} } = configuration;
  const switcherTracking = (applicationKey) => {
    tracking?.trackEvent(`AppSwitcher-${applicationKey}-click`);
  };

  const applicationItems = Object.keys(applications)
    .filter((appName) => !configuration?.hiddenApplications?.includes(appName))
    .map((appName) => {
      const url = () => {
        if (configuration.appName === "netilion")
          return `${applications[appName]}`;
        return applications[appName].indexOf("smartsystems") > 0
          ? applications[appName]
          : `${applications[appName]}/redirect${location.pathname.substring(CONFIGURATION.BASE_URL.length)}`;
      };
      const appIcon =
        appName === "fermentation"
          ? "icon3 icon3-eh-netilion-fermentation"
          : `icon lcm-iot-icon-${appName}`;
      return (
        <MenuItem
          id={`${appName}-item`}
          key={appName}
          className={sentryUnmask("menu-item")}
          href={url()}
          onClick={() => switcherTracking(appName)}
        >
          <div className="menu-app-icon">
            <span className={appIcon} />
          </div>
          <span className="title">
            <FormattedMessage id={`app.${appName}`} />
          </span>
        </MenuItem>
      );
    });

  return (
    <NavDropdown
      id="apps-navigation-dropdown"
      className="apps-menu"
      title={
        <div className="lcm-iot-icon-apps icon intro-health-022019-step-6" />
      }
    >
      {applicationItems}
    </NavDropdown>
  );
}

NavigationAppSwitcher.propTypes = {
  location: locationShape.isRequired,
  configuration: PropTypes.shape({
    appName: PropTypes.string,
    applications: PropTypes.shape({}),
    hiddenApplications: PropTypes.arrayOf(PropTypes.string),
  }), // not required but recommended
  tracking: trackingShape,
};

export default withConfiguration(withTracking(NavigationAppSwitcher));
