export function extractUserGroup(rawUserGroup) {
  const userGroup = {
    id: rawUserGroup.id,
    name: rawUserGroup.name,
    description: rawUserGroup.description,
  };
  return userGroup;
}

export function extractUserGroups(rawUserGroups) {
  return rawUserGroups.usergroups.map((userGroup) =>
    extractUserGroup(userGroup),
  );
}

export function extractUserForUserGroup(rawUser) {
  const user = {
    id: rawUser.id,
    firstName: rawUser.first_name,
    lastName: rawUser.last_name,
  };
  return user;
}

export function extractUserGroupUsers(rawUserGroupUsers) {
  return rawUserGroupUsers.users.map((user) => extractUserForUserGroup(user));
}
