import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import NotificationAlerts from "../components/Notifications/NotificationAlerts";
import NotificationAlert from "../components/Notifications/NotificationAlert";

const NotifierContext = React.createContext();

const notifierShape = PropTypes.shape({
  showError: PropTypes.func,
  showWarning: PropTypes.func,
  showSuccess: PropTypes.func,
});

const TIME_VISIBLE = 4000;

function NotifierContextProvider({ children, testNotifier }) {
  const notificationsRef = useRef([]);
  const [notifications, setNotifications] = useState([]);

  const removeNotification = (notification) => {
    notificationsRef.current = notificationsRef.current.filter(
      (n) => n !== notification,
    );
    setNotifications([...notificationsRef.current]);
  };

  const showNotification = (type, message, dismissAfter) => {
    const notification = {
      id: uuid(),
      type,
      message,
    };

    notificationsRef.current = [notification, ...notificationsRef.current];
    setNotifications([...notificationsRef.current]);
    setTimeout(() => removeNotification(notification), dismissAfter);
  };

  const context = useMemo(() => {
    let notifier;
    if (testNotifier) {
      notifier = testNotifier;
    } else {
      notifier = {
        showError: (message, dismissAfter = TIME_VISIBLE) =>
          showNotification("error", message, dismissAfter),
        showWarning: (message, dismissAfter = TIME_VISIBLE) =>
          showNotification("warning", message, dismissAfter),
        showSuccess: (message, dismissAfter = TIME_VISIBLE) =>
          showNotification("success", message, dismissAfter),
      };
    }
    global.notifier = notifier;
    return { notifier };
  }, []);
  return (
    <NotifierContext.Provider value={context}>
      <NotificationAlerts>
        {notifications.map((notification) => (
          <NotificationAlert
            key={notification.id}
            type={notification.type}
            message={notification.message}
            onClose={() => removeNotification(notification)}
          />
        ))}
      </NotificationAlerts>
      {children}
    </NotifierContext.Provider>
  );
}

NotifierContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  testNotifier: PropTypes.shape({}),
};

NotifierContext.defaultProps = {
  testNotifier: undefined,
};

const withNotifier = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <NotifierContext.Consumer>
        {(context) => <ChildComponent {...props} notifier={context.notifier} />}
      </NotifierContext.Consumer>
    );
  }
  ConnectedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

const useNotifier = () => React.useContext(NotifierContext).notifier;

export { useNotifier, notifierShape, NotifierContextProvider, withNotifier };
