import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { withUser, useNotifier, withConfiguration } from "../../context";
import { userShape } from "../../shapes";
import { htmlButton } from "../../utils";
import { useApi } from "../../hooks";
import Loader from "../Loader";

export function NavigationDemoDataBanner({ user, configuration }) {
  const notifier = useNotifier();
  const intl = useIntl();
  const api = useApi();
  const {
    mutate: deleteDemoData,
    isSuccess,
    isLoading,
  } = api.delete.useMutation("/users/current/demo_data");

  const deactivateDemoData = async () => {
    deleteDemoData(null, {
      onSuccess: () => {
        notifier.showSuccess(
          intl.formatMessage({
            id: "navigation.demo_data_deactivation_success_notification",
          }),
        );
        setTimeout(() => {
          window.location.href = configuration.baseUrl;
        }, 2000);
      },
    });
  };

  return user?.demoDataActive && !isSuccess ? (
    <div
      id="demo-data-banner"
      className="cyan-stripe navigation-banner-container"
    >
      <div className="navigation-banner">
        <div>
          <span className="lcm-iot-icon-info" />
          <Loader loading={isLoading} />
          <p id="banner-message">
            {!isLoading &&
              intl.formatMessage(
                { id: "root.demo_data_in_use" },
                { a: htmlButton(() => deactivateDemoData()) },
              )}
          </p>
        </div>
      </div>
    </div>
  ) : null;
}

NavigationDemoDataBanner.propTypes = {
  user: userShape,
  configuration: PropTypes.shape({ baseUrl: PropTypes.string }).isRequired,
};

NavigationDemoDataBanner.defaultProps = {
  user: undefined,
};

export default withConfiguration(withUser(NavigationDemoDataBanner));
