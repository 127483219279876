export function isEmptyObject(obj) {
  // istanbul ignore next
  return obj && typeof obj === "object" && Object.keys(obj).length === 0;
}

export function isNotEmptyObject(obj) {
  return !isEmptyObject(obj);
}

export function resolveAppName(currentPathString) {
  if (typeof currentPathString === "string") {
    const appName = currentPathString.split("/")[2];

    if (appName !== "") {
      return appName;
    }
  }

  return undefined;
}

export function checkAppName(currentPathString, appNameToCheck) {
  const appName = resolveAppName(currentPathString);
  return appNameToCheck === appName && typeof appName === "string";
}

/* eslint-disable prefer-rest-params */
/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
export function checkNested(obj /* , level1, level2, ... levelN */) {
  const args = Array.prototype.slice.call(arguments, 1);
  for (let i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}
