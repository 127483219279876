/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import PropTypes from "prop-types";
import React from "react";
import { isNotEmpty, handleFormikCheckboxChange } from "../../utils";
import InputAlert from "./InputAlert";
import { AlertType } from "../../constants";

function Checkbox(props) {
  const { input, label, labelNode, meta, disabled, id, additionalClassName } =
    props;

  // REDUX FORM
  if (input) {
    const { touched, error } = meta;
    const defaultClassName =
      touched && isNotEmpty(error) ? "form-group has-error" : "form-group";
    const className = additionalClassName
      ? `${defaultClassName} ${additionalClassName}`
      : defaultClassName;
    const errorMessage = touched && isNotEmpty(error) ? error : null;
    return (
      <div className={className}>
        <input {...input} id={id} type="checkbox" disabled={disabled} />
        <label htmlFor={id}>
          {label}
          {labelNode}
        </label>
        <InputAlert message={errorMessage} type={AlertType.ERROR} />
      </div>
    );
  }

  // FORMIK FORM
  const { name, isSubmitting, values, errors, status, touched } = props;
  const dirty = status === "submitted" || touched[name];
  const defaultClassName =
    dirty && isNotEmpty(errors[name]) ? "form-group has-error" : "form-group";
  const className = additionalClassName
    ? `${defaultClassName} ${additionalClassName}`
    : defaultClassName;
  const errorMessage = dirty && isNotEmpty(errors[name]) ? errors[name] : null;
  const value = values[name] === true ? true : false; // eslint-disable-line no-unneeded-ternary
  return (
    <div
      data-testid={`checkbox-${name}`}
      className={className}
      onClick={() => handleFormikCheckboxChange(props)}
    >
      <input
        id={id}
        name={name}
        checked={value}
        // will never be called but makes react happy
        onChange={
          /* istanbul ignore next */ () => handleFormikCheckboxChange(props)
        }
        type="checkbox"
        disabled={isSubmitting}
      />
      <label>
        {label}
        {labelNode}
      </label>
      <InputAlert message={errorMessage} type={AlertType.ERROR} />
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.string,
  labelNode: PropTypes.node,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  name: PropTypes.string,
  status: PropTypes.string,
  isSubmitting: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types */
  touched: PropTypes.object,
  /* eslint-disable react/forbid-prop-types */
  errors: PropTypes.object,
  /* eslint-disable react/forbid-prop-types */
  values: PropTypes.object,
  additionalClassName: PropTypes.string,
};

export default Checkbox;
