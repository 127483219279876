/* eslint-disable no-console */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { intlShape } from "../shapes";
import { getErrorTranslation, isSentry } from "../api";
import { logShape, withLog } from "./LogContext";

const BackendContext = React.createContext();

const backendShape = PropTypes.shape({
  get: PropTypes.func,
  getAll: PropTypes.func,
  post: PropTypes.func,
  patch: PropTypes.func,
  delete: PropTypes.func,
  head: PropTypes.func,
  upload: PropTypes.func,
  translateError: PropTypes.func,
});

function InternalBackendContextProvider({
  backendApiClient,
  children,
  intl,
  log,
  testBackend,
}) {
  const context = useMemo(() => {
    if (testBackend) {
      const backend = testBackend;
      return { backend };
    }
    return {
      backend: {
        get: (url, parameters, cache = true, responseType = null) =>
          backendApiClient(cache, true, responseType).get(url, parameters),
        getAll: (url, parameters, cache) =>
          backendApiClient(cache, true).getAll(url, parameters),
        post: (
          url,
          parametersOrObject,
          objectOrNull = null,
          redirectToSessionExpiredOnAuthError = true,
        ) =>
          backendApiClient(redirectToSessionExpiredOnAuthError, true).post(
            url,
            parametersOrObject,
            objectOrNull,
          ),
        patch: (url, parametersOrObject, objectOrNull = null) =>
          backendApiClient(true, true).patch(
            url,
            parametersOrObject,
            objectOrNull,
          ),
        delete: (url, parametersOrObject, objectOrNull = null) =>
          backendApiClient(true, true).delete(
            url,
            parametersOrObject,
            objectOrNull,
          ),
        head: (url, parameters) =>
          backendApiClient(true, true).head(url, parameters),
        upload: (url, parameters) =>
          backendApiClient(true, true).upload(url, parameters),
        translateError: (error) => {
          console.log(error);
          // all other errors should be handled!
          if (isSentry(error)) {
            log?.error("BackendContext", error);
          }
          return intl.formatMessage({ id: getErrorTranslation(error) });
        },
      },
    };
  }, []);
  return (
    <BackendContext.Provider value={context}>
      {children}
    </BackendContext.Provider>
  );
}

InternalBackendContextProvider.propTypes = {
  backendApiClient: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  intl: intlShape.isRequired,
  testBackend: PropTypes.shape({}),
  log: logShape,
};

const BackendContextProvider = injectIntl(
  withLog(InternalBackendContextProvider),
);

const withBackend = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <BackendContext.Consumer>
        {(context) => <ChildComponent {...props} backend={context.backend} />}
      </BackendContext.Consumer>
    );
  }
  ConnectedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

export { BackendContextProvider, BackendContext, backendShape, withBackend };
