import React, { useState } from "react";
import {
  withApi,
  withBrowser,
  withIntl,
  withNotifier,
  apiShape,
  intlShape,
  notifierShape,
  browserShape,
  ActionBar,
  Loader,
  Column,
  Row,
  withFlipper,
  EditActionButton,
} from "lcm-iot-commons";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import MultiFactorAuthenticationRecoveryCodes from "./MultiFactorAuthenticationRecoveryCodes";

export function MultiFactorAuthenticationDetails({
  browser,
  api,
  intl,
  notifier,
  flipper,
}) {
  if (flipper.manageMfa) {
    const [status, setStatus] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const loadUser = async () => {
      try {
        const user = await api.get("/users/current/", {}, false);
        setStatus(user.otp_module);
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
      setIsLoading(false);
    };

    React.useEffect(() => {
      loadUser();
    }, []);

    const mfaEnabled = status === "enabled";

    const icon = mfaEnabled ? (
      <i className="icon3-eh-radio-button-checked mfa-icon mfa-enabled-icon" />
    ) : (
      <i className="icon-eh-namur-failure mfa-icon mfa-disabled-icon" />
    );
    const message = status ? (
      <div className="mfa-message">
        {icon}
        {intl.formatMessage({ id: `mfa.${status}_header` })}
      </div>
    ) : null;

    const disabledTip =
      status && status === "disabled" ? (
        <Row>
          <Column id="mfa-tip" className="col-md-12 mfa-tip">
            {intl.formatMessage({ id: "mfa.tip" })}
          </Column>
        </Row>
      ) : null;

    const renderManageMultiFactorAuthentication = (
      <Row className="mfa-details-item">
        <Column className="mfa-row col-xs-8">{message}</Column>
        <Column className="mfa-config-button">
          <EditActionButton
            label={intl.formatMessage({ id: "mfa.config.change" })}
            id="edit-mfa"
            onClick={() => browser.redirectTo("/multi_factor_authentication")}
          />
        </Column>
      </Row>
    );

    const renderRecoveryCodes = mfaEnabled ? (
      <MultiFactorAuthenticationRecoveryCodes id="mfa-details-recovery-codes" />
    ) : null;

    return (
      <Loader loading={isLoading}>
        <div className="mfa-details">
          <ActionBar id="mfa-header">
            <h2 id="change-current-password-header">
              <FormattedMessage id="mfa.header" />
            </h2>
          </ActionBar>
          {disabledTip}
          {renderManageMultiFactorAuthentication}
          {renderRecoveryCodes}
        </div>
      </Loader>
    );
  }
  return null;
}

MultiFactorAuthenticationDetails.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  flipper: PropTypes.shape({
    manageMfa: PropTypes.bool,
  }).isRequired,
};

export default withBrowser(
  withFlipper(
    withNotifier(withApi(withIntl(MultiFactorAuthenticationDetails))),
  ),
);
