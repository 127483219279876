import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { isEmpty } from "lodash-es";
import { injectIntl } from "react-intl";

import {
  Form,
  SubmitButton,
  Button,
  TextInput,
  ButtonGroup,
  intlShape,
} from "lcm-iot-commons";

export function SignInOtpForm({ intl, onSubmit, onCancel, initialValues }) {
  const validateForm = (values) => {
    const errors = {};
    if (isEmpty(values.otpCodeToken)) {
      errors.otpCodeToken = intl.formatMessage({
        id: "validation.otp.mandatory",
      });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <p>{intl.formatMessage({ id: "signin.otp.description_1" })}</p>
        <p className="mfa-login-no-space">
          {intl.formatMessage({ id: "signin.otp.description_2" })}
        </p>
        <p>{intl.formatMessage({ id: "signin.otp.description_3" })}</p>
        <TextInput
          {...formProps}
          id="otp-code-token"
          name="otpCodeToken"
          placeholder="123456"
          label={intl.formatMessage({ id: "label.otp" })}
          required
          autoFocus
        />
        <ButtonGroup>
          <SubmitButton
            id="otp-form-submit-button"
            text={intl.formatMessage({ id: "button.verify" })}
            fetching={isSubmitting}
            disabled={!formProps.dirty}
          />
          <Button
            id="otp-form-cancel-button"
            text={intl.formatMessage({ id: "button.cancel" })}
            onClick={onCancel}
          />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <Formik
      id="asset-form"
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validateForm}
      render={renderForm}
    />
  );
}

SignInOtpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  initialValues: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
};

export default injectIntl(SignInOtpForm);
