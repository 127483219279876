import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import { FormattedMessage, injectIntl } from "react-intl";
import { intlShape, userShape } from "../../shapes";
import PlanVariantAddon from "../PlanVariants/PlanVariantAddon";

import { withConfiguration, withUser } from "../../context";
import { addonVisibleForUserrole } from "../../utils";

export function UnavailablePlan(props) {
  const { planVariant, intl, configuration, user } = props;
  const panelClass = "panel panel-default panel-disabled";

  const features = planVariant.features.map((item) => (
    <li className="list-item-checked" key={uuid()}>
      {item}
    </li>
  ));
  const planVariantPrice = parseFloat(planVariant.price);

  const displayPrice =
    configuration.planPriceDisplay === "yearly"
      ? `${intl.formatNumber(planVariantPrice.toFixed(2), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} / ${intl.formatMessage({ id: "planvariant.per_user" })} / ${intl.formatMessage({ id: "global.year" })}`
      : `${intl.formatNumber((planVariantPrice / 12).toFixed(2), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} / ${intl.formatMessage({ id: "planvariant.per_user" })} / ${intl.formatMessage({ id: "global.month" })}`;
  const price =
    planVariant.type === "free" || planVariantPrice === 0
      ? intl.formatMessage({ id: "planvariant.free" })
      : `${planVariant.currency} ${displayPrice}`;

  const outcome = planVariant.addons
    ? planVariant.addons
        .filter(
          (addon) =>
            addon.type === "outcome" &&
            addonVisibleForUserrole(user.userRoles, addon.limited_for_roles),
        )
        .map((addon) => (
          <PlanVariantAddon
            disabled
            key={addon.id}
            planVariantAddon={addon}
            amount={0}
          />
        ))
    : null;

  const connectivity = planVariant.addons
    ? planVariant.addons
        .filter(
          (addon) =>
            addon.type === "connectivity" &&
            addonVisibleForUserrole(user.userRoles, addon.limited_for_roles),
        )
        .map((addon) => (
          <PlanVariantAddon
            disabled
            key={addon.id}
            planVariantAddon={addon}
            amount={0}
          />
        ))
    : null;

  const addons = planVariant.addons
    ? planVariant.addons
        .filter(
          (addon) =>
            (addon.type === "addon" || addon.type === "extension") &&
            addonVisibleForUserrole(user.userRoles, addon.limited_for_roles),
        )
        .map((addon) => (
          <PlanVariantAddon
            disabled
            key={addon.id}
            planVariantAddon={addon}
            amount={0}
          />
        ))
    : null;

  const shortDescription = planVariant.short_description ? (
    <div id="short-description" className="space-after short-description">
      {planVariant.short_description}
    </div>
  ) : null;

  return (
    <div id={`plan-variant-${planVariant.id}`} className={panelClass}>
      <div className="panel-heading">
        {planVariant.name}
        <div id="price" className="panel-heading panel-subtitle">
          {price}
        </div>
        {planVariant.type === "free" || planVariantPrice === 0 ? null : (
          <p className="panel-subtitle">
            {intl.formatMessage({ id: "planvariant.billed_annually" })}
          </p>
        )}
      </div>
      <div className="panel-body">
        {shortDescription}
        <ul id="plan-variant-features" className="list-checked">
          {features}
        </ul>
        <div>
          {outcome && outcome.length > 0 ? (
            <>
              <h4 id="planvariant-outcome">
                <FormattedMessage id="planvariant.outcome" />
              </h4>
              <div id="planvariant-outcome-addons">{outcome}</div>
            </>
          ) : null}
          {connectivity && connectivity.length > 0 ? (
            <>
              <h4 id="header-connectivity">
                <FormattedMessage id="planvariant.connectivity" />
              </h4>
              <div id="planvariant-connectivity-addons">{connectivity}</div>
            </>
          ) : null}
          {addons && addons.length > 0 ? (
            <>
              <h4 id="header-addons">
                <FormattedMessage id="planvariant.addons" />
              </h4>
              <div id="planvariant-standard-addons">{addons}</div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

UnavailablePlan.propTypes = {
  planVariant: PropTypes.shape({
    id: PropTypes.string,
    currency: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    type: PropTypes.string,
    sort: PropTypes.number,
    popular: PropTypes.bool,
    button: PropTypes.string,
    assets: PropTypes.number,
    storage: PropTypes.number,
    short_description: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    // eslint-disable-next-line react/forbid-prop-types
    addons: PropTypes.arrayOf(PropTypes.any),
  }),
  intl: intlShape.isRequired,
  configuration: PropTypes.shape({
    planPriceDisplay: PropTypes.string,
  }),
  user: userShape.isRequired,
};

export default injectIntl(withUser(withConfiguration(UnavailablePlan)));
