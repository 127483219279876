import PropTypes from "prop-types";

export const sessionShape = PropTypes.shape({
  authenticated: PropTypes.bool,
  captchaRequired: PropTypes.bool,
  tokenExpiresAt: PropTypes.number,
  sessionExpiresAt: PropTypes.number,
  sessionExpired: PropTypes.bool,
  sessionDeleted: PropTypes.bool,
  activeSubscription: PropTypes.bool,
  subscriptionType: PropTypes.string,
  refreshFailed: PropTypes.bool,
  startUrl: PropTypes.string,
  refreshSession: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  applicationSpecifics: PropTypes.object,
  reseller: PropTypes.shape({
    customer: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
});
