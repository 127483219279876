import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { cloneDeep } from "lodash-es";

import { intlShape, relationShape } from "../../shapes";

import {
  apiShape,
  browserShape,
  notifierShape,
  withApi,
  withBrowser,
  withNotifier,
} from "../../context";

import { withIntl } from "../../wrappers";

import {
  ActionBar,
  ActionBarButtons,
  SortActionDropDownButton,
  CreateActionButton,
} from "../ActionBar";

import SearchDocuments from "../Search/SearchDocuments";

import Heading from "../Heading";
import {
  sortBy,
  sortByNestedAttribute,
  mapDocumentsDefaults,
} from "../../utils";
import Loader from "../Loader";
import ProductDocumentItem from "./ProductDocumentItem";
import { NotFoundError } from "../../api/errors";

export function ProductDocuments({
  api,
  browser,
  notifier,
  intl,
  object,
  withCreate,
  canCreate,
  target,
}) {
  const [productDocuments, setProductDocuments] = useState();
  const [initialDocuments, setInitialDocuments] = useState();
  const [fetching, setFetching] = useState(true);
  const [sortProperties, setSortProperties] = useState({
    sortOption: undefined,
    sortCounter: 0,
  });

  const loadProductDocuments = async () => {
    try {
      const filters = {
        include: "attachments, categories.parent, tenant",
        order_by: "-id",
      };
      let responseDocuments;
      if (object.product_variant) {
        responseDocuments = await api.getAll(
          `/product_variants/${object.product_variant.id}/documents`,
          filters,
        );
      }
      // no product variant or no product variant documents, load product documents
      if (!responseDocuments || !responseDocuments.documents.length) {
        responseDocuments = await api.getAll(
          `/products/${object.productId}/documents`,
          filters,
        );
      }
      const mappedDocuments = mapDocumentsDefaults(
        responseDocuments.documents,
        intl,
      );
      setProductDocuments(mappedDocuments);
      setInitialDocuments(mappedDocuments);
    } catch (error) {
      if (error instanceof NotFoundError) {
        setProductDocuments([]);
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    loadProductDocuments();
  }, [object.id]);

  const sortProductDocuments = (option, counter, documents) => {
    const sortOrder = counter === 2 ? "ASC" : "DESC";
    const sortedDocuments = documents;
    if (sortedDocuments?.length > 1) {
      switch (option) {
        case "name":
          return sortBy(sortedDocuments, "name", sortOrder);
        case "category":
          return sortByNestedAttribute(
            sortedDocuments,
            ["defaultCategory", "name"],
            sortOrder,
          );
        case "date":
          return sortByNestedAttribute(
            sortedDocuments,
            ["defaultAttachment", "content_date"],
            sortOrder,
          );
        default:
          return sortBy(sortedDocuments, "id", sortOrder);
      }
    }
    return sortedDocuments;
  };

  const handleOnSearch = (documents) => {
    setProductDocuments(
      sortProductDocuments(
        sortProperties.sortOption,
        sortProperties.sortCounter,
        documents,
      ),
    );
  };

  const handleSortProductDocuments = (option, counter) => {
    if (productDocuments?.length > 1) {
      const sortedDocuments = sortProductDocuments(
        option,
        counter,
        productDocuments,
      );
      setProductDocuments(sortedDocuments);
      setSortProperties({ sortOption: option, sortCounter: counter });
    }
  };

  const renderProductDocumentList =
    productDocuments?.length > 0 ? (
      <ul id="product-document-list" className="list">
        {productDocuments.map((document) => (
          <div className="details-item" key={document.id}>
            <ProductDocumentItem
              document={document}
              withCreate={withCreate}
              canCreate={canCreate}
              productId={object.id}
            />
          </div>
        ))}
      </ul>
    ) : (
      <div id="no-product-documents-found" className="details-item">
        <FormattedMessage id="document_list.no_documents_found_hint" />
      </div>
    );

  const renderProductDocumentSearch =
    initialDocuments?.length > 0 ? (
      <SearchDocuments
        documentList={cloneDeep(initialDocuments)}
        onSearch={(documents) => handleOnSearch(documents)}
      />
    ) : null;

  const onProductDocumentCreate = () => {
    if (target) {
      browser.navigateTo(target);
    } else {
      browser.navigateTo("/404");
    }
  };

  const renderDocumentCreateButton = withCreate ? (
    <CreateActionButton
      id="create-product-document"
      disabled={!canCreate}
      onClick={() => onProductDocumentCreate()}
    />
  ) : null;

  return (
    <Loader loading={fetching}>
      <div
        id="product-documents-list"
        className="document-list product-documents-list"
      >
        <ActionBar>
          <Heading
            title={intl.formatMessage({ id: "document_list.products.header" })}
            level={2}
          />
          <ActionBarButtons>
            {renderDocumentCreateButton}
            <SortActionDropDownButton
              id="sort-dropdown-button"
              handleSortClick={(option, counter) =>
                handleSortProductDocuments(option, counter)
              }
              sortOptions={["name", "category", "date"]}
            />
          </ActionBarButtons>
        </ActionBar>
        {renderProductDocumentSearch}
        {fetching ? null : renderProductDocumentList}
      </div>
    </Loader>
  );
}

ProductDocuments.propTypes = {
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  object: PropTypes.shape({
    id: PropTypes.number,
    production_date: PropTypes.string,
    productId: PropTypes.number,
    product_variant: relationShape,
  }).isRequired,
  withCreate: PropTypes.bool,
  canCreate: PropTypes.bool,
  target: PropTypes.string,
};

ProductDocuments.defaultProps = {
  withCreate: false,
  canCreate: false,
  target: undefined,
};

export default withApi(withBrowser(withNotifier(withIntl(ProductDocuments))));
