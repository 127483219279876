import { FormattedMessage, injectIntl } from "react-intl";
import React from "react";
import ContextualHelp from "../Contextuals/ContextualHelp";
import { intlShape } from "../../shapes";

export function AssetGPSSettingsHelp({ intl }) {
  return (
    <ContextualHelp title={intl.formatMessage({ id: "label.gps_settings" })}>
      <p className="space-after">
        <FormattedMessage id="gps_help.description" />
      </p>
      <p>
        <strong>
          <FormattedMessage id="gps_help_status.title" />
        </strong>
        :&nbsp;
        <FormattedMessage id="gps_help_status.description" />
      </p>
      <ul>
        <li>
          <FormattedMessage id="gps_help_status_description.active" />
        </li>
        <li>
          <FormattedMessage id="gps_help_status_description.inactive" />
        </li>
      </ul>
      <p>
        <strong>
          <FormattedMessage id="label.gps_interval" />
        </strong>
        :&nbsp;
        <FormattedMessage id="gps_help_interval.description" />
        <br />
        <FormattedMessage id="gps_help_interval.description2" />
      </p>
      <ul>
        <li>
          <FormattedMessage id="gps_help_interval.condition1" />
        </li>
        <li>
          <FormattedMessage id="gps_help_interval.condition2" />
        </li>
        <li>
          <FormattedMessage id="gps_help_interval.condition3" />
        </li>
      </ul>
    </ContextualHelp>
  );
}

AssetGPSSettingsHelp.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AssetGPSSettingsHelp);
