import React from "react";
import SlickSlider from "react-slick";

import { intlShape, url, withIntl } from "lcm-iot-commons";
import Tile from "lcm-iot-commons/client/lib/components/Tiles/Tile";

export function SignUpTileSlider({ intl }) {
  const headerHrefs = [
    "https://www.nouryon.com/",
    "http://sucroliq.mx",
    "https://www.salzgitter-ag.com",
  ];
  const headerImgs = [
    url("/images/signup/nouryon-logo-color.png"),
    url("/images/signup/Sucroliq_Logo_Color.png"),
    url("/images/signup/salzgitter-logo.png"),
  ];

  const bodies = [
    intl.formatMessage({ id: "signup.signup.nouryon_body" }),
    intl.formatMessage({ id: "signup.signup.sucroliq_body" }),
    intl.formatMessage({ id: "signup.signup.salzgitter_body" }),
  ];

  const bottoms = [
    intl.formatMessage({ id: "signup.signup.nouryon_bottom" }),
    intl.formatMessage({ id: "signup.signup.sucroliq_bottom" }),
    intl.formatMessage({ id: "signup.signup.salzgitter_bottom" }),
  ];

  return (
    <SlickSlider arrows dots>
      {headerHrefs.map((header, i) => (
        <Tile
          key={headerHrefs[i]}
          id="signup-tile"
          header={
            <a href={headerHrefs[i]} target="_blank" rel="noopener noreferrer">
              <img src={headerImgs[i]} alt="" />
            </a>
          }
          footer={<p>{bottoms[i]}</p>}
        >
          <h3>{bodies[i]}</h3>
        </Tile>
      ))}
    </SlickSlider>
  );
}

SignUpTileSlider.propTypes = {
  intl: intlShape.isRequired,
};

withIntl(SignUpTileSlider);
