import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

import { htmlLink, url } from "../../utils";
import Heading from "../Heading";
import { errorTypes } from "./ErrorTypes";
import ErrorNavigationBar from "./ErrorNavigationBar";
import Template from "../../layouts/Template";
import Icon from "../Icon";
import { sentryUnmask } from "../../utils/sentryUtils";
import { Container } from "../Grid";

// When React crashes, or hub crashes (ex: React-router is unavailable, or config is unavailable)
export function ErrorPage({ details, errorType, header, isFullPage = false }) {
  const pictureUrl =
    {
      [errorTypes.not_found]: "/images/failure_screens_plug.png",
      [errorTypes.forbidden]: "/images/failure_screens_lock.png",
      [errorTypes.unknown]: "/images/failure_screens_repair.png",
    }[errorType] || "/images/failure_screens_plug.png";

  const backButton = (
    <div style={{ textAlign: "left" }}>
      <button
        className="back-button"
        onClick={() => {
          // do not use react-router here because it might be unavailable
          /* eslint-disable-next-line no-restricted-globals */
          window.history.back();
        }}
        type="button"
      >
        <Icon name="icon-eh-arrow-left" />
        <span>
          <FormattedMessage id="label.go_back" />
        </span>
      </button>
    </div>
  );

  const errorBanner = (
    <Container>
      <div className={sentryUnmask("centered-div")}>
        {backButton}
        <Heading id="error-page-header" title={header} />
        <div className="space-after">
          <p id="error-page-details" className="error-details">
            {details}
          </p>
        </div>
        <p>
          <FormattedMessage
            id="error_page.message"
            values={{
              home: htmlLink({ href: "/" }),
              contact: htmlLink({
                href: "https://netilion.endress.com/support/contact",
              }),
            }}
          />
        </p>
        <div className="error-picture-div sentry-unblock">
          <img
            className="img-responsive error-picture"
            src={url(pictureUrl)}
            alt={header}
          />
        </div>
      </div>
    </Container>
  );

  return isFullPage ? (
    <>
      <ErrorNavigationBar />
      <div className="navbar-separator" />
      <Template authenticated={false}>{errorBanner}</Template>
    </>
  ) : (
    errorBanner
  );
}

ErrorPage.propTypes = {
  details: PropTypes.string.isRequired,
  errorType: PropTypes.number,
  header: PropTypes.string.isRequired,
  isFullPage: PropTypes.bool, // when app crashes and cannot render navbar
};

export default ErrorPage;
