import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

export function DataExportsDescription({ dataExports, exportType }) {
  const intl = useIntl();
  const descriptions = [];
  if (exportType === "complete") {
    const exportCompleteDescription = dataExports.some(
      (exp) => exp.type === "complete",
    )
      ? intl.formatMessage({
          id: `export_data.complete.explanation.${dataExports.find((a) => a.type === "complete").status}`,
        })
      : intl.formatMessage({ id: "export_data.complete.explanation" });
    descriptions.push(exportCompleteDescription);
  }

  if (
    dataExports.some((exp) => exp.type === "batch_values") ||
    dataExports?.some((exp) => exp.type === "instrumentation_values")
  ) {
    descriptions.push(
      intl.formatMessage({
        id: "export_data.instrumentation_batch_values.common",
      }),
    );
  }

  return (
    <div id="descriptions" className="space-before space-after">
      {descriptions.map((msg) => (
        <p key={`desc-msg-${msg}`}>{msg}</p>
      ))}
    </div>
  );
}

DataExportsDescription.propTypes = {
  exportType: PropTypes.string.isRequired,
  dataExports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      finishedAt: PropTypes.string,
      availableUntil: PropTypes.string,
      asset: PropTypes.shape({
        serial_number: PropTypes.string,
      }),
      instrumentation: PropTypes.shape({
        tag: PropTypes.string,
      }),
      batch: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
};
