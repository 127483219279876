import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { NavDropdown, MenuItem } from "react-bootstrap";
import { displayUserName } from "../../utils";
import CONFIGURATION from "../../configuration";
import NavigationDemoData from "./NavigationDemoData";
import {
  withUser,
  withSession,
  withBrowser,
  withTracking,
  browserShape,
  trackingShape,
  withConfiguration,
} from "../../context";
import { userShape, locationShape } from "../../shapes";

export function NavigationUserMenu({
  configuration,
  user,
  showDemoData,
  showSupportItem,
  location,
  browser,
  session,
  children,
  tracking,
}) {
  const handleLogout = (e) => {
    e.preventDefault();
    tracking.reset();
    session.deleteSession();
  };

  return (
    <NavDropdown
      id="user-navigation-dropdown"
      title={<span>{displayUserName(user, true)}</span>}
      pullRight
    >
      {children}
      <MenuItem href="/app/id/profile">
        <FormattedMessage id="navigation.user_profile" />
      </MenuItem>
      {user && showDemoData && <NavigationDemoData />}
      <MenuItem href="/app/id/administration">
        <FormattedMessage id="navigation.administration" />
      </MenuItem>
      {showSupportItem && (
        <MenuItem
          id="support-menu-item"
          active={
            location && location.pathname.indexOf("/support/tickets") !== -1
          }
          onClick={() => browser.navigateTo("/support/tickets")}
        >
          <FormattedMessage id="navigation.support" />
        </MenuItem>
      )}
      {configuration.appCannyBoardToken && (
        <MenuItem
          id="feedback-menu-item"
          active={location && location.pathname.indexOf("/feedback") === 0}
          onClick={() => browser.navigateTo("/feedback")}
        >
          <FormattedMessage id="navigation.feedback" />
        </MenuItem>
      )}
      <MenuItem href={CONFIGURATION.HELP_URL} target="_blank">
        <FormattedMessage id="navigation.help" />
      </MenuItem>
      <MenuItem href={CONFIGURATION.LEGAL_URL} target="_blank">
        <FormattedMessage id="navigation.legal" />
      </MenuItem>
      <MenuItem onClick={handleLogout} id="user-navigation-dropdown-logout">
        <FormattedMessage id="navigation.logout" />
      </MenuItem>
    </NavDropdown>
  );
}

NavigationUserMenu.propTypes = {
  user: userShape,
  browser: browserShape.isRequired,
  session: PropTypes.shape({ deleteSession: PropTypes.func }),
  location: locationShape,
  showDemoData: PropTypes.bool,
  showSupportItem: PropTypes.bool,
  children: PropTypes.node,
  tracking: trackingShape.isRequired,
  configuration: PropTypes.shape({ appCannyBoardToken: PropTypes.string })
    .isRequired,
};

export default withTracking(
  withSession(withBrowser(withUser(withConfiguration(NavigationUserMenu)))),
);
