import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom-v5-compat";

import {
  ActionBar,
  ActionBarButtons,
  CreateActionButton,
  Container,
  List,
  Loader,
  Row,
  Column,
  withUser,
  userShape,
  RiskOfMaintainability,
  useApi,
  SearchBar,
} from "lcm-iot-commons";
import { useIntl } from "react-intl";

import ProductItem from "./ProductItem";
import NoProducts from "./NoProducts";

export function Products({ user }) {
  const api = useApi();
  const intl = useIntl();
  const { riskOfMaintainability } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const productName = decodeURIComponent(searchParams.get("productName") || "");
  const searchTerm =
    productName !== "*" && productName.length
      ? `*${productName}*`
      : productName;

  const riskOfMaintainabilityValue = riskOfMaintainability?.toLowerCase();
  const validRiskOfMaintainability =
    RiskOfMaintainability.indexOf(riskOfMaintainabilityValue) >= 0;

  const { data: tenantsResponse, isLoading: isLoadingTenants } =
    api.getAll.useQuery("/tenants", { admin_user_id: user.id });
  const hasNoTenants = tenantsResponse?.tenants.length === 0;
  const tenantIds = tenantsResponse?.tenants.map((t) => t.id);
  const showTenant = tenantIds?.length > 1;

  const parameters = {
    tenant_id: tenantIds,
    include: ["status", "tenant", "manufacturer"],
    ...(searchTerm !== "" && { name: searchTerm.trim() }),
    ...(validRiskOfMaintainability && {
      risk_of_maintainability: riskOfMaintainability,
    }),
  };

  const {
    data,
    isInitialLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = api.get.useInfiniteQuery("/products", parameters, {
    enabled: tenantIds?.length > 0,
    select: (d) => ({
      products: d?.pages.map((page) => page.products).flat(),
      totalCount: d?.pages[0]?.pagination?.total_count,
    }),
  });

  const removeProduct = () => refetch();

  const onSearch = (term) => {
    const q = encodeURIComponent(term);
    if (q.length) searchParams.set("productName", q);
    else searchParams.delete("productName");
    setSearchParams(searchParams);
  };

  const isLoading = isInitialLoading || isFetchingNextPage || isLoadingTenants;
  const products = data?.products;
  const totalCount = data?.totalCount;

  const badge = (
    <span id="products-count" className="badge">
      {!isLoading ? (
        intl.formatNumber(totalCount)
      ) : (
        <span className="loading">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      )}
    </span>
  );

  const isNoProductsVisible =
    (!isLoading && hasNoTenants) || (products && !products.length);

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <h1 id="products-header">
              {intl.formatMessage({ id: "products.header" })}
              {validRiskOfMaintainability &&
                intl.formatMessage(
                  { id: "product.header_rom" },
                  {
                    risk_of_maintainability: intl.formatMessage({
                      id: `insights.risk_of_maintainability.${riskOfMaintainabilityValue}.adjective`,
                    }),
                  },
                )}
              {!hasNoTenants ? badge : null}
            </h1>
            <ActionBarButtons>
              <CreateActionButton
                id="create-product-button"
                target="/products/create"
              />
            </ActionBarButtons>
          </ActionBar>
          <SearchBar
            timeout={200}
            onSearch={onSearch}
            defaultValue={productName}
            filterWildcard={false}
            placeholder={intl.formatMessage({
              id: "products.search_placeholder",
            })}
          />
          {products && !!products.length && (
            <List id="products-list">
              <InfiniteScroll
                data-testid="infinite-scroll"
                initialLoad={false}
                loadMore={fetchNextPage}
                hasMore={hasNextPage && !isFetchingNextPage}
              >
                {products.map((product) => (
                  <ProductItem
                    key={product.id}
                    product={product}
                    removeProduct={removeProduct}
                    showTenant={showTenant}
                  />
                ))}
              </InfiniteScroll>
            </List>
          )}
          {isNoProductsVisible && <NoProducts />}
          <Loader loading={isFetchingNextPage || isLoading} />
        </Column>
      </Row>
    </Container>
  );
}

Products.propTypes = {
  user: userShape,
};

Products.defaultProps = {
  user: undefined,
};

export default withUser(Products);
