import { isNil } from "lodash-es";

class ApiClientError extends Error {
  constructor(name, status, message, request, response, cause) {
    super(message);

    /* istanbul ignore next */
    this.name = name;
    this.status = status;
    this.request = request;
    this.response = response;
    this.cause = cause;

    /* istanbul ignore next */
    if (this.response && this.response.data) {
      this.errors = this.response.data.errors;
    }
  }

  contains = (type, attribute = null) => {
    if (attribute) {
      return (
        !isNil(this.errors) &&
        !isNil(
          this.errors.find(
            (error) => error.type === type && error.attribute === attribute,
          ),
        )
      );
    }
    return (
      !isNil(this.errors) &&
      !isNil(this.errors.find((error) => error.type === type))
    );
  };

  extractError = (type) => this.errors.find((error) => error.type === type);
}

export default ApiClientError;
