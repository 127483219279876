import PropTypes from "prop-types";
import React from "react";

function Button({ id, text, disabled, fetching, mobileIcon, onClick, hero }) {
  const spinner = fetching ? <i className="btn-spinner" /> : null;
  const icon = mobileIcon && !fetching ? <i className={mobileIcon} /> : null;
  let className = "btn btn-default";
  if (hero) {
    className = mobileIcon
      ? "btn btn-primary btn-mobile-icon"
      : "btn btn-primary";
  } else {
    className = mobileIcon
      ? "btn btn-default btn-mobile-icon"
      : "btn btn-default";
  }

  const buttonRef = React.useRef();

  const hanldeOnClick = () => {
    onClick();
    buttonRef.current.blur();
  };

  return (
    <button
      id={id}
      ref={buttonRef}
      name="submit"
      onClick={() => hanldeOnClick()}
      className={className}
      disabled={disabled || fetching}
      type="button"
    >
      <span className="btn-icon">{icon}</span>
      <span className="btn-text">{text}</span>
      {spinner}
    </button>
  );
}

Button.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  fetching: PropTypes.bool,
  hero: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  mobileIcon: PropTypes.string,
};

export default Button;
