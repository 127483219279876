import PropTypes from "prop-types";

export const fieldGatewayShape = PropTypes.shape({
  id: PropTypes.number,
  ipAddress: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  statusName: PropTypes.string,
  typeName: PropTypes.string,
  port: PropTypes.number,
  hartAddress: PropTypes.string,
});
export const fieldGatewaysShape = PropTypes.arrayOf(fieldGatewayShape);
