import { locale } from "./browserUtils";
import { sortBy } from "./langUtils";

export function findAttachmentWithLanguage(attachments, language) {
  let attachmentWithLanguage = null;
  const languageWithoutRegion = language?.split("-")[0];

  // eslint-disable-next-line no-unused-expressions
  attachments?.forEach((attachment) => {
    if (
      attachmentWithLanguage === null &&
      attachment.languages &&
      attachment.languages.includes(languageWithoutRegion)
    ) {
      attachmentWithLanguage = attachment;
    }
  });

  return attachmentWithLanguage;
}

export function selectAttachment(attachments) {
  let attachment = null;
  attachment = findAttachmentWithLanguage(attachments, locale());
  // Fallback to English if the Browser Language is not available
  if (!attachment) {
    attachment = findAttachmentWithLanguage(attachments, "en");
  }

  // Fallback to first attachment if English is not available
  if (!attachment) {
    attachment = attachments[0];
  }

  return attachment;
}

export const mapDocumentsDefaults = (documents) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  documents.map((document) => ({
    ...document,
    defaultAttachment: selectAttachment(document.attachments),
    defaultCategory: document.categories?.[0] ?? {},
  }));

export function sortAttachmentsByLanguage(objects) {
  const browserLocale = locale().split("-")[0];
  const defaultOrder = [
    "en",
    "ar",
    "bg",
    "bs",
    "cs",
    "da",
    "de",
    "el",
    "es",
    "et",
    "fi",
    "fr",
    "hr",
    "hu",
    "id",
    "it",
    "ja",
    "ko",
    "lt",
    "lv",
    "ms",
    "nl",
    "no",
    "pl",
    "pt",
    "ro",
    "ru",
    "sk",
    "sl",
    "sr",
    "sv",
    "th",
    "tr",
    "vi",
    "yy",
    "zh",
  ];
  const topLanguages = [
    browserLocale,
    ...defaultOrder.filter((lang) => lang !== browserLocale),
  ];

  const languageMap = {};
  topLanguages.forEach((lang, index) => {
    languageMap[lang] = index;
  });

  objects?.sort((a, b) => {
    const langA = a.languages ? a.languages[0] : undefined;
    const langB = b.languages ? b.languages[0] : undefined;
    const orderA =
      languageMap[langA] !== undefined ? languageMap[langA] : Infinity;
    const orderB =
      languageMap[langB] !== undefined ? languageMap[langB] : Infinity;
    return orderA - orderB;
  });

  return objects;
}

export function addSortedSuccessorCategories(
  allDocumentCategories,
  documents,
  noCategoryLabel,
) {
  const noCategory = {
    id: "null",
    code: "no_category",
    name: noCategoryLabel,
  };

  const categories = sortBy(
    documents.reduce((acc, document) => {
      if (document.categories.length === 0) {
        const exists = acc.find((c) => c.id === noCategory.id);
        if (!exists) {
          acc.push(noCategory);
        }
      }

      document.categories.forEach((cat) => {
        const exists = acc.find((c) => c.id === cat.id);
        if (!exists) {
          let parentId = cat.parent && cat.parent.id;
          while (parentId !== undefined) {
            const parentCategory = allDocumentCategories.find(
              // eslint-disable-next-line no-loop-func
              (c) => c.id === parentId,
            );
            const parentExists = acc.find((c) => c.id === parentCategory.id);
            if (parentExists) {
              break;
            }
            acc.push(parentCategory);
            // eslint-disable-next-line prefer-destructuring
            parentId = parentCategory.parent?.id;
          }
          acc.push(cat);
        }
      });
      return acc;
    }, []),
    "name",
  );
  const categoriesFactorySort = [
    "manual",
    "certificate",
    "report",
    "technical_characteristics",
    "activity_specific",
    "raw_data",
    "software",
    "other",
    "identification",
    "documentation",
    "contract_documents",
    "eclass",
    "picture",
  ];
  return categories.sort((a, b) => {
    let positionA = categoriesFactorySort.indexOf(a.code);
    let positionB = categoriesFactorySort.indexOf(b.code);
    if (positionA === -1) {
      positionA = Number.MAX_SAFE_INTEGER;
    }
    if (positionB === -1) {
      positionB = Number.MAX_SAFE_INTEGER;
    }
    return positionA - positionB;
  });
}

export function getDocumentLanguages(documents, selectedCategories) {
  const languagesSet = new Set();

  documents?.forEach((document) => {
    const { categories, attachments } = document;

    const isSelectedCategory =
      selectedCategories.length === 0 ||
      (categories?.length === 0 &&
        selectedCategories.some(({ id }) => id === "null")) ||
      categories?.some((category) =>
        selectedCategories.some(({ id }) => id === category.id),
      );

    if (isSelectedCategory) {
      attachments?.forEach((attachment) => {
        const { languages } = attachment;

        if (languages?.length > 0) {
          attachment.languages.forEach((language) =>
            languagesSet.add(language),
          );
        } else {
          languagesSet.add("null");
        }
      });
    }
  });

  return [...languagesSet];
}

export async function handleDownloadItemClicked(
  downloadUrl,
  session,
  target = "_blank",
) {
  if (
    session.tokenExpiresAt === null ||
    session.tokenExpiresAt - Date.now() <= 0
  ) {
    await session.refreshSession();
  }
  window.open(downloadUrl, target, "noopener,noreferrer");
}
