import React from "react";
import { Row, Column, Container, ActionBar } from "lcm-iot-commons";

import { FormattedMessage } from "react-intl";
import ProductForm from "./ProductForm";

export function ProductCreate() {
  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <h1>
              <FormattedMessage id="product_create.header" />
            </h1>
          </ActionBar>
        </Column>
      </Row>
      <Row>
        <Column lg="7">
          <ProductForm />
        </Column>
      </Row>
    </Container>
  );
}

export default ProductCreate;
