import React from "react";
import PropTypes from "prop-types";

function Form({ handleSubmit, setStatus, children }) {
  const beforeHandleSubmit = (event) => {
    setStatus("submitted");
    handleSubmit(event);
  };

  return (
    <form onSubmit={beforeHandleSubmit} noValidate>
      {children}
    </form>
  );
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Form;
