import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { rulesShape, withRules } from "./RulesContext";
import { withLog, logShape } from "./LogContext";
import { loadUsage } from "../actions/usageActions";
import { usageShape } from "../shapes";
import { fetchCurrentUser } from "../actions/currentUserActions";

const UserContext = React.createContext();

const userShape = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  demoDataActive: PropTypes.bool,
  userRoles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  refreshUsage: PropTypes.func,
  updateDemoDataStatus: PropTypes.func,
});

class UserContextProvider extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    deprecatedUser: userShape,
    deprecatedUsage: usageShape,
    children: PropTypes.node.isRequired,
    rules: rulesShape.isRequired,
    log: logShape.isRequired,
    testUser: userShape,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.user = this.user.bind(this);
    this.refreshUsage = this.refreshUsage.bind(this);
    this.refresh = this.refresh.bind(this);
    this.updateDemoDataStatus = this.updateDemoDataStatus.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { deprecatedUser, deprecatedUsage, log } = this.props;
    if (deprecatedUser) {
      log.setUser(deprecatedUser);
    }

    if (
      this.refreshUsageResolve &&
      prevProps.deprecatedUsage !== deprecatedUsage
    ) {
      this.refreshUsageResolve(deprecatedUsage);
    }

    if (this.refreshResolve && prevProps.deprecatedUser !== deprecatedUser) {
      this.refreshResolve(deprecatedUsage);
    }
  }

  user() {
    const { deprecatedUser, deprecatedUsage, testUser } = this.props;
    if (testUser) {
      return { ...testUser };
    }
    return deprecatedUser
      ? {
          ...deprecatedUser,
          usage: deprecatedUsage,
          updateDemoDataStatus: this.updateDemoDataStatus,
          refreshUsage: this.refreshUsage,
          refresh: this.refresh,
        }
      : undefined;
  }

  refreshUsage() {
    const { dispatch, deprecatedUser } = this.props;
    return new Promise((resolve) => {
      dispatch(loadUsage(deprecatedUser.id));
      this.refreshUsageResolve = resolve;
    });
  }

  updateDemoDataStatus(newDemoDataActivationStatus) {
    const { deprecatedUser } = this.props;
    deprecatedUser.demoDataActive = newDemoDataActivationStatus;
    return deprecatedUser;
  }

  refresh() {
    const { dispatch, rules } = this.props;
    return new Promise((resolve) => {
      dispatch(
        fetchCurrentUser({
          specifications: rules.application().get("userSpecifications"),
        }),
      );
      this.refreshResolve = resolve;
    });
  }

  render() {
    const { children } = this.props;
    return (
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <UserContext.Provider value={{ user: this.user() }}>
        {children}
      </UserContext.Provider>
    );
  }
}

const ConnectedUserContextProvider =
  process.env.NODE_ENV === "test" && process.env.FULL_USER_CONTEXT == null
    ? withLog(withRules(UserContextProvider))
    : withLog(
        withRules(
          connect((state) => ({
            deprecatedUser: state.currentUser.user,
            deprecatedUsage: state.usage,
          }))(UserContextProvider),
        ),
      );

const withUser = (ChildComponent, options = { waitForUser: true }) => {
  function ConnectedComponent(props) {
    return (
      <UserContext.Consumer>
        {(context = {}) =>
          context.user || !options.waitForUser ? (
            <ChildComponent {...props} user={context.user} />
          ) : null
        }
      </UserContext.Consumer>
    );
  }
  ConnectedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

// istanbul ignore line can be removed when useUser is used in commons
/* istanbul ignore next */
const useUser = () => useContext(UserContext).user;

export {
  // userShape #FIXME: first we need to kill the old userShape
  ConnectedUserContextProvider as UserContextProvider,
  withUser,
  useUser,
};
