import moment from "moment-timezone";

// Get all time zones
export const getTimeZones = () => {
  const zones = moment.tz.names().map((tz) => ({
    identifier: tz,
    label: `${tz} (${moment.tz(tz).format("Z")})`,
  }));
  return zones.sort((a, b) => a.identifier.localeCompare(b.identifier));
};

export const getTimeZone = (identifier) => {
  const tz = moment.tz(identifier);
  return identifier
    ? { label: `${identifier} (${tz.format("Z")})`, identifier }
    : { label: "", identifier: "none" };
};
