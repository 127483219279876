import ApiClientError from "./ApiClientError";

class NetworkError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super(
      "NetworkError",
      undefined,
      "No internet connection or server is not reachable.",
      request,
      response,
      cause,
    );
  }
}

export default NetworkError;
