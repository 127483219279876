import { isNotEmpty } from "./langUtils";

function appendLine(formattedAddress, a, b = null) {
  let value = null;
  if (isNotEmpty(a) && isNotEmpty(b)) {
    value = `${a} ${b}`;
  } else if (isNotEmpty(a)) {
    value = a;
  } else if (isNotEmpty(b)) {
    value = b;
  }

  if (isNotEmpty(value)) {
    formattedAddress.push(value);
  }
}
// TODO check - we are no longer using this - maybe we should
export function formatAddress(address) {
  const formattedAddress = [];

  appendLine(formattedAddress, address.companyName);
  appendLine(formattedAddress, address.firstName, address.lastName);

  switch (address.countryCode) {
    case "FR":
      appendLine(formattedAddress, address.streetNumber, address.streetName);
      appendLine(formattedAddress, address.zipCode, address.city);
      appendLine(formattedAddress, address.regionCode);
      break;
    case "GB":
      appendLine(formattedAddress, address.streetNumber, address.streetName);
      appendLine(formattedAddress, address.regionCode);
      appendLine(formattedAddress, address.city);
      appendLine(formattedAddress, address.zipCode);
      break;
    case "US":
      appendLine(formattedAddress, address.streetNumber, address.streetName);
      appendLine(
        formattedAddress,
        `${address.city} ${address.regionCode}`,
        address.zipCode,
      );
      break;
    default:
      appendLine(formattedAddress, address.streetName, address.streetNumber);
      appendLine(formattedAddress, address.zipCode, address.city);
      appendLine(formattedAddress, address.regionCode);
  }

  appendLine(formattedAddress, address.countryName);

  if (isNotEmpty(address.phone) || isNotEmpty(address.email)) {
    formattedAddress.push("");
  }

  appendLine(formattedAddress, address.phone);
  appendLine(formattedAddress, address.email);

  return formattedAddress;
}
