import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button, withConfiguration } from "lcm-iot-commons";
import PropTypes from "prop-types";

export function WamDiscontinuationInfoModal({ show, configuration }) {
  const history = useHistory();
  const intl = useIntl();

  const onClose = () => {
    history.push(`${configuration.baseUrl}/sign_in`);
  };

  const onDiscoverNetilion = () => {
    window.open("https://netilion.endress.com", "_self");
  };

  return (
    <Modal show={show} onHide={onClose} restoreFocus={false} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "wam_discontinuation_info_modal.title" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {intl.formatMessage({
            id: "wam_discontinuation_info_modal.body.text",
          })}
        </p>
        <div className="wam-discontinuation-info-modal-btn-grp">
          <Button
            onClick={onDiscoverNetilion}
            text={intl.formatMessage({
              id: "wam_discontinuation_info_modal.button_discover_netilion",
            })}
            hero
          />
          <Button
            onClick={onClose}
            text={intl.formatMessage({
              id: "wam_discontinuation_info_modal.button_login",
            })}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p>
          {intl.formatMessage({
            id: "wam_discontinuation_info_modal.footer.text",
          })}
        </p>
      </Modal.Footer>
    </Modal>
  );
}

WamDiscontinuationInfoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  configuration: PropTypes.shape({
    baseUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default withConfiguration(WamDiscontinuationInfoModal);
