import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { redirectTo } from "../utils/browserUtils";

import { fetchCurrentUser, sessionExpired } from "../actions";
import { locationShape, sessionShape } from "../shapes";

class SessionHandler extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
    location: locationShape,
    session: sessionShape,
    unsecuredEndpoints: PropTypes.arrayOf(PropTypes.string),
    userSpecifications: PropTypes.arrayOf(PropTypes.string),
    /* eslint-disable react/no-unused-prop-types */
    enforceSubscription: PropTypes.bool,
    subscriptionEndpoints: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    enforceSubscription: true,
    userSpecifications: ["eh.pcps.user.newsletter_recipient"], // DEPRECATED use ApplicationRules instead
    unsecuredEndpoints: [],
    subscriptionEndpoints: [],
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleCurrentUser = this.handleCurrentUser.bind(this);
  }

  componentDidMount() {
    this.handleLocation(this.props);
    this.handleSessionTimeout(this.props.session.sessionExpiresAt - Date.now());
    this.handleCurrentUser(this.props);
    const self = this;
    window.addEventListener("focus", () => {
      if (self.props.session.sessionExpiresAt <= Date.now()) {
        self.props.dispatch(sessionExpired());
      } else {
        self.handleSessionTimeout(
          self.props.session.sessionExpiresAt - Date.now(),
        );
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { session } = prevProps;
    if (session.sessionExpiresAt !== this.props.session.sessionExpiresAt) {
      this.handleSessionTimeout(
        this.props.session.sessionExpiresAt - Date.now(),
      );
    }
    this.handleLocation(this.props);
    this.handleCurrentUser(this.props);
  }

  handleCurrentUser(relevantProps) {
    const { session, user } = relevantProps;

    const { dispatch, userSpecifications } = this.props;
    if (!user && session.authenticated && !session.sessionDeleted) {
      dispatch(fetchCurrentUser({ specifications: userSpecifications }));
    }
  }

  // eslint-disable-next-line consistent-return
  handleLocation(props) {
    const { session, location, unsecuredEndpoints } = props;
    if (session.sessionDeleted) {
      return redirectTo("/logout");
    }
    if (session.sessionExpired && location.pathname !== "/session_expired") {
      return redirectTo("/session_expired");
    }
    if (
      !session.authenticated &&
      unsecuredEndpoints.indexOf(location.pathname) < 0
    ) {
      return redirectTo("/");
    }
  }

  handleSessionTimeout(timeout) {
    if (this.sessionTimerId) {
      clearTimeout(this.sessionTimerId);
    }
    const self = this;
    this.sessionTimerId = setTimeout(() => {
      self.props.dispatch(sessionExpired());
    }, timeout);
  }

  render() {
    const { session, location, children, unsecuredEndpoints } = this.props;
    if (
      session.authenticated ||
      unsecuredEndpoints.indexOf(location.pathname) >= 0
    ) {
      return <div>{children}</div>;
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
  user: state.currentUser.user,
});

export default connect(mapStateToProps)(SessionHandler);
