/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";
import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Modal } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { intlShape, DetailsItem, showSuccess } from "lcm-iot-commons";

class TechnicalUserModal extends Component {
  constructor() {
    super();
    /* istanbul ignore next */
    this.onCopyPassword = this.onCopyPassword.bind(this);
  }

  onCopyPassword() {
    const { intl } = this.props;
    showSuccess(
      intl.formatMessage({
        id: "technical_user_modal.password_copy_successful",
      }),
    );
  }

  render() {
    const {
      titleText,
      username,
      password,
      intl,
      show,
      contextBodyText,
      onClose,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onClose}
        restoreFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="space-after" />
            {titleText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{contextBodyText}</p>
          <p>
            {intl.formatMessage({
              id: "technical_user_modal.security_message",
            })}
          </p>
          <br />
          <div className="modal-details">
            <DetailsItem
              label={intl.formatMessage({
                id: "technical_user_modal.username_title",
              })}
            >
              <span id="username">{username}</span>
            </DetailsItem>
            <DetailsItem
              className="password-padding-bottom"
              label={intl.formatMessage({
                id: "technical_user_modal.password_title",
              })}
            >
              <div className="password-style">
                <div className="password-overflow">
                  <span id="password">{password}</span>
                </div>
                <span style={{ width: "10px" }} />
                <CopyToClipboard text={password} onCopy={this.onCopyPassword}>
                  <div>
                    <span
                      id="copy-password"
                      className="icon lcm-iot-icon-clipboard icon-style"
                    />
                  </div>
                </CopyToClipboard>
              </div>
            </DetailsItem>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-group">
            <button
              id="button-close"
              type="button"
              className="btn btn-primary"
              onClick={onClose}
            >
              {intl.formatMessage({ id: "button.close" })}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

TechnicalUserModal.propTypes = {
  show: PropTypes.bool,
  titleText: PropTypes.string,
  contextBodyText: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  onClose: PropTypes.func,
  intl: intlShape,
};

export default injectIntl(TechnicalUserModal);
