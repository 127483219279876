import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { intlShape } from "../../shapes";
import ErrorPage from "./ErrorPage";
import { errorTypes } from "./ErrorTypes";

export function Unknown({ intl, isFullPage = false }) {
  return (
    <ErrorPage
      isFullPage={isFullPage}
      header={intl.formatMessage({ id: "unknown_error.header" })}
      details={intl.formatMessage({ id: "unknown_error.details" })}
      errorType={errorTypes.unknown}
    />
  );
}

Unknown.propTypes = {
  intl: intlShape,
  isFullPage: PropTypes.bool,
};

// warning config might not be available
export default injectIntl(Unknown);
