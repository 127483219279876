import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import { isEmpty } from "lodash-es";
import {
  Row,
  Column,
  Form,
  intlShape,
  BadRequestError,
  CancelButton,
  SubmitButton,
  TextInput,
  TextArea,
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  withBrowser,
  browserShape,
} from "lcm-iot-commons";

import Loader from "lcm-iot-commons/client/lib/components/Loader";

export function TenantForm({
  api,
  browser,
  notifier,
  intl,
  isLoadingTenant,
  edit,
  tenant,
}) {
  const onSubmit = async (values, actions) => {
    try {
      if (edit) {
        await api.patch(`/tenants/${tenant.id}`, {
          name: values.name,
          description: values.description,
        });
        browser.navigateTo(`/tenants/${tenant.id}`);
      } else {
        const response = await api.post(`/tenants`, {
          name: values.name,
          description: values.description,
          public: false,
        });
        browser.navigateTo(`/tenants/${response.id}`);
      }
      notifier.showSuccess(
        intl.formatMessage({ id: "tenant_edit.success_notification" }),
      );
    } catch (error) {
      const formErrors = {};
      if (error instanceof BadRequestError && error.contains("taken")) {
        formErrors.name = intl.formatMessage({ id: "api.error.tenant.taken" });
      } else {
        notifier.showError(intl.formatMessage({ id: "api.error.unknown" }));
      }
      actions.setErrors(formErrors);
      actions.setSubmitting(false);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (isEmpty(values.name?.trim())) {
      errors.name = intl.formatMessage({ id: "validation.name.mandatory" });
    }
    if (values.name?.length > 60) {
      errors.name = intl.formatMessage(
        { id: "validation.name.too_long" },
        { characters: 60 },
      );
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <Row>
          <Column>
            <TextInput
              {...formProps}
              id="tenant-name"
              name="name"
              label={intl.formatMessage({ id: "label.name" })}
              required
            />
            <TextArea
              {...formProps}
              id="tenant-description"
              name="description"
              label={intl.formatMessage({ id: "label.description" })}
            />
          </Column>
        </Row>
        <div className="btn-group">
          <SubmitButton
            id="tenant-form-submit"
            fetching={isSubmitting}
            disabled={!formProps.dirty}
          />
          <CancelButton id="tenant-form-cancel" disabled={isSubmitting} />
        </div>
      </Form>
    );
  };

  return isLoadingTenant ? (
    <Loader loading />
  ) : (
    <Formik
      validate={validate}
      onSubmit={onSubmit}
      render={renderForm}
      initialValues={{
        name: tenant?.name,
        description: tenant?.description,
      }}
    />
  );
}

TenantForm.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  isLoadingTenant: PropTypes.bool,
  edit: PropTypes.bool,
  tenant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  notifier: notifierShape.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

TenantForm.defaultProps = {
  isLoadingTenant: false,
  edit: false,
  tenant: null,
  match: null,
};

export default withBrowser(withNotifier(withApi(injectIntl(TenantForm))));
