/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../hooks";
import AssignAssetToSubscriptionModal from "../Modal/AssignAssetToSubscriptionModal";

import { useNotifier } from "../../context";
import { enforceAccessRightCanRead, withAccessRights } from "../../wrappers";
import {
  ActionBar,
  ActionBarButtons,
  DeleteActionButton,
  EditActionButton,
} from "../ActionBar";
import { ConflictError } from "../../api";
import { formatDateTypes, url } from "../../utils";
import { Details, DetailsHeaderItem, DetailsItem } from "../Details";
import AssetStatus from "./AssetStatus";
import More from "../More";
import { Column, Container, Row } from "../Grid";
import Loader from "../Loader";
import AssetComponentsList from "./AssetComponentsList";
import NodesAssignList from "../ObjectAssignList/NodesAssignList";
import InstrumentationsAssignList from "../ObjectAssignList/InstrumentationsAssignList";
import AssetLastSeen from "./AssetLastSeen";
import {
  extractAssets,
  extractPictures,
  extractBestPictureUrl,
} from "../../extractors";
import AssetFirmwareVersion from "./AssetFirmwareVersion";
import SpecificationDetails from "../Specifications/SpecificationDetails";
import AssetBreadcrumb from "./AssetBreadcrumb";
import { accessRightsShape } from "../../shapes";
import ProductCodeDetails from "./ProductCodeDetails/ProductCodeDetails";
import AssetDetailsBasicInfo from "./AssetDetailsBasicInfo";

function AssetDetails({
  appSpecificDetailsItem,
  moreContent,
  appSpecificContent,
  shouldCollapse,
  onDeleteAsset,
  accessRights,
}) {
  const { id } = useParams();
  const intl = useIntl();
  const api = useApi();
  const history = useHistory();
  const notifier = useNotifier();
  const queryClient = useQueryClient();

  const t = (translationId) => intl.formatMessage({ id: translationId });

  const include =
    "parent,product,product.pictures,product.status,product,product.manufacturer,status,pictures,instrumentations,specifications";
  const { data: assetResponse, isLoading } = api.get.useQuery(`/assets/${id}`, {
    include: `${include},global_id`,
  });
  const { data: assets } = api.get.useQuery("/assets", {
    parent_id: id,
    include,
  });

  const asset = assetResponse
    ? {
        ...assetResponse,
        itemType: "asset",
        productPictureUrl: extractBestPictureUrl(
          assetResponse?.product?.pictures,
        ),
        pictures: assetResponse.pictures
          ? extractPictures(assetResponse)
          : null,
        manufacturerName: assetResponse.product?.manufacturer?.name,
        productCode: assetResponse.product?.product_code,
        productTenantPublic: assetResponse.product?.tenant?.public,
        assetComponents: extractAssets(assets),
      }
    : undefined;

  const orderCodeLookUp =
    asset?.manufacturerName === "Endress+Hauser" &&
    asset.specifications?.["eh.pcps.tmp.ordercode"] &&
    /^[a-zA-Z0-9]+-+[a-zA-Z0-9]+\/[a-zA-Z0-9]+/g.exec(
      asset.specifications["eh.pcps.tmp.ordercode"].value,
    );
  const orderCode =
    asset?.specifications?.["eh.pcps.tmp.ordercode"]?.value ?? "";

  const { data: eocResp } = api.get.useQuery(
    "/endress/extended_order_code_lookup",
    { order_code: orderCode },
    {
      enabled: !!orderCodeLookUp,
      retries: false,
      meta: { useGlobalErrorHandling: false },
    },
  );

  if (asset) {
    asset.extendedOrderCode = eocResp?.extended_order_code;
  }

  const { mutate: deleteAsset } = api.delete.useMutation(`/assets/${id}`, {
    onSuccess: async () => {
      onDeleteAsset();
      queryClient.invalidateQueries({ queryKey: ["/subscription"] });
      notifier.showSuccess(t`asset.actions.delete.notification`);
    },
    onError: (error) => {
      if (
        error instanceof ConflictError &&
        error.errors.length > 0 &&
        error.errors[0].type === "assigned_restriction"
      ) {
        notifier.showError(t`api.error.asset.assigned_restriction`);
      } else if (
        error instanceof ConflictError &&
        error.errors.length > 0 &&
        error.errors[0].type === "device_not_deactivated_conflict"
      ) {
        notifier.showError(t`api.error.device_not_deactivated_conflict`);
      } else {
        notifier.showError(t`api.error.unknown`);
      }
    },
  });

  const onConfirmDelete = () =>
    deleteAsset(null, {
      onSuccess: () => {
        history.push(url("/nodes"));
      },
    });

  const renderAssetDetails = () =>
    asset ? (
      <div>
        <AssetDetailsBasicInfo
          productPictureUrl={asset.productPictureUrl}
          pictures={asset.pictures}
          serialNumber={asset.serial_number}
          productName={asset.product?.name}
          manufacturerName={asset.product?.manufacturer?.name}
        />
        <AssetStatus asset={asset} />
        {asset.global_id ? (
          <DetailsHeaderItem
            id="asset-global-id"
            translationKey="label.global_asset_id"
            value={asset.global_id}
          />
        ) : null}
        {appSpecificDetailsItem}
        <SpecificationDetails specifications={asset.specifications} />
      </div>
    ) : null;

  // in_subscription will be undefined in id
  const fullAppFunctionality =
    asset && (asset.in_subscription === undefined || asset.in_subscription);
  const renderMoreDetails = () => {
    if (!asset || !fullAppFunctionality) return null;
    const productCode = asset.specifications?.["eh.pcps.tmp.ordercode"]?.value
      ? asset.specifications["eh.pcps.tmp.ordercode"].value
      : asset.product?.product_code;

    return (
      <div>
        <AssetLastSeen asset={asset} />
        <AssetFirmwareVersion asset={asset} />
        <DetailsItem
          id="asset-description"
          translationKey="label.description"
          value={asset.description}
        />
        <DetailsItem
          id="asset-product-code"
          translationKey="label.product_code"
        >
          <div className="product-code-wrapper">
            <span>{productCode}</span>
            {asset.extendedOrderCode && (
              <span className="additional-info">
                ({asset.extendedOrderCode})
              </span>
            )}
            {asset.product_variant && (
              <ProductCodeDetails
                productCode={productCode}
                productCodeExtended={asset.extendedOrderCode}
                productVariantId={asset.product_variant.id}
              />
            )}
          </div>
        </DetailsItem>
        <DetailsItem
          id="asset-production-date"
          translationKey="label.asset_production_date"
          value={formatDateTypes(asset.production_date, intl)}
        />
        {moreContent}
      </div>
    );
  };

  const renderAssignmentLists = () => (
    <Details>
      {asset.assetComponents?.length > 0 && (
        <AssetComponentsList
          assets={asset.assetComponents}
          accessRights={accessRights}
        />
      )}
      <InstrumentationsAssignList object={asset} accessRights={accessRights} />
      <NodesAssignList
        object={asset}
        accessRights={accessRights}
        testId="assigned_systems1"
      />
    </Details>
  );

  return (
    <Container>
      <AssetBreadcrumb asset={asset} />
      <Row>
        <Column>
          <ActionBar>
            <h1>{t`asset_details.header`}</h1>
            <ActionBarButtons>
              <EditActionButton
                id="edit-asset-button"
                disabled={!accessRights?.canUpdate}
                target={`/assets/${id}/edit`}
              />
              <DeleteActionButton
                id="delete-asset"
                disabled={!accessRights?.canDelete}
                modalTitle={t`asset.actions.delete.modal_title`}
                modalMessage={t`asset.actions.delete.modal_message`}
                onConfirm={onConfirmDelete}
              />
            </ActionBarButtons>
          </ActionBar>
          <Details>
            {renderAssetDetails()}
            {isLoading ? (
              <Loader id="asset-details-loader" loading={isLoading} />
            ) : (
              <div>
                {shouldCollapse ? (
                  <More>{renderMoreDetails()}</More>
                ) : (
                  renderMoreDetails()
                )}
              </div>
            )}
          </Details>
          {fullAppFunctionality && (
            <div>
              {appSpecificContent}
              {renderAssignmentLists()}
            </div>
          )}
          {asset && !fullAppFunctionality && (
            <AssignAssetToSubscriptionModal asset={asset} />
          )}
        </Column>
      </Row>
    </Container>
  );
}

AssetDetails.propTypes = {
  accessRights: accessRightsShape,
  appSpecificDetailsItem: PropTypes.node,
  moreContent: PropTypes.node,
  shouldCollapse: PropTypes.bool,
  appSpecificContent: PropTypes.node,
  onDeleteAsset: PropTypes.func,
};

AssetDetails.defaultProps = {
  shouldCollapse: true,
  onDeleteAsset: () => {},
};

export default withAccessRights(
  AssetDetails,
  "Asset",
  enforceAccessRightCanRead,
);
