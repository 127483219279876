import { Route, Switch } from "react-router-dom";
import React from "react";
import { url, NotFound, Forbidden } from "lcm-iot-commons";
import SignIn from "../components/Users/SignIn";
import RequestPasswordReset from "../components/Users/RequestPasswordReset";
import SignUp from "../components/Users/SignUp";
import PasswordReset from "../components/Users/PasswordReset";
import AccountConfirmation from "../components/Users/AccountConfirmation";
import SignUpIdentification from "../components/Users/SignUpIdentification";
import SignUpSuccess from "../components/Users/SignUpSuccess";
import SignInTermsConfirmation from "../components/Users/SignInTermsConfirmation";

export function SsoRoutes() {
  return (
    <Switch>
      <Route path={url("/sign_in")} component={SignIn} exact />
      <Route path={url("/oauth/authorize")} component={SignIn} exact />
      <Route path={url("/password_reset")} component={PasswordReset} exact />
      <Route
        path={url("/request_password_reset")}
        component={RequestPasswordReset}
        exact
      />
      <Route path={url("/sign_up")} component={SignUp} exact />
      <Route
        path={url("/sign_up_success")}
        component={SignUpIdentification}
        exact
      />
      <Route path={url("/sign_up_complete")} component={SignUpSuccess} exact />
      <Route
        path={url("/confirmation")}
        component={AccountConfirmation}
        exact
      />
      <Route
        path={url("/sign_in_confirmation")}
        component={SignInTermsConfirmation}
        exact
      />
      <Route path={url("/403")} component={Forbidden} exact />
      <Route path={url("/404")} component={NotFound} exact />
      <Route path={url("/*")} component={NotFound} exact />
    </Switch>
  );
}

export default SsoRoutes;
