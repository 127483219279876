import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import { MenuItem } from "react-bootstrap";
import { intlShape } from "../../shapes";
import { apiShape, notifierShape, withApi, withNotifier } from "../../context";
import { BadRequestError, PaymentRequiredError } from "../../api";
import { withIntl } from "../../wrappers";
import { useSubscriptionQuery } from "../../hooks";

export function SubscriptionAssignmentMenuItem({
  api,
  intl,
  assign,
  id,
  type,
  notifier,
}) {
  const { data: subscription } = useSubscriptionQuery();
  const handleOnSubscriptionAssign = async () => {
    try {
      await api.post(`/subscriptions/${subscription.id}/asset_assignment`, {
        object_id: id,
        object_type: type,
      });
      notifier.showSuccess(
        intl.formatMessage({ id: "asset_assignment.assign_success" }),
      );
    } catch (error) {
      if (
        error instanceof BadRequestError &&
        error.contains("empty_asset_list")
      ) {
        notifier.showError(
          intl.formatMessage({ id: "api.error.empty_asset_list" }),
        );
      } else if (
        error instanceof PaymentRequiredError &&
        error.contains("quota_exceeded")
      ) {
        notifier.showError(
          intl.formatMessage({ id: "api.error.quota_exceeded" }),
        );
      } else {
        notifier.showError(intl.formatMessage({ id: "api.error.unknown" }));
      }
    }
  };

  const handleOnSubscriptionUnassign = async () => {
    try {
      await api.delete(`/subscriptions/${subscription.id}/asset_assignment`, {
        object_id: id,
        object_type: type,
      });
      notifier.showSuccess(
        intl.formatMessage({ id: "asset_assignment.unassign_success" }),
      );
    } catch (error) {
      if (
        error instanceof BadRequestError &&
        error.contains("empty_asset_list")
      ) {
        notifier.showError(
          intl.formatMessage({ id: "api.error.empty_asset_list" }),
        );
      } else {
        notifier.showError(intl.formatMessage({ id: "api.error.unknown" }));
      }
    }
  };

  if (subscription) {
    if (assign) {
      return (
        <MenuItem
          id="item-subscription-assign"
          onSelect={handleOnSubscriptionAssign}
        >
          <FormattedMessage id="button.assign_assets_to_subscription" />
        </MenuItem>
      );
    }
    return (
      <MenuItem
        id="item-subscription-unassign"
        onSelect={handleOnSubscriptionUnassign}
      >
        <FormattedMessage id="button.unassign_assets_to_subscription" />
      </MenuItem>
    );
  }
  return null;
}

SubscriptionAssignmentMenuItem.propTypes = {
  api: apiShape.isRequired,
  assign: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  id: PropTypes.number,
  type: PropTypes.string,
  notifier: notifierShape,
};

export default withIntl(withNotifier(withApi(SubscriptionAssignmentMenuItem)));
