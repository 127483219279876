export function annotateResult(result) {
  return result.map((entry) => {
    entry.matches.map((match) => {
      if (match.key.includes("attachments.")) {
        entry.item.attachments.map((attachment, i) => {
          if (i === match.refIndex) {
            // eslint-disable-next-line no-param-reassign
            attachment.match = match;
          }
          return attachment;
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        entry.item.match = match;
      }
      return match;
    });
    return entry;
  });
}

export function extractItems(list) {
  return list.map((entry) => {
    const { item } = entry;
    item.collapsed = false;
    return item;
  });
}
