import PropTypes from "prop-types";
import React from "react";
import { injectIntl } from "react-intl";
import { intlShape } from "../../shapes";
import { isNotEmpty } from "../../utils";

export function SubmitButton(props) {
  const button = React.useRef();

  // Hack to prevent click to get lost, if dom/button position changes
  const handleMouseClick = (event) => {
    const { onClick } = props;
    if (onClick) {
      onClick(event);
    } else {
      button.current.focus();
      event.preventDefault();
      event.persist();
      // i'm really sorry for this one! otherwise formik will submit before all value got updated
      setTimeout(() => {
        event.target.click();
        event.target.blur();
      }, 1);
    }
  };

  const { id, text, fetching, intl, disabled, hero, onFocus, isMouseUp } =
    props;

  const spinner = fetching ? <i className="btn-spinner" /> : null;
  const displayText = isNotEmpty(text)
    ? text
    : intl.formatMessage({ id: "button.submit" });

  let isDisabled = false;
  if (fetching || disabled) {
    isDisabled = true;
  }
  const className = hero ? "btn btn-primary" : "btn";

  return (
    <button
      id={id}
      data-testid="submit-button-testid"
      name="submit"
      ref={button}
      onMouseDown={isMouseUp ? undefined : handleMouseClick}
      onMouseUp={isMouseUp ? handleMouseClick : undefined}
      onFocus={onFocus}
      className={className}
      disabled={isDisabled}
      type="submit"
    >
      {displayText}
      {spinner}
    </button>
  );
}

SubmitButton.propTypes = {
  id: PropTypes.string,
  isMouseUp: PropTypes.bool,
  hero: PropTypes.bool,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  intl: intlShape.isRequired,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
};

SubmitButton.defaultProps = {
  id: undefined,
  isMouseUp: false,
  hero: true,
  fetching: false,
  disabled: false,
  text: undefined,
  onFocus: undefined,
  onClick: undefined,
};

export default injectIntl(SubmitButton);
