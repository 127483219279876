export const DocumentLanguages = [
  { code: "ar", translationKey: "language.arabic" },
  { code: "bg", translationKey: "language.bulgarian" },
  { code: "bs", translationKey: "language.bosnian" },
  { code: "cs", translationKey: "language.czech" },
  { code: "da", translationKey: "language.danish" },
  { code: "de", translationKey: "language.german" },
  { code: "el", translationKey: "language.greek" },
  { code: "en", translationKey: "language.english" },
  { code: "es", translationKey: "language.spanish" },
  { code: "et", translationKey: "language.estonian" },
  { code: "fi", translationKey: "language.finnish" },
  { code: "fr", translationKey: "language.french" },
  { code: "hr", translationKey: "language.croatian" },
  { code: "hu", translationKey: "language.hungarian" },
  { code: "id", translationKey: "language.indonesian" },
  { code: "it", translationKey: "language.italian" },
  { code: "ja", translationKey: "language.japanese" },
  { code: "ko", translationKey: "language.korean" },
  { code: "lt", translationKey: "language.lithuanian" },
  { code: "lv", translationKey: "language.latvian" },
  { code: "ms", translationKey: "language.malay" },
  { code: "nl", translationKey: "language.dutch" },
  { code: "no", translationKey: "language.norwegian" },
  { code: "pl", translationKey: "language.polish" },
  { code: "pt", translationKey: "language.portuguese" },
  { code: "ro", translationKey: "language.romanian" },
  { code: "ru", translationKey: "language.russian" },
  { code: "sk", translationKey: "language.slovak" },
  { code: "sl", translationKey: "language.slovenian" },
  { code: "sr", translationKey: "language.serbian" },
  { code: "sv", translationKey: "language.swedish" },
  { code: "th", translationKey: "language.thai" },
  { code: "tr", translationKey: "language.turkish" },
  { code: "vi", translationKey: "language.vietnamese" },
  { code: "zh", translationKey: "language.chinese" },
];
