import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import TypeaheadBox from "../Form/TypeaheadBox";
import { withApi, apiShape, withNotifier, notifierShape } from "../../context";
import { extractProductCategoryItem, sortBy } from "../../utils";
import intlShape from "../../shapes/intlShape";

export function ProductCategoryTypeahead(props) {
  const [categories, setCategories] = React.useState();
  const [fetching, setFetching] = React.useState();

  const { api, notifier, intl, values, name, setFieldValue, tenantId } = props;

  let selectedCategory = null;
  if (values[name]?.parent) {
    selectedCategory = extractProductCategoryItem(values[name]);
    values[name] = selectedCategory;
  }

  const extractProductCategories = (allCategories) => {
    const mappedCategories = allCategories.categories.map((category) =>
      extractProductCategoryItem(category),
    );
    setCategories(sortBy(mappedCategories, "name"));
  };

  const extractTenant = (allPublicTenants) =>
    allPublicTenants.tenants.map((tenant) => tenant.id);

  const loadProductCategories = async (searchText = "") => {
    if (categories) {
      setFieldValue(name, null);
    }
    try {
      setFetching(true);

      const responsePublicTenants = await api.getAll("/tenants?public=true");
      const publicTenants =
        responsePublicTenants?.tenants?.length > 0
          ? extractTenant(responsePublicTenants).toString()
          : "";

      let include = tenantId
        ? {
            include: "tenant,parent",
            tenant_id: `${publicTenants},${tenantId}`,
          }
        : { include: "tenant,parent", tenant_id: `${publicTenants}` };
      if (searchText) {
        include = { ...include, name: `*${searchText}*` };
      }
      const response = await api.getAll("/product/categories", include);
      if (response && response.categories) {
        extractProductCategories(response);
      } else {
        setCategories([]);
      }
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    loadProductCategories();
  }, [tenantId]);

  return (
    <TypeaheadBox
      {...props}
      options={categories}
      info={
        selectedCategory?.new || values[name]?.new
          ? intl.formatMessage({ id: "product_category_typeahead.new_info" })
          : null
      }
      fetching={fetching}
      loadOptions={loadProductCategories}
      loadOptionsOnMount={!values[name]}
    />
  );
}

ProductCategoryTypeahead.propTypes = {
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  name: PropTypes.string.isRequired,
  tenantId: PropTypes.number,
  intl: intlShape.isRequired,
  values: PropTypes.shape({
    category: PropTypes.shape({}),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

ProductCategoryTypeahead.defaultProps = {
  tenantId: null,
};

export default injectIntl(withNotifier(withApi(ProductCategoryTypeahead)));
