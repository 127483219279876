import InfiniteScroll from "react-infinite-scroller";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import List from "../List/List";
import { intlShape } from "../../shapes";
import {
  apiShape,
  notifierShape,
  withApi,
  withNotifier,
  withBrowser,
  browserShape,
} from "../../context";
import { Row, Column } from "../Grid";
import Loader from "../Loader";
import {
  formatAssetDetails,
  isEmpty,
  isNotEmpty,
  isSomething,
  readLocalStorage,
} from "../../utils";
import ListItemImage from "../List/ListItemImage";
import ListItem from "../List/ListItem";
import { extractAssets } from "../../extractors";

export function SubscriptionAssignedAssetsList({
  intl,
  api,
  subscriptionId,
  subscriptionRoute,
  notifier,
  browser,
}) {
  const [assets, setAssets] = useState([]);
  const [pagination, setPagination] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const loadAssignedAssets = async (assetsUrl) => {
    try {
      setIsLoading(true);
      const assetInclude = {
        include: "product.manufacturer,product.tenant,product.pictures",
      };
      const response = await api.get(assetsUrl, assetInclude);
      setPagination(response.pagination);
      const newAssets = extractAssets(response);
      setAssets(assets.concat(newAssets));
      setIsLoading(false);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const handleOnClick = (assetId) => {
    const rootBackUrl = readLocalStorage("rootBackUrl");
    const redirectUrl =
      isSomething(rootBackUrl) && !rootBackUrl.endsWith("netilion")
        ? `${rootBackUrl}/assets/${assetId}`
        : `/app/analytics/assets/${assetId}`;
    browser.redirectToExtern(redirectUrl);
  };

  const loadMoreAssignedAssets = async () => {
    loadAssignedAssets(pagination.next).then();
  };

  React.useEffect(() => {
    /* istanbul ignore next */
    if (isEmpty(assets)) {
      loadAssignedAssets(
        `/${subscriptionRoute}/${subscriptionId}/assets`,
      ).then();
    }
  }, [subscriptionId]);

  const renderItems = () =>
    assets.map((asset) => (
      <ListItem
        key={`asset-item-${asset.id}`}
        id={`asset-item-${asset.id}`}
        image={
          asset.thumbnailUrl ? (
            <ListItemImage pictureUrl={asset.thumbnailUrl} />
          ) : undefined
        }
        icon={asset.thumbnailUrl ? undefined : "icon-eh-device"}
        title={asset.serialNumber}
        description={formatAssetDetails(asset)}
        status={asset.assetStatusName}
        namur={asset.assetStatusCode}
        onClick={() => handleOnClick(asset.id)}
      />
    ));

  const showEmptyMessage = () =>
    !isLoading ? (
      <p id="emptyMessage" className="space-after">
        {intl.formatMessage({ id: "subscription.no_assets_assigned" })}
      </p>
    ) : (
      <p />
    );

  return (
    <Loader loading={isLoading}>
      <Row>
        <Column>
          <List id="assigned-assets-list">
            <InfiniteScroll
              initialLoad={false}
              loadMore={loadMoreAssignedAssets}
              hasMore={isSomething(pagination) && isSomething(pagination.next)}
            >
              {isNotEmpty(assets) ? renderItems() : showEmptyMessage()}
            </InfiniteScroll>
          </List>
        </Column>
      </Row>
    </Loader>
  );
}

SubscriptionAssignedAssetsList.propTypes = {
  subscriptionId: PropTypes.number,
  subscriptionRoute: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default withRouter(
  injectIntl(
    withBrowser(withNotifier(withApi(SubscriptionAssignedAssetsList))),
  ),
);
