import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as SessionActions from "../actions/sessionActions";

const SessionContext = React.createContext();

const sessionShape = PropTypes.shape({
  authenticated: PropTypes.bool,
  subscriptionStorageQuota: PropTypes.number,
  activeSubscription: PropTypes.bool,
  refreshSession: PropTypes.func,
  subscriptionType: PropTypes.string,
  token: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  applicationSpecifics: PropTypes.object,
  tokenExpiresAt: PropTypes.number,
  reseller: PropTypes.shape({
    customer: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
});

function SessionContextProvider({
  children,
  dispatch,
  deprecatedSession,
  testSession = {},
}) {
  const refreshSession = () =>
    new Promise((resolve, reject) => {
      dispatch(SessionActions.refreshSession())
        // eslint-disable-next-line no-use-before-define
        .then(() => resolve(session()))
        .catch(reject);
    });

  const deleteSession = () => dispatch(SessionActions.deleteSession());

  function session() {
    return {
      authenticated: deprecatedSession.authenticated,
      activeSubscription: deprecatedSession.activeSubscription,
      subscriptionStorageQuota: deprecatedSession.subscriptionStorageQuota,
      subscriptionType: deprecatedSession.subscriptionType,
      token: deprecatedSession.token,
      applicationSpecifics: deprecatedSession.applicationSpecifics,
      reseller: deprecatedSession.reseller,
      tokenExpiresAt: deprecatedSession.tokenExpiresAt,
      refreshSession,
      deleteSession,
      ...testSession,
    };
  }

  const value = React.useMemo(
    () => ({ session: session() }),
    [deprecatedSession, testSession],
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}

SessionContextProvider.propTypes = {
  dispatch: PropTypes.func,
  deprecatedSession: sessionShape,
  children: PropTypes.node.isRequired,
  testSession: PropTypes.shape({}),
};

const ConnectedSessionContextProvider = connect((state) => ({
  deprecatedSession: state.session,
}))(SessionContextProvider);

const withSession = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <SessionContext.Consumer>
        {(context) => <ChildComponent {...props} session={context.session} />}
      </SessionContext.Consumer>
    );
  }
  ConnectedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

const useSession = () => React.useContext(SessionContext).session;

export {
  // sessionShape #FIXME: first we need to kill the old sessionShape
  ConnectedSessionContextProvider as SessionContextProvider,
  withSession,
  useSession,
};
