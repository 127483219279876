import {
  extractBestPictureUrl,
  extractInstrumentations,
} from "lcm-iot-commons";

export function extractAsset(rawAsset) {
  return {
    id: rawAsset.id,
    serialNumber: rawAsset.serial_number,
    manufacturerName: rawAsset.product.manufacturer
      ? rawAsset.product.manufacturer.name
      : null,
    productPictureUrl: extractBestPictureUrl(rawAsset.product.pictures),
    instrumentations: rawAsset.instrumentations
      ? extractInstrumentations(rawAsset.instrumentations)
      : null,
  };
}
export function extractAssets(rawAssets) {
  return rawAssets.assets.map((asset) => extractAsset(asset));
}
