import { addTranslations } from "lcm-iot-commons";

// warning! mutates state for translations of the `lib/translations` module
export async function mergeAppMessages(userLanguage) {
  const messages = (
    await import(
      /* webpackChunkName: "translations" */
      `./${userLanguage}.js`
    )
  ).default;
  addTranslations(userLanguage, messages);
  return messages;
}
