import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useQueryClient } from "@tanstack/react-query";
import { Button, ButtonGroup } from "../../Form";
import { useApi } from "../../../hooks";

function DataExportExistsStep({ handleNext, onClose, existingDataExport }) {
  const intl = useIntl();
  const api = useApi();
  const queryClient = useQueryClient();

  const { mutate: deleteExistingExport, isLoading } = api.delete.useMutation(
    `/users/current/data_exports/${existingDataExport?.id}`,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["/users/current/data_exports"],
        });
      },
    },
  );

  const handleConfirmClick = () => {
    deleteExistingExport(null, {
      onSuccess: () => {
        handleNext();
      },
    });
  };

  return (
    <div className="data-export-exists-step">
      <Modal.Body>
        <h3>
          <FormattedMessage id="export_files_dialog_delete.title" />
        </h3>
        <p>
          <FormattedMessage id="export_files_dialog_delete.text" />
        </p>
        <br />
        <b>
          <FormattedMessage id="export_files_dialog_delete.hint" />
        </b>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            text={intl.formatMessage({ id: "button.close" })}
            onClick={onClose}
          />
          <Button
            hero
            fetching={isLoading}
            text={intl.formatMessage({ id: "button.delete" })}
            onClick={handleConfirmClick}
          />
        </ButtonGroup>
      </Modal.Footer>
    </div>
  );
}

DataExportExistsStep.propTypes = {
  handleNext: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  existingDataExport: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

export default DataExportExistsStep;
