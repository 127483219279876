import { isEmpty, isNil } from "lodash-es";
import { isNothing, isSomething } from "./langUtils";

export function findPrimaryValue(values, doNotUseFirstValue = false) {
  if (!isNil(values) && !isEmpty(values)) {
    let primary = values.find((value) => value.key === "level");
    if (!primary) {
      primary = values.find(
        (value) => ["pv", "distance"].indexOf(value.key) >= 0,
      );
    }
    if (primary) {
      return primary;
    }
    if (doNotUseFirstValue) {
      // dirty hack for FWR30 if it doesn't send level or distance
      return values.find(
        (value) =>
          value.key !== "angle" &&
          value.key !== "temperature" &&
          value.key !== "signal_quality" &&
          value.key !== "battery" &&
          value.key !== "rssi",
      );
    }
    return values[0];
  }
  return undefined;
}

export function findValueByKey(values, key) {
  if (!isNil(values) && !isEmpty(values) && !isNil(key)) {
    return values.find((value) => value.key === key);
  }
  return undefined;
}

export function addUnitToValue(value, unit, defaultNoValue) {
  if (!value || value === "NaN") {
    return defaultNoValue;
  }
  return `${value}${unit && unit.name ? unit.name : ""}`;
}

export function convertAssetValues(asset, values, rules) {
  if (isNothing(values)) return values;
  if (values.length > 0 && asset.systems) {
    // filter values
    if (
      asset.isMebakActivated &&
      rules.asset(asset).get("defaultToMebakKeyMapping")
    ) {
      const mebakKeys = rules.asset(asset).get("defaultToMebakKeyMapping");
      let filteredValues = [];
      Object.values(mebakKeys).forEach((key) => {
        filteredValues.push(values.find((item) => item.key === key));
      });
      filteredValues = filteredValues.filter((item) => item !== undefined);
      return filteredValues;
    }
    const system = asset.systems[0];
    if (system && system.keyValueMapping) {
      const keyValueMapping = Object.values(system.keyValueMapping).filter(
        (item) => isSomething(item) && item !== "empty",
      );
      if (keyValueMapping.length > 0) {
        let filteredValues = [];
        keyValueMapping.forEach((key) => {
          // This pushes also undefined values into the array, therefore they should be filtered afterwards
          filteredValues.push(values.find((item) => item.key === key));
        });
        filteredValues = filteredValues.filter((item) => item !== undefined);

        filteredValues.forEach((v) => {
          if (typeof v.unit === "object") {
            // eslint-disable-next-line no-param-reassign
            v.unit = v.unit.symbol;
          }
        });
        return filteredValues;
      }
    }
  }
  if (values.length > 0 && asset.instrumentations?.items) {
    const convertedValues = [];
    const instrumentation = asset.instrumentations?.items?.find((i) =>
      isSomething(i.specifications),
    );
    const instrumentationUnit =
      instrumentation && instrumentation?.specifications["eh.user_config.unit"]
        ? {
            name: instrumentation.specifications["eh.user_config.unit"]?.value,
            symbol: instrumentation.specifications["eh.user_config.unit"].value,
          }
        : undefined;
    values.forEach((value) => {
      let convertedValue = value;
      if (instrumentationUnit) {
        const ruleValue = rules
          .asset(asset)
          ?.get("convertDistanceValue", instrumentationUnit, value);
        convertedValue = ruleValue || value;
      }
      if (typeof convertedValue.unit === "object") {
        // hack to provide the unit in correct format for AssetValueTile and AssetDetailsValueLatest
        // necessary due to different loading / handling of the parent components
        convertedValue.unit = convertedValue.unit.symbol;
      }
      convertedValues.push(convertedValue);
    });
    return convertedValues;
  }
  values.forEach((v) => {
    if (typeof v.unit === "object") {
      // eslint-disable-next-line no-param-reassign
      v.unit = v.unit.symbol;
    }
  });
  return values;
}

export function convertValuesIntoInstrumentationUnit(asset, values, rules) {
  if (isNothing(values)) return values;
  if (values.length > 0 && asset.instrumentations) {
    const convertedValues = [];
    const instrumentation = asset.instrumentations.find((i) =>
      isSomething(i.unit),
    );
    const instrumentationUnit = instrumentation
      ? {
          name: instrumentation.unit,
          symbol: instrumentation.unit,
        }
      : undefined;
    values.forEach((value) => {
      let convertedValue = value;
      if (instrumentationUnit) {
        const ruleValue = rules
          .asset(asset)
          ?.get("convertDistanceValue", instrumentationUnit, value);
        convertedValue = ruleValue || value;
      }
      if (typeof convertedValue.unit === "object") {
        // hack to provide the unit in correct format for AssetValueTile and AssetDetailsValueLatest
        // necessary due to different loading loading / handling of the parent components
        convertedValue.unit = convertedValue.unit.symbol;
      }
      convertedValues.push(convertedValue);
    });
    return convertedValues;
  }
  values.forEach((v) => {
    if (typeof v.unit === "object") {
      // eslint-disable-next-line no-param-reassign
      v.unit = v.unit.symbol;
    }
  });
  return values;
}

export function filterSpecification(specificationString, specifications) {
  return specifications
    ? Object.entries(specifications).filter((specification) =>
        specification[0].includes(specificationString),
      )
    : null;
}
