import React from "react";
import PropTypes from "prop-types";

import { Row, intlShape, withIntl } from "lcm-iot-commons";

const getRegTypeRegAddressAndBitInfo = (
  regType,
  regBegin,
  regEnd,
  bitBegin,
  bitEnd,
) => {
  if (!bitBegin && !bitEnd) {
    return regBegin === regEnd
      ? `${regType}, ${regBegin}`
      : `${regType}, ${regBegin} - ${regEnd}`;
  }
  if (!bitEnd) {
    return `${regType}, ${regBegin} (bit ${bitBegin})`;
  }
  return regBegin === regEnd
    ? `${regType}, ${regBegin} (bits ${bitBegin} - ${bitEnd})`
    : `${regType}, ${regBegin} - ${regEnd} (bits ${bitBegin} - ${bitEnd})`;
};

export function FieldGatewayGenericModbusDiagnosisItem({
  itemRegisterType,
  itemRegisterBegin,
  itemRegisterEnd,
  itemBitBegin,
  itemBitEnd,
  intl,
}) {
  const { t } = intl;
  return (
    <Row>
      <div className="col-lg-3">
        <b>{t`field_gateway.modbus.config.item.command_register`}</b>
      </div>
      <div className="col-lg-6">
        {getRegTypeRegAddressAndBitInfo(
          itemRegisterType,
          itemRegisterBegin,
          itemRegisterEnd,
          itemBitBegin,
          itemBitEnd,
        )}
      </div>
    </Row>
  );
}

FieldGatewayGenericModbusDiagnosisItem.propTypes = {
  itemRegisterType: PropTypes.string.isRequired,
  itemRegisterBegin: PropTypes.string.isRequired,
  itemRegisterEnd: PropTypes.string.isRequired,
  itemBitBegin: PropTypes.string.isRequired,
  itemBitEnd: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default withIntl(FieldGatewayGenericModbusDiagnosisItem);
