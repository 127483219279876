import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

export default function CountBadge({
  isLoading,
  count = 0,
  maximum = 0,
  idPrefix = "",
}) {
  const intl = useIntl();
  const id = `${idPrefix}count-badge-loading`;
  const badge = isLoading ? (
    <span id={id} className="loading" data-testid={`${id}-testid`}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  ) : (
    `${intl.formatNumber(count)}${maximum > 0 && count === maximum ? "+" : ""}`
  );

  return <span className="badge">{badge}</span>;
}

CountBadge.propTypes = {
  idPrefix: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number,
  maximum: PropTypes.number,
};
