import PropTypes from "prop-types";

export const companyShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  tenantId: PropTypes.number,
  tenantName: PropTypes.string,
});

export const companiesShape = PropTypes.arrayOf(companyShape);
