/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormattedMessage, injectIntl } from "react-intl";
import React, { Component } from "react";
import {
  ActionBar,
  AddressForm,
  apiErrorsContain,
  bindRef,
  CancelButton,
  scrollToTop,
  handleUnknownErrors,
  isNotFoundError,
  loadAllowedCountries,
  Loader,
  navigateTo,
  redirectToExtern,
  showSuccess,
  SubmitButton,
  Details,
  DetailsItem,
  ConfirmationModal,
  CONFIGURATION,
  isSomething,
  isNotEmpty,
  isEmpty,
  addCountryObject,
  checkAddonBlacklist,
  addonIsOnBlacklist,
  RadioSelect,
  updatePlanCartParameters,
  intlShape,
  htmlFormat,
  htmlLink,
  showError,
  loadCurrentUser,
  withConfiguration,
} from "lcm-iot-commons";
import PropTypes from "prop-types";

import {
  loadSubscriptionPlanCart,
  checkoutSubscriptionPlanCart,
} from "../../api/planCartApi";
import {
  loadConnectSubscription,
  loadConnectSubscriptionClientApplications,
} from "../../api/subscriptionApi";
import { packPlanCartCheckout } from "../../packmans/planCartPackman";
import ClientApplicationForm from "../ClientApplications/ClientApplicationForm";

export class ConnectSubscriptionCreate extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnPaymentMethodChange =
      this.handleOnPaymentMethodChange.bind(this);
    this.handleOnPaymentMethodBlur = this.handleOnPaymentMethodBlur.bind(this);
    this.bindClientApplicationForm = bindRef(this, "clientApplicationForm");
    this.bindBillingAddressForm = bindRef(this, "billingAddressForm");
    this.bindShippingAddressForm = bindRef(this, "shippingAddressForm");
    this.handleOnShippingDifferentChange =
      this.handleOnShippingDifferentChange.bind(this);
    this.addonBillingCountryAllowed =
      this.addonBillingCountryAllowed.bind(this);
    this.addonShippingCountryAllowed =
      this.addonShippingCountryAllowed.bind(this);
    this.onConfirmModal = this.onConfirmModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);

    this.state = {
      loading: true,
      submitting: false,
      countries: undefined,
      shippingDifferent: false,
      addonsNotAllowed: [],
      showModal: false,
      target: "/plan_variants",
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    const { target } = this.state;
    this.loadData().catch((apiErrors) => {
      this.setState({ loading: false });
      if (isNotFoundError(apiErrors)) {
        navigateTo(target);
      } else {
        handleUnknownErrors(
          apiErrors,
          intl.formatMessage({ id: "api.error.unknown" }),
        );
      }
    });
  }

  onConfirmModal() {
    const { target } = this.state;
    this.setState({ showModal: false });
    navigateTo(target);
  }

  onCloseModal() {
    this.setState({ showModal: false });
  }

  async loadData() {
    let target = "/plan_variants";
    const user = await loadCurrentUser();
    const countries = await loadAllowedCountries();
    const existingClientApplications =
      await loadConnectSubscriptionClientApplications({});
    const planCart = await loadSubscriptionPlanCart();
    planCart.billingAddress =
      addCountryObject(planCart.billingAddress, countries) || {};
    planCart.shippingAddress = addCountryObject(
      planCart.shippingAddress,
      countries,
    );
    if (user.billingEmail) {
      planCart.billingAddress.email = user.billingEmail;
    }
    const addonsNotAllowed = checkAddonBlacklist(planCart);
    try {
      /* istanbul ignore next */
      if (planCart && planCart.subscriptionId) {
        const subscription = await loadConnectSubscription(
          planCart.subscriptionId,
        );
        if (subscription.status && subscription.status !== "open") {
          if (
            subscription.status &&
            subscription.status === "confirmed" &&
            subscription.subscriptionName &&
            subscription.subscriptionName === "Basic"
          ) {
            target = `/subscriptions/connect/${planCart.subscriptionId}/upgrade`;
          } else {
            target = `/subscriptions/connect/${planCart.subscriptionId}`;
          }
        }
      }
    } catch (error) {
      /* istanbul ignore next */
      console.log(error); // eslint-disable-line no-console
      // do nothing
    }
    this.setState({
      countries,
      planCart,
      target,
      shippingDifferent: isSomething(planCart.shippingAddress),
      loading: false,
      addonsNotAllowed,
      showModal: addonsNotAllowed.length > 0,
      existingClientApplications,
    });
  }

  handleOnSubmit(event) {
    const { intl } = this.props;
    const { planCart, shippingDifferent } = this.state;
    if (event) {
      event.preventDefault();
    }
    const paid =
      planCart.planVariant.type === "paid" || isNotEmpty(planCart.addons);
    const clientAppCheck =
      (this.clientApplicationForm && this.clientApplicationForm.submit()) ||
      (!this.clientApplicationForm && planCart.clientApplication);

    if (
      clientAppCheck &&
      (!paid || this.billingAddressForm.submit()) &&
      (!shippingDifferent || this.shippingAddressForm.submit())
    ) {
      this.setState({ submitting: true });
      const clientAppFormValues = this.clientApplicationForm
        ? this.clientApplicationForm.values()
        : planCart.clientApplication;
      const autoCollection =
        paid && planCart.autoCollection ? planCart.autoCollection : "on";
      const billingAddressFormValues =
        paid && this.billingAddressForm
          ? this.billingAddressForm.values()
          : undefined;
      const shippingAddressFormValues =
        paid && shippingDifferent && this.shippingAddressForm
          ? this.shippingAddressForm.values()
          : undefined;

      if (autoCollection === "on") {
        checkoutSubscriptionPlanCart(
          clientAppFormValues,
          autoCollection,
          billingAddressFormValues,
          shippingAddressFormValues,
        )
          .then((nextPlanCart) => {
            if (
              nextPlanCart.planVariant.type === "free" &&
              isEmpty(planCart.addons)
            ) {
              showSuccess(
                intl.formatMessage({
                  id: "subscription_create.success_notification_free",
                }),
              );
              navigateTo(
                `/subscriptions/connect/${nextPlanCart.subscriptionId}`,
              );
            } else {
              redirectToExtern(nextPlanCart.checkoutUrl);
            }
            /* istanbul ignore next */
          })
          .catch((apiErrors) => {
            /* istanbul ignore next */
            this.handleAddressFormErrors(apiErrors);
          });
      } else {
        const planCartParams = packPlanCartCheckout(
          clientAppFormValues,
          autoCollection,
          billingAddressFormValues,
          shippingAddressFormValues,
        );

        updatePlanCartParameters(planCartParams)
          .then(() => {
            navigateTo("/subscriptions/connect/new/overview");
          })
          .catch(
            /* istanbul ignore next */ (apiErrors) => {
              /* istanbul ignore next */
              this.handleAddressFormErrors(apiErrors);
            },
          );
      }
    } else {
      if (this.clientApplicationForm) {
        this.clientApplicationForm.setSubmitting(false);
      }
      if (paid) {
        this.billingAddressForm.setSubmitting(false);
        if (shippingDifferent) {
          this.shippingAddressForm.setSubmitting(false);
        }
      }
    }
  }

  handleAddressFormErrors(apiErrors) {
    const { intl } = this.props;
    const { planCart, shippingDifferent } = this.state;
    const paid =
      planCart.planVariant.type === "paid" || isNotEmpty(planCart.addons);

    this.setState({ submitting: false });
    scrollToTop();
    if (apiErrorsContain(apiErrors, "taken")) {
      this.clientApplicationForm.setErrors({
        name: intl.formatMessage({ id: "api.error.client_application.taken" }),
      });
    } else if (apiErrorsContain(apiErrors, "invalid_input", "tax_address")) {
      showError(
        intl.formatMessage({ id: "api.error.tax_address.invalid_input" }),
      );
    } else if (apiErrorsContain(apiErrors, "invalid_input", "redirect_uris")) {
      this.clientApplicationForm.setErrors({
        redirectURIs: intl.formatMessage({
          id: "api.error.redirect_uri.invalid_input",
        }),
      });
    } else if (
      apiErrorsContain(apiErrors, "invalid_input", "billing_address_zip_code")
    ) {
      this.billingAddressForm.setErrors({
        zipCode: intl.formatMessage({
          id: "api.error.billing_address_zip_code.invalid_input",
        }),
      });
    } else if (
      apiErrorsContain(apiErrors, "invalid_input", "shipping_address_zip_code")
    ) {
      this.shippingAddressForm.setErrors({
        zipCode: intl.formatMessage({
          id: "api.error.shipping_address_zip_code.invalid_input",
        }),
      });
    } else if (
      apiErrorsContain(apiErrors, "vat_number", "billing_address.base")
    ) {
      this.billingAddressForm.setErrors({
        vatNumber: intl.formatMessage({
          id: "validation.vat_number.mandatory",
        }),
      });
    } else if (
      apiErrorsContain(apiErrors, "invalid_input", "billing_address_vat_number")
    ) {
      this.billingAddressForm.setErrors({
        vatNumber: intl.formatMessage({
          id: "validation.vat_number.mandatory",
        }),
      });
    } else {
      handleUnknownErrors(
        apiErrors,
        intl.formatMessage({ id: "api.error.unknown" }),
      );
    }

    if (this.clientApplicationForm) {
      this.clientApplicationForm.setSubmitting(false);
    }

    if (paid) {
      this.billingAddressForm.setSubmitting(false);
      if (shippingDifferent) {
        this.shippingAddressForm.setSubmitting(false);
      }
    }
  }

  handleOnPaymentMethodChange(target) {
    const { planCart } = this.state;
    planCart.autoCollection = target.target.value;
    this.setState({ planCart });
  }

  handleOnPaymentMethodBlur() {
    return true;
  }

  handleOnShippingDifferentChange() {
    const { shippingDifferent, planCart } = this.state;
    const boxIsChecked = !shippingDifferent; // shippingDifferent is not yet updated in state
    const newAddonsNotAllowed = checkAddonBlacklist(planCart, boxIsChecked);
    this.setState({
      shippingDifferent: !shippingDifferent,
      addonsNotAllowed: newAddonsNotAllowed,
      showModal: newAddonsNotAllowed.length > 0,
    });
  }

  addonBillingCountryAllowed(country) {
    const { shippingDifferent, planCart, addonsNotAllowed } = this.state;
    let newAddonsNotAllowed = addonsNotAllowed;
    if (!shippingDifferent) {
      newAddonsNotAllowed = addonIsOnBlacklist(planCart.addons, country?.code);
    }
    this.setState({
      addonsNotAllowed: newAddonsNotAllowed,
      showModal: newAddonsNotAllowed.length > 0,
      planCart: {
        ...planCart,
        billingAddress: {
          country,
        },
      },
    });
  }

  addonShippingCountryAllowed(country) {
    const { planCart, addonsNotAllowed } = this.state;
    let newAddonsNotAllowed = addonsNotAllowed;
    newAddonsNotAllowed = addonIsOnBlacklist(planCart.addons, country?.code);
    this.setState({
      addonsNotAllowed: newAddonsNotAllowed,
      showModal: newAddonsNotAllowed.length > 0,
      planCart: {
        ...planCart,
        shippingAddress: {
          country,
        },
      },
    });
  }

  render(props) {
    const {
      planCart,
      loading,
      submitting,
      countries,
      shippingDifferent,
      addonsNotAllowed,
      showModal,
      target,
      existingClientApplications,
    } = this.state;
    const { intl, configuration } = this.props;

    const RADIO_SELECT_OPTIONS = configuration?.disablePaymentGateway
      ? [
          {
            value: "off",
            label: intl.formatMessage({
              id: "subscription.payment_method.invoice",
            }),
          },
        ]
      : [
          {
            value: "on",
            label: intl.formatMessage({
              id: "subscription.payment_method.credit_card",
            }),
          },
          {
            value: "off",
            label: intl.formatMessage({
              id: "subscription.payment_method.invoice",
            }),
          },
        ];

    const mtext = (
      <div>
        <FormattedMessage
          id="subscription_create.addon_country_modal_dialog.message"
          values={{
            ...htmlFormat,
            notAllowedAddons: addonsNotAllowed.join(", "),
            a: htmlLink({
              href: CONFIGURATION.HELP_URL_AVAILABILITY,
              target: "_blank",
            }),
          }}
        />
      </div>
    );

    const addonModal =
      showModal && !loading ? (
        <ConfirmationModal
          id="addon-country-not-allowed-modal-dialog"
          show={showModal}
          titleText={intl.formatMessage({
            id: "subscription_create.addon_country_modal_dialog.title",
          })}
          messageText={mtext}
          buttonConfirmText={intl.formatMessage({
            id: "subscription_create.addon_country_modal_dialog.button_close",
          })}
          buttonCancelText={intl.formatMessage({
            id: "subscription_create.addon_country_modal_dialog.button_confirm",
          })}
          onConfirm={this.onConfirmModal}
          onClose={this.onCloseModal}
        />
      ) : null;

    const byInvoiceHint = (
      <div className="space-after">
        {intl.formatMessage({
          id: "subscription.payment_method.by_invoice_hint",
        })}
      </div>
    );

    return (
      <div id="connect-subscription-create" className="container">
        <div className="row">
          <div className="col-md-7">
            {planCart ? (
              <div>
                <ActionBar>
                  <h1>
                    <FormattedMessage id="subscription_create.application_title" />
                  </h1>
                </ActionBar>
                {planCart.clientApplication ? (
                  <Details>
                    <DetailsItem
                      id="subscription-client-application-name"
                      translationKey="label.client_application"
                      value={planCart.clientApplication.name}
                    />
                    <DetailsItem
                      id="subscription-client-application-description"
                      translationKey="label.description"
                      value={planCart.clientApplication.description}
                    />
                  </Details>
                ) : (
                  <ClientApplicationForm
                    ref={this.bindClientApplicationForm}
                    initialValues={planCart.clientApplication}
                    onSubmit={this.handleOnSubmit}
                    takenNames={existingClientApplications}
                  />
                )}
                {planCart.planVariant.type === "paid" ||
                isNotEmpty(planCart.addons) ? (
                  <div>
                    <ActionBar>
                      <h1 id="subscription-payment-method-header">
                        <FormattedMessage id="subscription.payment_method.header" />
                      </h1>
                    </ActionBar>
                    <RadioSelect
                      {...props}
                      id="payment-options"
                      options={RADIO_SELECT_OPTIONS}
                      handleChange={this.handleOnPaymentMethodChange}
                      values={{ paymentOptions: planCart.autoCollection }}
                      name="paymentOptions"
                      handleBlur={this.handleOnPaymentMethodBlur}
                    />
                    {byInvoiceHint}
                    <ActionBar>
                      <h1 id="subscription-billing-address-header">
                        <FormattedMessage id="subscription.billing_address.header" />
                      </h1>
                    </ActionBar>
                    <AddressForm
                      id="billing-address"
                      initialValues={{
                        ...planCart.billingAddress,
                        customerPurchaseOrder: planCart.customerPurchaseOrder,
                      }}
                      onSubmit={this.handleOnSubmit}
                      countries={countries.countries}
                      prefix="billingAddress"
                      ref={this.bindBillingAddressForm}
                      showEmail
                      showVat
                      showCustomerPurchaseOrder
                      countryChangeAction={this.addonBillingCountryAllowed}
                      firstLastNameRequired
                    />
                    <div className="space-after">
                      <input
                        id="addressdifferent-checkbox"
                        type="checkbox"
                        className="unchecked"
                        disabled={submitting}
                        value="false"
                        onChange={this.handleOnShippingDifferentChange}
                        checked={shippingDifferent}
                      />
                      <label htmlFor="addressdifferent-checkbox">
                        <FormattedMessage id="subscription.addresses_different" />
                      </label>
                    </div>
                    {shippingDifferent ? (
                      <div className="space-after">
                        <ActionBar>
                          <h1 id="subscription-shipping-address-header">
                            <FormattedMessage id="subscription.shipping_address.header" />
                          </h1>
                        </ActionBar>
                        <AddressForm
                          id="shipping-address"
                          initialValues={planCart.shippingAddress}
                          onSubmit={this.handleOnSubmit}
                          countries={countries.countries}
                          prefix="shippingAddress"
                          ref={this.bindShippingAddressForm}
                          countryChangeAction={this.addonShippingCountryAllowed}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="btn-group">
                  <SubmitButton
                    id="checkout-subscription-submit"
                    text={intl.formatMessage({ id: "label.continue" })}
                    onClick={this.handleOnSubmit}
                    fetching={submitting}
                    disabled={addonsNotAllowed.length > 0}
                  />
                  <CancelButton
                    id="checkout-subscription-cancel"
                    disabled={submitting}
                    target={target}
                  />
                </div>
              </div>
            ) : null}
            {addonModal}
            <Loader loading={loading} />
          </div>
        </div>
      </div>
    );
  }
}

ConnectSubscriptionCreate.propTypes = {
  intl: intlShape,
  configuration: PropTypes.shape({ disablePaymentGateway: PropTypes.bool }),
};

ConnectSubscriptionCreate.defaultProps = {
  intl: undefined,
  configuration: undefined,
};

export default injectIntl(withConfiguration(ConnectSubscriptionCreate));
