import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

const SignUpContext = React.createContext();

const SignUpBreadcrumbLabels = [
  {
    key: "sign-up",
  },
  {
    key: "identification",
  },
  {
    key: "verification",
  },
  {
    key: "success",
  },
];

const SignInTermsBreadcrumbLabels = [
  {
    key: "sign-up",
  },
  {
    key: "confirmation",
  },
  {
    key: "success",
  },
];

const signUpShape = PropTypes.shape({
  signUpDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    redirectUrl: PropTypes.string,
  }),
  updateSignUpDetails: PropTypes.func,
});

function SignUpContextProvider({ children }) {
  const [signUpDetails, setSignUpDetails] = useState(
    JSON.parse(sessionStorage.getItem("signUpUser")) || {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      redirectUrl: undefined,
    },
  );

  const updateSignUpDetails = (values) => {
    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      redirectUrl: values.redirectUrl,
    };
    sessionStorage.setItem("signUpUser", JSON.stringify(user));
    setSignUpDetails(user);
  };

  const context = useMemo(
    () => ({
      signUp: { signUpDetails, updateSignUpDetails },
    }),
    [signUpDetails],
  );

  return (
    <SignUpContext.Provider value={context}>{children}</SignUpContext.Provider>
  );
}

SignUpContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const withSignUp = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <SignUpContext.Consumer>
        {(context) => <ChildComponent {...props} signUp={context.signUp} />}
      </SignUpContext.Consumer>
    );
  }
  ConnectedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

export {
  signUpShape,
  SignUpContextProvider,
  withSignUp,
  SignInTermsBreadcrumbLabels,
  SignUpBreadcrumbLabels,
};
