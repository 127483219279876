import PropTypes from "prop-types";
import React from "react";

import { withConfiguration, withSession } from "../../context";
import Icon from "../Icon";

export function Picture({
  alt,
  session,
  placeholder,
  width,
  height,
  src,
  configuration,
}) {
  const style = { width: `${width}px`, height: `${height}px` };

  const [errorLoadingImage, setErrorLoadingImage] = React.useState(false);

  return src && !errorLoadingImage ? (
    <img
      src={
        src.startsWith(configuration.lcmApiUrl)
          ? `${src}?access_token=${session.token}`
          : src
      }
      alt={alt}
      onError={
        /* istanbul ignore next */
        () => {
          setErrorLoadingImage(true);
        }
      }
      style={style}
    />
  ) : (
    <div className="list-item-icon placeholder">
      <Icon name={placeholder} />
    </div>
  );
}

Picture.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  token: PropTypes.string,
  lcmApiUrl: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  configuration: PropTypes.shape({
    lcmApiUrl: PropTypes.string,
  }),
  session: PropTypes.shape({
    token: PropTypes.string,
  }),
};

Picture.defaultProps = {
  placeholder: "icon-eh-device",
  width: 200,
  height: 200,
};

export default withConfiguration(withSession(Picture));
