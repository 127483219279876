import React from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ButtonGroup, Clue, url, intlShape } from "lcm-iot-commons";

export function NoTenants({ intl }) {
  return (
    <Clue
      id="no-tenants-found"
      title={intl.formatMessage({ id: "tenants.no_tenants_found" })}
      details={intl.formatMessage({
        id: "tenants.no_tenants_found.ask_create",
      })}
      className="notification"
      highlighted
    >
      <ButtonGroup>
        <Link className="btn btn-primary" to={url("/tenants/create")}>
          {intl.formatMessage({ id: "button.create_tenant" })}
        </Link>
      </ButtonGroup>
    </Clue>
  );
}

NoTenants.propTypes = {
  intl: intlShape.isRequired,
};

NoTenants.defaultProps = {};

export default injectIntl(NoTenants);
