import ApiClientError from "./ApiClientError";

class UnknownError extends ApiClientError {
  /* istanbul ignore next */
  constructor(request, response, cause) {
    super(
      "UnknownError",
      undefined,
      "An unknown error has occurred.",
      request,
      response,
      cause,
    );
  }
}

export default UnknownError;
