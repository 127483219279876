import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { intlShape } from "lcm-iot-commons";
import { apiKeyShape } from "../../shapes/apiKeysShape";
import {
  apiKeyIconClassName,
  apiKeyStatusIconClassName,
} from "../../utils/statusUtils";

export class ApiKeyItem extends Component {
  displayApiKeyIcon() {
    return (
      <div className="details-item-value">
        <span className={apiKeyIconClassName()} />
      </div>
    );
  }

  render() {
    const { apiKey, intl } = this.props;

    const description = apiKey.description ? `, ${apiKey.description}` : "";
    const authenticationType = `${intl.formatMessage({ id: "label.authentication_type" })}: ${intl.formatMessage({ id: `enum.authentication_type.${apiKey.authenticationType}` })}`;

    const apiSecret = apiKey.apiSecret ? (
      <div className="list-item-details">
        <span>
          {intl.formatMessage({ id: "label.api_secret" })}:{apiKey.apiSecret}
        </span>
      </div>
    ) : null;

    return (
      <li className="list-item">
        <div className="list-item-image">{this.displayApiKeyIcon()}</div>
        <div className="list-item-content">
          <div className="list-item-header">{apiKey.apiKey}</div>
          <div className="list-item-details">
            <span className={apiKeyStatusIconClassName(apiKey.disabled)} />
            <span>
              {authenticationType}
              {description}
            </span>
          </div>
          {apiSecret}
        </div>
      </li>
    );
  }
}

ApiKeyItem.propTypes = {
  intl: intlShape.isRequired,
  apiKey: apiKeyShape.isRequired,
};

export default injectIntl(ApiKeyItem);
