import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { withConfiguration } from "./ConfigurationContext";
import ConsoleLogging from "./logging/ConsoleLogging";
import SentryLogging from "./logging/SentryLogging";

const LogContext = React.createContext();

const logShape = PropTypes.shape({
  error: PropTypes.func,
  setUser: PropTypes.func,
});

function InitializedLogContextProvider({ children, configuration }) {
  const LogContextProviderImplementation = configuration?.sentryDSN
    ? SentryLogging
    : ConsoleLogging;

  return (
    <LogContextProviderImplementation>
      {(log) => {
        // FIXME: FALLBACK for old code don't use it!
        global.log = log;
        const context = useMemo(() => ({ log }));
        return (
          <LogContext.Provider value={context}>{children}</LogContext.Provider>
        );
      }}
    </LogContextProviderImplementation>
  );
}

InitializedLogContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  configuration: PropTypes.shape({ sentryDSN: PropTypes.string }), // highly recommended
};

const LogContextProvider = withConfiguration(InitializedLogContextProvider);

const withLog = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <LogContext.Consumer>
        {(context = {}) => <ChildComponent {...props} log={context.log} />}
      </LogContext.Consumer>
    );
  }
  ConnectedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

const useLog = () => React.useContext(LogContext).log;

export { LogContextProvider, withLog, logShape, useLog };
