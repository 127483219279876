// helper to map document category to the respective icon file name / file reference
// ... or file extension to respective icon file name / file reference

const MIME_ICONS = {
  software: ["exe"],
  picture: ["jpg", "jpeg", "png", "gif", "tif", "tiff", "bmp", "dwg"],
  code: ["xml", "xsd", "json", "html", "js", "rb", "java", "py", "c"],
  text: ["txt", "rtf", "doc", "docx"],
  certificate: ["der", "cer"],
  tables: ["csv", "xls", "xlsx", "xlsxm", "numbers"],
  compressed: ["zip", "rar"],
  pdf: ["pdf"],
};

const CATEGORY_ICONS = [
  "certificate",
  "report",
  "manual",
  "other",
  "picture",
  "software",
];

const EXTENSION_MAPPING = {};

Object.keys(MIME_ICONS).forEach((key) => {
  MIME_ICONS[key].forEach((extension) => {
    EXTENSION_MAPPING[extension] = key;
  });
});

export function iconForFileName(fileName) {
  const extension = fileName.split(".").pop().toLowerCase();
  return EXTENSION_MAPPING[extension]
    ? `lcm-iot-icon-${EXTENSION_MAPPING[extension]}`
    : "lcm-iot-icon-other";
}

export function iconForFileCategory(categoryCode) {
  return CATEGORY_ICONS.includes(categoryCode)
    ? `lcm-iot-icon-${categoryCode}`
    : "lcm-iot-icon-other";
}
