import React from "react";
import PropTypes from "prop-types";
import Clickable from "../Clickable";

function BreadcrumbItem({ id, title, onClick, target, className, focus }) {
  return (
    <div
      id={id}
      className={focus ? `${className} breadcrumb-focus` : className}
    >
      {onClick || target ? (
        <Clickable onClick={onClick} target={target}>
          {title}
        </Clickable>
      ) : (
        <div>{title}</div>
      )}
    </div>
  );
}

BreadcrumbItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  target: PropTypes.string,
  className: PropTypes.string,
  focus: PropTypes.bool,
};

BreadcrumbItem.defaultProps = {
  id: undefined,
  onClick: undefined,
  target: undefined,
  className: "breadcrumb-item",
  focus: false,
};

export default BreadcrumbItem;
