import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { connect } from "react-redux";

import {
  ApplicationContext,
  configureApplication,
  Intl,
  Layout,
  locationShape,
  sessionShape,
  SessionHandler,
  url,
  Loader,
} from "lcm-iot-commons";
import Navigation from "../components/Navigation";
import { mergeAppMessages } from "../translations";

configureApplication({
  BASE_URL: "/app/id",
});

const UNSECURED_ENDPOINTS = [
  url("/sign_in"),
  url("/sign_up"),
  url("/sign_up_success"),
  url("/sign_up_complete"),
  url("/session_expired"),
  url("/sign_in_confirmation"),
  url("/request_password_reset"),
  url("/password_reset"),
  url("/confirmation"),
  url("/404"),
  url("/403"),
  url("/500"),
];

export function Root({ session, location, children }) {
  return (
    <Suspense fallback={<Loader />}>
      <SessionHandler
        location={location}
        unsecuredEndpoints={UNSECURED_ENDPOINTS}
      >
        <Intl mergeAppMessages={mergeAppMessages}>
          <ApplicationContext unsecuredEndpoints={UNSECURED_ENDPOINTS}>
            <Navigation location={location} showNavigationItems />
            <div className="navbar-separator" />
            <Layout authenticated={session.authenticated}>{children}</Layout>
          </ApplicationContext>
        </Intl>
      </SessionHandler>
    </Suspense>
  );
}

Root.propTypes = {
  session: sessionShape,
  children: PropTypes.node.isRequired,
  location: locationShape,
};

Root.defaultProps = {
  session: undefined,
  location: undefined,
};

const mapStateToProps = (state) => ({
  session: state.session,
  configuration: state.configuration,
  user: state.currentUser.user,
});

export default connect(mapStateToProps)(Root);
