import { translationKeyExists } from "../translations";
import { isArray, isSomething } from "../utils/langUtils";
import { handleUnknownErrors } from "../utils/errorUtils";

import ApiError from "./ApiError";

import { showError } from "../utils/notificationUtils";

const UNKNOWN_ERROR_KEY = "api.error.unknown";

export function extractCommonApiError(error, mappings, request) {
  let errorKey = `api.error.${error.type}`;

  if (mappings && mappings[error.type]) {
    errorKey = `api.error.${mappings[error.type]}`;
  }

  if (translationKeyExists(errorKey)) {
    return errorKey;
  }
  handleUnknownErrors({ error, request });
  return UNKNOWN_ERROR_KEY;
}

export function extractCommonApiErrors(errors, mappings) {
  if (errors) {
    let commonErrors;
    if (errors.filter((error) => error.attribute === "captcha").length > 0) {
      commonErrors = errors
        .filter((error) => error.attribute)
        .map((error) => extractCommonApiError(error, mappings, errors.request));
    } else {
      commonErrors = errors
        .filter((error) => !error.attribute)
        .map((error) => extractCommonApiError(error, mappings, errors.request));
    }
    // sadly IE doe not get it when using fancy spread operator, so lets do it the old school way
    // return [...new Set(commonErrors)]
    const errorSet = new Set(commonErrors);
    const errorArray = [];
    errorSet.forEach((v) => errorArray.push(v));
    return errorArray;
  }
  return [];
}

export function extractAttributeApiError(error) {
  const errorKey = `api.error.${error.attribute}.${error.type}`;
  if (translationKeyExists(errorKey)) {
    return errorKey;
  }
  handleUnknownErrors(error);
  return UNKNOWN_ERROR_KEY;
}

export function handleCommonApiErrors(props, nextProps, mappings) {
  const { errors, intl } = nextProps;
  if (errors && errors !== props.errors) {
    if (isArray(errors)) {
      extractCommonApiErrors(errors, mappings).forEach((error) => {
        showError(intl.formatMessage({ id: error }));
      });
    } else {
      handleUnknownErrors(
        errors,
        intl.formatMessage({ id: "api.error.unknown" }),
      );
    }
  }
}

export function handleApiErrors(callback, props, nextProps, typeMappings) {
  handleCommonApiErrors(props, nextProps, typeMappings);
  if (callback) {
    callback.resolve();
  }
}

export function assignApiAttribute(errors, type, attribute) {
  if (isArray(errors)) {
    const mappedErrors = [];
    errors.forEach((error) => {
      if (error.type === type) {
        mappedErrors.push({ ...error, attribute });
      } else {
        mappedErrors.push(error);
      }
    });
    return mappedErrors;
  }
  return errors;
}

export function mapApiErrorType(errors, type, newType) {
  if (isArray(errors)) {
    const mappedErrors = [];
    errors.forEach((error) => {
      if (error.type === type) {
        mappedErrors.push({ ...error, type: newType });
      } else {
        mappedErrors.push(error);
      }
    });
    return mappedErrors;
  }
  return errors;
}

export function mapApiAttributes(errors, attribute) {
  if (isArray(errors)) {
    const mappedErrors = [];
    errors.forEach((error) => {
      if (error.attribute) {
        mappedErrors.push({ ...error, attribute });
      } else {
        mappedErrors.push(error);
      }
    });
    return mappedErrors;
  }
  return errors;
}

export function ignoreNotFoundError(errors) {
  if (isArray(errors)) {
    return errors.filter((e) => e.type !== "not_found_no_permission");
  }
  return errors;
}

export function ignoreInvalidInputError(errors) {
  if (isArray(errors)) {
    return errors.filter((e) => e.type !== "invalid_input");
  }
  return errors;
}

export function ignoreServiceUnavailable(errors) {
  if (isArray(errors)) {
    return errors.filter((e) => e.type !== "service_unavailable");
  }
  return errors;
}

export function isNotFoundError(errors, ignoreOthers = true) {
  return (
    errors &&
    isArray(errors) &&
    isSomething(errors.find((e) => e.type === "not_found_no_permission")) &&
    (ignoreOthers || errors.length === 1)
  );
}

// used only in test / can remove
export function removeApiAttributes(errors, attribute) {
  const mappedErrors = [];
  if (isArray(errors)) {
    errors.forEach((error) => {
      if (error.attribute === attribute) {
        mappedErrors.push({ type: error.type, message: error.message });
      } else {
        mappedErrors.push(error);
      }
    });
    return mappedErrors;
  }
  return errors;
}

export function combineErrors(errors) {
  const combinedErrors = new ApiError();
  errors.forEach((values) => {
    if (isSomething(values)) {
      values.forEach((value) => combinedErrors.push(value));
    }
  });

  if (combinedErrors.length > 0) {
    return combinedErrors;
  }

  return null;
}

export function apiErrorsContain(errors, type, attribute = null) {
  if (isArray(errors)) {
    if (attribute) {
      return isSomething(
        errors.find(
          (error) => error.type === type && error.attribute === attribute,
        ),
      );
    }
    return isSomething(errors.find((error) => error.type === type));
  }
  return false;
}
