import LANGUAGES_LIST from "../constants/langData";

export function validate(code) {
  return Object.prototype.hasOwnProperty.call(LANGUAGES_LIST, code);
}

export function getLanguageName(code) {
  return validate(code) ? LANGUAGES_LIST[code].name : "";
}

export function getNativeLanguageName(code) {
  return validate(code) ? LANGUAGES_LIST[code].nativeName : "";
}

/* istanbul ignore next */
export function getAllLanguageNames() {
  return Object.values(LANGUAGES_LIST).map((l) => l.name);
}

/* istanbul ignore next */
export function getAllLanguageCodes() {
  return Object.keys(LANGUAGES_LIST);
}

/* istanbul ignore next */
export function getAllNativeLanguageNames() {
  return Object.values(LANGUAGES_LIST).map((l) => l.nativeName);
}

export function getLanguages(codes = []) {
  return codes.map((code) => ({
    code,
    name: getLanguageName(code),
    nativeName: getNativeLanguageName(code),
  }));
}

export function getLanguageCode(name) {
  const code = Object.keys(LANGUAGES_LIST).find((c) => {
    const language = LANGUAGES_LIST[c];

    return (
      language.name.toLowerCase() === name.toLowerCase() ||
      language.nativeName.toLowerCase() === name.toLowerCase()
    );
  });
  return code || "";
}
/* istanbul ignore next */
export function getLanguagesList() {
  const codes = getAllLanguageCodes();
  return codes.map((code) => ({ id: code, code, name: getLanguageName(code) }));
}
