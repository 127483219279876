export function buildSingleDisplayName(category) {
  if (category) {
    let displayName = category.name || "";

    if (category.parent?.name) {
      displayName = `${buildSingleDisplayName(category.parent)} - ${category.name}`;
    }
    return displayName;
  }
  return "";
}

export function extractProductCategoryItem(rawProductCategory) {
  if (rawProductCategory) {
    const displayName = buildSingleDisplayName(rawProductCategory);

    if (rawProductCategory.id) {
      return {
        id: rawProductCategory.id,
        name: displayName,
      };
    }
    return {};
  }
  return {};
}
