import React from "react";
import { Formik } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  intlShape,
  isEmpty,
  isNotEmpty,
  ActionBar,
  CancelButton,
  SubmitButton,
  withBackend,
  backendShape,
  withNotifier,
  withBrowser,
  notifierShape,
  browserShape,
  withApi,
  apiShape,
  FilesUpload,
} from "lcm-iot-commons";

import PropTypes from "prop-types";

export function OpcUaServerCaCertificateUpload({
  api,
  intl,
  backend,
  match,
  browser,
  notifier,
}) {
  React.useEffect(() => {
    const loadData = async () => {
      try {
        const subscription = await api.get(
          `/api_subscriptions/${match.params.subscription_id}`,
        );
        // eslint-disable-next-line camelcase
        const accessRightsResponse = await api.get(
          "/users/current/access_rights",
          {
            permitable_type: "ClientApplication",
            permitable_id: subscription?.client_application.id,
          },
        );
        if (!accessRightsResponse.can_update) {
          browser.navigateTo("/403");
        }
      } catch (error) {
        notifier.showError(backend.translateError(error));
        browser.navigateTo("/403");
      }
    };
    loadData();
  }, [match]);

  const submitData = async (values, actions) => {
    await backend.upload(
      `/api_subscriptions/${match.params.subscription_id}/opc_ua_servers/${match.params.id}/ca_certificates`,
      {
        file: values.fileUpload.acceptedFiles[0],
        name: values.fileUpload.acceptedFiles[0].name,
      },
    );
    actions.setSubmitting(false);
    notifier.showSuccess(
      intl.formatMessage({ id: "ca_cert_upload.success_notification" }),
    );
    browser.goBack();
  };

  const onSubmit = (values, actions) => {
    submitData(values, actions).catch((apiErrors) => {
      notifier.showError(backend.translateError(apiErrors));
      actions.setErrors(apiErrors);
      actions.setSubmitting(false);
    });
  };

  const validateForm = (values) => {
    const errors = {};

    if (
      !values.fileUpload ||
      (isEmpty(values.fileUpload.acceptedFiles) &&
        isEmpty(values.fileUpload.rejectedFiles))
    ) {
      errors.fileUpload = intl.formatMessage({
        id: "ca_cert_upload.file.mandatory",
      });
    }

    if (values.fileUpload && isNotEmpty(values.fileUpload.rejectedFiles)) {
      errors.fileUpload = intl.formatMessage({
        id: "ca_cert_upload.dropzone.rejection",
      });
    }

    return errors;
  };

  const renderForm = (formProps) => {
    const { handleSubmit, isSubmitting, setStatus } = formProps;

    /* istanbul ignore next */
    const beforeHandleSubmit = (event) => {
      /* istanbul ignore next */
      setStatus("submitted");
      /* istanbul ignore next */
      handleSubmit(event);
    };

    return (
      <form onSubmit={beforeHandleSubmit} noValidate>
        <FilesUpload
          {...formProps}
          name="fileUpload"
          multiple={false}
          whiteList={[".der"]}
        />
        <div className="btn-group">
          <SubmitButton
            id="opc-ua-server-ca-cert-upload-submit"
            fetching={isSubmitting}
          />
          <CancelButton
            id="opc-ua-server-ca-cert-upload-cancel"
            disabled={isSubmitting}
          />
        </div>
      </form>
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <ActionBar>
            <h1>
              <FormattedMessage id="ca_cert_upload.header" />
            </h1>
          </ActionBar>
        </div>
      </div>
      <div className="row">
        <div id="opc-ua-server-ca-cert-upload-details" className="col-sm-6">
          <Formik
            validate={validateForm}
            onSubmit={onSubmit}
            render={renderForm}
          />
        </div>
      </div>
    </div>
  );
}

OpcUaServerCaCertificateUpload.propTypes = {
  intl: intlShape.isRequired,
  backend: backendShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  api: apiShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      subscription_id: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(
  withBrowser(
    withApi(withNotifier(withBackend(OpcUaServerCaCertificateUpload))),
  ),
);
