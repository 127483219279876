import PropTypes from "prop-types";
import React from "react";
import {
  withIntl,
  withNotifier,
  isEmpty,
  intlShape,
  notifierShape,
  CopySaveModal,
} from "lcm-iot-commons";

export function RecoveryCodesModal({
  intl,
  recoveryCodes,
  show,
  notifier,
  onClose,
}) {
  const onSave = () => {
    const data = new Blob([recoveryCodes.join("\n")], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.setAttribute("download", "Netilion_Recovery_Codes.txt");
    document.body.appendChild(link);
    // start download of the file
    link.click();
    // clean up and remove link element
    link.parentNode.removeChild(link);
  };

  const onCopy = () => {
    notifier.showSuccess(
      intl.formatMessage({ id: "mfa.recovery_codes.copy_successfull" }),
    );
  };

  const renderRecoveryCodes = () => {
    const recoveryCodesListItems = isEmpty(recoveryCodes)
      ? null
      : recoveryCodes.map((recoveryCode) => (
          <li key={recoveryCode}>{recoveryCode}</li>
        ));

    return (
      <div>
        <div>
          <span>
            {intl.formatMessage({
              id: "mfa.recovery_codes.change.notification_1",
            })}
          </span>
        </div>
        <div className="space-before">
          <span>
            {intl.formatMessage({
              id: "mfa.recovery_codes.change.notification_2",
            })}
          </span>
        </div>
        <ul className="mfa-modal">{recoveryCodesListItems}</ul>
      </div>
    );
  };

  return show ? (
    <CopySaveModal
      id="copy-save-recovery-codes"
      showClose
      show={show}
      title={intl.formatMessage({ id: "mfa.recovery_codes" })}
      message={renderRecoveryCodes()}
      onSave={() => onSave()}
      copyValues={recoveryCodes.join("\n")}
      onCopyValue={() => onCopy()}
      onClose={() => onClose()}
    />
  ) : null;
}

RecoveryCodesModal.propTypes = {
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  recoveryCodes: PropTypes.arrayOf(PropTypes.string),
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

RecoveryCodesModal.defaultProps = {
  recoveryCodes: undefined,
};

export default withNotifier(withIntl(RecoveryCodesModal));
