/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import validator from "validator";
import {
  Form,
  Loader,
  Heading,
  Container,
  Row,
  Column,
  ActionBar,
  TextInput,
  TextArea,
  SelectBox,
  ButtonGroup,
  SubmitButton,
  CancelButton,
  BadRequestError,
  apiShape,
  intlShape,
  isEmpty,
  browserShape,
  notifierShape,
  withApi,
  withIntl,
  withBrowser,
  withNotifier,
  withAccessRights,
  handleFormikValueChange,
  pullValueFromSpec,
  pullUpdatedAtFromSpec,
  removeNullAttributes,
  setEmptyOrUndefinedToNull,
} from "lcm-iot-commons";
import FieldGatewaySpecificationsInput from "./FieldGatewaySpecificationsInput";
import validateModbusConfig from "./validateModbusConfig";
import ModbusImport from "./ModbusImport";
import {
  FieldGatewayStatuses,
  FieldGatewayTypes,
  EHEdmSpecificationKeys,
  EHEdmSpecificationValues,
  FieldGatewayConstants,
} from "./FieldGatewayConstants";
import FieldGatewayGenericModbusDetails from "./FieldGatewayGenericModbusDetails";

export const validateAccessRights = (accessRights) => accessRights.canUpdate;

const getChannelNameByString = (channel) =>
  channel === "0"
    ? {
        id: FieldGatewayConstants.ch0LiquilinePlattform,
        name: FieldGatewayConstants.ch0LiquilinePlattform,
      }
    : {
        id: `${FieldGatewayConstants.chPrefix}${channel}`,
        name: `${FieldGatewayConstants.chPrefix}${channel}`,
      };

const createAllAvailableModbusChannelOptions = () =>
  Array.from(Array(9).keys()).map((id) =>
    getChannelNameByString(id.toString()),
  );

const createModbusVariableOptions = (numOf, prefix) =>
  Array.from(Array(numOf).keys()).map((idx) => {
    const value = (idx + 1).toString().padStart(2, "0");
    return { id: `${prefix}${value}`, name: `${prefix}${value}` };
  });

const createAllAvailableModbusVariableOptions = () => [
  ...createModbusVariableOptions(16, "ai_"),
  ...createModbusVariableOptions(8, "di_"),
];

export const getInitialAiAndDiKeys = (response) => {
  if (!response.specifications) {
    return [];
  }
  return Object.keys(response.specifications).filter(
    (specKey) =>
      specKey.includes(FieldGatewayConstants.configAiKeyPrefix) ||
      specKey.includes(FieldGatewayConstants.configDiKeyPrefix),
  );
};

export const getInitialGenericModbusKeys = (response) => {
  if (!response.specifications) {
    return [];
  }
  return Object.keys(response.specifications).filter((specKey) =>
    specKey.includes(FieldGatewayConstants.configModbusKeyPrefix),
  );
};

export const getInitialConfigStatusKeys = (response) => {
  if (!response.specifications) {
    return [];
  }
  return Object.keys(response.specifications).filter((specKey) =>
    specKey.includes(FieldGatewayConstants.configStatusKeyPrefix),
  );
};

export const getAllSelectedVariables = (usedVariables) =>
  usedVariables
    .filter((usedVariable) => usedVariable.split(".").length === 2)
    .map((usedVariable) => {
      const usedVariableSubKeys = usedVariable.split(".");
      return { id: usedVariableSubKeys[1], name: usedVariableSubKeys[1] };
    });

export const getAvailableVariables = (allVariables, usedVariables) =>
  allVariables.filter((allVariable) =>
    usedVariables.every(
      (usedVariable) =>
        JSON.stringify(allVariable) !== JSON.stringify(usedVariable),
    ),
  );

const getRemovedKeys = (initialSpecs, update) => {
  if (!initialSpecs || !initialSpecs.length) {
    return [];
  }
  return initialSpecs.filter((x) => !update.includes(x));
};

export const getRemovedAiAndDiKeys = (initialSpecs, currentSpecs) =>
  getRemovedKeys(initialSpecs, getInitialAiAndDiKeys(currentSpecs));
export const getRemovedGenericModbusKeys = (initialSpecs, currentSpecs) =>
  getRemovedKeys(initialSpecs, getInitialGenericModbusKeys(currentSpecs));
export const getRemovedConfigStatusKeys = (initialSpecs, currentSpecs) =>
  getRemovedKeys(initialSpecs, getInitialConfigStatusKeys(currentSpecs));

export const getRemovedSpecificationKeys = (values, payload) => {
  const removedAiDiKeys = getRemovedAiAndDiKeys(
    values.initialAiAndDiKeys,
    payload,
  );
  const removedGenericModbusKeys = getRemovedGenericModbusKeys(
    values.initialGenericModbusKeys,
    payload,
  );
  const removedConfigStatusKeys = getRemovedConfigStatusKeys(
    values.initialConfigStatusKeys,
    payload,
  );
  return removedAiDiKeys
    .concat(removedGenericModbusKeys)
    .concat(removedConfigStatusKeys);
};

const allAvailableModbusChannelOptions =
  createAllAvailableModbusChannelOptions();
const allAvailableModbusVariableOptions =
  createAllAvailableModbusVariableOptions();

const getStringAsShownInValueApp = (orgName) =>
  orgName
    .replaceAll(".", " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");

export const sortByModbusRegister = (modbusConfig) =>
  modbusConfig
    .slice()
    .sort((a, b) => a.regName.name.localeCompare(b.regName.name));

export const getModbusConfig = (response) => {
  if (!response.specifications) {
    return [];
  }
  const result = [];
  const specificationNames = getInitialAiAndDiKeys(response);
  specificationNames.forEach((specName) => {
    const specValue = pullValueFromSpec(response.specifications, [
      specName,
    ]).split(";");
    const specSubKeys = specName.split(".");
    if (specValue.length === 2 && specSubKeys.length === 2) {
      result.push({
        valueName: getStringAsShownInValueApp(specValue[1]),
        channel: getChannelNameByString(specValue[0]),
        regName: { id: specSubKeys[1], name: specSubKeys[1] },
      });
    }
  });

  return sortByModbusRegister(result);
};

export const getGenericModbusConfig = (response) => {
  if (!response.specifications) {
    return [];
  }
  const specificationNames = getInitialGenericModbusKeys(response);
  return specificationNames.map((specName) => ({
    key: specName,
    value: `${pullValueFromSpec(response.specifications, [specName])}`,
    updated_at: `${pullUpdatedAtFromSpec(response.specifications, [specName])}`,
  }));
};

export const getModbusMapping = (modbusConfig) =>
  modbusConfig
    .filter((item) => item.regName?.id && item.regName?.name)
    .map((item) => item.regName);

export function extractInitialValues(response) {
  const initialValues = { ...response };
  initialValues.status = response.status.name;
  initialValues.port = response.port?.toString();
  initialValues.hart_address = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_HART_ADDRESS,
  ]);
  initialValues.serial_number = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_FGW_SERIAL_NUMBER,
  ]);
  initialValues.tag = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_FGW_TAG,
  ]);
  initialValues.byte_order = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_FGW_BYTE_ORDER,
  ]);
  initialValues.unit_identifier = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_FGW_UNIT_IDENTIFIER,
  ]);
  initialValues.allAvailableChannels = allAvailableModbusChannelOptions;
  initialValues.allAvailableVariables = allAvailableModbusVariableOptions;
  initialValues.genericModbusConfig = getGenericModbusConfig(response);
  initialValues.initialGenericModbusKeys =
    getInitialGenericModbusKeys(response);
  initialValues.initialConfigStatusKeys = getInitialConfigStatusKeys(response);
  if (initialValues.type?.code === FieldGatewayTypes.CM44MODBUSTCP) {
    initialValues.modbusConfig = getModbusConfig(response);
    initialValues.initialAiAndDiKeys = getInitialAiAndDiKeys(response);
    initialValues.selectedVariables = getAllSelectedVariables(
      initialValues.initialAiAndDiKeys,
    );
    initialValues.availableVariables = getAvailableVariables(
      allAvailableModbusVariableOptions,
      initialValues.selectedVariables,
    );
  } else if (
    initialValues.type?.code === FieldGatewayTypes.GENERIC_MODBUS_TCP
  ) {
    initialValues.byte_order = initialValues.byte_order ?? "0";
    initialValues.unit_identifier = initialValues.unit_identifier ?? 0;
  }
  return initialValues;
}

const removeChannelPrefixAndLiquilineText = (content) =>
  content
    .replaceAll(FieldGatewayConstants.chPrefix, "")
    .replaceAll(FieldGatewayConstants.ch0LiquilinePlattform, "0");

export const createPayload = (values) => {
  const { type, name, description, ip_address, hart_address, port } = values;
  const payload = {
    type: { id: type.id },
    name: name?.trim(),
    description: description?.trim(),
    ip_address,
  };
  if (
    type.code === FieldGatewayTypes.SWG70 ||
    type.code === FieldGatewayTypes.SWG50
  ) {
    payload.port = port || 5094;
    payload.specifications = {
      [`${EHEdmSpecificationKeys.EH_EDM_HART_ADDRESS}`]: {
        value: hart_address || "1",
      },
    };
  } else if (type.code === FieldGatewayTypes.NXA820) {
    payload.port = port || 3000;
  } else if (type.code === FieldGatewayTypes.CM44MODBUSTCP) {
    payload.specifications = {};

    values.modbusConfig
      ?.filter(
        (element) =>
          element.regName?.name &&
          element.channel?.name &&
          element.valueName.length,
      )
      .forEach((element) => {
        payload.specifications[
          `${FieldGatewayConstants.configKeyPrefix}${element.regName.name}`
        ] = {
          value: `${removeChannelPrefixAndLiquilineText(element.channel.name)};${element.valueName.replaceAll(" ", ".")}`,
        };
        payload.specifications[
          `${FieldGatewayConstants.configKeyPrefix}${element.regName.name}`.replace(
            `${FieldGatewayConstants.configKeyPrefix}`,
            `${FieldGatewayConstants.configStatusKeyPrefix}`,
          )
        ] = {
          value: `${EHEdmSpecificationValues.CONFIG_STATUS_UNDEFINED}`,
        };
      });
  } else if (type.code === FieldGatewayTypes.GENERIC_MODBUS_TCP) {
    payload.specifications = {
      [`${EHEdmSpecificationKeys.EH_EDM_FGW_SERIAL_NUMBER}`]: {
        value: values.serial_number,
      },
      [`${EHEdmSpecificationKeys.EH_EDM_FGW_TAG}`]: { value: values.tag },
      [`${EHEdmSpecificationKeys.EH_EDM_FGW_BYTE_ORDER}`]: {
        value: values.byte_order,
      },
      [`${EHEdmSpecificationKeys.EH_EDM_FGW_UNIT_IDENTIFIER}`]: {
        value: values.unit_identifier,
      },
      [`${EHEdmSpecificationKeys.CONFIG_STATUS_SERIAL_NUMBER}`]: {
        value: `${EHEdmSpecificationValues.CONFIG_STATUS_UNDEFINED}`,
      },
      [`${EHEdmSpecificationKeys.CONFIG_STATUS_TAG}`]: {
        value: `${EHEdmSpecificationValues.CONFIG_STATUS_UNDEFINED}`,
      },
      [`${EHEdmSpecificationKeys.CONFIG_STATUS_BYTE_ORDER}`]: {
        value: `${EHEdmSpecificationValues.CONFIG_STATUS_UNDEFINED}`,
      },
      [`${EHEdmSpecificationKeys.CONFIG_STATUS_UNIT_IDENTIFIER}`]: {
        value: `${EHEdmSpecificationValues.CONFIG_STATUS_UNDEFINED}`,
      },
    };
  }
  if (
    type.code === FieldGatewayTypes.CM44MODBUSTCP ||
    type.code === FieldGatewayTypes.GENERIC_MODBUS_TCP
  ) {
    values.genericModbusConfig
      ?.filter((element) => element?.key && element?.value)
      .forEach((element) => {
        payload.specifications[element.key] = { value: element.value };
        payload.specifications[
          element.key.replace(
            FieldGatewayConstants.configModbusKeyPrefix,
            FieldGatewayConstants.configStatusModbusKeyPrefix,
          )
        ] = { value: `${EHEdmSpecificationValues.CONFIG_STATUS_UNDEFINED}` };
      });
  }

  return setEmptyOrUndefinedToNull(payload);
};

export const isValidImportData = (data, code) => {
  if (data.config_type === "specifications" && data.config_version === 1) {
    if (
      code === FieldGatewayTypes.CM44MODBUSTCP &&
      data.byte_order === undefined &&
      data.unit_identifier === undefined
    ) {
      return true;
    }
    if (
      code === FieldGatewayTypes.GENERIC_MODBUS_TCP &&
      data.byte_order !== undefined &&
      data.unit_identifier !== undefined
    ) {
      return (
        data.byte_order >= 0 &&
        data.byte_order <= 3 &&
        data.unit_identifier >= 0 &&
        data.unit_identifier <= 255
      );
    }
  }
  return false;
};

export const onImportData = (data, deviceTypeCode) => {
  const convertedData = { modbusConfig: [], genericModbusConfig: [] };
  if (isValidImportData(data, deviceTypeCode)) {
    const toConvert = { specifications: data.configuration };
    if (data.byte_order !== undefined) {
      convertedData.byte_order = data.byte_order;
    }
    if (data.unit_identifier !== undefined) {
      convertedData.unit_identifier = data.unit_identifier;
    }
    convertedData.modbusConfig = getModbusConfig(toConvert);
    convertedData.genericModbusConfig = getGenericModbusConfig(toConvert);
  } else {
    convertedData.uploadError = true;
  }

  return convertedData;
};

export const validateSerialNumber = (intl, serial_number) => {
  if (isEmpty(serial_number) || isEmpty(serial_number.trim())) {
    return intl.formatMessage({
      id: "validation.modbus.config.serial_number.mandatory",
    });
  }
  if (serial_number.trim().length < 4) {
    return intl.formatMessage({
      id: "validation.modbus.config.serial_number.too_short",
    });
  }
  if (serial_number.length > 255) {
    return intl.formatMessage({
      id: "validation.modbus.config.serial_number.too_long",
    });
  }
  return undefined;
};

export const validateTag = (intl, tag) => {
  if (isEmpty(tag) || isEmpty(tag.trim())) {
    return intl.formatMessage({ id: "validation.modbus.config.tag.mandatory" });
  }
  if (tag.length > 255) {
    return intl.formatMessage({ id: "validation.modbus.config.tag.too_long" });
  }
  return undefined;
};
export function FieldGatewayCreateEdit(props) {
  const { api, intl, notifier, browser, match } = props;
  const { id, fieldGatewayId } = match.params;
  const [initialValues, setInitialValues] = useState();
  const [statusOptions, setStatusOptions] = useState();
  const [typeOptions, setTypeOptions] = useState();
  const creating = !fieldGatewayId;

  const loadData = async () => {
    try {
      const [statuses, types] = await Promise.all([
        api.get("/edm/field_gateway/statuses"),
        api.get("/edm/field_gateway/types"),
      ]);
      setStatusOptions(statuses.field_gateway_statuses);
      setTypeOptions(
        types.field_gateway_types.filter(
          (item) => item.code !== FieldGatewayTypes.UNDEFINED,
        ),
      );
      if (creating) {
        setInitialValues({
          type: types.field_gateway_types.find(
            (item) => item.code === FieldGatewayTypes.SFG500,
          ),
          allAvailableChannels: [...allAvailableModbusChannelOptions],
          allAvailableVariables: [...allAvailableModbusVariableOptions],
        });
        return;
      }

      const response = await api.get(
        `/edm/edge_devices/${id}/field_gateways/${fieldGatewayId}`,
        { include: "status, type, specifications" },
        false,
      );
      const initValues = extractInitialValues(response);
      setInitialValues(initValues);
    } catch (error) {
      notifier.showError(api.translateError(error));
      setInitialValues({});
    }
  };
  React.useEffect(() => {
    loadData();
  }, []);

  const onSubmit = async (values, actions) => {
    const payload = createPayload(values);
    const statusUndefined = statusOptions.find(
      (item) => item.code === FieldGatewayStatuses.UNDEFINED,
    );
    if (statusUndefined) {
      payload.status = { id: statusUndefined.id };
    }
    let field_gateway_id = fieldGatewayId;
    try {
      if (creating) {
        const response = await api.post(
          `/edm/edge_devices/${id}/field_gateways`,
          payload,
        ); // create
        field_gateway_id = response.id;
      } else {
        await api.patch(
          `/edm/edge_devices/${id}/field_gateways/${fieldGatewayId}`,
          payload,
        ); // update
      }

      if (payload.specifications) {
        await api.patch(
          `/edm/edge_devices/${id}/field_gateways/${field_gateway_id}/specifications`,
          payload.specifications,
        );
      }

      const removedSpecificationKeys = getRemovedSpecificationKeys(
        values,
        payload,
      );
      if (removedSpecificationKeys && removedSpecificationKeys.length) {
        await api.delete(
          `/edm/edge_devices/${id}/field_gateways/${field_gateway_id}/specifications`,
          removedSpecificationKeys,
        );
      }

      notifier.showSuccess(
        intl.formatMessage({ id: "edit_field_gateway.success_notification" }),
      );
      browser.navigateTo(
        `/edge_devices/${id}/field_gateways/${field_gateway_id}`,
      );
    } catch (error) {
      if (error instanceof BadRequestError && error.contains("taken")) {
        notifier.showError(
          intl.formatMessage({ id: "api.error.ip_address.taken" }),
        );
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validateForm = (values) => {
    const { type, ip_address, hart_address, port, serial_number, tag } = values;
    const errors = {};
    errors.ip_address = validator.isIP(ip_address || "")
      ? null
      : intl.formatMessage({ id: "validation.ip_address.invalid" });
    if (
      type.code === FieldGatewayTypes.SWG70 ||
      type.code === FieldGatewayTypes.SWG50
    ) {
      if (port !== undefined) {
        errors.port = validator.isPort(port.toString())
          ? null
          : intl.formatMessage({ id: "validation.port.invalid" });
      }
      if (hart_address !== undefined) {
        errors.hart_address = validator.isInt(hart_address, { min: 0, max: 63 })
          ? null
          : intl.formatMessage({ id: "validation.hart_address.invalid" });
      }
    } else if (type.code === FieldGatewayTypes.NXA820) {
      if (port !== undefined) {
        errors.port = validator.isPort(port.toString())
          ? null
          : intl.formatMessage({ id: "validation.port.invalid" });
      }
    } else if (type.code === FieldGatewayTypes.GENERIC_MODBUS_TCP) {
      const serialNumberErrors = validateSerialNumber(intl, serial_number);
      if (serialNumberErrors !== undefined) {
        errors.serial_number = serialNumberErrors;
      }
      const tagErrors = validateTag(intl, tag);
      if (tagErrors !== undefined) {
        errors.tag = tagErrors;
      }
    }

    const specificationErrors = validateModbusConfig(intl, values);
    if (specificationErrors) {
      errors.modbusConfig = specificationErrors;
    }

    return removeNullAttributes(errors);
  };

  const renderForm = (formProps) => {
    const { isSubmitting, values } = formProps;
    const isSwg70OrSwg50 =
      values.type?.code === FieldGatewayTypes.SWG70 ||
      values.type?.code === FieldGatewayTypes.SWG50;
    const isNXA820 = values.type?.code === FieldGatewayTypes.NXA820;
    const isMBTCPLiquiline =
      values.type?.code === FieldGatewayTypes.CM44MODBUSTCP;
    const isMBTCPGeneric =
      values.type?.code === FieldGatewayTypes.GENERIC_MODBUS_TCP;
    return (
      <Form {...formProps}>
        <SelectBox
          {...formProps}
          id="type"
          name="type"
          labelKey="name"
          valueKey="code"
          label={intl.formatMessage({ id: "label.type" })}
          options={typeOptions}
          handleChange={(event) =>
            handleFormikValueChange(
              { ...formProps, key: "type", name: "type" },
              event.target.value,
            )
          }
          required
        />
        <TextInput
          {...formProps}
          id="name"
          name="name"
          label={intl.formatMessage({ id: "label.name" })}
        />
        <TextArea
          {...formProps}
          id="description"
          name="description"
          label={intl.formatMessage({ id: "label.description" })}
        />
        <TextInput
          {...formProps}
          id="ip-address"
          name="ip_address"
          label={intl.formatMessage({ id: "label.ip_address" })}
          placeholder={intl.formatMessage({ id: "general.default_ip" })}
          required
        />
        {isSwg70OrSwg50 && (
          <div id="section-swg70-swg50">
            <TextInput
              {...formProps}
              id="hart-address"
              name="hart_address"
              label={intl.formatMessage({ id: "label.hart_address" })}
              placeholder={intl.formatMessage({
                id: "field_gateway.hart_address.placeholder",
              })}
            />
            <TextInput
              {...formProps}
              id="port"
              name="port"
              label={intl.formatMessage({ id: "label.port" })}
            />
          </div>
        )}
        {isNXA820 && (
          <div id="section-nxa820">
            <TextInput
              {...formProps}
              id="port"
              name="port"
              label={intl.formatMessage({ id: "label.port" })}
            />
          </div>
        )}
        {isMBTCPGeneric && (
          <div id="section-generic-modbus-tcp">
            <TextInput
              {...formProps}
              id="serial-number"
              name="serial_number"
              label={intl.formatMessage({ id: "label.serial_number" })}
              required
            />
            <TextInput
              {...formProps}
              id="tag"
              name="tag"
              label={intl.formatMessage({ id: "label.tag" })}
              required
            />
          </div>
        )}
        {(isMBTCPGeneric || isMBTCPLiquiline) && (
          <div id="section-modbus-import">
            <ModbusImport
              {...formProps}
              onImport={onImportData}
              deviceTypeCode={values.type?.code}
            />
          </div>
        )}
        {isMBTCPLiquiline && (
          <div id="section-cm44">
            <FieldGatewaySpecificationsInput {...formProps} />
          </div>
        )}
        {(isMBTCPGeneric || isMBTCPLiquiline) && (
          <div id="section-modbus-details">
            <FieldGatewayGenericModbusDetails
              specifications={values.genericModbusConfig}
              serialNumber={values.serial_number || "-"}
              subAddress={values.unit_identifier || 0}
            />
            <p />
          </div>
        )}
        <ButtonGroup>
          <SubmitButton
            id="form-submit-button"
            fetching={!initialValues || isSubmitting}
            disabled={!formProps.dirty || isSubmitting}
          />
          <CancelButton
            id="form-cancel-button"
            fetching={!initialValues || isSubmitting}
            disabled={isSubmitting}
          />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading
              id={
                creating
                  ? "create-field-gateway-header"
                  : "edit-field-gateway-header"
              }
              title={
                creating
                  ? intl.formatMessage({ id: "create_field_gateway.header" })
                  : intl.formatMessage({ id: "edit_field_gateway.header" })
              }
            />
          </ActionBar>
        </Column>
      </Row>
      {initialValues && (
        <Row>
          <Column>
            <Formik
              id="field-gateway-form"
              onSubmit={onSubmit}
              validate={validateForm}
              initialValues={initialValues}
              render={renderForm}
            />
          </Column>
        </Row>
      )}
      <Loader loading={!initialValues} />
    </Container>
  );
}

FieldGatewayCreateEdit.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      fieldGatewayId: PropTypes.string,
    }),
  }).isRequired,
};

export default withBrowser(
  withApi(
    withIntl(
      withNotifier(
        withAccessRights(
          FieldGatewayCreateEdit,
          "EDM::EdgeDevice",
          validateAccessRights,
        ),
      ),
    ),
  ),
);
