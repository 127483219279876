const ObjectsType = {
  Nodes: "nodes",
  Assets: "assets",
  Systems: "systems",
  Recipes: "recipes",
  Batches: "batches",
  Instrumentations: "instrumentations",
  FilteredInstrumentations: "filteredInstrumentations",
};

export const toCapitalizedSingular = (objectType) => {
  switch (objectType) {
    case ObjectsType.Batches:
      return "Batch";
    default:
      return (
        objectType.charAt(0).toUpperCase() +
        objectType.slice(1, objectType.length - 1)
      );
  }
};

export default ObjectsType;
