import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { intlShape, accessRightsShape } from "../../shapes";
import AssignableUserList from "../Users/AssignableUserList";
import {
  ActionBar,
  ActionBarButtons,
  EditActionButton,
  DeleteActionButton,
} from "../ActionBar";
import Loader from "../Loader";
import { DetailsItem } from "../Details";
import { NotFoundError } from "../../api";

import { sortBy } from "../../utils";
import { withAccessRights, withIntl } from "../../wrappers";
import {
  apiShape,
  browserShape,
  notifierShape,
  withApi,
  withBrowser,
  withNotifier,
} from "../../context";
import { Column, Container, Row } from "../Grid";
import BackButton from "../BackButton";

export function UserGroupDetails({
  intl,
  match,
  accessRights,
  api,
  notifier,
  browser,
}) {
  const [fetching, setFetching] = useState(false);
  const [usergroup, setUsergroup] = useState();
  const [users, setUsers] = useState([]);

  React.useEffect(() => {
    const loadData = async () => {
      setFetching(true);
      try {
        const [response, sortedUsers] = await Promise.all([
          api.get(`/usergroups/${match.params.id}`, {}),
          api.getAll(`/usergroups/${match.params.id}/users`, {
            order_by: "first_name",
            include: "technical_connect_users",
          }),
        ]);
        setUsergroup(response);
        setUsers(sortedUsers.users);
      } catch (error) {
        if (error instanceof NotFoundError) {
          browser.navigateTo("/404");
        } else {
          notifier.showError(api.translateError(error));
        }
      } finally {
        setFetching(false);
      }
    };
    loadData();
  }, []);

  const onConfirmDelete = async () => {
    try {
      await api.delete(`/usergroups/${match.params.id}/`);
      notifier.showSuccess(
        intl.formatMessage({ id: "user_group.actions.delete.notification" }),
      );
      browser.goBack();
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const handleOnAssignUser = async (user) => {
    await api.post(`/usergroups/${usergroup.id}/users`, {
      users: [{ id: user.id }],
    });
    setUsers(sortBy(users.concat([{ ...{ ...user } }]), "first_name"));
  };

  const handleOnUnassignUser = async (user) => {
    await api.delete(`/usergroups/${usergroup.id}/users`, {
      users: [{ id: user.id }],
    });
    setUsers(users.filter((u) => u.id !== user.id));
  };

  const usergroupElement = usergroup ? (
    <div>
      <DetailsItem
        id="user-group-name"
        translationKey="label.name"
        value={usergroup.name}
      />
      <DetailsItem
        id="user-group-description"
        translationKey="label.description"
        value={usergroup.description}
      />
    </div>
  ) : null;

  const usersList =
    usergroup && users && accessRights ? (
      <div id="user-list">
        <h2>
          <FormattedMessage id="users.header" />
        </h2>
        <AssignableUserList
          users={users}
          onAssignUser={handleOnAssignUser}
          onUnassignUser={handleOnUnassignUser}
          canUpdate={accessRights.canUpdate}
          acceptConnectUsers
        />
      </div>
    ) : null;

  return (
    <Container>
      <Row>
        <Column>
          <BackButton />
        </Column>
      </Row>
      <Row>
        <Column>
          <ActionBar>
            <h1 id="user-group-details-header">
              <FormattedMessage id="user_group_details.header" />
            </h1>
            <ActionBarButtons>
              <EditActionButton
                id="edit-user-group-button"
                target={`/usergroups/${match.params.id}/edit`}
                disabled={!accessRights.canUpdate}
              />
              <DeleteActionButton
                id="delete-user-group-button"
                disabled={!accessRights.canDelete}
                modalTitle={intl.t`user_group.actions.delete.modal_title`}
                modalMessage={intl.t`user_group.actions.delete.modal_message`}
                onConfirm={onConfirmDelete}
              />
            </ActionBarButtons>
          </ActionBar>
        </Column>
      </Row>
      <Row>
        <Column>
          {usergroupElement}
          {usersList}
        </Column>
      </Row>
      <Loader id="user-group-details-loader" loading={fetching} />
    </Container>
  );
}

UserGroupDetails.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
  accessRights: accessRightsShape,
  api: apiShape,
  notifier: notifierShape,
  browser: browserShape,
};

export default withIntl(
  withApi(
    withBrowser(withNotifier(withAccessRights(UserGroupDetails, "Usergroup"))),
  ),
);
