import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import { ActionBar } from "../ActionBar";
import Loader from "../Loader";
import InstrumentationForm from "./InstrumentationForm";
import ConfirmationModal from "../ConfirmationModal";
import InstrumentationDuplicateModal from "./InstrumentationDuplicateModal";
import { extractInstrumentations } from "../../extractors/instrumentationsExtractor";

import {
  parseIntlNumber,
  url,
  findMatchingInstrumentationType,
  htmlFormat,
  convertLabelToSpecificationKey,
} from "../../utils";

import {
  extractAsset,
  extractInstrumentationStatuses,
  extractInstrumentationType,
  extractInstrumentationTypes,
} from "../../extractors";
import { packInstrumentationSpecifications } from "../../packmans";

import {
  withRules,
  rulesShape,
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  withBrowser,
  browserShape,
  withBackend,
  backendShape,
} from "../../context";

import { intlShape } from "../../shapes";
import { Column, Container, Row } from "../Grid";
import BackButton from "../BackButton";

export function InstrumentationCreate({
  intl,
  api,
  backend,
  browser,
  match,
  notifier,
  rules,
  isSubComponent,
  cancelTarget,
}) {
  const [showActivationModal, toggleActivationModal] = useState(false);
  const [showDuplicateTagModal, toggleDuplicateTagModal] = useState(false);
  const [instrumentationDetails, setInstrumentationDetails] = useState();
  const [duplicateTagValues, setDuplicateTagValues] = useState();
  const [duplicateTags, setDuplicateTags] = useState([]);
  const [duplicateActions, setDuplicateActions] = useState();
  const instrumentationRules =
    instrumentationDetails && instrumentationDetails.asset
      ? rules.instrumentation(undefined, [instrumentationDetails.asset])
      : undefined;

  // eslint-disable-next-line camelcase
  const loadProductLookupTag = async (serial_number) => {
    try {
      const lookupResponse = await api.get("/endress/product_lookup", {
        // eslint-disable-next-line camelcase
        serial_number,
        include: "tag",
      });
      return lookupResponse.tag;
    } catch (error) {
      return undefined;
    }
  };

  const loadData = async () => {
    try {
      let assetLoaded;
      let firmwareLoaded;
      let firmwareVersion;
      let defaultType = "undefined";
      let tag;
      const tagTypes = await api.getAll("/instrumentation/types", {
        include: "tenant",
      });
      const tenantIdsResponse = await api.getAll("/tenants", { public: true });
      const publicTenantIds = tenantIdsResponse.tenants.map(
        (tenant) => tenant.id,
      );
      const tagStatuses = await api.getAll("/instrumentation/statuses", {
        tenant_id: publicTenantIds.toString(),
      });

      if (
        match.params &&
        match.params.assetId &&
        match.params.assetId !== "undefined"
      ) {
        assetLoaded = await api.get(`/assets/${match.params.assetId}`, {
          include: "product,product.manufacturer,product.tenant",
        });
        firmwareLoaded = await api.get(
          `/assets/${match.params.assetId}/softwares`,
          { software_type_id: 1 },
        );
        // eslint-disable-next-line camelcase
        tag =
          isSubComponent && assetLoaded?.manufacturer?.name === "Endress+Hauser"
            ? await loadProductLookupTag(assetLoaded.serial_number)
            : undefined;

        if (
          firmwareLoaded &&
          firmwareLoaded.softwares &&
          firmwareLoaded.softwares.length > 0
        ) {
          firmwareVersion = firmwareLoaded.softwares[0].version_number;
        }

        const product = await api.get(`/products/${assetLoaded.product.id}`, {
          include: "categories.parent",
        });
        const tagType = findMatchingInstrumentationType(
          product.categories,
          tagTypes.instrumentation_types,
        );
        defaultType = tagType ? tagType.code : "undefined";
      }

      let defaultTagType = tagTypes.instrumentation_types.find(
        (t) => t.code === defaultType,
      );
      if (defaultTagType) {
        defaultTagType = extractInstrumentationType(defaultTagType);
      }

      const instrumentationDefaultValues = {
        status: tagStatuses.instrumentation_statuses.find(
          (s) => s.code === "undefined",
        ),
        type: defaultTagType,
        tag,
      };
      setInstrumentationDetails({
        defaultValues: instrumentationDefaultValues,
        asset: assetLoaded ? extractAsset(assetLoaded) : null,
        firmwareVersion,
        statuses: extractInstrumentationStatuses(tagStatuses),
        types: extractInstrumentationTypes(tagTypes),
      });
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const onConfirmActivationModal = () => {
    toggleActivationModal(false);
    browser.navigateTo("/nodes");
  };

  const onCloseActivationModal = () => {
    toggleActivationModal(false);
  };

  const onSubmit = async (values, actions) => {
    try {
      let existingInstrumentations = [];
      if (!showDuplicateTagModal && isSubComponent) {
        const result = await api.getAll("/instrumentations", {
          include: "type",
          tag: values.tag,
          permission: "can_permit",
        });
        existingInstrumentations = extractInstrumentations(result);
      }
      setDuplicateTags(existingInstrumentations);
      if (existingInstrumentations && existingInstrumentations.length > 0) {
        toggleDuplicateTagModal(true);
        setDuplicateTagValues(values);
        setDuplicateActions(actions);
        actions.setSubmitting(false);
      } else {
        actions.setSubmitting(true);
        toggleDuplicateTagModal(false);
        setDuplicateTags([]);
        let packSpecificationValues = instrumentationRules?.get(
          "needsFullEmptySettings",
        )
          ? {
              ...values,
              full: parseIntlNumber(values.full).toString(),
              empty: parseIntlNumber(values.empty).toString(),
              blockDistance: parseIntlNumber(values.blockDistance).toString(),
              sensitivity: values.sensitivity.id,
            }
          : values;

        if (values.mediumType) {
          packSpecificationValues = {
            ...packSpecificationValues,
            mediumType: values.mediumType.id,
          };
        }

        const newTag = {
          tag: values.tag,
          description: values.description,
          status: { id: values.status.id },
          type: { id: values.type.id },
          criticality: values.criticality
            ? values.criticality.id.toLowerCase()
            : null,
          accessibility: values.accessibility
            ? values.accessibility.id.toLowerCase()
            : null,
        };
        if (!values.type.tenantPublic) {
          // FIXME: this assigns the tenant of the type when creating a new instrumentation. We need real tenant handling instead here!
          newTag.tenant = { id: values.type.tenantId };
        }

        const tag = await api.post("/instrumentations", newTag);
        if (match.params.nodeId) {
          await api.post(`/nodes/${match.params.nodeId}/instrumentations`, {
            instrumentations: [{ id: tag.id }],
          });
        }

        const specifications = packInstrumentationSpecifications(
          packSpecificationValues,
        );

        values.specifications?.forEach((specification) => {
          if (specification.key !== "" && specification.key?.name) {
            specifications[
              convertLabelToSpecificationKey(specification.key.name)
            ] = {
              value: specification.value,
              ui_visible: true,
            };
          }
        });

        await api.patch(
          `/instrumentations/${tag.id}/specifications`,
          specifications,
        );

        if (
          match.params &&
          match.params.assetId &&
          match.params.assetId !== "undefined"
        ) {
          await api.patch(`/instrumentations/${tag.id}/assets`, {
            assets: [{ id: match.params.assetId }],
          });
          if (match.params && match.params.nodeId) {
            await api.delete(`/assets/${match.params.assetId}/nodes`, {
              nodes: [{ id: match.params.nodeId }],
            });
          }
        }

        if (instrumentationRules?.get("needsFullEmptySettings")) {
          await backend.patch(`/connected_instrumentations/${tag.id}`);
        }

        actions.setSubmitting(false);
        notifier.showSuccess(
          intl.formatMessage({
            id: "instrumentation_create.success_notification",
          }),
        );
        if (
          instrumentationRules?.get("navigateToIndexAfterInstrumentationCreate")
        ) {
          toggleActivationModal(true);
        } else {
          browser.navigateTo(`/instrumentations/${tag.id}`);
        }
      }
    } catch (error) {
      actions.setSubmitting(false);
      notifier.showError(api.translateError(error));
    }
  };

  const onCreateDuplicate = async () => {
    toggleDuplicateTagModal(false);
    await onSubmit(duplicateTagValues, duplicateActions);
  };

  const onCloseDuplicate = () => {
    toggleDuplicateTagModal(false);
  };

  const duplicateInstrumentationModal =
    duplicateTags?.length > 0 ? (
      <InstrumentationDuplicateModal
        id="duplicate-instrumentation-modal-dialog"
        onCreateDuplicate={onCreateDuplicate}
        onCloseDuplicate={onCloseDuplicate}
        duplicateTags={duplicateTags}
        intl={intl}
        assetId={match.params.assetId}
      />
    ) : null;

  const instrumentationForm = instrumentationDetails ? (
    <Row>
      <Column sm="6">
        {instrumentationRules?.get("showModalActivation") ? (
          <ConfirmationModal
            id="configured-radar-info-modal-dialog"
            show={showActivationModal}
            titleText={intl.formatMessage({
              id: instrumentationRules.get("modalActivationTitleKey"),
            })}
            buttonConfirmText={intl.formatMessage({ id: "button.ok" })}
            messageText={
              <FormattedMessage
                id={instrumentationRules.get("modalActivationTextKey")}
                values={htmlFormat}
              />
            }
            onConfirm={onConfirmActivationModal}
            onClose={onCloseActivationModal}
            image={url(instrumentationRules.get("modalActivationImagePath"))}
            showCancel={false}
          />
        ) : null}
        <InstrumentationForm
          id="instrumentation-Form"
          intl={intl}
          instrumentationStatuses={instrumentationDetails.statuses}
          instrumentationTypes={instrumentationDetails.types}
          defaultValues={instrumentationDetails.defaultValues}
          assetFirmwareVersion={instrumentationDetails.firmwareVersion}
          onSubmit={onSubmit}
          cancelTarget={cancelTarget}
          instrumentationRules={instrumentationRules}
        />
      </Column>
    </Row>
  ) : null;

  return isSubComponent ? (
    <div>
      <Row>
        <Column>
          <h2>
            <FormattedMessage id="instrumentation_create_new.header" />
          </h2>
        </Column>
      </Row>
      {instrumentationForm}
      {showDuplicateTagModal && duplicateTags.length > 0
        ? duplicateInstrumentationModal
        : null}
      <Row>
        <Column>
          <Loader loading={!instrumentationDetails} />
        </Column>
      </Row>
    </div>
  ) : (
    <Container>
      <Row>
        <Column>
          <BackButton />
        </Column>
      </Row>
      <Row>
        <Column>
          <ActionBar>
            <h1>
              <FormattedMessage id="instrumentation_create.header" />
            </h1>
          </ActionBar>
        </Column>
      </Row>
      {instrumentationForm}
      <Row>
        <Column>
          <Loader loading={!instrumentationDetails} />
        </Column>
      </Row>
    </Container>
  );
}

InstrumentationCreate.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetId: PropTypes.string,
      nodeId: PropTypes.string,
    }),
  }),
  isSubComponent: PropTypes.bool,
  cancelTarget: PropTypes.string,
  rules: rulesShape,
  notifier: notifierShape,
  api: apiShape,
  backend: backendShape,
  browser: browserShape,
};

export default withRules(
  withBrowser(
    withNotifier(withApi(withBackend(injectIntl(InstrumentationCreate)))),
  ),
);
