import { lazy } from "react";
import { withSuspense } from "../../wrappers";

const ComponentLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "qr-code-scanner" */ "./SearchQrCodeScannerMain"
    ),
);

export default withSuspense(ComponentLazy, "SearchQrCodeScanner");
