import { backendApiClient, packPlanCartCreate } from "lcm-iot-commons";

import { extractPlanCart } from "../extractors/planCartExtractor";
import { packPlanCartCheckout } from "../packmans/planCartPackman";

/* istanbul ignore next */
export function createSubscriptionPlanCart(planVariant, planVariantAddons) {
  return backendApiClient().post(
    "/plan_cart",
    { language: global.configuration.language },
    packPlanCartCreate(planVariant, planVariantAddons),
  );
}

/* istanbul ignore next */
export async function loadSubscriptionPlanCart() {
  return extractPlanCart(
    await backendApiClient().get("/plan_cart", {
      language: global.configuration.language,
    }),
  );
}

/* istanbul ignore next */
export async function checkoutSubscriptionPlanCart(
  clientApplication,
  autoCollection,
  billingAddress,
  shippingAddress,
) {
  const planCart = packPlanCartCheckout(
    clientApplication,
    autoCollection,
    billingAddress,
    shippingAddress,
  );
  return extractPlanCart(
    await backendApiClient().post(
      "/plan_cart/checkout",
      { language: global.configuration.language },
      planCart,
    ),
  );
}
