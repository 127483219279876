import React, { useState } from "react";
import PropTypes from "prop-types";
import SelectBox from "../Form/SelectBox";
import { withApi, apiShape, withNotifier, notifierShape } from "../../context";

export function AssetStatusSelect(props) {
  const [assetStatuses, setAssetStatuses] = useState();
  const [fetching, setFetching] = useState();
  const { api, notifier, assetId } = props;

  const loadAssetStatuses = async () => {
    try {
      setFetching(true);
      const endpoint = assetId
        ? `/assets/${assetId}/status-options`
        : "/asset/statuses";

      const response = await api.get(endpoint, { include: "tenant" });
      setAssetStatuses(
        response.asset_statuses.map((assetStatus) => ({
          id: assetStatus.id,
          name: assetStatus.name,
          tenantId: assetStatus?.tenant?.id,
          tenantPublic: assetStatus?.tenant?.public,
          tenantName: assetStatus?.tenant?.name,
        })),
      );
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    loadAssetStatuses();
  }, [assetId]);

  return <SelectBox {...props} options={assetStatuses} fetching={fetching} />;
}

AssetStatusSelect.propTypes = {
  api: apiShape,
  notifier: notifierShape,
  assetId: PropTypes.number,
};

export default withNotifier(withApi(AssetStatusSelect));
