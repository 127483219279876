import React from "react";
import { useIntl } from "react-intl";
import { getErrorTranslation, isSentry } from "../api";
import { useLog } from "../context/LogContext";

/**
 * Custom hook to translate and log errors.
 *
 * This hook provides a function that takes an error and an optional flag to enable console logging.
 * It logs the error to the console if the flag is enabled, logs the error using the log context if it is a Sentry error,
 * and returns a translated error message using react-intl.
 *
 * @returns {Function} A function that takes an error and an optional boolean flag to enable console logging.
 *
 * @param {Object} error - The error object to be translated and logged.
 * @param {boolean} [isConsoleEnabled=true] - Optional flag to enable console logging.
 */
export const useTranslateError = () => {
  const intl = useIntl();
  const log = useLog();

  return React.useCallback(
    (error, isConsoleEnabled = true) => {
      if (isConsoleEnabled) {
        /* eslint-disable no-console */
        console.log(error);
      }
      // all other errors should be handled!
      if (isSentry(error)) {
        log.error("ApiContext", error);
      }
      return intl.formatMessage({ id: getErrorTranslation(error) });
    },
    [log, intl],
  );
};
