/* eslint-disable camelcase */

export const FieldGatewayStatuses = {
  UNDEFINED: "undefined",
  NOT_FOUND: "not_found",
  ONLINE: "online",
  CONFIGURATION_ERROR: "configuration_error",
};

export const FieldGatewayTypes = {
  UNDEFINED: "undefined",
  SFG500: "sfg500",
  SWG70: "swg70",
  SWG50: "swg50",
  CM44MODBUSTCP: "cm44modbustcp",
  GENERIC_MODBUS_TCP: "genericmodbustcp",
  NXA820: "nxa820",
};

export const EHEdmSpecificationKeys = {
  EH_EDM_HART_ADDRESS: "eh.edm.hart.address",
  EH_EDM_FGW_SERIAL_NUMBER: "eh.edm.fgw.serial_number",
  EH_EDM_FGW_TAG: "eh.edm.fgw.tag",
  EH_EDM_FGW_BYTE_ORDER: "eh.edm.fgw.byte_order",
  EH_EDM_FGW_UNIT_IDENTIFIER: "eh.edm.fgw.unit_identifier",

  CONFIG_STATUS_SERIAL_NUMBER: "config.status.serial_number",
  CONFIG_STATUS_TAG: "config.status.tag",
  CONFIG_STATUS_BYTE_ORDER: "config.status.byte_order",
  CONFIG_STATUS_UNIT_IDENTIFIER: "config.status.unit_identifier",
};

export const EHEdmSpecificationValues = {
  CONFIG_STATUS_UNDEFINED: "UNDEFINED",
};

export const FieldGatewayConstants = {
  chPrefix: "CH",
  ch0LiquilinePlattform: "Liquiline Platform",

  configAiKeyPrefix: "config.ai_",
  configDiKeyPrefix: "config.di_",

  configModbusKeyPrefix: "config.modbus_",
  configStatusModbusKeyPrefix: "config.status.modbus_",

  configKeyPrefix: "config.",
  configStatusKeyPrefix: "config.status.",
};

export const ModbusCommandNumber = {
  READ_HOLDING_REGISTER: "3",
  READ_INPUT_REGISTER: "4",
};

export const ModbusDataTypes = {
  BOOLEAN: 1,
  SIGNED_8: 2,
  UNSIGNED_8: 3,
  SIGNED_16: 4,
  UNSIGNED_16: 5,
  SIGNED_32: 6,
  UNSIGNED_32: 7,
  SIGNED_64: 8,
  UNSIGNED_64: 9,
  FLOAT: 10,
  DOUBLE: 11,
  STRING: 12,
  DATE_TIME: 13,
};
