import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { Switch, BrowserRouter } from "react-router-dom";
import {
  initializeSession,
  registerStore,
  ConfigurationContextProvider,
  writeLocalStorage,
  Loader,
} from "lcm-iot-commons";

import { CompatRouter } from "react-router-dom-v5-compat";
import ErrorBoundary from "lcm-iot-commons/client/lib/components/Errors/ErrorBoundary";
import Intl from "lcm-iot-commons/client/lib/components/Intl";
import configureStore from "../store/configureStore";
import Routes from "./Routes";
import SsoRoutes from "./SsoRoutes";
import * as AppRoot from "../containers/Root";
import SsoRoot from "../containers/SsoRoot";

import "react-widgets/lib/scss/react-widgets.scss";
import "../assets/styles/main.scss";
import { mergeAppMessages } from "../translations";

require("../rules");

const store = configureStore();
registerStore(store);

function RootApp(props) {
  const { initialization } = props;
  const { configuration = {}, session } = initialization;

  const rootBackUrl = `${window.location.origin}/app/id`;
  writeLocalStorage("rootBackUrl", rootBackUrl);

  // FIXME: FALLBACK for old code don't use it!
  global.configuration = configuration;
  store.dispatch(initializeSession(session));

  const Root = configuration.loginOnly ? SsoRoot : AppRoot.default;

  return (
    <Suspense fallback={<Loader />}>
      <Intl mergeAppMessages={mergeAppMessages}>
        <ErrorBoundary isFullPage>
          <ConfigurationContextProvider configuration={configuration}>
            <Provider store={store}>
              <BrowserRouter>
                <CompatRouter>
                  <Switch>
                    <Root>
                      <Switch>
                        {configuration.loginOnly ? <SsoRoutes /> : <Routes />}
                      </Switch>
                    </Root>
                  </Switch>
                </CompatRouter>
              </BrowserRouter>
            </Provider>
          </ConfigurationContextProvider>
        </ErrorBoundary>
      </Intl>
    </Suspense>
  );
}

RootApp.propTypes = {
  initialization: PropTypes.shape({
    configuration: PropTypes.shape({
      appName: PropTypes.string,
      lcmApiUrl: PropTypes.string,
      backendApiUrl: PropTypes.string,
      loginOnly: PropTypes.bool,
    }),
    session: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};

window.app = {
  store,
};

const App =
  (props, railsContext) =>
  // eslint-disable-next-line
  () => <RootApp {...{ ...props, railsContext }} />;

export default App;

export { RootApp };
