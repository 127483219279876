import PropTypes from "prop-types";
import React, { useState } from "react";
import { TextInput } from "lcm-iot-commons";

import withZxcvbn, { zxcvbnShape } from "../../wrappers/withZxcvbn";

const DEFAULT_STATE = {
  warning: null,
  success: null,
  suggestions: [],
};

export function PasswordInput(props) {
  const { values, name, zxcvbn, touched, setFieldValue, userDetails } = props;
  const [state, setState] = useState(DEFAULT_STATE);

  const onPasswordPaste = (e) => {
    const clipboardData =
      e.clipboardData || /* istanbul ignore next */ window.clipboardData;
    if (!clipboardData) return true; // use default behaviour

    e.preventDefault();
    const text = clipboardData.getData("text").replace(/[\n\r]/g, "");
    setFieldValue(name, text);
    return false;
  };

  React.useEffect(() => {
    if (values && values[name] && values[name].length) {
      const extractedUserInputs = zxcvbn.extractUserInputs(values, [
        name,
        "captcha",
        "password",
      ]);
      const userInputs = extractedUserInputs.concat([
        userDetails.email,
        userDetails.first_name,
        userDetails.last_name,
      ]);
      const result = zxcvbn.checkPassword(values[name], userInputs);
      setState(result);
    } else {
      setState(DEFAULT_STATE);
    }
  }, [values, name, touched]);

  const { warning, success, suggestions } = state;
  touched[name] =
    touched[name] || (values && values[name] && values[name].length);

  return (
    <TextInput
      {...props}
      type="password"
      warning={warning}
      success={success}
      moreAlerts={suggestions}
      onPaste={onPasswordPaste}
    />
  );
}

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  touched: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({}),
  setFieldValue: PropTypes.func.isRequired,
  zxcvbn: zxcvbnShape.isRequired,
  userDetails: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
};

PasswordInput.defaultProps = {
  values: undefined,
  userDetails: {},
};

export default withZxcvbn(PasswordInput);
