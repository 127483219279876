import lcmApiClient from "./lcmApiClient";

/* istanbul ignore next */
export async function createTenant(tenant) {
  const response = await lcmApiClient().post("/tenants", {
    name: tenant.name,
    description: tenant.description,
    public: false,
  });
  return response.id;
}
