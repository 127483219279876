import React, { useState } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import {
  isNotEmpty,
  isEmpty,
  Form,
  getLanguagesList,
  withApi,
  apiShape,
  withIntl,
  withNotifier,
  notifierShape,
  withBrowser,
  intlShape,
  browserShape,
  FilesUpload,
  TextInput,
  BadRequestError,
} from "lcm-iot-commons";
import SubmitButton from "lcm-iot-commons/client/lib/components/Form/SubmitButton";
import CancelButton from "lcm-iot-commons/client/lib/components/Form/CancelButton";
import TypeaheadBox from "lcm-iot-commons/client/lib/components/Form/TypeaheadBox";
import DocumentFormLogic from "./DocumentFormLogic";
import AttachmentOverrideModal from "../Attachments/AttachmentOverrideModal";

export function DocumentForm({
  api,
  intl,
  notifier,
  match,
  browser,
  addMode,
  initialValues,
  documentCategories,
  createMode,
}) {
  const [isDeclined, setIsDeclined] = useState(false);
  const [overrideKnownError, setOverrideKnownError] = useState(null);

  const languages = getLanguagesList();

  /* istanbul ignore next */
  const validateForm = (values) => {
    const errors = {};

    if (isEmpty(values.name) || isEmpty(values.name.trim())) {
      errors.name = intl.formatMessage({ id: "validation.name.mandatory" });
    } else if (values.name.length > 255) {
      errors.name = intl.formatMessage(
        { id: "validation.name.too_long" },
        { characters: 255 },
      );
    }

    if (!values.language) {
      errors.language = intl.formatMessage({
        id: "validation.language.mandatory",
      });
    }

    if (values.contentDate) {
      if (
        !/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(
          values.contentDate,
        )
      ) {
        errors.contentDate = intl.formatMessage({
          id: "validation.valid_from.invalid",
        });
      }
    }

    if (values.validFrom) {
      if (createMode) {
        if (
          !/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(
            values.validFrom,
          )
        ) {
          errors.validFrom = intl.formatMessage({
            id: "validation.valid_from.invalid",
          });
        }
      }
    }

    if (values.validUntil) {
      if (createMode) {
        if (
          !/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(
            values.validUntil,
          )
        ) {
          errors.validUntil = intl.formatMessage({
            id: "validation.valid_until.invalid",
          });
        }
      }
    }

    if (createMode && values.validFrom && values.validUntil) {
      if (values.validFrom > values.validUntil) {
        errors.validUntil = intl.formatMessage({
          id: "validation.valid_until.must_be_older",
        });
      }
    }

    if (
      !values.fileUpload ||
      (isEmpty(values.fileUpload.acceptedFiles) &&
        isEmpty(values.fileUpload.rejectedFiles))
    ) {
      errors.fileUpload = intl.formatMessage({
        id: "validation.file.mandatory",
      });
    }

    if (values.fileUpload && isNotEmpty(values.fileUpload.rejectedFiles)) {
      errors.fileUpload = intl.formatMessage({
        id: "document_create.dropzone.rejection",
      });
    }

    return errors;
  };

  const onOverrideDecline = () => {
    setIsDeclined(true);
  };

  const onCloseModal = (error) => {
    setIsDeclined(true);
    if (error instanceof BadRequestError) {
      if (error.contains("spoofed_media_type", "file")) {
        setOverrideKnownError({
          fileUpload: intl.formatMessage({
            id: "document_create.dropzone.rejection.illegal",
          }),
        });
      } else if (error.contains("invalid_input")) {
        setOverrideKnownError({
          fileUpload: intl.formatMessage({
            id: "document_create.dropzone.rejection.security",
          }),
        });
      } else {
        setOverrideKnownError({
          fileUpload: intl.formatMessage({ id: "unknown_error.details" }),
        });
      }
    }
  };

  const renderForm = (formProps) => {
    const { isSubmitting, status, errors } = formProps;

    const fileUploadErrors = overrideKnownError || errors;

    let overrideModal;
    if (status === "duplicated_attachment" && !isDeclined) {
      let values = { ...formProps.values };
      if (values.version === "-") {
        values = { ...values, version: undefined };
      }
      overrideModal = (
        <AttachmentOverrideModal
          id="attachment-override-modal"
          values={values}
          params={match.params}
          onDecline={onOverrideDecline}
          onClose={onCloseModal}
        />
      );
    }

    return (
      <Form id="document-form" {...formProps}>
        <h3 style={{ marginTop: "10px" }}>
          <FormattedMessage id="document_details.header" />
        </h3>
        <TextInput
          {...formProps}
          id="document-name"
          name="name"
          label={intl.formatMessage({ id: "label.name" })}
          required={createMode}
          autoFocus={createMode}
          disabled={addMode}
          intl
        />
        <TypeaheadBox
          {...formProps}
          id="document-category"
          name="category"
          label={intl.formatMessage({ id: "label.category" })}
          options={documentCategories}
          disabled={addMode}
          intl
        />
        <TextInput
          {...formProps}
          id="document-version"
          name="version"
          label={intl.formatMessage({ id: "label.version" })}
          disabled={addMode}
          intl
        />
        <TextInput
          {...formProps}
          id="document-valid-from"
          name="validFrom"
          label={intl.formatMessage({ id: "label.valid_from" })}
          disabled={addMode}
          intl
          placeholder={intl.formatMessage({ id: "placeholder.date_format" })}
        />
        <TextInput
          {...formProps}
          id="document-valid-until"
          name="validUntil"
          label={intl.formatMessage({ id: "label.valid_until" })}
          disabled={addMode}
          intl
          placeholder={intl.formatMessage({ id: "placeholder.date_format" })}
        />
        <h3 style={{ marginTop: "30px" }}>
          <FormattedMessage id="attachment_details.header" />
        </h3>
        <TypeaheadBox
          {...formProps}
          id="document-attachment-language"
          name="language"
          label={intl.formatMessage({ id: "label.language" })}
          options={languages}
          intl
          autoFocus={addMode}
          required
        />
        <TextInput
          {...formProps}
          id="document-content-date"
          name="contentDate"
          label={intl.formatMessage({ id: "label.content_date" })}
          intl
          placeholder={intl.formatMessage({ id: "placeholder.date_format" })}
        />
        <FilesUpload
          {...formProps}
          multiple={false}
          id="attachment-upload"
          dropzoneInfoPrefix="add_attachment"
          name="fileUpload"
          errors={fileUploadErrors}
        />
        <div className="btn-group">
          <SubmitButton
            id="document-upload-submit"
            fetching={isSubmitting}
            disabled={!formProps.dirty}
            intl={intl}
          />
          <CancelButton
            id="document-upload-cancel"
            fetching={isSubmitting}
            intl={intl}
          />
        </div>
        {overrideModal}
      </Form>
    );
  };

  const documentFormLogic = new DocumentFormLogic(
    api,
    notifier,
    intl,
    browser,
    match,
    addMode,
    createMode,
    initialValues,
  );

  const handleOnSubmit = async (values, actions) => {
    setOverrideKnownError(null);
    setIsDeclined(false);
    if (values.version === "-") {
      documentFormLogic.handleOnSubmit(
        { ...values, version: undefined },
        actions,
      );
    } else {
      documentFormLogic.handleOnSubmit(values, actions);
    }
  };

  return (
    <Formik
      id="formik-document"
      render={renderForm}
      validate={validateForm}
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      validateOnChange={createMode}
      validateOnBlur={false}
    />
  );
}

DocumentForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      documentId: PropTypes.string,
      model: PropTypes.string,
    }),
  }).isRequired,
  notifier: notifierShape.isRequired,
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  addMode: PropTypes.bool,
  createMode: PropTypes.bool,
  initialValues: PropTypes.shape({
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.shape({
      name: PropTypes.string,
    }),
    validFrom: PropTypes.string,
    validUntil: PropTypes.string,
  }),
  documentCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
};

DocumentForm.defaultProps = {
  documentCategories: [],
  addMode: false,
  createMode: false,
  initialValues: {},
};

export default withBrowser(withApi(withIntl(withNotifier(DocumentForm))));
