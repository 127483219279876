import {
  lcmApiClient,
  packSubscription,
  backendApiClient,
} from "lcm-iot-commons";
import { extractServiceSubscriptions } from "../extractors/serviceSubscriptionsExtractor";
import {
  extractConnectSubscription,
  extractConnectSubscriptions,
  extractCurrentSubscription,
} from "../extractors/connectSubscriptionsExtractor";
import { extractAssets } from "../extractors/assetsExtractor";
import { extractEdgeDevices } from "../extractors/edgeDevicesExtractor";

/* istanbul ignore next */
export async function loadServiceSubscriptions(parameters) {
  const filter = {
    include: "specifications",
    ...parameters,
  };

  const response = await lcmApiClient().getAll("/subscriptions", filter);
  return extractServiceSubscriptions(response);
}

/* istanbul ignore next */
export async function loadServiceSubscriptionAssets(id, parameters) {
  // this method is at the moment only relevant for smart systems subscriptions.
  // Thats the reason why we do not take care if their are more than 25 assets available for the subscription
  const response = await lcmApiClient().get(
    `/subscriptions/${id}/assets`,
    parameters,
  );
  return extractAssets(response);
}

/* istanbul ignore next */
export async function loadConnectSubscriptions(parameters) {
  const response = await lcmApiClient().get("/api_subscriptions", parameters);
  return {
    connectSubscriptions: extractConnectSubscriptions(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}
/* istanbul ignore next */
export async function loadConnectSubscriptionClientApplications(parameters) {
  const response = await lcmApiClient().getAll(
    "/api_subscriptions",
    parameters,
  );
  return response.api_subscriptions.map(
    (subscription) => subscription.client_application.name,
  );
}
/* istanbul ignore next */
export async function loadConnectSubscription(id) {
  const response = await lcmApiClient(false).get(`/api_subscriptions/${id}`);
  return extractConnectSubscription(response);
}

/* istanbul ignore next */
export async function patchConnectSubscriptionAddress(
  id,
  customerPurchaseOrder,
  billingAddress,
  shippingAddress,
) {
  await lcmApiClient().patch(
    `/api_subscriptions/${id}`,
    packSubscription(customerPurchaseOrder, billingAddress, shippingAddress),
  );
  await backendApiClient(false).patch(
    `/api_subscriptions/${id}/update_billing_details`,
    packSubscription(customerPurchaseOrder, billingAddress, shippingAddress),
  );
}

/* istanbul ignore next */
export async function loadSubscriptionEdgeDevices(id, parameter) {
  const response = await lcmApiClient().get(
    `/api_subscriptions/${id}/edge_devices`,
    { ...parameter, include: "status,type" },
  );
  return extractEdgeDevices(response);
}

/* istanbul ignore next */
export async function loadNextConnectSubscriptions(url) {
  const response = await lcmApiClient().get(url);
  return {
    connectSubscriptions: extractConnectSubscriptions(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function loadCurrentSubscription(
  id,
  paymentDetails = false,
  generateAdvancedInvoiceIfNeeded = false,
) {
  let parameters = paymentDetails ? { include: "payment_details" } : {};
  parameters = generateAdvancedInvoiceIfNeeded
    ? { ...parameters, generate_advanced_invoice_if_needed: "true" }
    : parameters;
  const response = await backendApiClient(false).get(
    `/subscriptions/${id}`,
    parameters,
  );
  return extractCurrentSubscription(response);
}
