import React from "react";
import Tippy from "@tippy.js/react";
import PropTypes from "prop-types";

export function Tooltip({ content, children }) {
  return (
    <Tippy className="tool-tip" placement="right" content={content}>
      {children}
    </Tippy>
  );
}

Tooltip.propTypes = {
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
