import React from "react";
import PropTypes from "prop-types";

export function SignUpStepExplanation({ text, description, step }) {
  const [showDescription, setShowDescription] = React.useState(false);

  const handleOnClick = () => {
    setShowDescription(!showDescription);
  };

  return (
    <div id={`step-${step}-explanation`} className="step-explanation-item">
      <div
        id="explanation-text"
        className="step-explanation-text-flex-container"
      >
        <span className="step-explanation-bullet">{step}</span>
        <p className="step-explanation-text">{text}</p>
        {description ? (
          <button
            id={`step-${step}-more`}
            type="button"
            className="btn-primary btn-link"
            onClick={() => handleOnClick()}
          >
            <span
              className={
                showDescription
                  ? "icon icon2-eh-arrow-up"
                  : "icon icon2-eh-arrow-down"
              }
            />
          </button>
        ) : null}
      </div>
      {showDescription ? (
        <div className="step-explanation-description-flex-container space-after">
          <span className="step-explanation-place-holder" />
          {description}
        </div>
      ) : null}
    </div>
  );
}

SignUpStepExplanation.propTypes = {
  text: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  step: PropTypes.string,
};

SignUpStepExplanation.defaultProps = {
  text: undefined,
  description: undefined,
  step: "1",
};

export default SignUpStepExplanation;
