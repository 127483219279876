import React, { useState } from "react";
import { injectIntl } from "react-intl";
import {
  apiShape,
  browserShape,
  notifierShape,
  rulesShape,
  withApi,
  withBrowser,
  withNotifier,
  withRules,
} from "../../context";
import { intlShape } from "../../shapes";
import Clue from "../Clue/Clue";

import SpecificationKeys from "../../constants/SpecificationKeys";
import { hasAddon } from "../../utils";
import { useSubscriptionQuery } from "../../hooks";

export function ConnectedAssetSetupClue({
  intl,
  rules,
  browser,
  api,
  notifier,
}) {
  const [visible, setVisible] = useState(false);
  const { data: subscription } = useSubscriptionQuery();
  const hasConnectedAssetAddon = hasAddon(subscription, "connected-asset");

  React.useEffect(() => {
    const loadData = async () => {
      try {
        if (hasConnectedAssetAddon) {
          const products = await api.getAll("/products", {
            specifications_key: SpecificationKeys.ProductSpecs.CONNECTED_ASSET,
            specifications_value: "true",
          });
          const response = await api.head("/assets", {
            product_id: products.products
              ?.map((product) => product.id)
              .join(","),
          });
          const numberOfConnectedAssets = parseInt(response["total-count"], 10);
          setVisible(numberOfConnectedAssets === 0);
        }
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    };
    if (rules.application().get("supportsConnectedAssets")) {
      loadData();
    }
  }, [hasConnectedAssetAddon]);

  return (
    visible && (
      <Clue
        id="connected-asset-setup"
        title={intl.formatMessage({ id: "label.connected_asset" })}
        details={intl.formatMessage({ id: "connected_asset.notification" })}
        className="notification"
        action={intl.formatMessage({ id: "button.learn_more" })}
        onClick={() => {
          browser.redirectToExtern(
            "https://help.netilion.endress.com/hc/en-us/sections/360002152220-Value",
          );
        }}
        highlighted
      />
    )
  );
}

ConnectedAssetSetupClue.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  rules: rulesShape.isRequired,
};

export default withNotifier(
  withApi(withBrowser(withRules(injectIntl(ConnectedAssetSetupClue)))),
);
