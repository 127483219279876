import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  Loader,
  Details,
  DetailsPicture,
  DetailsHeaderItem,
  ActionBar,
  ActionBarButtons,
  DeleteActionButton,
  EditActionButton,
  DetailsItem,
  PictureGallery,
  apiShape,
  intlShape,
  browserShape,
  notifierShape,
  accessRightsShape,
  withApi,
  withIntl,
  withBrowser,
  withNotifier,
  sortBy,
  withAccessRights,
  extractPictures,
  statusIconClassName,
  statusForCriticality,
} from "lcm-iot-commons";
import { extractProductCategoryItem } from "lcm-iot-commons/client/lib/utils/productUtils";
import { ProductDocuments } from "lcm-iot-commons/client/lib/components/Products";

export function ProductDetails({
  intl,
  api,
  notifier,
  match,
  browser,
  accessRights,
}) {
  const [fetching, setFetching] = useState(true);
  const [productDetails, setProductDetails] = useState(null);
  const [productCategories, setProductCategories] = useState(null);

  const onConfirmDelete = async () => {
    try {
      await api.delete(`/products/${match.params.id}`);
      notifier.showSuccess(
        intl.formatMessage({ id: "product.actions.delete.notification" }),
      );
      browser.navigateTo("/products");
    } catch (e) {
      notifier.showError(api.translateError(e));
    }
  };

  const extractProductCategories = (allCategories, categories) => {
    const mappedCategories = allCategories.categories.map((category) =>
      extractProductCategoryItem(category),
    );
    const foundDisplayed = [];
    categories.categories.map((cat) => {
      const mappedCategory = mappedCategories.find((c) => c.id === cat.id);
      foundDisplayed.push({
        id: mappedCategory.id,
        displayName: mappedCategory.name,
      });
      return foundDisplayed;
    });
    setProductCategories(sortBy(foundDisplayed, "displayName"));
  };

  const loadDetails = async () => {
    try {
      const product = await api.get(
        `/products/${match.params.id}`,
        { include: "tenant,manufacturer,parent,pictures,status" },
        false,
      );
      setProductDetails({
        name: product.name,
        description: product.description,
        productCode: product.product_code,
        pictures: product.pictures ? extractPictures(product) : null,
        statusName: product.status.name,
        tenantName: product.tenant.name,
        parentName: product.parent ? product.parent.name : null,
        manufacturerName: product.manufacturer.name,
        riskOfMaintainability: product.risk_of_maintainability,
      });
      const allCategories = await api.getAll(
        "/product/categories",
        { include: "tenant,parent" },
        false,
      );
      const categories = await api.getAll(
        `/products/${match.params.id}/categories`,
        { include: "tenant,parent" },
        false,
      );
      extractProductCategories(allCategories, categories);
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    loadDetails();
  }, [match.params.id]);

  const categories = productDetails ? (
    <div id="product-categories" className="details-item">
      <div id="categories-title" className="details-item-name">
        {productCategories?.length > 1 ? (
          <FormattedMessage id="label.categories" />
        ) : (
          <FormattedMessage id="label.category" />
        )}
      </div>
      {productCategories?.length > 0 ? (
        <ul id="categories-list" className="list">
          {productCategories.map((item) => (
            <div key={item.id}>
              <div id="category-item" className="details-item-value">
                {item.displayName}
              </div>
            </div>
          ))}
        </ul>
      ) : (
        "-"
      )}
    </div>
  ) : null;

  const productDocumentList = (
    <div className="space-after">
      <ProductDocuments
        id="product-document-list"
        object={{
          ...match.params,
          id: Number(match.params.id),
          productId: Number(match.params.id),
        }}
        canCreate={accessRights.canUpdate}
        withCreate
        target={`/products/${match.params.id}/documents/create`}
      />
    </div>
  );

  const renderDetails = () =>
    productDetails ? (
      <Details>
        <DetailsPicture>
          <PictureGallery
            pictures={productDetails.pictures}
            placeholder="lcm-iot-icon-product"
          />
        </DetailsPicture>
        <DetailsHeaderItem
          id="product-name"
          translationKey="label.name"
          value={productDetails.name}
        />
        <DetailsItem
          id="product-manufacturer"
          translationKey="label.manufacturer"
          value={productDetails.manufacturerName}
        />
        <DetailsItem
          id="product-tenant"
          translationKey="label.tenant"
          value={productDetails.tenantName}
        />
        <DetailsItem
          id="product-code"
          translationKey="label.product_code"
          value={productDetails.productCode}
        />
        <DetailsItem
          id="product-parent"
          translationKey="label.product_parent"
          value={productDetails.parentName}
        />
        <DetailsItem
          id="product-description"
          translationKey="label.description"
          value={productDetails.description}
        />
        <DetailsItem
          id="product-status"
          translationKey="label.status"
          value={productDetails.statusName}
        />
        <DetailsItem
          id="product-risk-of-maintainability"
          icon={statusIconClassName(
            statusForCriticality(productDetails?.riskOfMaintainability),
          )}
          translationKey="product.risk_of_maintainability"
          value={intl.formatMessage({
            id: `product.risk_of_maintainability.${productDetails?.riskOfMaintainability}`,
          })}
        />
        {categories}
        {productDocumentList}
      </Details>
    ) : null;

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ActionBar>
            <h1 id="details-header">
              <FormattedMessage id="product_details.header" />
            </h1>
            <ActionBarButtons>
              <EditActionButton
                id="edit-product-button"
                disabled={!accessRights.canUpdate || fetching}
                target={`/products/${match.params.id}/edit`}
              />
              <DeleteActionButton
                id="delete-product-button"
                disabled={!accessRights.canDelete || fetching}
                modalTitle={intl.formatMessage({
                  id: "product.actions.delete.modal_title",
                })}
                modalMessage={intl.formatMessage({
                  id: "product.actions.delete.modal_message",
                })}
                onConfirm={onConfirmDelete}
              />
            </ActionBarButtons>
          </ActionBar>
          {fetching ? null : renderDetails()}
          <Loader id="product-details-loader" loading={fetching} />
        </div>
      </div>
    </div>
  );
}

ProductDetails.propTypes = {
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  accessRights: accessRightsShape.isRequired,
};

export default withIntl(
  withNotifier(
    withApi(withBrowser(withAccessRights(ProductDetails, "product"))),
  ),
);
