import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { SubmitButton } from "../Form";

import intlShape from "../../shapes/intlShape";

export function SubscriptionPaymentEditModal({
  isCredit,
  intl,
  onAccept,
  onDecline,
  onChange,
  canChangeToInvoice,
}) {
  const keySuffix = isCredit ? "credit" : "invoice";
  const classNameStyle = isCredit ? "col-sm-4" : "col-sm-6";

  let bodyText = intl.formatMessage({
    id: `payment_details_edit.modal_message_${keySuffix}`,
  });
  if (isCredit && !canChangeToInvoice) {
    bodyText = intl.formatMessage({
      id: `payment_details_edit.modal_message_${keySuffix}_no_invoice_btn`,
    });
  }

  return (
    <Modal
      id="payment-edit-modal"
      dialogClassName="payment-edit-modal"
      show
      onHide={onDecline}
      restoreFocus={false}
      size="lg"
      backdrop
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "payment_details_edit.modal_title" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <div className="container payment-edit-modal-footer">
          <div className="row">
            <div className={classNameStyle}>
              <SubmitButton
                id="payment-method-confirm"
                onClick={onAccept}
                text={intl.formatMessage({
                  id: `payment_details_edit.confirm_${keySuffix}`,
                })}
              />
            </div>
            {isCredit && canChangeToInvoice ? (
              <div className={classNameStyle}>
                <button
                  id="payment-method-change"
                  type="button"
                  className="btn-link btn-action"
                  onClick={onChange}
                >
                  {intl.formatMessage({
                    id: "payment_details_edit.change_credit_to_invoice",
                  })}
                </button>
              </div>
            ) : null}
            <div className={classNameStyle}>
              <button
                id="payment-method-cancel"
                type="button"
                className="btn-link btn-action"
                onClick={onDecline}
              >
                {intl.formatMessage({
                  id: `payment_details_edit.cancel_${keySuffix}`,
                })}
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

SubscriptionPaymentEditModal.propTypes = {
  isCredit: PropTypes.bool.isRequired,
  onDecline: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  canChangeToInvoice: PropTypes.bool.isRequired,
};

export default injectIntl(SubscriptionPaymentEditModal);
