import { parseISO8601Date } from "../utils/dateTimeUtils";
import { findPrimaryValue } from "../utils/valueUtils";

export function extractValue(rawValue) {
  if (rawValue) {
    return {
      key: rawValue.key,
      timestamp: new Date(parseISO8601Date(rawValue.timestamp)),
      unit: rawValue.unit.symbol,
      value: parseFloat(rawValue.value),
    };
  }
  return null;
}

export function extractAssetValues(rawAssets, doNotUseFirstValue) {
  const values = [];

  rawAssets.assets.forEach((asset) => {
    if (asset.values.length > 0) {
      const value = findPrimaryValue(asset.values, doNotUseFirstValue);
      values.push({
        ...extractValue(value),
        id: asset.id,
        serialNumber: asset.serial_number,
        tag:
          asset.instrumentations.items.length > 0
            ? asset.instrumentations.items[0].tag
            : null,
        tagId:
          asset.instrumentations.items.length > 0
            ? asset.instrumentations.items[0].id
            : null,
      });
    }
  });
  return values;
}

export function extractPrimaryValue(values, doNotUseFirstValue) {
  if (values && values.length > 0) {
    return extractValue(findPrimaryValue(values, doNotUseFirstValue));
  }
  return undefined;
}
