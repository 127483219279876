import { parseISO8601Date } from "lcm-iot-commons";

export function extractNetworkInterface(rawNetworkInterface) {
  return {
    id: rawNetworkInterface.id,
    ipAddress: rawNetworkInterface.ip_address,
    name: rawNetworkInterface.name,
    type: rawNetworkInterface.type,
    number: rawNetworkInterface.number,
    status: rawNetworkInterface.status,
    modus: rawNetworkInterface.modus,
    subnetMask: rawNetworkInterface.subnet_mask,
    defaultGateway: rawNetworkInterface.default_gateway,
    httpProxyHost: rawNetworkInterface.http_proxy_host,
    httpProxyPort: rawNetworkInterface.http_proxy_port,
    httpProxyAuth: rawNetworkInterface.http_proxy_auth,
    dns1: rawNetworkInterface.dns1,
    dns2: rawNetworkInterface.dns2,
    applyTimestamp: rawNetworkInterface.apply_timestamp
      ? parseISO8601Date(rawNetworkInterface.apply_timestamp)
      : null,
  };
}

export function extractNetworkInterfaces(rawNetworkInterfaces) {
  return rawNetworkInterfaces.network_interfaces.map((networkInterface) =>
    extractNetworkInterface(networkInterface),
  );
}
