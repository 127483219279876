import lcmApiClient from "./lcmApiClient";
import { extractTenants } from "../extractors/tenantsExtractor";
import { extractUser } from "../extractors/usersExtractor";

/* istanbul ignore next */
export async function loadCurrentUserAdminTenants(user) {
  const response = await lcmApiClient(false).getAll("/tenants", {
    admin_user_id: user.id,
  });
  return extractTenants(response);
}

/* istanbul ignore next */
export async function loadCurrentUser() {
  const response = await lcmApiClient(false).get("/users/current");
  return extractUser(response);
}

/* istanbul ignore next */
export function findDefaultAdminTenant(user, adminTenants) {
  const defaultAdminTenantRegex = new RegExp(
    `${user.firstName} ${user.lastName} ([\\d])+`,
  );
  const defaultAdminTenant = adminTenants.find(
    (tenant) => tenant.name === `${user.firstName} ${user.lastName}`,
  );
  const defaultAdminTenants = adminTenants.filter((tenant) =>
    defaultAdminTenantRegex.test(tenant.name),
  );
  if (defaultAdminTenant) {
    return defaultAdminTenant;
  }
  if (defaultAdminTenants.length > 0) {
    return defaultAdminTenants[0];
  }
  if (adminTenants.length > 0) {
    return adminTenants[0];
  }
  return null;
}

/* istanbul ignore next */
export async function patchUserSpecification(user, params) {
  return lcmApiClient(false).patch(`/users/${user.id}/specifications`, params);
}
