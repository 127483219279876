import React from "react";
import PropTypes from "prop-types";
import { picturesShape } from "../../shapes";
import DetailsPicture from "../Details/DetailsPicture";
import PictureGallery from "../Pictures/PictureGallery";
import DetailsHeaderItem from "../Details/DetailsHeaderItem";

export default function InstrumentationDetailsBasicInfo({
  pictures,
  tag,
  typeDisplayName,
}) {
  return (
    <>
      <DetailsPicture>
        <PictureGallery
          pictures={pictures}
          placeholder="lcm-iot-icon-instrumentation"
        />
      </DetailsPicture>
      <DetailsHeaderItem
        id="instrumentation-tag"
        translationKey="label.tag"
        value={tag}
      />
      <DetailsHeaderItem
        id="instrumentation-type"
        translationKey="label.type"
        value={typeDisplayName}
      />
    </>
  );
}

InstrumentationDetailsBasicInfo.propTypes = {
  pictures: picturesShape,
  tag: PropTypes.string,
  typeDisplayName: PropTypes.string,
};
