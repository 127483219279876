import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  withApi,
  withNotifier,
  withBrowser,
  withUser,
  apiShape,
  notifierShape,
  browserShape,
} from "../../context";
import SwitchInput from "../Form/SwitchInput";
import MenuItemAddon from "./MenuItemAddon";
import { intlShape, userShape } from "../../shapes";
import { withFlipper, withIntl } from "../../wrappers";
import CONFIGURATION from "../../configuration";

export function NavigationDemoData({
  api,
  notifier,
  intl,
  flipper,
  browser,
  user,
}) {
  const [activated, setActivation] = useState(user.demoDataActive);
  const [switchDisabled, setSwitchDisabled] = useState(false);

  if (!flipper.demoDataActive) return null;

  const saveDemoDataActivationState = async () => {
    setSwitchDisabled(true);
    try {
      if (user.demoDataActive) {
        await api.delete("/users/current/demo_data");
        notifier.showSuccess(
          intl.formatMessage({
            id: "navigation.demo_data_deactivation_success_notification",
          }),
        );
      } else {
        await api.post("/users/current/demo_data");
        notifier.showSuccess(
          intl.formatMessage({
            id: "navigation.demo_data_activation_success_notification",
          }),
        );
      }
      user.updateDemoDataStatus(!user.demoDataActive);
      setActivation(!user.demoDataActive);
      setTimeout(() => {
        browser.redirectToExtern(`${CONFIGURATION.BASE_URL}`);
      }, 2000);
    } catch (apiErrors) {
      notifier.showError(api.translateError(apiErrors));
    }
  };

  return (
    <MenuItemAddon className="navigation-demo-data">
      <p>{intl.formatMessage({ id: "navigation.demo_data" })}</p>
      <SwitchInput
        className="navigation-demo-data-button"
        id="navigation-demo-data"
        name="navigation-demo-data"
        values={{ "navigation-demo-data": activated }}
        handleChange={saveDemoDataActivationState}
        disabled={switchDisabled}
        hideLabel
        smallButton
      />
    </MenuItemAddon>
  );
}

NavigationDemoData.propTypes = {
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  intl: intlShape.isRequired,
  user: userShape.isRequired,
  browser: browserShape.isRequired,
  flipper: PropTypes.shape({
    demoDataActive: PropTypes.bool,
  }).isRequired,
};

export default withBrowser(
  withNotifier(withFlipper(withApi(withIntl(withUser(NavigationDemoData))))),
);
