import PropTypes from "prop-types";

import { addressShape } from "./addressShapes";
import { planVariantAddonShape, planVariantShape } from "./planVariantsShape";
import { invoiceEstimateShape } from "./invoiceEstimateShapes";
import { cardPaymentDetailsShape } from "./cardPaymentDetailsShape";
import { invoicePaymentDetailsShape } from "./invoicePaymentDetailsShape";

export const planCartAddonShape = PropTypes.shape({
  planVariantAddon: planVariantAddonShape,
  amount: PropTypes.number,
});

export const planCartAddonsShape = PropTypes.arrayOf(planCartAddonShape);

export const planCartShape = PropTypes.shape({
  planVariant: planVariantShape,
  addons: planCartAddonsShape,
  customerPurchaseOrder: PropTypes.string,
  billingAddress: addressShape,
  shippingAddress: addressShape,
  invoiceEstimate: invoiceEstimateShape,
  couponCode: PropTypes.string,
  couponIsAllowed: PropTypes.bool,
  cardPaymentDetails: cardPaymentDetailsShape,
  invoicedPaymentDetails: invoicePaymentDetailsShape,
});
