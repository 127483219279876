import PropTypes from "prop-types";
import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { MenuItem, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom-v5-compat";
import { useQueryClient } from "@tanstack/react-query";
import ItemMenu from "../ItemMenu";
import { useApi, useSubscriptionQuery } from "../../hooks";
import ConfirmationModal from "../ConfirmationModal";
import { FormattedNumber } from "../Format";
import AllObjectsSelectModal from "../AllObjects/AllObjectsSelectModal";
import { withRules, rulesShape, useNotifier } from "../../context";
import {
  assetShape,
  accessRightsShape,
  nodeShape,
  instrumentationShape,
} from "../../shapes";

import { formatAssetDetails, url } from "../../utils";

import { BadRequestError, ConflictError } from "../../api";
import DocumentExportModal from "../Documents/DocumentExportModal";
import Picture from "../Pictures/Picture";
import ListItem from "../List/ListItem";
import CustomOverlayTrigger from "../CustomOverlayTrigger";
import {
  getAssignedNodesChips,
  getSpecificationChips,
} from "../../utils/chipsUtils";

export function AssetItem({
  asset,
  onAssetRemoved,
  showItemMenu,
  parentNode,
  parentInstrumentation,
  parentAccessRights,
  rules,
  canMove,
  options,
}) {
  const api = useApi();
  const navigate = useNavigate();
  const notifier = useNotifier();
  const intl = useIntl();
  const { data: subscription } = useSubscriptionQuery();
  const queryClient = useQueryClient();

  const t = (translationId) => intl.formatMessage({ id: translationId });

  const defaultPictureSquareSize = 50;

  const [assigning, setAssigning] = useState(false);
  const [moving, setMoving] = useState(false);
  const [unassigning, setUnassigning] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [manualAssignment, setManualAssignment] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const resetActionsState = () => {
    setAssigning(false);
    setUnassigning(false);
    setDeleting(false);
    setMoving(false);
  };

  const handleApiErrors = (error) => {
    if (
      error instanceof BadRequestError &&
      error.errors.length > 0 &&
      error.errors[0].type === "associations_already_added"
    ) {
      notifier.showError(t`asset.actions.assign.error`);
    } else {
      notifier.showError(t`api.error.unknown`);
    }
  };

  const { data: assetAccessRights, isFetching: isLoadingAccessRights } =
    api.get.useQuery(
      "/users/current/access_rights",
      {
        permitable_type: "Asset",
        permitable_id: asset?.id,
      },
      {
        initialData: {
          canUpdate: false,
          canDelete: false,
        },
        select: (response) => ({
          type: "Asset",
          id: asset?.id,
          canRead: response.can_read,
          canUpdate: response.can_update,
          canDelete: response.can_delete,
          canPermit: response.can_permit,
        }),
        enabled: showItemMenu && isMenuOpen,
      },
    );

  const { mutate: assignNodeToAsset } = api.post.useMutation(
    `/assets/${asset?.id}/nodes`,
    {
      onSuccess: () => {
        if (!parentNode && !parentInstrumentation) {
          notifier.showSuccess(t`asset.actions.move_assignment.notification`);
          onAssetRemoved(asset);
        } else if (assigning) {
          notifier.showSuccess(t`asset.actions.assign.notification`);
        }
        resetActionsState();
      },
      onError: (error) => {
        handleApiErrors(error);
      },
    },
  );

  const { mutate: assignInstrumentationToAsset } = api.post.useMutation(
    `/assets/${asset?.id}/instrumentations`,
    {
      onError: (error) => {
        handleApiErrors(error);
      },
    },
  );

  const { mutate: assignAssetToSubscription } = api.post.useMutation(
    `/subscriptions/${subscription?.id}/assets`,
    {
      onSuccess: () => {
        setManualAssignment(true);
        notifier.showSuccess(
          intl.formatMessage({ id: "asset.actions.assign.notification" }),
        );
      },
    },
  );

  const { mutate: deleteAsset } = api.delete.useMutation(
    `/assets/${asset?.id}`,
    {
      onSuccess: async () => {
        notifier.showSuccess(t`asset.actions.delete.notification`);
        onAssetRemoved(asset);
        queryClient.invalidateQueries({ queryKey: ["/subscription"] });
      },
      onError: (error) => {
        if (
          error instanceof ConflictError &&
          error.errors.length > 0 &&
          error.errors[0].type === "assigned_restriction"
        ) {
          notifier.showError(t`api.error.asset.assigned_restriction`);
        } else if (
          error instanceof ConflictError &&
          error.errors.length > 0 &&
          error.errors[0].type === "device_not_deactivated_conflict"
        ) {
          notifier.showError(t`api.error.device_not_deactivated_conflict`);
        } else {
          notifier.showError(t`api.error.unknown`);
        }
      },
    },
  );

  const { mutate: unassignAssetFromNode } = api.delete.useMutation(
    `/nodes/${parentNode?.id}/assets`,
    {
      onSuccess: () => {
        notifier.showSuccess(t`asset.actions.unassign.notification`);
        onAssetRemoved(asset);
      },
    },
  );

  const { mutate: unassignAssetFromInstrumentation } = api.delete.useMutation(
    `/instrumentations/${parentInstrumentation?.id}/assets`,
    {
      onSuccess: () => {
        notifier.showSuccess(t`asset.actions.unassign.notification`);
        onAssetRemoved(asset);
      },
    },
  );

  const { mutate: unassignNodeFromAsset } = api.delete.useMutation(
    `/assets/${asset?.id}/nodes`,
    {
      onSuccess: () => {
        notifier.showSuccess(t`asset.actions.move_assignment.notification`);
        onAssetRemoved(asset);
        resetActionsState();
      },
    },
  );

  const handleOnConfirm = () => {
    resetActionsState();
    if (deleting) {
      deleteAsset(null);
    }
    if (unassigning) {
      if (parentNode) {
        unassignAssetFromNode({ assets: [{ id: asset?.id }] });
      }
      if (parentInstrumentation) {
        unassignAssetFromInstrumentation({ assets: [{ id: asset?.id }] });
      }
    }
  };

  const handleOnItemMenuOpen = () => {
    setIsMenuOpen(true);
  };
  const handleOnMoveAssignmentClick = () => {
    setMoving(true);
  };

  const handleOnAssignClick = () => {
    setAssigning(true);
  };

  const handleOnUnassignClick = () => {
    setUnassigning(true);
  };

  const handleOnAssignToSubscriptionClick = () => {
    if (
      subscription?.asset_quota > 0 &&
      subscription?.number_assigned_assets >= subscription?.asset_quota
    ) {
      notifier.showError(
        intl.formatMessage(
          { id: "subscription.limit.asset_details" },
          { asset_quota: subscription?.asset_quota },
        ),
      );
      return;
    }

    assignAssetToSubscription({ assets: [{ id: asset?.id }] });
  };

  const handleOnDeleteClick = () => {
    setDeleting(true);
  };

  const handleOnModalClose = () => {
    resetActionsState();
  };

  const handleOnNodeSelect = (node) => {
    if (assigning && node) {
      assignNodeToAsset({
        nodes: [{ id: node.id }],
      });
    }
    /* istanbul ignore else */
    if (moving && asset && parentNode && node) {
      assignNodeToAsset(
        { nodes: [{ id: node.id }] },
        {
          onSuccess: () => {
            unassignNodeFromAsset({ nodes: [{ id: parentNode.id }] });
          },
        },
      );
    }
  };

  const handleOnInstrumentationSelect = (instrumentation) => {
    if (assigning && instrumentation) {
      assignInstrumentationToAsset(
        { instrumentations: [{ id: instrumentation.id }] },
        {
          onSuccess: () => {
            if (!parentNode && !parentInstrumentation) {
              notifier.showSuccess(
                t`asset.actions.move_assignment.notification`,
              );
              onAssetRemoved(asset);
            } else {
              notifier.showSuccess(t`asset.actions.assign.notification`);
            }
            resetActionsState();
          },
        },
      );
    }
    /* istanbul ignore else */
    if (moving && asset && parentNode && instrumentation) {
      assignInstrumentationToAsset(
        { instrumentations: [{ id: instrumentation.id }] },
        {
          onSuccess: () => {
            unassignNodeFromAsset({ nodes: [{ id: parentNode.id }] });
          },
        },
      );
    }
  };

  const assetAssigned = manualAssignment || asset?.inSubscription;
  const assetRules = rules.asset(asset);
  const applicationRules = rules.application();

  const getListItemValue = () =>
    assetAssigned && asset?.value ? (
      <>
        <FormattedNumber
          minimumFractionDigits={assetRules.get(
            "minDecimalDigits(asset.value.key)",
          )}
          maximumFractionDigits={assetRules.get(
            "maxDecimalDigits(asset.value.key)",
          )}
          value={asset?.value.value}
        />
        <span>{` ${asset?.value.unit}`}</span>
      </>
    ) : null;

  const getUnassignedAssetItem = () => (
    <ItemMenu onOpen={handleOnItemMenuOpen}>
      <MenuItem onSelect={handleOnAssignToSubscriptionClick}>
        <FormattedMessage id="button.assign_to_subscription" />
      </MenuItem>
    </ItemMenu>
  );

  const getAssignedAssetItem = () => (
    <>
      {showItemMenu ? (
        <ItemMenu
          isLoading={isLoadingAccessRights}
          onOpen={handleOnItemMenuOpen}
        >
          <MenuItem
            onSelect={() => navigate(url(`/assets/${asset?.id}/edit`))}
            disabled={!assetAccessRights.canUpdate}
          >
            <FormattedMessage id="button.edit" />
          </MenuItem>
          {parentNode && (
            <>
              <MenuItem
                onSelect={handleOnAssignClick}
                disabled={!assetAccessRights.canPermit}
              >
                <FormattedMessage id="button.assign" />
              </MenuItem>
              <MenuItem
                onSelect={handleOnUnassignClick}
                disabled={
                  !parentAccessRights.canUpdate || !assetAccessRights.canPermit
                }
              >
                <FormattedMessage id="button.unassign" />
              </MenuItem>
              {canMove && (
                <MenuItem
                  onSelect={handleOnMoveAssignmentClick}
                  disabled={
                    !parentAccessRights.canUpdate ||
                    !assetAccessRights.canPermit
                  }
                >
                  <FormattedMessage id="button.move" />
                </MenuItem>
              )}
            </>
          )}
          {!parentNode && !parentInstrumentation && canMove && (
            <MenuItem
              onSelect={handleOnAssignClick}
              disabled={!assetAccessRights.canPermit}
            >
              <FormattedMessage id="button.move" />
            </MenuItem>
          )}
          {parentInstrumentation && (
            <MenuItem
              onSelect={handleOnUnassignClick}
              disabled={
                !parentAccessRights.canUpdate || !assetAccessRights.canPermit
              }
            >
              <FormattedMessage id="button.unassign" />
            </MenuItem>
          )}
          {applicationRules.get("showFilesExportInContextMenu") && (
            <DocumentExportModal object={{ asset }}>
              {({ showModal }) => (
                <MenuItem onSelect={showModal}>
                  <FormattedMessage id="button.export_files" />
                </MenuItem>
              )}
            </DocumentExportModal>
          )}
          <MenuItem
            onSelect={handleOnDeleteClick}
            disabled={!assetAccessRights.canDelete}
          >
            <FormattedMessage id="button.delete" />
          </MenuItem>
        </ItemMenu>
      ) : null}

      {assigning || moving ? (
        <AllObjectsSelectModal
          onClose={handleOnModalClose}
          onSelectNode={handleOnNodeSelect}
          onSelectInstrumentation={handleOnInstrumentationSelect}
          canSelectInstrumentations
          canSelectSystems
          type={
            ((parentNode || parentInstrumentation) && assigning && "assign") ||
            "move"
          }
        />
      ) : null}

      {deleting || unassigning ? (
        <ConfirmationModal
          intl={intl}
          id="confirmation-modal"
          show
          titleText={intl.formatMessage({
            id: deleting
              ? "asset.actions.delete.modal_title"
              : "asset.actions.unassign.confirmation.title",
          })}
          messageText={intl.formatMessage({
            id: deleting
              ? "asset.actions.delete.modal_message"
              : "asset.actions.unassign.confirmation.message",
          })}
          onConfirm={handleOnConfirm}
          onClose={handleOnModalClose}
        />
      ) : null}
    </>
  );

  const specifications =
    assetAssigned && options?.includes("specifications")
      ? getSpecificationChips(asset?.specifications, intl)
      : undefined;
  const nodes =
    assetAssigned && options?.includes("nodes")
      ? getAssignedNodesChips(asset?.nodes, intl)
      : undefined;

  const getListItem = (listItemActions) => (
    <ListItem
      id={`asset-item-${asset?.id}`}
      data-testid={`asset-item-${asset?.id}`}
      image={
        asset?.thumbnailUrl ? (
          <Picture
            width={defaultPictureSquareSize}
            height={defaultPictureSquareSize}
            src={asset?.thumbnailUrl}
            placeholder="icon icon-eh-device"
          />
        ) : undefined
      }
      icon={asset?.thumbnailUrl ? undefined : "icon-eh-device"}
      title={asset?.serialNumber}
      description={formatAssetDetails(asset)}
      namur={assetAssigned ? asset?.assetStatusCode : null}
      target={url(`/assets/${asset?.id}`)}
      disabled={!assetAssigned}
      value={getListItemValue(assetAssigned)}
      actions={listItemActions}
      specifications={specifications}
      nodes={nodes}
    />
  );
  const getDisplayTooltip = () => (
    <Tooltip id="tooltip">
      {intl.formatMessage({
        id: "subscription.asset_inactive_because_not_assigned_message",
      })}
    </Tooltip>
  );

  return assetAssigned ? (
    getListItem(getAssignedAssetItem())
  ) : (
    <CustomOverlayTrigger placement="bottom" overlay={getDisplayTooltip()}>
      <span>{getListItem(getUnassignedAssetItem())}</span>
    </CustomOverlayTrigger>
  );
}

AssetItem.propTypes = {
  asset: assetShape,
  parentAccessRights: accessRightsShape,
  onAssetRemoved: PropTypes.func,
  showItemMenu: PropTypes.bool,
  rules: rulesShape,
  parentInstrumentation: instrumentationShape,
  parentNode: nodeShape,
  canMove: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
};

AssetItem.defaultProps = {
  asset: undefined,
  parentNode: undefined,
  parentInstrumentation: undefined,
  showItemMenu: false,
  parentAccessRights: undefined,
  onAssetRemoved: undefined,
  canMove: true,
};

export default withRules(AssetItem);
