import React, { useState } from "react";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import {
  ActionBar,
  AlertType,
  apiErrorsContain,
  apiShape,
  browserShape,
  ButtonGroup,
  CancelButton,
  Column,
  CONFIGURATION,
  ConflictError,
  Container,
  Form,
  Heading,
  InputAlert,
  intlShape,
  isEmpty,
  Loader,
  NotFoundError,
  notifierShape,
  Row,
  SubmitButton,
  TextInput,
  userShape,
  withApi,
  withBackend,
  withBrowser,
  withFlipper,
  withNotifier,
  withUser,
  withIntl,
  handleFormikValueChange,
  SelectBox,
  htmlFormat,
  htmlLink,
  TextArea,
} from "lcm-iot-commons";
import PropTypes from "prop-types";
import { TenantTypeahead } from "lcm-iot-commons/client/lib/components/Tenants/TenantTypeahead";
import { UserGroupTypeahead } from "lcm-iot-commons/client/lib/components/UserGroups/UserGroupTypeahead";
import { ContextualHelp } from "lcm-iot-commons/client/lib/components/Contextuals/ContextualHelp";
import { useSearchParams } from "react-router-dom-v5-compat";

export function EdgeDeviceCreate({
  api,
  browser,
  intl,
  notifier,
  user,
  flipper,
}) {
  const [errors, setErrors] = useState();
  const [formData, setFormData] = useState();
  const [searchParams] = useSearchParams();

  const loadSubscriptionsWithFreeAddons = async () => {
    const subscriptionNames = [];

    const addonsResponse = await api.get(
      "/add_ons?scope=USABLE&add_on_type=connectivity&product_code=uplink&include=addonable_name",
    );
    addonsResponse.add_ons.forEach((addon) => {
      if (addon.quantity > addon.assigned_edge_devices_count) {
        subscriptionNames.push({
          name: addon.addonable.name,
          addOnId: addon.id,
          id: addon.addonable.id,
          externalReference: addon.external_reference,
        });
      }
    });
    return subscriptionNames;
  };

  const getSelectedSubscriptionFromQueryParams = (subscriptions) => {
    const querySubscriptionId = searchParams.get("subscription_id");
    if (querySubscriptionId) {
      return subscriptions.find((obj) => obj.id === +querySubscriptionId);
    }
    return null;
  };

  const loadEdgeDeviceTypes = async () => {
    const response = await api.get("/edm/edge_device/types");
    return response.edge_device_types;
  };

  const loadEdgeDeviceTypesOnSubscriptionSelect = async (
    selectedSubscription,
  ) => {
    const edgeDeviceTypes = await loadEdgeDeviceTypes();
    let filteredEdgeDeviceTypes = [];
    let isThirdPartyDevice = false;
    if (
      flipper.edgeDeviceCreateNni &&
      selectedSubscription?.externalReference?.includes("connect-")
    ) {
      filteredEdgeDeviceTypes = edgeDeviceTypes.filter(
        (obj) => obj.code === "third_party_edge_device",
      );
      isThirdPartyDevice = true;
    } else {
      filteredEdgeDeviceTypes = edgeDeviceTypes.filter(
        (obj) => obj.code === "sgc500_karbon_300",
      );
    }
    /* istanbul ignore next */
    setFormData({
      selectedSubscription,
      edgeDeviceTypes: filteredEdgeDeviceTypes,
      selectedEdgeDeviceType:
        filteredEdgeDeviceTypes.length === 1
          ? filteredEdgeDeviceTypes[0]
          : null,
      edgeDeviceTypeIsThirdParty: isThirdPartyDevice,
    });
  };

  async function fetchData() {
    const subscriptionsWithFreeAddons = await loadSubscriptionsWithFreeAddons();
    const selectedSubscriptionFromQueryParams =
      getSelectedSubscriptionFromQueryParams(subscriptionsWithFreeAddons);

    if (selectedSubscriptionFromQueryParams) {
      await loadEdgeDeviceTypesOnSubscriptionSelect(
        selectedSubscriptionFromQueryParams,
      );
    }

    setFormData((prevState) => ({
      ...prevState,
      serialNumber: "",
      tenant: null,
      usergroup: null,
      subscriptions: subscriptionsWithFreeAddons,
      selectedSubscription: selectedSubscriptionFromQueryParams ?? null,
      selectSubscriptionDisabled: !!selectedSubscriptionFromQueryParams,
    }));
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleOnSubmitLink = async (values, actions) => {
    try {
      let { tenant } = values;
      let { usergroup } = values;
      if (values.tenant?.new === true) {
        tenant = {
          id: (await api.post("/tenants", { name: values.tenant.name })).id,
        };
      }
      if (values.usergroup?.new === true) {
        usergroup = {
          id: (await api.post("/usergroups", { name: values.usergroup.name }))
            .id,
        };
      }

      /* istanbul ignore next */
      await api.patch("/edm/edge_devices/link", {
        serial_number: values.serialNumber,
        tenant: {
          id: tenant.id,
        },
        usergroup: {
          id: usergroup.id,
        },
        add_on: {
          id: formData.selectedSubscription
            ? formData.selectedSubscription.addOnId
            : values.selectedSubscription.addOnId,
        },
      });
      setErrors([]);
      notifier.showSuccess(
        intl.formatMessage({ id: "edge_device_create.success_message" }),
      );
      browser.navigateTo("/edge_devices");
    } catch (error) {
      if (error.contains("association_not_found", "usergroup")) {
        setErrors([{ type: "association_not_found_usergroup" }]);
      } else if (
        error.contains("taken", "name") &&
        values.tenant.id === undefined
      ) {
        actions.setErrors({
          tenant: intl.formatMessage({ id: "api.error.tenant.taken" }),
        });
      } else if (error instanceof NotFoundError) {
        setErrors([{ type: "not_found" }]);
      } else if (error instanceof ConflictError) {
        setErrors([{ type: "taken" }]);
      } else {
        setErrors([{ type: "api_error" }]);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleOnSubmitCreate = async (values, actions) => {
    try {
      let { usergroup } = values;
      if (values.usergroup?.new === true) {
        usergroup = {
          id: (await api.post("/usergroups", { name: values.usergroup.name }))
            .id,
        };
      }

      const edgeDeviceStatusesResponse = await api.get(
        "/edm/edge_device/statuses",
      );
      const edgeDeviceStatusWithCodeUndefined =
        edgeDeviceStatusesResponse.edge_device_statuses.find(
          (obj) => obj.code === "undefined",
        );

      await api.post("/edm/edge_devices", {
        status: {
          id: edgeDeviceStatusWithCodeUndefined.id,
        },
        add_on: {
          id: formData.selectSubscriptionDisabled
            ? formData.selectedSubscription.addOnId
            : values.selectedSubscription.addOnId,
        },
        /* istanbul ignore next */
        type: {
          id: /* istanbul ignore next */ formData.selectedEdgeDeviceType
            ? formData.selectedEdgeDeviceType?.id
            : values.selectedEdgeDeviceType.id,
        },
        name: values.edgeDeviceName,
        description: values.description ?? null,
        serial_number: values.serialNumber,
        usergroup: {
          id: usergroup.id,
        },
      });
      setErrors([]);
      notifier.showSuccess(
        intl.formatMessage({ id: "edge_device_create.success_message" }),
      );
      browser.navigateTo("/edge_devices");
    } catch (error) {
      if (error?.contains?.("association_not_found", "usergroup")) {
        setErrors([{ type: "association_not_found_usergroup" }]);
      } else if (error instanceof NotFoundError) {
        setErrors([{ type: "not_found" }]);
      } else if (error instanceof ConflictError) {
        setErrors([{ type: "taken" }]);
      } else {
        setErrors([{ type: "api_error" }]);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleOnSubmit = async (values, actions) => {
    if (formData.edgeDeviceTypeIsThirdParty) {
      await handleOnSubmitCreate(values, actions);
    } else {
      await handleOnSubmitLink(values, actions);
    }
  };

  const validateForm = (values) => {
    const formErrors = {};
    if (
      !formData.selectSubscriptionDisabled &&
      values.selectedSubscription?.id === undefined
    ) {
      formErrors.selectedSubscription = intl.formatMessage({
        id: "edge_device_create.error.subscription",
      });
      setErrors();
    }
    if (
      formData.selectedEdgeDeviceType?.id === undefined &&
      values.selectedEdgeDeviceType?.id === undefined
    ) {
      formErrors.selectedEdgeDeviceType = intl.formatMessage({
        id: "edge_device_create.error.selected_edge_device_type",
      });
      setErrors();
    }
    if (isEmpty(values.serialNumber) || isEmpty(values.serialNumber.trim())) {
      formErrors.serialNumber = intl.formatMessage({
        id: "validation.serial_number.mandatory",
      });
      setErrors();
    }
    if (
      !formData.edgeDeviceTypeIsThirdParty &&
      values.tenant?.id === undefined &&
      !values.tenant?.new === true
    ) {
      formErrors.tenant = intl.formatMessage({
        id: "validation.product.tenant.mandatory",
      });
      setErrors();
    }
    if (values.usergroup?.id === undefined && !values.usergroup?.new === true) {
      formErrors.usergroup = intl.formatMessage({
        id: "validation.user_group.mandatory",
      });
      setErrors();
    }
    if (
      formData.edgeDeviceTypeIsThirdParty &&
      (isEmpty(values.edgeDeviceName) || isEmpty(values.edgeDeviceName.trim()))
    ) {
      formErrors.edgeDeviceName = intl.formatMessage({
        id: "validation.name.mandatory",
      });
      setErrors();
    }
    return formErrors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;

    const contextHelperUserGroup = (
      <ContextualHelp
        title={intl.formatMessage({ id: "edit_permissions.user_groups" })}
        interactive
      >
        {intl.formatMessage({ id: "edge_device_create.user_group.help_text" })}
      </ContextualHelp>
    );

    const contextHelperTenant = (
      <ContextualHelp
        title={intl.formatMessage({ id: "label.tenant" })}
        interactive
      >
        {intl.formatMessage({ id: "edge_device_create.tenant.help_text" })}
      </ContextualHelp>
    );

    return (
      <Form {...formProps}>
        <SelectBox
          {...formProps}
          id="subscription"
          name="selectedSubscription"
          label={intl.formatMessage({ id: "edge_device_create.subscription" })}
          options={formData.subscriptions}
          values={{
            selectedSubscription: formData.selectedSubscription,
          }}
          handleChange={(event) => {
            handleFormikValueChange(
              {
                ...formProps,
                name: "selectedSubscription",
              },
              event.target.value,
            );
            /* istanbul ignore next */
            loadEdgeDeviceTypesOnSubscriptionSelect(event.target.value);
          }}
          required
          disabled={formData.selectSubscriptionDisabled === true}
        />
        <SelectBox
          {...formProps}
          id="edge-device-type"
          name="selectedEdgeDeviceType"
          label={intl.formatMessage({ id: "label.type" })}
          options={formData.edgeDeviceTypes}
          values={{
            selectedEdgeDeviceType: formData.selectedEdgeDeviceType,
          }}
          handleChange={(event) => {
            handleFormikValueChange(
              {
                ...formProps,
                name: "selectedEdgeDeviceType",
              },
              event.target.value,
            );
            /* istanbul ignore next */
            if (event.target.value) {
              /* istanbul ignore next */
              formData.edgeDeviceTypeIsThirdParty =
                event.target.value.code === "third_party_edge_device";
            }
          }}
          required
        />
        <TextInput
          {...formProps}
          id="edge-device-serial-number"
          name="serialNumber"
          label={intl.formatMessage({ id: "label.serial_number" })}
          required
        />
        {!formData.edgeDeviceTypeIsThirdParty ? (
          <TenantTypeahead
            {...formProps}
            id="edge-device-tenant"
            data-testid="tenant-typehead"
            name="tenant"
            label={intl.formatMessage({ id: "label.tenant" })}
            canCreate
            required
            user={user}
            intl={intl}
            notifier={notifier}
            api={api}
            help={contextHelperTenant}
          />
        ) : null}
        {formData.edgeDeviceTypeIsThirdParty ? (
          <TextInput
            {...formProps}
            id="edge-device-name"
            name="edgeDeviceName"
            label={intl.formatMessage({ id: "label.name" })}
            required
          />
        ) : null}
        {formData.edgeDeviceTypeIsThirdParty ? (
          <TextArea
            {...formProps}
            id="edge-device-description"
            name="description"
            label={intl.formatMessage({ id: "label.description" })}
          />
        ) : null}
        <UserGroupTypeahead
          {...formProps}
          id="edge-device-user-group"
          name="usergroup"
          data-testid="usergroup-typehead"
          label={intl.formatMessage({ id: "edit_permissions.user_groups" })}
          intl={intl}
          notifier={notifier}
          api={api}
          required
          canCreate
          help={contextHelperUserGroup}
        />
        <ButtonGroup>
          <SubmitButton
            id="edge-device-add-submit"
            fetching={isSubmitting}
            text={intl.formatMessage({
              id: "edge_device_create.submit_button",
            })}
            intl={intl}
          />
          <CancelButton
            id="edge-device-add-cancel"
            disabled={isSubmitting}
            intl={intl}
          />
        </ButtonGroup>
      </Form>
    );
  };

  const renderErrorMessage = () => {
    const errorReasons = [];

    if (apiErrorsContain(errors, "not_found")) {
      errorReasons.push(
        <li id="not-found" key="not_found">
          <FormattedMessage id="edge_device_create.errors.not_found" />
        </li>,
      );
    } else if (apiErrorsContain(errors, "association_not_found_usergroup")) {
      errorReasons.push(
        <li id="taken" key="taken">
          <FormattedMessage id="edge_device_create.errors.assotiation_usergroup" />
        </li>,
      );
    } else if (apiErrorsContain(errors, "taken")) {
      errorReasons.push(
        <li id="taken" key="taken">
          <FormattedMessage
            id="edge_device_create.errors.conflict"
            values={{
              ...htmlFormat,
              a: htmlLink({ href: `${CONFIGURATION}/support/tickets` }),
            }}
          />
        </li>,
      );
    } else if (apiErrorsContain(errors, "api_error")) {
      errorReasons.push(
        <li id="api-error" key="api_error">
          <FormattedMessage
            id="edge_device_create.errors.unknown"
            values={{
              ...htmlFormat,
              a: htmlLink({ href: `${CONFIGURATION}/support/tickets` }),
            }}
          />
        </li>,
      );
    }

    return errorReasons.length > 0 ? (
      <div id="edge-device-add-error" className="space-before">
        <InputAlert
          id="edge-device-add-error-message"
          message={intl.formatMessage({
            id: "edge_device_create.errors.general",
          })}
          type={AlertType.ERROR}
        >
          <ul id="edge-device-add-error-reasons">{errorReasons}</ul>
        </InputAlert>
      </div>
    ) : null;
  };

  return user ? (
    <Loader loading={!formData}>
      <Container>
        <Row id="edge-device-add-header">
          <Column>
            <ActionBar>
              <Heading
                title={intl.formatMessage({ id: "edge_device_create.header" })}
              />
            </ActionBar>
          </Column>
        </Row>
        <Row id="edge-device-add-form">
          <Column sm="6">
            {formData ? (
              <Formik
                onSubmit={handleOnSubmit}
                validate={validateForm}
                initialValues={{ type: formData.initialType }}
                render={renderForm}
              />
            ) : null}
          </Column>
        </Row>
        <Row id="edge-device-add-errors">
          <Column sm="6">{renderErrorMessage()}</Column>
        </Row>
      </Container>
    </Loader>
  ) : null;
}

EdgeDeviceCreate.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  user: userShape,
  flipper: PropTypes.shape({
    edgeDeviceLateBinding: PropTypes.bool,
    edgeDeviceCreateNni: PropTypes.bool,
  }),
};

EdgeDeviceCreate.defaultProps = {
  user: undefined,
  flipper: PropTypes.shape({
    edgeDeviceLateBinding: false,
    edgeDeviceCreateNni: false,
  }),
};

export default withFlipper(
  withApi(
    withBackend(
      withUser(withBrowser(withNotifier(withIntl(EdgeDeviceCreate)))),
    ),
  ),
);
