import { noop } from "lodash-es";

export function handleFormikValueChange(props, value) {
  const { handleChange, handleBlur = noop, name } = props;
  const event = {
    persist: () => false,
    target: {
      value,
      name,
      type: "text",
    },
  };
  // we need to call blur to set touched to true!
  handleBlur(event);
  handleChange(event);
}

function handleCheckboxChange(props) {
  const { handleChange, name } = props;
  const value = props.values[name] === true ? false : true; // eslint-disable-line no-unneeded-ternary
  const event = {
    persist: () => false,
    target: {
      value,
      name,
      type: "text",
    },
  };
  handleChange(event);
}

export function handleFormikCheckboxChange(props, isEventPropagate) {
  // Fix for IE
  if (!isEventPropagate && window.document.documentMode) {
    handleCheckboxChange(props);
  }

  // Normal configuration
  if (!window.document.documentMode) {
    handleCheckboxChange(props);
  }
}
