import { packAddress } from "lcm-iot-commons";

export function packPlanCartCheckout(
  clientApplication,
  autoCollection,
  billingAddress,
  shippingAddress,
) {
  let redirectURIs = null;

  if (clientApplication.redirectURIs) {
    redirectURIs = clientApplication.redirectURIs.split("\n");
  }
  return {
    client_application: {
      name: clientApplication.name,
      description: clientApplication.description,
      redirect_uris: redirectURIs,
    },
    customer_purchase_order: billingAddress
      ? billingAddress.customerPurchaseOrder
      : null,
    auto_collection: autoCollection,
    billing_address: billingAddress ? packAddress(billingAddress) : null,
    shipping_address: shippingAddress ? packAddress(shippingAddress) : null,
  };
}
