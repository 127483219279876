import PropTypes from "prop-types";
import React, { Component } from "react";
import InputGroup from "./InputGroup";

class TextArea extends Component {
  static propTypes = {
    input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    label: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    cols: PropTypes.number,
    rows: PropTypes.number,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    name: PropTypes.string,
    isSubmitting: PropTypes.bool,
    /* eslint-disable react/forbid-prop-types */
    values: PropTypes.object,
    placeholder: PropTypes.string,
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus();
    }
  }

  focus() {
    setTimeout(() => {
      /* istanbul ignore next */
      if (this.inputField) {
        this.inputField.focus();
      }
    }, 50);
  }

  render() {
    const { input, disabled, cols, rows, placeholder } = this.props;
    let textarea;
    // REDUX FORM
    if (input) {
      textarea = (
        <textarea
          {...input}
          onChange={input.onChange}
          className="form-control"
          value={input.value}
          disabled={disabled}
          ref={(i) => {
            this.inputField = i;
          }}
          cols={cols || 50}
          rows={rows || 4}
          placeholder={placeholder}
        />
      );
      //  FORMIK
    } else {
      const { handleChange, handleBlur, name, values, isSubmitting } =
        this.props;
      const onChange = (e) => {
        handleBlur(e);
        handleChange(e);
      };
      textarea = (
        <textarea
          {...input}
          className="form-control"
          cols={cols || 50}
          disabled={disabled || isSubmitting}
          name={name}
          onChange={onChange}
          ref={
            /* istanbul ignore next */ (i) => {
              this.inputField = i;
            }
          }
          rows={rows || 4}
          value={values[name] || ""}
          maxLength={4000}
          placeholder={placeholder}
        />
      );
    }
    return <InputGroup {...this.props}>{textarea}</InputGroup>;
  }
}

export default TextArea;
