import { parseISO8601Date } from "lcm-iot-commons";

export function extractApiKey(rawApiKey) {
  return {
    id: rawApiKey.id,
    clientApplicationId: rawApiKey.client_application.id,
    authenticationType: rawApiKey.authentication_type,
    apiKey: rawApiKey.api_key,
    apiSecret: rawApiKey.api_secret ? rawApiKey.api_secret : null,
    validFrom: parseISO8601Date(rawApiKey.valid_from),
    validTo: parseISO8601Date(rawApiKey.valid_to),
    disabled: rawApiKey.disabled,
    description: rawApiKey.description ? rawApiKey.description : null,
  };
}

export function extractApiKeys(rawApiKeys) {
  return rawApiKeys.api_keys.map((apiKey) => extractApiKey(apiKey));
}
