import React from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import uuid from "uuid";

import { invoiceEstimateShape, intlShape } from "../../shapes";

export function SubscriptionInvoiceEstimate({
  invoiceEstimate,
  perInvoice,
  intl,
}) {
  const lineItems = (type, title) => {
    const filtered = invoiceEstimate.lineItems.filter((i) => i.type === type);
    if (filtered.length === 0) {
      return null;
    }
    const nodes = filtered.map((lineItem, index) => (
      <tr id={`line-item-${type}-${index}`} className="line-item" key={uuid()}>
        <td>
          {`${lineItem.description} (${lineItem.quantity} x `}
          <FormattedNumber
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            value={lineItem.amount / lineItem.quantity}
          />
          {` ${invoiceEstimate.currency}`})
        </td>
        <td className="amount">
          <FormattedNumber
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            value={lineItem.amount}
          />
          {` ${invoiceEstimate.currency}`}
        </td>
      </tr>
    ));

    nodes.unshift(
      <tr id={`line-item-title-${type}`} className="subtitle" key={uuid()}>
        <td>{title}</td>
        <td />
      </tr>,
    );
    return nodes;
  };

  if (invoiceEstimate) {
    const discountLine =
      invoiceEstimate.totalDiscount > 0 ? (
        <tr id="discount" className="discount" key={uuid()}>
          <td className="discount_description">
            {`${intl.formatMessage({ id: "invoice_estimate.discount" })} (${invoiceEstimate.discountDescription})`}
          </td>
          <td className="amount">
            <FormattedNumber
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              value={invoiceEstimate.totalDiscount * -1}
            />
            {` ${invoiceEstimate.currency}`}
          </td>
        </tr>
      ) : null;

    const finalTotalLabel = perInvoice ? (
      <td>
        <FormattedMessage id="invoice_estimate.total_due_no_prorata" />
      </td>
    ) : (
      <td>
        <FormattedMessage id="invoice_estimate.total_due" />
      </td>
    );

    const subTotalLine =
      !perInvoice || invoiceEstimate.subTotal !== invoiceEstimate.totalDue ? (
        <tr id="subtotal" className="subtotal" key={uuid()}>
          <td>
            <FormattedMessage id="invoice_estimate.total" />
          </td>
          <td className="amount">
            <FormattedNumber
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              value={invoiceEstimate.subTotal}
            />
            {` ${invoiceEstimate.currency}`}
          </td>
        </tr>
      ) : null;

    const creditsLine =
      !perInvoice && invoiceEstimate.credits > 0 ? (
        <tr id="credits" className="credits" key={uuid()}>
          <td>
            <FormattedMessage id="invoice_estimate.credits" />
          </td>
          <td className="amount">
            <FormattedNumber
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              value={invoiceEstimate.credits * -1}
            />
            {` ${invoiceEstimate.currency}`}
          </td>
        </tr>
      ) : null;

    const proRataDescription = !perInvoice ? (
      <p>
        <FormattedMessage id="invoice_estimate.description" />
      </p>
    ) : null;

    const taxItems =
      invoiceEstimate.totalTaxes > 0
        ? invoiceEstimate.taxes.map((taxItem, index) => (
            <tr
              id={`line-item-tax-${index}`}
              className="line-item"
              key={uuid()}
            >
              <td>{taxItem.description}</td>
              <td className="amount">
                <FormattedNumber
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={taxItem.amount}
                />
                {` ${invoiceEstimate.currency}`}
              </td>
            </tr>
          ))
        : null;

    const taxLine =
      invoiceEstimate.totalTaxes > 0 ? (
        <tr id="tax-total" className="total" key={uuid()}>
          <td>
            <FormattedMessage id="invoice_estimate.taxes" />
          </td>
          <td className="amount">
            <FormattedNumber
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              value={invoiceEstimate.totalTaxes}
            />
            {` ${invoiceEstimate.currency}`}
          </td>
        </tr>
      ) : null;

    return (
      <div className="order-overview">
        <table className="table table-striped">
          <tbody>
            {lineItems("plan", <FormattedMessage id="planvariant.plan" />)}
            {lineItems(
              "outcome",
              <FormattedMessage id="planvariant.outcome" />,
            )}
            {lineItems(
              "connectivity",
              <FormattedMessage id="planvariant.connectivity" />,
            )}
            {lineItems("addon", <FormattedMessage id="planvariant.addons" />)}
            {subTotalLine}
            {discountLine}
            {taxItems}
            {taxLine}
            {creditsLine}
            <tr id="final-total" className="final-total" key={uuid()}>
              {finalTotalLabel}
              <td className="amount">
                <FormattedNumber
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={invoiceEstimate.totalDue}
                />
                {` ${invoiceEstimate.currency}`}
              </td>
            </tr>
          </tbody>
        </table>
        {proRataDescription}
      </div>
    );
  }
  return null;
}

SubscriptionInvoiceEstimate.propTypes = {
  invoiceEstimate: invoiceEstimateShape,
  perInvoice: PropTypes.bool,
  intl: intlShape,
};

export default injectIntl(SubscriptionInvoiceEstimate);
