/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { last } from "lodash-es";
import { withApi, apiShape } from "../../context";
import { couldBeAnEndressHauserSerialNumber, extractCode } from "../../utils";
import ProductRules from "../../rules/ProductRules";

export function AssetFormSynchronization(props) {
  const {
    api,
    values,
    setFieldValue,
    userAdminTenants,
    showMeasuringInterval,
    showTimeOfFirstMeasurement,
    showTransmissionInterval,
    gpsIntervalOptions,
    setFieldTouched,
    loadGPSErrors,
    gpsEnabled,
    skipFirstProductLookup,
  } = props;
  const {
    manufacturer,
    tenant,
    serial_number,
    product,
    measuringInterval,
    transmissionInterval,
    firstMeasurement,
    gpsInterval,
    gpsActive,
    orderCode,
  } = values;

  const productRules = ProductRules.find(
    manufacturer?.name,
    product?.code,
    manufacturer?.tenantPublic,
  );

  const isNotEndress =
    manufacturer &&
    (!manufacturer.tenantPublic || manufacturer.name !== "Endress+Hauser");

  React.useEffect(() => {
    const sync = async () => {
      if (
        product &&
        !product.new &&
        manufacturer &&
        manufacturer?.id !== product?.manufacturerId
      ) {
        setFieldValue("product", undefined);
      }

      if (manufacturer?.new && !tenant && userAdminTenants?.length === 1) {
        setFieldValue("tenant", userAdminTenants[0]);
      }

      if (
        product?.new &&
        manufacturer &&
        !manufacturer.new &&
        !userAdminTenants?.find((t) => t.id === manufacturer.tenantId)
      ) {
        setFieldValue("manufacturer", {
          name: manufacturer.name,
          new: true,
        });
      }

      if (product && !product.new) {
        if (showMeasuringInterval && !measuringInterval) {
          setFieldValue(
            "measuringInterval",
            productRules.get("defaultMeasuringInterval"),
          );
        }
        if (showTransmissionInterval && !showMeasuringInterval) {
          setFieldValue("measuringInterval", undefined);
          setFieldValue(
            "transmissionInterval",
            productRules.get("defaultTransmissionInterval"),
          );
          setFieldValue("firstMeasurement", undefined);
        }
        if (showTimeOfFirstMeasurement && !firstMeasurement) {
          setFieldValue("firstMeasurement", new Date());
        }
      }
    };
    sync();
  }, [manufacturer, product]);

  React.useEffect(() => {
    const sync = async () => {
      if (measuringInterval && showMeasuringInterval) {
        if (
          transmissionInterval &&
          measuringInterval.transmissionOptions?.find(
            (o) => o.name === transmissionInterval.name,
          )
        ) {
          setFieldValue(
            "transmissionInterval",
            measuringInterval.transmissionOptions.find(
              (o) => o.name === transmissionInterval.name,
            ),
          );
        } else if (
          productRules.get("defaultMeasuringInterval") &&
          measuringInterval.id ===
            productRules.get("defaultMeasuringInterval").id
        ) {
          setFieldValue(
            "transmissionInterval",
            productRules.get("defaultTransmissionInterval"),
          );
        } else {
          setFieldValue(
            "transmissionInterval",
            last(measuringInterval.transmissionOptions),
          );
        }
      }
    };
    sync();
  }, [measuringInterval]);

  React.useEffect(() => {
    const sync = async () => {
      if (
        transmissionInterval &&
        showTransmissionInterval &&
        !showMeasuringInterval
      ) {
        setFieldValue("transmissionInterval", transmissionInterval);
      }
      if (gpsEnabled) {
        if (
          gpsInterval &&
          gpsIntervalOptions?.find((o) => o.name === gpsInterval.name)
        ) {
          setFieldValue(
            "gpsInterval",
            gpsIntervalOptions?.find((o) => o.name === gpsInterval.name),
          );
        } else if (
          gpsInterval &&
          gpsIntervalOptions &&
          gpsIntervalOptions.length &&
          gpsIntervalOptions?.find((o) => o.name === gpsInterval?.name) ===
            undefined
        ) {
          // set GPS not in range error
          setFieldValue("gpsInterval", null);
          setFieldTouched("gpsInterval", true);
          loadGPSErrors("validation.gps_interval.not_in_range");
        } else if (!gpsIntervalOptions || gpsIntervalOptions.length === 0) {
          // set less than one hour error
          setFieldValue("gpsInterval", null);
          setFieldValue("gpsActive", false);
          setFieldTouched("gpsInterval", true);
          loadGPSErrors("validation.gps_interval.less");
        } else {
          // clear errors for initial loading
          setFieldValue("gpsInterval", null);
          loadGPSErrors(undefined);
        }
      }
    };
    sync();
  }, [transmissionInterval, gpsEnabled]);

  React.useEffect(() => {
    const sync = async () => {
      // sets GPS interval error message if field is null
      if (gpsInterval === null && gpsActive) {
        loadGPSErrors("validation.gps_interval.undefined");
      }
    };
    sync();
  }, [gpsInterval]);

  React.useEffect(() => {
    const sync = async () => {
      // sets GPS interval error message for switch button changes
      if (!gpsIntervalOptions && gpsEnabled) {
        setFieldValue("gpsInterval", null);
        loadGPSErrors("validation.gps_interval.less");
      }
      setFieldTouched("gpsInterval", true);
    };
    sync();
  }, [gpsActive]);

  const setExtendedOrderCode = (extendedOrderCode) => {
    if (extendedOrderCode) {
      setFieldValue("extendedOrderCode", extendedOrderCode);
    } else {
      setFieldValue("extendedOrderCode", null);
    }
  };

  // react to changes in order code in form
  React.useEffect(() => {
    const sync = async () => {
      if (orderCode) {
        try {
          const order_code = `${values.product.code}-${values.orderCode}`;
          const response = await api.get(
            "/endress/extended_order_code_lookup",
            { order_code },
          );
          const extendedOrderCode = response.extended_order_code
            ? extractCode(response.extended_order_code)
            : null;
          setExtendedOrderCode(extendedOrderCode);
        } catch (e) {
          setExtendedOrderCode(null);
        }
      }
    };
    sync();
  }, [orderCode]);

  const initialization = React.useRef(skipFirstProductLookup);
  React.useEffect(() => {
    // skip lookup on first call from asset edit
    if (manufacturer && initialization.current) {
      initialization.current = false;
      return;
    }

    const sync = async () => {
      if (isNotEndress) {
        return;
      }
      if (couldBeAnEndressHauserSerialNumber(serial_number)) {
        try {
          const lookupResponse = await api.get("/endress/product_lookup", {
            serial_number,
            include: "order_code",
          });
          const extendedOrderCode = lookupResponse.extended_order_code
            ? extractCode(lookupResponse.extended_order_code)
            : undefined;
          const orderCodeValue = lookupResponse.order_code
            ? extractCode(lookupResponse.order_code)
            : undefined;
          const response = await api.get(`/products/${lookupResponse.id}`, {
            include: "tenant",
          });
          setFieldValue("manufacturer", {
            id: response.manufacturer.id,
            name: response.tenant.name,
            tenantId: response.tenant.id,
            tenantPublic: response.tenant.public,
            tenantName: response.tenant.name,
          });
          setFieldValue("product", {
            id: response.id,
            name: response.name,
            code: response.product_code,
            manufacturerId: response.manufacturer.id,
            tenantId: response.tenant.id,
            tenantPublic: response.tenant.public,
            tenantName: response.tenant.name,
          });
          if (orderCodeValue) {
            setFieldValue("orderCode", orderCodeValue);
          }
          setExtendedOrderCode(extendedOrderCode);
        } catch {
          // ok
        }
      }
    };

    sync();
  }, [serial_number]);

  return null;
}

AssetFormSynchronization.propTypes = {
  api: apiShape.isRequired,
  tenant: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  userAdminTenants: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.shape({
    tenant: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      public: PropTypes.bool,
    }),
    id: PropTypes.number,
    manufacturer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      new: PropTypes.bool,
      tenantId: PropTypes.number,
      tenantPublic: PropTypes.bool,
      gpsActive: PropTypes.bool,
    }),
    gpsInterval: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    serial_number: PropTypes.string,
    product: PropTypes.shape({
      manufacturer: PropTypes.shape({ id: PropTypes.number }),
      new: PropTypes.bool,
      code: PropTypes.string,
      manufacturerId: PropTypes.number,
      tenantId: PropTypes.number,
      tenantPublic: PropTypes.bool,
      tenantName: PropTypes.string,
    }),
    calibration_time: PropTypes.instanceOf(Date),
    measuringInterval: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      transmissionOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
    transmissionInterval: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    firstMeasurement: PropTypes.instanceOf(Date),
    gpsActive: PropTypes.bool,
    orderCode: PropTypes.string,
  }).isRequired,
  gpsEnabled: PropTypes.bool,
  skipFirstProductLookup: PropTypes.bool,
  showMeasuringInterval: PropTypes.bool,
  showTimeOfFirstMeasurement: PropTypes.bool,
  showTransmissionInterval: PropTypes.bool,
  gpsIntervalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      gpsOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ),
  setFieldTouched: PropTypes.func,
  loadGPSErrors: PropTypes.func,
};

AssetFormSynchronization.defaultProps = {
  userAdminTenants: undefined,
  showMeasuringInterval: false,
  showTimeOfFirstMeasurement: false,
  showTransmissionInterval: false,
  skipFirstProductLookup: false,
};

export default withApi(AssetFormSynchronization);
