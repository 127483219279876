import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {
  withIntl,
  withApi,
  intlShape,
  Column,
  Row,
  Form,
  Checkbox,
  TextInput,
  ButtonGroup,
  SubmitButton,
  CancelButton,
  CountriesSelectFormik,
  removeNullAttributes,
  handleUnknownErrors,
  NotFoundError,
  apiShape,
} from "lcm-iot-commons";

export function ResellerCustomerForm({
  intl,
  api,
  initialValues,
  onSubmit,
  ssoUser,
}) {
  const [customerNumberInfo, setCustomerNumberInfo] = useState(null);
  const [customerNumberWarning, setCustomerNumberWarning] = useState(null);
  const [bdihCustomerName, setBdihCustomerName] = useState(null);

  const loadCustomerFromBdih = async (customerNumber) => {
    try {
      const bdihCustomer = await api.get(
        "/endress/customer_number_lookup",
        { customer_number: customerNumber },
        false,
      );
      const customerName = bdihCustomer?.customer_name;
      setBdihCustomerName(customerName);
      setCustomerNumberInfo(
        intl.formatMessage(
          { id: "customer_typeahead.bdih_found_info" },
          { customerName },
        ),
      );
    } catch (error) {
      if (error instanceof NotFoundError) {
        setCustomerNumberWarning(
          intl.formatMessage({ id: "customer_typeahead.new_info" }),
        );
      } else {
        handleUnknownErrors(
          error,
          intl.formatMessage({ id: "api.error.unknown" }),
        );
      }
    }
  };

  const handleOnAccountNumberChange = (element) => {
    setCustomerNumberInfo(null);
    setCustomerNumberWarning(null);
    const customerNumber = element.target.value;
    if (customerNumber) loadCustomerFromBdih(customerNumber);
  };

  const validateCustomerNumber = (values, errors) => {
    const newErrors = { ...errors };
    if (!values.number) {
      newErrors.number = intl.formatMessage({
        id: "validation.customer_number.mandatory",
      });
    }
    return newErrors;
  };

  const validateAccount = (values, errors) => {
    const newErrors = { ...errors };
    if (values.account?.length > 150) {
      newErrors.account = intl.formatMessage(
        { id: "validation.account.too_long" },
        { max: "150" },
      );
    }
    return newErrors;
  };

  const validateAddress = (values, errors) => {
    const newErrors = { ...errors };
    newErrors.streetName = !values.streetName
      ? intl.formatMessage({ id: "validation.street_name.mandatory" })
      : null;
    newErrors.zipCode = !values.zipCode
      ? intl.formatMessage({ id: "validation.zip_code.mandatory" })
      : null;
    newErrors.city = !values.city
      ? intl.formatMessage({ id: "validation.city.mandatory" })
      : null;
    newErrors.country = !values.country?.code
      ? intl.formatMessage({ id: "validation.country.mandatory" })
      : null;
    if (values.firstName?.length > 150) {
      newErrors.firstName = intl.formatMessage(
        { id: "validation.first_name.too_long" },
        { max: "150" },
      );
    }
    if (values.lastName?.length > 150) {
      newErrors.lastName = intl.formatMessage(
        { id: "validation.last_name.too_long" },
        { max: "150" },
      );
    }
    if (values.streetName?.length > 150) {
      newErrors.streetName = intl.formatMessage(
        { id: "validation.street_name.too_long" },
        { max: "150" },
      );
    }
    if (values.zipCode?.length > 20) {
      newErrors.zipCode = intl.formatMessage(
        { id: "validation.zip_code.too_long" },
        { max: "20" },
      );
    }
    if (values.city?.length > 50) {
      newErrors.city = intl.formatMessage(
        { id: "validation.city.too_long" },
        { max: "50" },
      );
    }
    if (values.streetNumber?.length > 150) {
      newErrors.streetNumber = intl.formatMessage(
        { id: "validation.street_number.too_long" },
        { max: "150" },
      );
    }
    if (values.regionCode?.length > 50) {
      newErrors.regionCode = intl.formatMessage(
        { id: "validation.region_code.too_long" },
        { max: "50" },
      );
    }
    if (values.phone?.length > 15) {
      newErrors.phone = intl.formatMessage({ id: "validation.phone.too_long" });
    }
    return newErrors;
  };

  const validateForm = (values) => {
    let errors = {};
    errors = validateCustomerNumber(values, errors);
    errors = validateAccount(values, errors);
    if (values.withAddress) {
      errors = validateAddress(values, errors);
    }
    removeNullAttributes(errors);
    return errors;
  };

  const onCustomerFormSubmit = async (values, actions) => {
    const submitValues = {
      ...values,
      account: bdihCustomerName || values.account,
    };
    onSubmit(submitValues, actions);
  };

  const handleCustomerNumberBlur = (element, formProps) => {
    formProps.handleBlur(element);
    if (!initialValues || Object.keys(initialValues).length === 0)
      handleOnAccountNumberChange(element);
  };

  const renderForm = (formProps) => {
    const { isSubmitting, values } = formProps;
    return (
      <Form {...formProps}>
        <Row id="customer-account">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="account"
              name="account"
              values={{ account: bdihCustomerName || formProps.values.account }}
              label={intl.formatMessage({ id: "label.account" })}
            />
          </Column>
        </Row>
        <Row id="customer-number">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="number"
              name="number"
              warning={customerNumberWarning}
              info={customerNumberInfo}
              handleBlur={(element) => {
                handleCustomerNumberBlur(element, formProps);
              }}
              label={intl.formatMessage({ id: "label.number" })}
              required
            />
          </Column>
        </Row>
        <Checkbox
          {...formProps}
          id="with-address"
          name="withAddress"
          label={intl.formatMessage({ id: "reseller_customer.with_address" })}
        />
        {values.withAddress && (
          <div>
            <Row id="customer-name">
              <Column md={4}>
                <TextInput
                  {...formProps}
                  id="first-name"
                  name="firstName"
                  label={intl.formatMessage({ id: "label.first_name" })}
                  disabled={ssoUser}
                />
              </Column>
              <Column md={4}>
                <TextInput
                  {...formProps}
                  id="last-name"
                  name="lastName"
                  label={intl.formatMessage({ id: "label.last_name" })}
                  disabled={ssoUser}
                />
              </Column>
            </Row>
            <Row id="customer-street">
              <Column md={6}>
                <TextInput
                  {...formProps}
                  id="street-name"
                  name="streetName"
                  label={intl.formatMessage({ id: "label.street_name" })}
                  required
                />
              </Column>
              <Column md={2}>
                <TextInput
                  {...formProps}
                  id="street-number"
                  name="streetNumber"
                  label={intl.formatMessage({ id: "label.street_number" })}
                />
              </Column>
            </Row>
            <Row id="customer-city">
              <Column md={2}>
                <TextInput
                  {...formProps}
                  id="zip-code"
                  name="zipCode"
                  label={intl.formatMessage({ id: "label.zip_code" })}
                  required
                />
              </Column>
              <Column md={6}>
                <TextInput
                  {...formProps}
                  id="city"
                  name="city"
                  label={intl.formatMessage({ id: "label.city" })}
                  required
                />
              </Column>
            </Row>
            <Row id="customer-country">
              <Column md={2}>
                <TextInput
                  {...formProps}
                  id="region-code"
                  name="regionCode"
                  label={intl.formatMessage({ id: "label.region_code" })}
                />
              </Column>
              <Column md={6}>
                <CountriesSelectFormik
                  {...formProps}
                  id="country"
                  name="country"
                  label={intl.formatMessage({ id: "label.country" })}
                  required
                  initializeEmpty
                  all
                />
              </Column>
            </Row>
            <Row id="customer-phone">
              <Column md={8}>
                <TextInput
                  {...formProps}
                  id="phone"
                  name="phone"
                  label={intl.formatMessage({ id: "label.phone" })}
                />
              </Column>
            </Row>
          </div>
        )}
        <ButtonGroup className="btn-group">
          <SubmitButton
            id="customer-submit"
            fetching={isSubmitting}
            intl={intl}
            disabled={!formProps.dirty}
          />
          <CancelButton
            id="customer-cancel"
            disabled={isSubmitting}
            intl={intl}
            target="/reseller/customers"
          />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <div className="form-group">
      <Formik
        id="customer-form"
        initialValues={initialValues}
        onSubmit={onCustomerFormSubmit}
        validate={validateForm}
        render={renderForm}
      />
    </div>
  );
}

ResellerCustomerForm.propTypes = {
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    ssoProvider: PropTypes.string,
    address: PropTypes.shape({
      account: PropTypes.string,
      streetName: PropTypes.string,
      streetNumber: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      regionCode: PropTypes.string,
      country: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
      }),
      phone: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func,
  ssoUser: PropTypes.bool,
};

ResellerCustomerForm.defaultProps = {
  initialValues: {
    address: {
      country: {
        code: undefined,
        name: undefined,
      },
    },
  },
  onSubmit: undefined,
  ssoUser: false,
};

export default withApi(withIntl(ResellerCustomerForm));
