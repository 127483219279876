/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom-v5-compat";
import { url } from "../../utils";
import CustomOverlayTrigger from "../CustomOverlayTrigger";

export function Chip({ id, name, link, onRemove, withTooltip }) {
  const handleRemoveClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove(id);
  };

  const chip = (
    <div
      className="chip"
      role="button"
      tabIndex={withTooltip ? 0 : undefined}
      onClick={
        /* istanbul ignore next */ (e) => {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    >
      {link ? (
        <Link className="chip-clickable" to={url(link)}>
          {name}
        </Link>
      ) : (
        <span>{name}</span>
      )}
      {onRemove && (
        <button
          data-testid="chip-remove-button"
          type="button"
          onClick={handleRemoveClicked}
          className="icon-eh-close"
        />
      )}
    </div>
  );

  if (!withTooltip) return chip;

  return (
    <CustomOverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="tooltip">{name}</Tooltip>}
    >
      {chip}
    </CustomOverlayTrigger>
  );
}

Chip.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onRemove: PropTypes.func,
  withTooltip: PropTypes.bool,
};

export default Chip;
