import { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { useConfiguration } from "../context/ConfigurationContext";
import { useSession } from "../context/SessionContext";

const subscriptionEndpointsDefaults = [
  "/plan_variants",
  "/reseller/subscriptions/create",
  "/subscription",
  "/subscription/create",
  "/subscription/enterprise-quotation-request/success",
  "/subscription/invoice/success",
  "/subscription/overview",
  "/subscriptions",
];

const unsecuredEndpointsDefaults = ["/403", "/404", "/500", "/session_expired"];

const removeBaseUrl = (urls, baseUrl) =>
  urls.map((url) => {
    if (url.indexOf(baseUrl) === 0) {
      return url.substring(baseUrl.length);
    }
    return url;
  });

export default function SubscriptionGuard({
  children,
  enforceSubscription = false,
  subscriptionEndpoints = subscriptionEndpointsDefaults,
  unsecuredEndpoints = unsecuredEndpointsDefaults,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const session = useSession();
  const configuration = useConfiguration();

  const shouldRedirect = (() => {
    if (!session.authenticated) return false;
    const relevantLocationUrl = location.pathname.substring(
      configuration.baseUrl.length,
    );
    return (
      !session.activeSubscription &&
      enforceSubscription &&
      !/\/subscriptions\/\d+/.test(location.pathname) && // special handling to access subscription via permissions
      removeBaseUrl(subscriptionEndpoints, configuration.baseUrl).indexOf(
        relevantLocationUrl,
      ) < 0 &&
      removeBaseUrl(unsecuredEndpoints, configuration.baseUrl).indexOf(
        relevantLocationUrl,
      ) < 0
    );
  })();

  useEffect(() => {
    if (shouldRedirect) {
      navigate(`${configuration.baseUrl}/plan_variants`);
    }
  }, [shouldRedirect, navigate, configuration.baseUrl]);

  return shouldRedirect ? null : children;
}

SubscriptionGuard.propTypes = {
  children: PropTypes.node,
  configuration: PropTypes.shape({ baseUrl: PropTypes.string }),
  enforceSubscription: PropTypes.bool,
  subscriptionEndpoints: PropTypes.arrayOf(PropTypes.string),
  unsecuredEndpoints: PropTypes.arrayOf(PropTypes.string),
};
