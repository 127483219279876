import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import Fuse from "fuse.js";
import { useSearchParams } from "react-router-dom-v5-compat";
import { trackingShape, withTracking } from "../../context";
import {
  isNotEmpty,
  isTouchDevice,
  isNullOrEmpty,
  formatDateTime,
} from "../../utils";
import { documentsShape } from "../../shapes";
import SearchBar from "./SearchBar";
import { annotateResult, extractItems } from "../../utils/searchDocumentsUtils";
import { useUrlState } from "../../hooks/useUrlState";

export function SearchDocuments({ documentList, onSearch, tracking }) {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filesQuery, setFilesQuery] = useUrlState(
    "filesQuery",
    searchParams,
    setSearchParams,
  );
  const timeoutRef = React.useRef(null);

  const searchOptions = {
    includeMatches: true,
    findAllMatches: true,
    ignoreLocation: true,
    threshold: 0.0,
    keys: [
      "name",
      "categories.name",
      "categories_label",
      "attachments.file_name",
      "attachments.content_date",
      "defaultAttachment.content_date",
    ],

    getFn: (obj, path) => {
      const key = path.join(".");

      let value = Fuse.config.getFn(obj, path);

      if (
        key === "attachments.content_date" &&
        obj?.attachments[0]?.content_date
      ) {
        const contentDate = obj?.attachments[0].content_date;
        value = formatDateTime(contentDate, intl);
      }

      if (
        key === "defaultAttachment.content_date" &&
        obj?.defaultAttachment?.content_date
      ) {
        const contentDate = obj.defaultAttachment.content_date;
        value = formatDateTime(contentDate, intl);
      }

      return value;
    },
  };

  const handleOnSearch = (term) => {
    if (isNotEmpty(term) && !isNullOrEmpty(term)) {
      const fuse = new Fuse(documentList, searchOptions);
      const a = fuse.search(term);
      const result = annotateResult(a);
      const documents = extractItems(result);
      setFilesQuery(term);

      // TODO - outsource debounce behaviour to a custom hook and use it in Netilion e.G. SearchBar as well.
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        tracking.trackEvent("DocumentsSearch-typed", { term });
      }, 1250);

      onSearch(documents);
    } else {
      setFilesQuery(null);
      onSearch(documentList);
    }
  };

  React.useEffect(() => {
    if (filesQuery.length) handleOnSearch(filesQuery);
  }, []);

  return (
    <div>
      <SearchBar
        id="searchBar"
        autoFocus={!isTouchDevice()}
        onSearch={handleOnSearch}
        placeholder={intl.formatMessage({ id: "search.searchbox_placeholder" })}
        minChars={1}
        timeout={150}
        resettable
        filterWildcard={false}
        defaultValue={filesQuery}
      />
    </div>
  );
}

SearchDocuments.defaultProps = {
  documentList: [],
  onSearch: undefined,
};

SearchDocuments.propTypes = {
  documentList: documentsShape,
  onSearch: PropTypes.func,
  tracking: trackingShape.isRequired,
};

export default withTracking(SearchDocuments);
