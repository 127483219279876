/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NamurType from "../constants/NamurType";

import Ok from "../assets/images/ok.svg";

import Failure from "../assets/images/failure.svg";
import CheckFunction from "../assets/images/check-function.svg";
import MaintenanceRequired from "../assets/images/maintenance-required.svg";
import OutOfSpecification from "../assets/images/out-of-specification.svg";
import Unknown from "../assets/images/unknown.svg";
import Undefined from "../assets/images/undefined.svg";

function NamurIcon({ type, size, mobileSize }) {
  /* eslint-disable no-nested-ternary */
  const calculateSize = () =>
    mobileSize ? (window.innerWidth >= 768 ? size : mobileSize) : size;
  const [calculatedSize, setCalculatedSize] = useState(calculateSize());

  useEffect(() => {
    const handleResize = () => setCalculatedSize(calculateSize());
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const secondSize = Math.round(calculatedSize * 0.8);
  const style = {
    position: "relative",
    textAlign: "center",
    display: "inline-block",
  };
  switch (type) {
    case NamurType.OK:
      return (
        <div style={style}>
          <Ok
            width={secondSize}
            height={secondSize}
            viewBox="0 0 56 56"
            data-testid="Namur-OK"
          />
        </div>
      );
    case NamurType.FAILURE:
      return (
        <div style={style}>
          <Failure
            width={secondSize}
            height={secondSize}
            viewBox="0 0 56 56"
            data-testid="Namur-Failure"
          />
        </div>
      );
    case NamurType.CHECK_FUNCTION:
      return (
        <div style={style}>
          <CheckFunction
            width={calculatedSize}
            height={calculatedSize}
            viewBox="0 0 68 60"
            data-testid="Namur-CheckFunction"
          />
        </div>
      );
    case NamurType.OUT_OF_SPECIFICATION:
      return (
        <div style={style}>
          <OutOfSpecification
            width={calculatedSize}
            height={calculatedSize}
            viewBox="0 0 68 60"
            data-testid="Namur-OOS"
          />
        </div>
      );
    case NamurType.MAINTENANCE_REQUIRED:
      return (
        <div style={style}>
          <MaintenanceRequired
            width={calculatedSize}
            height={calculatedSize}
            viewBox="0 0 60 60"
            data-testid="Namur-Maintenance"
          />
        </div>
      );
    case NamurType.UNKNOWN:
      return (
        <div style={style}>
          <Unknown
            width={secondSize}
            height={secondSize}
            viewBox="0 0 52 52"
            data-testid="Namur-Unknown"
          />
        </div>
      );
    default:
      return (
        <div style={style}>
          <Undefined
            width={calculatedSize}
            height={calculatedSize}
            viewBox="0 0 68 60"
            data-testid="Namur-Undefined"
          />
        </div>
      );
  }
}

NamurIcon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
  mobileSize: PropTypes.number,
};

NamurIcon.defaultProps = {
  type: undefined,
  size: 32,
  mobileSize: undefined,
};

export default NamurIcon;
