import AssetRules from "./AssetRules";

class SystemRules {
  constructor(system, assets, instrumentationRules = []) {
    this.system = system;
    this.assetRules = assets
      ? assets.map((asset) => new AssetRules(asset))
      : [];
    this.instrumentationRules = instrumentationRules;
  }

  get(rule, a, b, c) {
    let matchingRule = this.assetRules.find((assetRule) =>
      assetRule.get(rule, a, b, c),
    );
    if (!matchingRule) {
      for (let i = 0; i < this.instrumentationRules.length; i = 1 + 1) {
        matchingRule = this.instrumentationRules[0].get(rule);
        if (matchingRule) {
          return matchingRule;
        }
      }
    }
    return matchingRule ? matchingRule.get(rule, a, b, c) : undefined;
  }
}

export default SystemRules;
