import { isNotEmpty } from "./langUtils";
import {
  extractInstrumentationTypeTree,
  extractPictures,
  extractPrimaryValue,
} from "../extractors";
import { convertSpecsToAttributes } from "./specificationUtils";

export function formatInstrumentationDetails(instrumentation) {
  let details = instrumentation.tag;
  details = instrumentation.type.displayName;
  if (isNotEmpty(instrumentation.description)) {
    details = details.concat(", ").concat(instrumentation.description);
  }
  return details;
}

export function findMatchingInstrumentationType(
  productCategories,
  instrumentationTypes,
) {
  let matchingType = null;
  productCategories.some((categorie) =>
    instrumentationTypes.some((type, index) => {
      if (
        type.code === categorie.code ||
        (categorie.parent && type.code === categorie.parent.code)
      ) {
        matchingType = instrumentationTypes[index];
        return true;
      }
      return false;
    }),
  );
  return matchingType;
}

export function prepareInstrumentationForDisplay(apiResponse) {
  let newInstrumentation = apiResponse;
  const specsWhitelist = [
    "eh.iba.environmental_condition",
    "eh.iba.special_demands",
    "eh.iba.criticality_quality",
    "eh.iba.criticality_environment",
    "eh.iba.criticality_safety",
    // The following specs are shared by value & inventory.
    // They get displayed by appShowsMediumType & appShowsFullEmptyCalibration (bools in props)
    "eh.user_config.medium_type",
    "eh.user_config.unit",
    "eh.user_config.empty",
    "eh.user_config.full",
    "eh.user_config.sensitivity",
    "eh.user_config.block_distance",
  ];
  newInstrumentation.itemType = "instrumentation";
  newInstrumentation.type = extractInstrumentationTypeTree(apiResponse.type);
  newInstrumentation.pictures = extractPictures(apiResponse);
  newInstrumentation.value = extractPrimaryValue(apiResponse.values);
  newInstrumentation = convertSpecsToAttributes(
    newInstrumentation,
    apiResponse.specifications,
    specsWhitelist,
  );
  return newInstrumentation;
}
