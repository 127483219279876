/* eslint-disable import/no-cycle */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { MenuItem } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import { BadRequestError, ConflictError } from "../../api";
import {
  withNotifier,
  notifierShape,
  withRules,
  rulesShape,
  withApi,
  apiShape,
  withBrowser,
  browserShape,
} from "../../context";
import AllObjectsSelectModal from "../AllObjects/AllObjectsSelectModal";
import ConfirmationModal from "../ConfirmationModal";
import ItemMenu from "../ItemMenu";
import ListItem from "../List/ListItem";

import {
  accessRightsShape,
  nodeShape,
  assetShape,
  instrumentationShape,
  intlShape,
} from "../../shapes";

import { isNotEmpty, url } from "../../utils";
import SubscriptionAssignmentMenuItem from "../Subscriptions/SubscriptionAssigmentMenuItem";
import { FormattedNumber } from "../Format";
import { extractAccessRights } from "../../extractors/accessRightsExtractor";
import {
  getAssignedNodesChips,
  getSpecificationChips,
} from "../../utils/chipsUtils";

export class InstrumentationItem extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnItemMenuOpen = this.handleOnItemMenuOpen.bind(this);
    this.handleOnEditClick = this.handleOnEditClick.bind(this);
    this.handleOnConfirm = this.handleOnConfirm.bind(this);
    this.handleOnAssignClick = this.handleOnAssignClick.bind(this);
    this.handleOnUnassignClick = this.handleOnUnassignClick.bind(this);
    this.handleOnMoveAssignmentClick =
      this.handleOnMoveAssignmentClick.bind(this);
    this.handleOnModalClose = this.handleOnModalClose.bind(this);
    this.handleOnNodeSelect = this.handleOnNodeSelect.bind(this);
    this.handleOnDeleteClick = this.handleOnDeleteClick.bind(this);

    this.state = {
      accessRights: { canUpdate: false, canDelete: false, isLoading: true },
      assigning: false,
      unassigning: false,
      moving: false,
      deleting: false,
    };
  }

  async handleOnItemMenuOpen() {
    const { instrumentation, api, notifier } = this.props;
    try {
      const accessRights = extractAccessRights(
        "Instrumentation",
        instrumentation.id,
        await api.get("/users/current/access_rights", {
          permitable_type: "Instrumentation",
          permitable_id: instrumentation.id,
        }),
      );

      this.setState({ accessRights: { ...accessRights, isLoading: false } });
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  }

  handleOnEditClick() {
    const { instrumentation, browser } = this.props;
    browser.navigateTo(`/instrumentations/${instrumentation.id}/edit`);
  }

  async handleOnConfirm() {
    const {
      intl,
      onInstrumentationRemoved,
      parentNode,
      parentAsset,
      instrumentation,
      notifier,
      api,
    } = this.props;
    const { deleting, unassigning } = this.state;
    this.resetActionsState();
    if (deleting) {
      try {
        await api.delete(`/instrumentations/${instrumentation.id}`);
        notifier.showSuccess(
          intl.formatMessage({
            id: "instrumentation.actions.delete.notification",
          }),
        );
        onInstrumentationRemoved?.(instrumentation);
      } catch (error) {
        if (error instanceof ConflictError)
          notifier.showError(
            intl.formatMessage({
              id: "api.error.instrumentation.assigned_restriction",
            }),
          );
        else notifier.showError(api.translateError(error));
      }
    }
    if (unassigning) {
      try {
        if (parentNode) {
          await api.delete(`/nodes/${parentNode.id}/instrumentations`, {
            instrumentations: [{ id: instrumentation.id }],
          });
          notifier.showSuccess(
            intl.formatMessage({
              id: "instrumentation.actions.unassign.notification",
            }),
          );
          onInstrumentationRemoved?.(instrumentation);
        } else if (parentAsset) {
          await api.delete(`/assets/${parentAsset.id}/instrumentations`, {
            instrumentations: [{ id: instrumentation.id }],
          });

          notifier.showSuccess(
            intl.formatMessage({
              id: "instrumentation.actions.unassign.notification",
            }),
          );
          onInstrumentationRemoved?.(instrumentation);
        }
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    }
    return null;
  }

  handleOnAssignClick() {
    this.setState({ assigning: true });
  }

  handleOnUnassignClick() {
    this.setState({ unassigning: true });
  }

  handleOnMoveAssignmentClick() {
    this.setState({ moving: true });
  }

  handleOnDeleteClick() {
    this.setState({ deleting: true });
  }

  handleOnModalClose() {
    this.resetActionsState();
  }

  async handleOnNodeSelect(node) {
    const {
      instrumentation,
      onInstrumentationRemoved,
      intl,
      parentNode,
      parentAsset,
      api,
      notifier,
    } = this.props;
    const { assigning, moving } = this.state;
    try {
      if (assigning && node) {
        await api.post(`/instrumentations/${instrumentation.id}/nodes`, {
          nodes: [{ id: node.id }],
        });
        if (!parentNode && !parentAsset) {
          notifier.showSuccess(
            intl.formatMessage({
              id: "instrumentation.actions.move_assignment.notification",
            }),
          );
          onInstrumentationRemoved?.(instrumentation);
        } else {
          notifier.showSuccess(
            intl.formatMessage({
              id: "instrumentation.actions.assign.notification",
            }),
          );
        }
        this.resetActionsState();
      } else if (moving) {
        await api.post(`/instrumentations/${instrumentation.id}/nodes`, {
          nodes: [{ id: node.id }],
        });
        await api.delete(`/instrumentations/${instrumentation.id}/nodes`, {
          nodes: [{ id: parentNode.id }],
        });
        notifier.showSuccess(
          intl.formatMessage({
            id: "instrumentation.actions.move_assignment.notification",
          }),
        );
        onInstrumentationRemoved?.(instrumentation);
        this.resetActionsState();
      }
    } catch (error) {
      if (error instanceof BadRequestError) {
        notifier.showError(
          intl.formatMessage({ id: "instrumentation.actions.assign.error" }),
        );
      } else {
        notifier.showError(api.translateError(error));
      }
    }
  }

  formatDetails(instrumentation) {
    let details = instrumentation.typeName ? instrumentation.typeName : "";

    if (isNotEmpty(details) && isNotEmpty(instrumentation.description)) {
      details = details.concat(", ");
    }

    if (isNotEmpty(instrumentation.description)) {
      details = details.concat(instrumentation.description);
    }

    if (instrumentation.statusCode === "inactive") {
      details = details.concat(` (${instrumentation.statusName})`);
    }

    return details;
  }

  resetActionsState() {
    this.setState({
      assigning: false,
      unassigning: false,
      moving: false,
      deleting: false,
    });
  }

  renderActions() {
    const {
      instrumentation,
      showItemMenu,
      intl,
      parentNode,
      parentAsset,
      parentAccessRights,
    } = this.props;

    const { canUpdate, canDelete, canPermit } = this.state.accessRights;
    const { assigning, moving, deleting, unassigning, assignedNodeIds } =
      this.state;

    return (
      <>
        <ItemMenu
          isLoading={this.state.accessRights.isLoading}
          onOpen={this.handleOnItemMenuOpen}
        >
          <MenuItem onSelect={this.handleOnEditClick} disabled={!canUpdate}>
            <FormattedMessage id="button.edit" />
          </MenuItem>
          {!!(showItemMenu && parentNode) && (
            <>
              <MenuItem
                onSelect={this.handleOnAssignClick}
                disabled={!canPermit}
              >
                <FormattedMessage id="button.assign" />
              </MenuItem>
              <MenuItem
                onSelect={this.handleOnUnassignClick}
                disabled={!parentAccessRights.canUpdate || !canPermit}
              >
                <FormattedMessage id="button.unassign" />
              </MenuItem>
              <MenuItem
                onSelect={this.handleOnMoveAssignmentClick}
                disabled={!parentAccessRights.canUpdate || !canPermit}
              >
                <FormattedMessage id="button.move" />
              </MenuItem>
            </>
          )}
          {!!(showItemMenu && !parentNode && !parentAsset) && (
            <MenuItem onSelect={this.handleOnAssignClick} disabled={!canPermit}>
              <FormattedMessage id="button.move" />
            </MenuItem>
          )}
          {!!(showItemMenu && parentAsset) && (
            <MenuItem
              onSelect={this.handleOnUnassignClick}
              disabled={!parentAccessRights.canPermit || !canUpdate}
            >
              <FormattedMessage id="button.unassign" />
            </MenuItem>
          )}
          <MenuItem onSelect={this.handleOnDeleteClick} disabled={!canDelete}>
            <FormattedMessage id="button.delete" />
          </MenuItem>
          <SubscriptionAssignmentMenuItem
            id={instrumentation.id}
            type="instrumentation"
            assign
          />
          <SubscriptionAssignmentMenuItem
            id={instrumentation.id}
            type="instrumentation"
            assign={false}
          />
        </ItemMenu>
        {assigning || moving ? (
          <AllObjectsSelectModal
            onClose={this.handleOnModalClose}
            onSelectNode={this.handleOnNodeSelect}
            canNavigateDisabledNodes
            disabledNodeIds={assignedNodeIds}
            type={
              ((parentNode || parentAsset) && assigning && "assign") || "move"
            }
          />
        ) : null}
        {deleting || unassigning ? (
          <ConfirmationModal
            intl={intl}
            id="confirmation-modal"
            show
            titleText={intl.formatMessage({
              id: deleting
                ? "instrumentation.actions.delete.modal_title"
                : "instrumentation.actions.unassign.confirmation.title",
            })}
            messageText={intl.formatMessage({
              id: deleting
                ? "instrumentation.actions.delete.modal_message"
                : "instrumentation.actions.unassign.confirmation.message",
            })}
            onConfirm={this.handleOnConfirm}
            onClose={this.handleOnModalClose}
          />
        ) : null}
      </>
    );
  }

  render() {
    const { instrumentation, intl, onClick, rules, hideStatus, options } =
      this.props;

    const instrumentationRules = rules.instrumentation(
      instrumentation,
      instrumentation.assets,
    );

    let status = instrumentation.worstStatusCode || "undefined";
    if (instrumentation.statusCode === "inactive") {
      status = status.concat(" inactive");
    }

    status = hideStatus ? "hidden" : status;

    const specifications = options?.includes("specifications")
      ? getSpecificationChips(instrumentation.specifications, intl)
      : undefined;
    const nodes = options?.includes("nodes")
      ? getAssignedNodesChips(instrumentation.nodes, intl)
      : undefined;

    return (
      <ListItem
        id={`instrumentation-item-${instrumentation.id}`}
        icon="lcm-iot-icon-instrumentation"
        title={instrumentation.tag}
        description={this.formatDetails(instrumentation)}
        onClick={onClick ? () => onClick(instrumentation) : null}
        status={status}
        target={
          onClick ? undefined : url(`/instrumentations/${instrumentation.id}`)
        }
        specifications={specifications}
        nodes={nodes}
        value={
          instrumentation.value ? (
            <>
              <FormattedNumber
                minimumFractionDigits={instrumentationRules.get(
                  "minDecimalDigits",
                  instrumentation.value.key,
                )}
                maximumFractionDigits={instrumentationRules.get(
                  "maxDecimalDigits",
                  instrumentation.value.key,
                )}
                value={instrumentation.value.value}
              />
              <span>{` ${instrumentation.value.unit}`}</span>
            </>
          ) : null
        }
        actions={this.renderActions()}
      />
    );
  }
}

InstrumentationItem.propTypes = {
  instrumentation: instrumentationShape,
  intl: intlShape,
  onInstrumentationRemoved: PropTypes.func,
  onClick: PropTypes.func,
  showItemMenu: PropTypes.bool,
  parentNode: nodeShape,
  parentAsset: assetShape,
  parentAccessRights: accessRightsShape,
  rules: rulesShape,
  hideStatus: PropTypes.bool,
  notifier: notifierShape,
  api: apiShape,
  browser: browserShape,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default withBrowser(
  withApi(withNotifier(withRules(injectIntl(InstrumentationItem)))),
);
