import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/de";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/de";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { IntlProvider } from "react-intl";
import { withConfiguration } from "../context/ConfigurationContext";
import TRANSLATIONS, { getTranslations } from "../translations";

export function Intl({
  children,
  configuration = { language: "en", locale: "en" },
  mergeAppMessages = () => {},
}) {
  useMemo(() => {
    mergeAppMessages(configuration.language);
    getTranslations(configuration.language);
  }, []);

  return (
    <IntlProvider
      locale={configuration.locale}
      messages={TRANSLATIONS[configuration.language]}
    >
      {children}
    </IntlProvider>
  );
}

Intl.propTypes = {
  children: PropTypes.node.isRequired,
  mergeAppMessages: PropTypes.func,
  configuration: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
};

export default withConfiguration(Intl);
