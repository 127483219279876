import { ApplicationRules, ProductRules } from "lcm-iot-commons";
import IdRules from "./IdRules";
import ProductEndressHauserConnectedAssetRules from "./ProductEndressHauserConnectedAssetRules";

ApplicationRules.register(IdRules);
ProductRules.register(
  "Endress+Hauser",
  "5W8C*",
  ProductEndressHauserConnectedAssetRules,
);
ProductRules.register(
  "Endress+Hauser",
  "FWR30",
  ProductEndressHauserConnectedAssetRules,
);
ProductRules.register(
  "Endress+Hauser",
  "QWX43",
  ProductEndressHauserConnectedAssetRules,
);
