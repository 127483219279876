import React from "react";
import { useIntl } from "react-intl";
import { ActionBar, Column, Container, Heading } from "lcm-iot-commons";
import { Row } from "react-bootstrap";
import TenantForm from "./TenantForm";

export function TenantCreate() {
  const intl = useIntl();
  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading
              title={intl.formatMessage({ id: "tenant_create.header" })}
            />
          </ActionBar>
        </Column>
      </Row>
      <Row>
        <Column>
          <TenantForm intl={intl} />
        </Column>
      </Row>
    </Container>
  );
}

export default TenantCreate;
