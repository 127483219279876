/* eslint-disable */
import PropTypes from "prop-types";
import { Component } from "react";

class NavigationItem extends Component {
  static componentName = "NavigationItem";

  static propTypes = {
    active: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    target: PropTypes.string,
    translationKey: PropTypes.string,
    className: PropTypes.string,
    isExternalLink: PropTypes.bool,
    externalLinkTargetName: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };
}

export default NavigationItem;
