import PropTypes from "prop-types";

export const productShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  productCode: PropTypes.string,
  pictureUrl: PropTypes.string,
});

export const productsShape = PropTypes.arrayOf(productShape);
