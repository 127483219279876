import React from "react";
import PropTypes from "prop-types";

function StatusBadge({ name }) {
  return (
    <div
      className={`status-badge ${name}`}
      data-testid={`status-badge-${name}`}
    />
  );
}

StatusBadge.propTypes = {
  name: PropTypes.string,
};

StatusBadge.defaultProps = {
  name: undefined,
};

export default StatusBadge;
