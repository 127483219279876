import React, { useState } from "react";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import md5 from "js-md5";

import {
  backendShape,
  List,
  Loader,
  intlShape,
  Row,
  Column,
  notifierShape,
  withBackend,
  accessRightsShape,
  withNotifier,
  ButtonGroup,
  Form,
  SubmitButton,
  TextInput,
} from "lcm-iot-commons";

import OpcUaServerWhitelistItem from "./OpcUaServerWhitelistItem";

export function OpcUaServerWhitelist({
  intl,
  opcUaServer,
  notifier,
  accessRights,
  backend,
}) {
  const [whitelist, setWhitelist] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [fetching, setFechting] = useState(false);

  const loadWhitelist = async () => {
    try {
      const response = await backend.get(
        `/api_subscriptions/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}/whitelist`,
      );
      setWhitelist(response);
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadWhitelist();
  }, [opcUaServer]);

  const handleOnDelete = async (id) => {
    setFechting(true);
    try {
      await backend.delete(
        `/api_subscriptions/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}/whitelist/${id}`,
      );
      setWhitelist(whitelist.filter((i) => i.id !== id));
      notifier.showSuccess(
        intl.formatMessage({ id: "whitelist_item.delete.notification" }),
      );
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
    setFechting(false);
  };

  const handleOnSubmit = async (values, actions) => {
    setFechting(true);
    try {
      await backend.post(
        `/api_subscriptions/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}/whitelist`,
        values,
      );
      setWhitelist([...whitelist, { id: md5(values.name), name: values.name }]);
      notifier.showSuccess(
        intl.formatMessage({ id: "whitelist_item.added.notification" }),
      );
    } catch (error) {
      notifier.showError(backend.translateError(error));
    }
    actions.setSubmitting(false);
    actions.resetForm({});
    setFechting(false);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name || values.name.trim().length === 0) {
      errors.name = intl.formatMessage({ id: "whitelist_item.error.invalid" });
    } else if (values.name === "default") {
      errors.name = intl.formatMessage({ id: "whitelist_item.error.default" });
    } else if (whitelist.find((v) => v.name === values.name.trim())) {
      errors.name = intl.formatMessage({ id: "whitelist_item.error.exists" });
    }

    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <TextInput
          {...formProps}
          id="name"
          name="name"
          label={intl.formatMessage({ id: "label.common_name" })}
          disabled={isLoading || isSubmitting || fetching}
          hideLabel
        />
        <ButtonGroup>
          <SubmitButton
            id="add-whitelist-entry-button"
            fetching={isSubmitting}
            disabled={isLoading || isSubmitting || fetching}
            text={intl.formatMessage({ id: "button.add" })}
          />
        </ButtonGroup>
        <div className="space-after" />
      </Form>
    );
  };

  return (
    <Loader loading={isLoading}>
      <Row>
        <Column>
          {accessRights.canUpdate && (
            <Formik
              onSubmit={handleOnSubmit}
              render={renderForm}
              validate={validate}
            />
          )}
        </Column>
      </Row>
      <Row>
        <Column>
          {whitelist && whitelist.length > 0 && (
            <List>
              {whitelist.map((item) => (
                <OpcUaServerWhitelistItem
                  key={item.id}
                  disabled={fetching}
                  onDelete={handleOnDelete}
                  accessRights={accessRights}
                  item={item}
                />
              ))}
            </List>
          )}
          {whitelist && whitelist.length === 0 && (
            <p id="empty-whitelist-message" className="space-after">
              {intl.formatMessage({ id: "opc_ua_server_details.no_whitelist" })}
            </p>
          )}
        </Column>
      </Row>
    </Loader>
  );
}

OpcUaServerWhitelist.propTypes = {
  intl: intlShape.isRequired,
  opcUaServer: PropTypes.shape({
    id: PropTypes.string,
    subscription_id: PropTypes.string,
  }).isRequired,
  accessRights: accessRightsShape.isRequired,
  notifier: notifierShape.isRequired,
  backend: backendShape.isRequired,
};

export default injectIntl(withNotifier(withBackend(OpcUaServerWhitelist)));
