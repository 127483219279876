import { isSomething } from "../utils/langUtils";

const BREADCRUMP_SEPARATOR = "-";

export function extractNodeType(rawNodeType) {
  const tenantId = rawNodeType.tenant ? rawNodeType.tenant.id : undefined;
  const tenantName = rawNodeType.tenant ? rawNodeType.tenant.name : undefined;
  const tenantPublic = rawNodeType.tenant
    ? rawNodeType.tenant.public
    : undefined;

  return {
    id: rawNodeType.id,
    name: rawNodeType.name,
    code: rawNodeType.code,
    tenantId,
    tenantPublic,
    tenantName,
    parentId: rawNodeType.parent ? rawNodeType.parent.id : null,
    parentCode: rawNodeType.parent ? rawNodeType.parent.code : null,
  };
}

function buildDisplayNameForTree(type) {
  if (isSomething(type.parent)) {
    return `${buildDisplayNameForTree(type.parent)} ${BREADCRUMP_SEPARATOR} ${type.name}`;
  }
  return type.name;
}

function buildDisplayName(type, allTypes) {
  if (isSomething(type.parentId)) {
    const parent = allTypes.find((t) => t.id === type.parentId);
    if (parent.parentId) {
      return `${buildDisplayName(parent, allTypes)} ${BREADCRUMP_SEPARATOR} ${type.name}`;
    }
    return `${parent.name} ${BREADCRUMP_SEPARATOR} ${type.name}`;
  }
  return type.name;
}

export function extractNodeTypes(rawTypes, buildBreadcrumbNames = false) {
  const mappedTypes = rawTypes.node_types.map((type) => extractNodeType(type));
  if (buildBreadcrumbNames) {
    mappedTypes.forEach((type) => {
      /* eslint-disable no-param-reassign */
      type.name = buildDisplayName(type, mappedTypes);
    });
  }
  return mappedTypes;
}

export function extractNodeTypeTree(rawNodeType) {
  const type = extractNodeType(rawNodeType);
  type.name = buildDisplayNameForTree(rawNodeType);
  return type;
}
