import PropTypes from "prop-types";

// Tenant
export const apiKeyShape = PropTypes.shape({
  id: PropTypes.number,
  clientApplicationId: PropTypes.number,
  apiKey: PropTypes.string,
  apiSecret: PropTypes.string,
  authenticationType: PropTypes.string,
  validFrom: PropTypes.number,
  validTo: PropTypes.number,
  disabled: PropTypes.bool,
  description: PropTypes.string,
});
export const apiKeysShape = PropTypes.arrayOf(apiKeyShape);
