import React from "react";
import { injectIntl } from "react-intl";
import { intlShape } from "../../shapes";
import ActionButton from "./ActionButton";

function CreateActionButton(props) {
  return (
    <ActionButton
      {...props}
      icon="icon-eh-new"
      defaultLabel={props.intl.formatMessage({ id: "button.create" })}
    />
  );
}

CreateActionButton.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CreateActionButton);
