import React from "react";
import PropTypes from "prop-types";

import InfiniteScroll from "react-infinite-scroller";
import AssetItem from "../Assets/AssetItem";
import InstrumentationItem from "../Instrumentations/InstrumentationItem";
import NodeItem from "../Nodes/NodeItem";

import List from "../List/List";

import {
  accessRightsShape,
  assetsShape,
  instrumentationShape,
  nodeShape,
} from "../../shapes";
import { useSearch } from "../../hooks/useSearch";

export function AllObjectsList({
  accessRights,
  isLoading,
  hasMore,
  items,
  loadMore,
  node,
  onItemRemoved,
}) {
  const { options } = useSearch();

  const renderItem = (item) => {
    const commonProp = {
      showItemMenu: true,
      options,
    };

    if (item.itemType === "node") {
      return (
        <NodeItem
          key={`n${item.id}`}
          node={item}
          parentNode={node || item}
          onNodeRemoved={onItemRemoved}
          {...commonProp}
        />
      );
    }
    if (item.itemType === "instrumentation") {
      return (
        <InstrumentationItem
          key={`i${item.id}`}
          instrumentation={item}
          parentNode={node}
          parentAccessRights={accessRights}
          onInstrumentationRemoved={onItemRemoved}
          {...commonProp}
        />
      );
    }
    if (item.itemType === "asset") {
      return (
        <AssetItem
          key={`a${item.id}`}
          asset={item}
          parentNode={node}
          parentAccessRights={accessRights}
          onAssetRemoved={onItemRemoved}
          {...commonProp}
        />
      );
    }
    return null;
  };

  return (
    <List id="nodes-list">
      <InfiniteScroll
        initialLoad={false}
        loadMore={loadMore}
        hasMore={!isLoading && hasMore}
        threshold={700}
        id="objects"
      >
        {items.map(renderItem)}
      </InfiniteScroll>
    </List>
  );
}

AllObjectsList.propTypes = {
  accessRights: accessRightsShape,
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([nodeShape, instrumentationShape, assetsShape]),
  ).isRequired,
  loadMore: PropTypes.func.isRequired,
  node: nodeShape,
  onItemRemoved: PropTypes.func.isRequired,
};

export default AllObjectsList;
