import React from "react";
import PropTypes from "prop-types";
import { handleFormikValueChange, Select } from "lcm-iot-commons";

export function FieldGatewayModbusChannelSelect(props) {
  const { valueArrayName, objIdx, values } = props;

  return (
    <Select
      {...props}
      options={values.allAvailableChannels}
      selectedOption={values[valueArrayName][objIdx].channel || null}
      onChange={(value) => {
        handleFormikValueChange(props, value);
      }}
      required
      input
    />
  );
}

FieldGatewayModbusChannelSelect.propTypes = {
  valueArrayName: PropTypes.string.isRequired,
  objIdx: PropTypes.number.isRequired,
  values: PropTypes.shape({
    allAvailableChannels: PropTypes.arrayOf(PropTypes.shape({})),
    modbusConfig: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default FieldGatewayModbusChannelSelect;
