import React, { useState, useEffect } from "react";
import { isNotEmpty } from "lcm-iot-commons";
import scriptjs from "scriptjs";
import { PropTypes } from "prop-types";
import { injectIntl } from "react-intl";

let realZxcvbn;
let promise;
const BLACKLISTED_PASSWORDS = [
  "Endress",
  "Hauser",
  "Reinach",
  "Freiburg",
  "Proline",
  "Promass",
  "Micropilot",
  "Liquiline",
  "FieldCare",
  "IIoT",
  "Flow",
  "Netilion",
  "Endress+Hauser",
  "Fermentation",
  "Floodlight",
];

export function loadZxcvbn() {
  /* istanbul ignore next */
  if (!realZxcvbn) {
    /* istanbul ignore next */
    if (typeof process !== "undefined" && process?.env?.NODE_ENV === "test") {
      // eslint-disable-next-line global-require
      realZxcvbn = require("zxcvbn");
      promise = Promise.resolve();
    } else {
      promise = new Promise((resolve, reject) => {
        /* istanbul ignore next */
        scriptjs(
          "/app/id/javascript/zxcvbn.js",
          () => {
            realZxcvbn = global.window.zxcvbn;
            resolve();
          },
          (a) => {
            /* eslint-disable no-console */
            console.error(`not found${a}`);
            /* eslint-enable no-console */
            reject();
          },
        );
      });
    }
  }
  return promise;
}

export function extractUserInputs(values, keysToIgnore) {
  return Object.keys(values)
    .filter((key) => keysToIgnore.indexOf(key) < 0)
    .map((key) => values[key]);
}

export function checkPassword(password, intl, userInputs = []) {
  const blackListedPasswords = BLACKLISTED_PASSWORDS.concat(userInputs);
  const result = realZxcvbn(
    password || /* istanbul ignore next */ "",
    blackListedPasswords,
  );

  const passwordWarning = isNotEmpty(result.feedback.warning)
    ? intl.formatMessage({ id: result.feedback.warning })
    : null;
  const passwordSuggestions = result.feedback.suggestions.map((message) =>
    intl.formatMessage({ id: message }),
  );

  const checkResult = {
    suggestions: passwordSuggestions,
    error: null,
    warning: null,
    success: null,
  };
  if (result.score > 3) {
    const good = intl.formatMessage({ id: "validation.password.good" });
    checkResult.success = passwordWarning
      ? /* istanbul ignore next */ `${good} ${passwordWarning}`
      : good;
  } else if (result.score > 2) {
    const weak = intl.formatMessage({ id: "validation.password.weak" });
    checkResult.warning = passwordWarning
      ? /* istanbul ignore next */ `${weak} ${passwordWarning}`
      : weak;
  } else {
    // result.score 2, 1 or 0
    const invalid = intl.formatMessage({ id: "validation.password.invalid" });
    checkResult.error = passwordWarning
      ? `${invalid} ${passwordWarning}`
      : invalid;
  }
  return checkResult;
}

/* istanbul ignore next */
const withZxcvbn = (ChildComponent) => {
  const WrappedComponent = injectIntl((props) => {
    const createZxcvbn = () => {
      if (realZxcvbn) {
        return {
          checkPassword: (password, userInputs) =>
            checkPassword(password, props.intl, userInputs),
          extractUserInputs,
        };
      }

      return undefined;
    };

    const [zxcvbn, setZxcvbn] = useState(createZxcvbn());

    useEffect(() => {
      const load = async () => {
        await loadZxcvbn();
        setZxcvbn(createZxcvbn());
      };

      if (!realZxcvbn) {
        load();
      }
    }, []);

    return zxcvbn ? <ChildComponent {...props} zxcvbn={zxcvbn} /> : null;
  });

  WrappedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return WrappedComponent;
};

export const zxcvbnShape = PropTypes.shape({
  checkPassword: PropTypes.func,
  extractUserInputs: PropTypes.func,
});

export default withZxcvbn;
