/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { RadioSelect } from "./Form";
import { List, Tree } from "./List";
import { isTouchDevice } from "../utils";
import { SearchBar } from "./Search";
import { Column, Container, Row } from "./Grid";
import Chips from "./Chips/Chips";
import Icon from "./Icon";

function AllOrMultipleSelect({
  values,
  allItems,
  topItems,
  itemKey,
  title,
  handleSpecificSelectionChange,
  handleSelectionSwitch,
  moreItemsLabel,
  topItemsLabel,
  withTreeView,
  includeNoItems = true,
}) {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedItems, setSelectedItems] = React.useState([]);

  React.useEffect(() => {
    setSelectedItems(values.selectedItems);
  }, []);

  React.useEffect(() => {
    handleSpecificSelectionChange(selectedItems);
  }, [selectedItems]);

  const newAllItems = allItems
    ? [...allItems.map((item) => ({ ...item, code: item.name }))]
    : undefined;
  if (includeNoItems && newAllItems) {
    const noItemLabel = intl.formatMessage({
      id: `export_files_dialog.no_${itemKey}`,
    });
    if (!newAllItems?.find((item) => item.id === "null")) {
      newAllItems?.unshift({
        id: "null",
        code: "null",
        name: noItemLabel,
        checked: selectedItems?.includes("null"),
        tenant: undefined,
      });
    }
  }

  const filteredItems = searchTerm
    ? newAllItems?.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : newAllItems;

  const onChipRemove = (selectedItemIdToRemove) => {
    const newSelectedItems = [...selectedItems];
    setSelectedItems(
      newSelectedItems.filter(
        (selectedItem) =>
          selectedItem.id.toString() !== selectedItemIdToRemove.toString(),
      ),
    );
  };

  const handleListItemClicked = (itemId) => {
    const foundItem = selectedItems.find(
      (item) => item.id.toString() === itemId,
    );
    if (foundItem) {
      const tempSelected = selectedItems.filter(
        (item) => item.id.toString() !== itemId,
      );
      setSelectedItems([...tempSelected]);
    } else {
      const itemToAdd = [...newAllItems, ...(topItems || [])].find(
        (item) => item.id.toString() === itemId,
      );
      setSelectedItems([...selectedItems, itemToAdd]);
    }
  };

  function renderListItem(id, name, checked, tenant) {
    const enabled =
      values.selectedSwitch !== "specific_selection"
        ? ""
        : "tenant-option-enabled";
    const isEndress =
      tenant?.name && tenant?.public ? (
        <span className={`tenant-option-icon ${enabled}`}>
          <Icon name="icon3-eh-radio-button-checked" />
        </span>
      ) : null;

    const tenantContent =
      tenant?.name && !tenant?.public ? ` (${tenant?.name})` : null;

    return (
      <div key={`checkbox-${id}`} className="all-or-multiple-select-list-item">
        <input
          id={`${itemKey}-${id}-checkbox`}
          type="checkbox"
          className={checked ? "checked" : "unchecked"}
          checked={checked}
          value={id}
          onChange={(e) => handleListItemClicked(e.target.value)}
          disabled={values.selectedSwitch !== "specific_selection"}
        />
        <label htmlFor={`${itemKey}-${id}-checkbox`}>
          <span id={id}>{name}</span>
          {tenantContent}
          {isEndress}
        </label>
      </div>
    );
  }

  function onSelectionSwitch(value) {
    if (value === "all") {
      setSelectedItems([]);
    }
    handleSelectionSwitch(value);
  }

  const isLoading = !newAllItems;
  const isChecked = (id) =>
    !!selectedItems?.find((item) => item.id.toString() === id.toString());

  return (
    <>
      <h3>{title}</h3>
      <RadioSelect
        id={`${itemKey}-items-switch`}
        options={[
          { value: "all", label: intl.formatMessage({ id: "label.all" }) },
          {
            value: "specific_selection",
            label: intl.formatMessage({ id: "label.specific_selection" }),
          },
        ]}
        handleChange={(e) => onSelectionSwitch(e.target.value)}
        values={{ "items-type": values.selectedSwitch }}
        name="items-type"
        handleBlur={() => true}
      />
      <SearchBar
        autoFocus={!isTouchDevice()}
        disabled={values.selectedSwitch !== "specific_selection"}
        filterWildcard={false}
        onSearch={(searchResult) => setSearchTerm(searchResult)}
        placeholder={intl.formatMessage({ id: "search.searchbox_placeholder" })}
        resettable
        hideSubmit
        timeout={300}
      />
      {!!selectedItems.length && (
        <Chips items={selectedItems} onRemove={onChipRemove} withTooltip />
      )}
      <Container className="all-or-multiple-select-container">
        <Row>
          <Column>
            {!isLoading &&
              (withTreeView && !searchTerm ? (
                <Tree
                  list={newAllItems}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  disabled={values.selectedSwitch !== "specific_selection"}
                  showIsEndress
                />
              ) : (
                <List>
                  {!searchTerm && (
                    <>
                      {topItems?.length > 0 && (
                        <>
                          <div
                            className="all-or-multiple-select-top-more-items"
                            disabled={
                              values.selectedSwitch !== "specific_selection"
                            }
                          >
                            {topItemsLabel}
                          </div>
                          {topItems.map(({ id, name, tenant }) =>
                            renderListItem(id, name, isChecked(id), tenant),
                          )}
                        </>
                      )}
                      {filteredItems?.length > 0 && (
                        <div
                          className="all-or-multiple-select-top-more-items"
                          disabled={
                            values.selectedSwitch !== "specific_selection"
                          }
                        >
                          {moreItemsLabel}
                        </div>
                      )}
                    </>
                  )}
                  {filteredItems?.map(({ id, name, tenant }) =>
                    renderListItem(id, name, isChecked(id), tenant),
                  )}
                </List>
              ))}
            {!isLoading && searchTerm && !filteredItems?.length && (
              <div className="no-search-results-text">
                <FormattedMessage id="search.no_results_found" />
              </div>
            )}
            {isLoading && (
              <Skeleton
                containerTestId="all-or-multiple-select-skeleton"
                height={296}
              />
            )}
          </Column>
        </Row>
      </Container>
    </>
  );
}

AllOrMultipleSelect.propTypes = {
  itemKey: PropTypes.oneOf(["categories", "languages"]).isRequired,
  title: PropTypes.string.isRequired,
  topItemsLabel: PropTypes.string,
  moreItemsLabel: PropTypes.string,
  includeNoItems: PropTypes.bool,
  values: PropTypes.shape({
    selectedSwitch: PropTypes.string,
    selectedItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.number.isRequired,
        ]).isRequired,
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allItems: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  topItems: PropTypes.arrayOf(PropTypes.any),
  handleSpecificSelectionChange: PropTypes.func.isRequired,
  handleSelectionSwitch: PropTypes.func.isRequired,
  withTreeView: PropTypes.bool,
};

export default AllOrMultipleSelect;
