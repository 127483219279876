import React from "react";

import { useIntl } from "react-intl";
import { Clue } from "../Clue";
import { convertBytesToGB } from "../../utils";

import { useSubscriptionQuery } from "../../hooks";

export default function SubscriptionLimitClue() {
  const intl = useIntl();
  const { data: subscription } = useSubscriptionQuery();

  const assetLimitReached =
    subscription?.asset_quota > 0 &&
    subscription.usage?.asset_count >= subscription.asset_quota;
  const fileStorageLimitReached =
    subscription?.storage_quota > 0 &&
    subscription.usage?.file_storage >= subscription.storage_quota;
  const dataStorageLimitReached =
    subscription?.data_storage_quota > 0 &&
    subscription.usage?.data_storage >= subscription.data_storage_quota;

  const plan = <strong>{subscription?.display_name}</strong>;
  const clueDescription = intl.formatMessage(
    { id: "subscription.limit.details" },
    { subscription: plan },
  );

  const assetLimitMessage = assetLimitReached ? (
    <li id="asset-limit-reached-item">
      {`${intl.formatMessage({ id: "subscription_details.asset_amount" })} (${subscription.asset_quota})`}
    </li>
  ) : null;

  const fileLimitMessage = fileStorageLimitReached ? (
    <li id="file-storage-limit-reached-item">
      {`${intl.formatMessage({ id: "subscription_details.file_storage" })} (${convertBytesToGB(subscription.storage_quota, 1)} GB)`}
    </li>
  ) : null;

  const dataLimitMessage = dataStorageLimitReached ? (
    <li id="data-storage-limit-reached-item">
      {`${intl.formatMessage({ id: "subscription_details.data_storage" })} (${convertBytesToGB(subscription.data_storage_quota, 1)} GB)`}
    </li>
  ) : null;

  const subscriptionLimitClue = (
    <div id="subscription-limit-clue-details">
      {clueDescription}
      <ul id="subscription-limit-clue-details-list">
        {assetLimitMessage}
        {fileLimitMessage}
        {dataLimitMessage}
      </ul>
    </div>
  );

  const showClue =
    assetLimitReached || fileStorageLimitReached || dataStorageLimitReached;
  return showClue ? (
    <Clue
      id="subscription-limit-clue"
      title={intl.formatMessage({ id: "subscription.limit.header" })}
      details={subscriptionLimitClue}
      action={intl.formatMessage({ id: "button.upgrade" })}
      target="/upgrade"
      highlighted
    />
  ) : null;
}
