import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Loader,
  Heading,
  Container,
  Row,
  Column,
  PermissionsEdit,
  PermissionType,
  ActionBar,
  apiShape,
  intlShape,
  browserShape,
  notifierShape,
  withApi,
  withIntl,
  withBrowser,
  withNotifier,
} from "lcm-iot-commons";

import ResellerCustomerForm from "./ResellerCustomerForm";

export function extractInitialValues(response) {
  const initialValues = { ...response };
  initialValues.account = response.name;
  initialValues.withAddress = response.address?.city !== undefined;
  initialValues.firstName = response.address?.first_name;
  initialValues.lastName = response.address?.last_name;
  initialValues.streetName = response.address?.street;
  initialValues.streetNumber = response.address?.street_number;
  initialValues.zipCode = response.address?.zip_code;
  initialValues.regionCode = response.address?.region_code;
  initialValues.city = response.address?.city;
  initialValues.phone = response.address?.phone;
  initialValues.country = { code: response.address?.country_code };
  return initialValues;
}

export function ResellerCustomerCreateEdit(props) {
  const { api, intl, notifier, browser, match } = props;
  const { id } = match.params;

  const creating = !id;
  const [initialValues, setInitialValues] = useState();

  const loadData = async () => {
    if (creating) {
      setInitialValues({});
    } else {
      try {
        const response = await api.get(
          `/customers/${id}`,
          { include: "address" },
          false,
        );
        setInitialValues(extractInitialValues(response));
      } catch (error) {
        notifier.showError(api.translateError(error));
        setInitialValues({});
      }
    }
  };
  React.useEffect(() => {
    loadData();
  }, []);

  const onSubmit = async (values, actions) => {
    let payload = {
      name: values.account?.trim(),
      number: values.number?.trim(),
    };
    if (values.withAddress) {
      payload = {
        ...payload,
        address: {
          first_name: values.firstName,
          last_name: values.lastName,
          street: values.streetName,
          street_number: values.streetNumber,
          zip_code: values.zipCode,
          city: values.city,
          region_code: values.regionCode,
          country_code: values.country?.code,
          phone: values.phone,
        },
      };
    } else {
      payload = {
        ...payload,
        address: null,
      };
    }

    try {
      if (creating) {
        await api.post("/customers", payload); // create
      } else {
        await api.patch(`/customers/${id}`, payload); // update
      }
      notifier.showSuccess(
        intl.formatMessage({ id: "reseller_customers.success_notification" }),
      );
      browser.navigateTo("/reseller/customers");
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading
              id={
                creating
                  ? "reseller_customers.create.header"
                  : "reseller_customers.edit.headerr"
              }
              title={
                creating
                  ? intl.formatMessage({
                      id: "reseller_customers.create.header",
                    })
                  : intl.formatMessage({ id: "reseller_customers.edit.header" })
              }
            />
          </ActionBar>
        </Column>
      </Row>
      {initialValues && (
        <Row>
          <Column>
            <ResellerCustomerForm
              initialValues={initialValues}
              onSubmit={onSubmit}
              isCustomer
            />
          </Column>
        </Row>
      )}
      {!creating && initialValues ? (
        <PermissionsEdit
          id="edit-permissions"
          allowedPermissions={[
            PermissionType.READ,
            PermissionType.UPDATE,
            PermissionType.DELETE,
            PermissionType.PERMIT,
          ]}
          permitableType="Customer"
          permitableId={parseInt(id, 10)}
          targetOnDeleteOwnReadPermission="/reseller/customers"
        />
      ) : null}
      <Loader loading={id && !initialValues} />
    </Container>
  );
}

ResellerCustomerCreateEdit.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default withBrowser(
  withApi(withIntl(withNotifier(ResellerCustomerCreateEdit))),
);
