import PropTypes from "prop-types";

export const opcUaServerShape = PropTypes.shape({
  id: PropTypes.string,
  subscription_id: PropTypes.string,
  ip: PropTypes.string,
  status: PropTypes.string,
  hostname: PropTypes.string,
});
export const opcUaServersShape = PropTypes.arrayOf(opcUaServerShape);
