import { isEmpty, isSomething } from "../utils/langUtils";

// if you change something here, also do it also in vat_ident.rb
const VatPrefixes = {
  countries: [
    { code: "AT", vatPrefix: "AT" },
    { code: "BE", vatPrefix: "BE" },
    { code: "BG", vatPrefix: "BG" },
    { code: "HR", vatPrefix: "HR" },
    { code: "CY", vatPrefix: "CY" },
    { code: "CZ", vatPrefix: "CZ" },
    { code: "DK", vatPrefix: "DK" },
    { code: "EE", vatPrefix: "EE" },
    { code: "FI", vatPrefix: "FI" },
    { code: "FR", vatPrefix: "FR" },
    { code: "DE", vatPrefix: "DE" },
    { code: "GR", vatPrefix: "EL" },
    { code: "HU", vatPrefix: "HU" },
    { code: "IE", vatPrefix: "IE" },
    { code: "IT", vatPrefix: "IT" },
    { code: "LV", vatPrefix: "LV" },
    { code: "LT", vatPrefix: "LT" },
    { code: "LU", vatPrefix: "LU" },
    { code: "MT", vatPrefix: "MT" },
    { code: "NL", vatPrefix: "NL" },
    { code: "PL", vatPrefix: "PL" },
    { code: "PT", vatPrefix: "PT" },
    { code: "RO", vatPrefix: "RO" },
    { code: "SK", vatPrefix: "SK" },
    { code: "SI", vatPrefix: "SI" },
    { code: "ES", vatPrefix: "ES" },
    { code: "SE", vatPrefix: "SE" },
    { code: "CH", vatPrefix: "CHE" },
    { code: "GB", vatPrefix: "GB" },
  ],
};

VatPrefixes.forCountryCode = (countryCode) => {
  let vatPrefix = "";
  if (isSomething(countryCode)) {
    const countryVatPrefix = VatPrefixes.countries.find(
      (c) => c.code === countryCode,
    );
    if (isSomething(countryVatPrefix) && !isEmpty(countryVatPrefix.vatPrefix)) {
      // eslint-disable-next-line prefer-destructuring
      vatPrefix = countryVatPrefix.vatPrefix;
    }
  }
  return vatPrefix;
};

/* TODO remove if not longer needed, forCountrCode is fault tolerant and does not crash if no mapping is available */
VatPrefixes.prefixForCountryCode = (countryCode) =>
  VatPrefixes.countries.find((p) => p.code === countryCode).vatPrefix;
export default VatPrefixes;
