import PropTypes from "prop-types";

export const userShape = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  demoDataActive: PropTypes.bool,
  userRoles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
});

export const usersShape = PropTypes.arrayOf(userShape);
