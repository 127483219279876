import PropTypes from "prop-types";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../Loader";

import { isTouchDevice } from "../../utils";

import SearchResultItem from "./SearchResultItem";
import SearchBar from "./SearchBar";
import SearchFilters from "./SearchFilters";
import SearchOptions from "./SearchOptions";
import { useSearch } from "../../hooks/useSearch";
import { ListSkeleton } from "../List";

export function Search({ scanSearchSource }) {
  const intl = useIntl();
  const search = useSearch();

  const onSearch = (value) => {
    search.setSearchTerm(value || undefined);
    search.setFilterValue(search.filters.join(","));
    search.setOptionValue(search.options.join(","));
  };
  const hasMore = !search.isFetchingNextPage && search.hasNextPage;
  return (
    <div>
      <SearchBar
        autoFocus={!isTouchDevice()}
        onSearch={onSearch}
        placeholder={intl.formatMessage({ id: "search.searchbox_placeholder" })}
        minChars={1}
        timeout={300}
        resettable
        filterWildcard={false}
        scanSearchSource={scanSearchSource}
        searchCount={search.totalCount}
        defaultValue={search.searchTerm}
      />
      <div className="filter-container">
        <SearchFilters
          filters={search.filters}
          onChange={(filters) => search.setFilterValue(filters.join(","))}
        />
        <SearchOptions
          options={search.options}
          onChange={(options) => search.setOptionValue(options.join(","))}
        />
      </div>
      <div>
        {search.filters?.length > 0 && (
          <ul id="search-result-list" className="list">
            {search.results && (
              <InfiniteScroll
                initialLoad={false}
                // This is needed, because if the user types in a search term, then removes it and resizes the window, InfiniteScroll calls loadMore.
                loadMore={
                  hasMore
                    ? search.fetchNextPage
                    : /* istanbul ignore next */ () => {}
                }
                hasMore={hasMore}
              >
                {search.results.map((item, index) => (
                  <SearchResultItem
                    search={search}
                    key={`${item.type}${item.id}`}
                    item={item}
                    index={index}
                    showSpecifications={search.options.includes(
                      "specifications",
                    )}
                    showAssignedNodes={search.options.includes("nodes")}
                    disabled={
                      item.type === "Asset" &&
                      !(
                        item.in_subscription === undefined ||
                        item.in_subscription
                      )
                    }
                  />
                ))}
              </InfiniteScroll>
            )}
            {search.results?.length === 0 && (
              <div className="no-search-results-text">
                <FormattedMessage id="search.no_results_found" />
              </div>
            )}
          </ul>
        )}
      </div>
      {search.isInitialSearching && (
        <ListSkeleton withChips={!!search.options.length} />
      )}
      <Loader loading={search.isSearching && !search.isInitialSearching} />
    </div>
  );
}

Search.propTypes = {
  scanSearchSource: PropTypes.string,
};

export default Search;
