import { lcmApiClient } from "lcm-iot-commons";
import { extractClientApplication } from "../extractors/clientApplicationExtractor";

/* istanbul ignore next */
export async function loadClientApplication(id, parameters) {
  return extractClientApplication(
    await lcmApiClient(false).get(`/client_applications/${id}`, parameters),
  );
}

/* istanbul ignore next */
export async function patchClientApplication(id, clientApplication) {
  const { name, description } = clientApplication;
  const redirectURIs = clientApplication.redirectURIs.trim().split("\n");
  return lcmApiClient().patch(`/client_applications/${id}`, {
    name,
    description,
    redirect_uris: redirectURIs,
  });
}

/* istanbul ignore next */
export async function loadClientApplicationAccessRights(id) {
  const response = await lcmApiClient().get("/users/current/access_rights", {
    permitable_type: "ClientApplication",
    permitable_id: id,
  });
  return {
    canRead: response.can_read,
    canUpdate: response.can_update,
    canDelete: response.can_delete,
    canPermit: response.can_permit,
  };
}
