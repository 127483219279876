/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Column,
  Container,
  Loader,
  ActionBar,
  Heading,
  Details,
  DetailsItem,
  PictureGallery,
  DetailsHeaderItem,
  DetailsPicture,
  ActionBarButtons,
  EditActionButton,
  ExportActionButton,
  DeleteActionButton,
  ContextualHelp,
  withApi,
  withUser,
  withBrowser,
  withAccessRights,
  apiShape,
  accessRightsShape,
  htmlFormat,
  htmlLink,
  intlShape,
  browserShape,
  withNotifier,
  notifierShape,
  NotFoundError,
  pullValueFromSpec,
  pullUpdatedAtFromSpec,
  withIntl,
} from "lcm-iot-commons";

import { FormattedMessage } from "react-intl";

import {
  gatewayIconClassName,
  fieldGatewayStatusIconClassName,
} from "../../utils/statusUtils";
import { FieldGatewayModbusConfigItem } from "./FieldGatewayModbusConfigItem";
import { FieldGatewayGenericModbusDetails } from "./FieldGatewayGenericModbusDetails";
import { getFieldGatewayImageUrl } from "./PicturePoolUrl";

import {
  FieldGatewayTypes,
  EHEdmSpecificationKeys,
  FieldGatewayConstants,
} from "./FieldGatewayConstants";

const getChannelNameByString = (channel) =>
  channel === "0" ? FieldGatewayConstants.ch0LiquilinePlattform : `${channel}`;

const getStringAsShownInValueApp = (orgName) =>
  orgName
    .replaceAll(".", " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");

export const sortByModbusRegister = (modbusConfig) =>
  modbusConfig.slice().sort((a, b) => a.regName.localeCompare(b.regName));

export const getModbusConfig = (response) => {
  if (!response.specifications) {
    return [];
  }
  const result = [];
  const specificationNames = Object.keys(response.specifications).filter(
    (specKey) =>
      specKey.includes(FieldGatewayConstants.configAiKeyPrefix) ||
      specKey.includes(FieldGatewayConstants.configDiKeyPrefix),
  );
  specificationNames.forEach((specName) => {
    const specValue = pullValueFromSpec(response.specifications, [
      specName,
    ]).split(";");
    const specSubKeys = specName.split(".");
    if (specValue.length === 2 && specSubKeys.length === 2) {
      result.push({
        valueName: getStringAsShownInValueApp(specValue[1]),
        channel: getChannelNameByString(specValue[0]),
        regName: specSubKeys[1],
      });
    }
  });

  return sortByModbusRegister(result);
};

export const getInitialModbusConfigExportKeys = (response) => {
  if (!response.specifications) {
    return [];
  }
  return Object.keys(response.specifications).filter(
    (specKey) =>
      specKey.includes(FieldGatewayConstants.configAiKeyPrefix) ||
      specKey.includes(FieldGatewayConstants.configDiKeyPrefix) ||
      specKey.includes(FieldGatewayConstants.configModbusKeyPrefix),
  );
};

const isEmpty = (obj) => Object.keys(obj).length === 0;

export const getInitialModbusConfigStatusExportKeys = (response) => {
  if (!response.specifications) {
    return [];
  }
  return Object.keys(response.specifications).filter((specKey) =>
    specKey.includes(FieldGatewayConstants.configStatusKeyPrefix),
  );
};

export const getModbusConfigExport = (response) => {
  if (!response.specifications) {
    return [];
  }
  const specificationNames = getInitialModbusConfigExportKeys(response);
  return specificationNames.map((specName) => ({
    key: specName,
    value: `${pullValueFromSpec(response.specifications, [specName])}`,
    updated_at: `${pullUpdatedAtFromSpec(response.specifications, [specName])}`,
  }));
};

export const getModbusConfigStatusExport = (response) => {
  if (!response.specifications) {
    return [];
  }
  const specificationNames = getInitialModbusConfigStatusExportKeys(response);
  return specificationNames.map((specName) => ({
    key: specName,
    value: `${pullValueFromSpec(response.specifications, [specName])}`,
    updated_at: `${pullUpdatedAtFromSpec(response.specifications, [specName])}`,
  }));
};

export const getInitialGenericModbusKeys = (response) =>
  Object.keys(response.specifications).filter((specKey) =>
    specKey.includes(FieldGatewayConstants.configModbusKeyPrefix),
  );

export const getGenericModbusConfig = (response) => {
  if (!response.specifications) {
    return [];
  }
  const specificationNames = getInitialGenericModbusKeys(response);
  return specificationNames.map((specName) => ({
    key: specName,
    value: `${pullValueFromSpec(response.specifications, [specName])}`,
    updated_at: `${pullUpdatedAtFromSpec(response.specifications, [specName])}`,
  }));
};

export const createFileData = (data) => {
  const jsonObj = {
    config_version: 1,
    config_type: "specifications",
    configuration: {},
    configuration_status: {},
  };

  if (data.byte_order != null) {
    jsonObj.byte_order = data.byte_order;
  }

  if (data.unit_identifier != null) {
    jsonObj.unit_identifier = data.unit_identifier;
  }

  data.modbusConfigExport
    ?.filter((element) => element?.key && element?.value && element?.updated_at)
    .forEach((element) => {
      jsonObj.configuration[element.key] = {
        value: element.value,
        updated_at: element.updated_at,
      };
    });

  data.modbusConfigStatusExport
    ?.filter((element) => element?.key && element?.value && element?.updated_at)
    .forEach((element) => {
      jsonObj.configuration_status[element.key] = {
        value: element.value,
        updated_at: element.updated_at,
      };
    });

  return JSON.stringify(jsonObj, null, 2);
};

export function extractInitialValues(response, accessRights) {
  const initialValues = { ...response };
  initialValues.status = response.status.name;
  initialValues.type = response.type?.name;
  initialValues.typeCode = response.type?.code;
  initialValues.isSWG70OrSWG50 =
    response.type.code === FieldGatewayTypes.SWG70 ||
    response.type.code === FieldGatewayTypes.SWG50;
  initialValues.isNXA820 = response.type.code === FieldGatewayTypes.NXA820;
  initialValues.port = response.port?.toString();
  initialValues.hart_address = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_HART_ADDRESS,
  ]);
  initialValues.serial_number = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_FGW_SERIAL_NUMBER,
  ]);
  initialValues.tag = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_FGW_TAG,
  ]);
  initialValues.byte_order = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_FGW_BYTE_ORDER,
  ]);
  initialValues.unit_identifier = pullValueFromSpec(response.specifications, [
    EHEdmSpecificationKeys.EH_EDM_FGW_UNIT_IDENTIFIER,
  ]);
  initialValues.icon = fieldGatewayStatusIconClassName(response.status.code);
  initialValues.placeholder = gatewayIconClassName();
  initialValues.editable = accessRights.canUpdate;
  initialValues.isMBTCPLiquiline =
    response.type.code === FieldGatewayTypes.CM44MODBUSTCP;
  initialValues.isMBTCPGeneric =
    response.type.code === FieldGatewayTypes.GENERIC_MODBUS_TCP;
  initialValues.modbusConfig = getModbusConfig(response);
  initialValues.modbusConfigExport = getModbusConfigExport(response);
  initialValues.modbusConfigStatusExport =
    getModbusConfigStatusExport(response);
  initialValues.genericModbusConfig = getGenericModbusConfig(response);
  if (initialValues.typeCode === FieldGatewayTypes.GENERIC_MODBUS_TCP) {
    initialValues.byte_order = initialValues.byte_order ?? "0";
    initialValues.unit_identifier = initialValues.unit_identifier ?? 0;
  }

  return initialValues;
}

export function PrintConfig({ params, intl }) {
  return params.map((parameters, index) => (
    <div
      key={`PrintConfig-${index.toString()}`}
      style={{ marginBottom: "20px" }}
    >
      <FieldGatewayModbusConfigItem
        itemHeader={parameters.valueName}
        itemVariable={parameters.regName}
        itemChannel={parameters.channel}
        intl={intl}
      />
    </div>
  ));
}

export function FieldGatewayDetails(props) {
  const { api, browser, accessRights, match, intl, notifier } = props;
  const { t } = intl;
  const { id, fieldGatewayId } = match.params;
  const [details, setDetails] = useState({});

  const loadData = async () => {
    try {
      const response = await api.get(
        `/edm/edge_devices/${id}/field_gateways/${fieldGatewayId}`,
        { include: "status, type, specifications" },
        false,
      );
      setDetails(extractInitialValues(response, accessRights));
    } catch (error) {
      if (error instanceof NotFoundError) {
        browser.navigateTo("/404");
      } else {
        notifier.showError(api.translateError(error));
        setDetails({});
      }
    }
  };
  React.useEffect(() => {
    loadData();
  }, [id]);

  const onConfirmDelete = async () => {
    try {
      await api.delete(
        `/edm/edge_devices/${id}/field_gateways/${fieldGatewayId}`,
      );
      notifier.showSuccess(t`delete_field_gateway.success_notification`);
      browser.navigateTo(`/edge_devices/${id}`);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const exportFilename = (data) =>
    `${data.name !== undefined ? `${data.name}` : ""}@${data.ip_address}.mbconf`;

  const onModbusDataExport = (dataValues) => {
    const fileData = createFileData(dataValues);
    const data = new Blob([fileData], { type: "application/json" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.setAttribute("download", exportFilename(dataValues));
    document.body.appendChild(link);
    // start download of the file
    link.click();
    // clean up and remove link element
    link.parentNode.removeChild(link);
  };

  const {
    icon,
    name,
    type,
    typeCode,
    status,
    description,
    editable,
    port,
    placeholder,
    ip_address,
    hart_address,
    serial_number,
    tag,
    byte_order,
    unit_identifier,
    isSWG70OrSWG50,
    isMBTCPLiquiline,
    isMBTCPGeneric,
    isNXA820,
    modbusConfig,
    modbusConfigExport,
    modbusConfigStatusExport,
    genericModbusConfig,
  } = details;

  const fieldGatewayImageUrl = getFieldGatewayImageUrl(typeCode, 500);

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading title={t`field_gateway.details.header`} />
            <ActionBarButtons>
              <EditActionButton
                id="edit-gateway-button"
                disabled={!editable}
                target={`/edge_devices/${match.params?.id}/field_gateways/${match.params?.fieldGatewayId}/edit`}
              />
              <DeleteActionButton
                id="delete-gateway-button"
                disabled={!editable}
                modalTitle={t`field_gateway.delete.modal_title`}
                modalMessage={t`field_gateway.delete.modal_message`}
                onConfirm={onConfirmDelete}
              />
            </ActionBarButtons>
          </ActionBar>
          {isEmpty(details) && <Loader id="loader" loading />}
          {!isEmpty(details) && (
            <Details>
              <DetailsPicture>
                {typeCode &&
                  (fieldGatewayImageUrl ? (
                    <PictureGallery
                      additionalPictureUrls={[fieldGatewayImageUrl]}
                    />
                  ) : (
                    <PictureGallery
                      placeholder={placeholder}
                      id="placeholder"
                    />
                  ))}
              </DetailsPicture>
              <DetailsHeaderItem
                id="name"
                translationKey="label.name"
                value={name}
              />
              <DetailsHeaderItem
                id="type"
                translationKey="label.type"
                value={type}
              />
              <DetailsHeaderItem
                id="ip-address"
                translationKey="label.ip_address"
                value={ip_address}
              />
              <DetailsItem
                id="status"
                icon={icon}
                translationKey="label.status"
                value={status}
              />
              <DetailsItem
                id="description"
                translationKey="label.description"
                value={description}
              />
              {isSWG70OrSWG50 && (
                <Details id="swg70-or-swg50-section">
                  <DetailsItem
                    id="hart-address"
                    translationKey="label.hart_address"
                    value={hart_address}
                  />
                  <DetailsItem
                    id="port"
                    translationKey="label.port"
                    value={port}
                  />
                </Details>
              )}
              {isNXA820 && (
                <Details id="nxa820-section">
                  <DetailsItem
                    id="port"
                    translationKey="label.port"
                    value={port}
                  />
                </Details>
              )}
              {(isMBTCPLiquiline || isMBTCPGeneric) && (
                <div id="modbus-tcp-details">
                  {isMBTCPGeneric && (
                    <div>
                      <DetailsItem
                        id="serial-number"
                        translationKey="label.serial_number"
                        value={serial_number}
                      />
                      <DetailsItem
                        id="tag"
                        translationKey="label.tag"
                        value={tag}
                      />
                    </div>
                  )}
                  <ActionBar>
                    {isMBTCPLiquiline && (
                      <Heading
                        id="modbus-process-value-mapping"
                        level={3}
                        title={t`field_gateway.modbus.config.label`}
                      />
                    )}
                    {isMBTCPGeneric && (
                      <Heading
                        id="modbus-config"
                        level={3}
                        title={t`field_gateway.modbus.config.label`}
                      >
                        <ContextualHelp
                          title={t`field_gateway.modbus.config.title`}
                          interactive
                        >
                          <p>
                            <FormattedMessage
                              id="field_gateway.modbus.config.description"
                              values={{
                                ...htmlFormat,
                                a: htmlLink({
                                  href: "https://help.netilion.endress.com/hc/en-us/articles/10913979447324",
                                  target: "_blank",
                                }),
                              }}
                            />
                          </p>
                        </ContextualHelp>
                      </Heading>
                    )}
                    <ActionBarButtons>
                      <EditActionButton
                        id="edit-gateway-button"
                        disabled={!editable}
                        target={`/edge_devices/${match.params?.id}/field_gateways/${match.params?.fieldGatewayId}/edit`}
                      />
                      <ExportActionButton
                        id="export-gateway-button"
                        modalTitle={t`field_gateway.export.modal_title`}
                        modalMessage={t`field_gateway.export.modal_message`}
                        onConfirm={() =>
                          onModbusDataExport({
                            name,
                            ip_address,
                            byte_order,
                            unit_identifier,
                            modbusConfigExport,
                            modbusConfigStatusExport,
                          })
                        }
                      />
                    </ActionBarButtons>
                  </ActionBar>
                  {isMBTCPLiquiline && (
                    <>
                      <h4>
                        <b>{t`field_gateway.modbus.config.item.ai_di_channel`}</b>
                      </h4>
                      <PrintConfig params={modbusConfig} intl={intl} />
                    </>
                  )}
                  <FieldGatewayGenericModbusDetails
                    id="section-modbus-details"
                    specifications={genericModbusConfig}
                    serialNumber={serial_number ?? "-"}
                    subAddress={unit_identifier ?? 0}
                    intl={intl}
                  />
                </div>
              )}
            </Details>
          )}
        </Column>
      </Row>
    </Container>
  );
}

FieldGatewayDetails.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      fieldGatewayId: PropTypes.string,
    }),
  }).isRequired,
};

export default withBrowser(
  withNotifier(
    withApi(
      withUser(
        withIntl(withAccessRights(FieldGatewayDetails, "EDM::EdgeDevice")),
      ),
    ),
  ),
);
