import { useBackend } from "./useBackend";

/**
 * Custom hook for querying the subscription of the current user.
 *
 * @param {import('@tanstack/react-query').UseQueryOptions} options - Additional options for the query.
 * @returns The result of the query.
 */
export const useSubscriptionQuery = (options) => {
  const backend = useBackend();
  return backend.get.useQuery("/subscription", null, {
    staleTime: Infinity,
    cacheTime: Infinity,
    // If the user does not have a subscription yet, some apps return 404
    // We don't want to show an error in this case
    meta: { errorNamesToIgnore: ["NotFoundError"] },
    // Workaround for the ID app which returns garbage (html data) for the /subscription call
    // If that happens, we return undefined instead
    select: (subscription) => (subscription?.id ? subscription : undefined),
    ...options,
  });
};
