import { lcmApiClient } from "lcm-iot-commons";
import { extractUserGroups } from "lcm-iot-commons/client/lib/extractors/userGroupsExtractor";

/* istanbul ignore next */
export async function loadUserGroups(parameters) {
  const response = await lcmApiClient(false).get("/usergroups", parameters);
  return {
    usergroups: extractUserGroups(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function loadNextUserGroups(nextPageUrl) {
  const response = await lcmApiClient().get(nextPageUrl);
  return {
    usergroups: extractUserGroups(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}
