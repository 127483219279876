import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { MenuItem } from "react-bootstrap";
import ConfirmationModal from "lcm-iot-commons/client/lib/components/ConfirmationModal";
import { intlShape, ItemMenu, ListItem } from "lcm-iot-commons";

import { accessRightsShape } from "../../shapes/accessRightsShape";

export function OpcUaServerWhitelistItem({
  intl,
  onDelete,
  item,
  accessRights,
  disabled,
}) {
  const [deleting, setDeleting] = useState(false);

  const handleOnConfirm = () => {
    setDeleting(false);
    onDelete(item.id);
  };

  return (
    <>
      <ListItem
        id={`whitelist-item-${item.id}`}
        icon="icon2-eh-record"
        title={item.name}
        actions={
          <ItemMenu>
            <MenuItem
              id={`whitelist-item-delete-${item.id}`}
              onSelect={() => setDeleting(true)}
              disabled={!accessRights.canUpdate || disabled}
            >
              <FormattedMessage id="button.delete" />
            </MenuItem>
          </ItemMenu>
        }
      />
      {deleting && (
        <ConfirmationModal
          id={`whitelist-item-delete-modal-${item.id}`}
          intl={intl}
          show
          titleText={intl.formatMessage({
            id: "whitelist_item_delete.actions.modal_title",
          })}
          messageText={intl.formatMessage(
            { id: "whitelist_item_delete.actions.modal_message" },
            { item: item.name },
          )}
          onConfirm={handleOnConfirm}
          onClose={() => setDeleting(false)}
        />
      )}
    </>
  );
}

OpcUaServerWhitelistItem.propTypes = {
  intl: intlShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

OpcUaServerWhitelistItem.defaultProps = {
  disabled: false,
};

export default injectIntl(OpcUaServerWhitelistItem);
