import React, { useState } from "react";
import {
  apiShape,
  intlShape,
  userShape,
  notifierShape,
  withApi,
  withIntl,
  withUser,
  withNotifier,
  Heading,
} from "lcm-iot-commons";

export function NotificationEmailsHandler({ api, intl, user, notifier }) {
  const [state, setState] = useState({ value: false, health: false });

  const baseKey = "eh.pcps.user.notification_enabled.";
  const keySuffixes = {
    NOTIFICATION_VALUE: "value",
    NOTIFICATION_HEALTH: "health",
  };

  const loadNotificationSpecifications = async () => {
    try {
      const notificationSpecifications = await api.get(
        `/users/${user.id}/specifications`,
        { key: `${baseKey}*` },
      );
      const currentState = {};

      if (
        !notificationSpecifications[
          `${baseKey}${keySuffixes.NOTIFICATION_VALUE}`
        ]
      ) {
        const params = {};
        params[`${baseKey}${keySuffixes.NOTIFICATION_VALUE}`] = {
          value: "true",
        };
        await api.patch(`/users/${user.id}/specifications`, params);
        currentState.value = true;
      } else {
        currentState.value =
          notificationSpecifications[
            `${baseKey}${keySuffixes.NOTIFICATION_VALUE}`
          ]?.value.toLowerCase() === "true";
      }

      if (
        !notificationSpecifications[
          `${baseKey}${keySuffixes.NOTIFICATION_HEALTH}`
        ]
      ) {
        const params = {};
        params[`${baseKey}${keySuffixes.NOTIFICATION_HEALTH}`] = {
          value: "true",
        };
        await api.patch(`/users/${user.id}/specifications`, params);
        currentState.health = true;
      } else {
        currentState.health =
          notificationSpecifications[
            `${baseKey}${keySuffixes.NOTIFICATION_HEALTH}`
          ]?.value.toLowerCase() === "true";
      }

      setState(currentState);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  React.useEffect(() => {
    loadNotificationSpecifications();
  }, []);

  const handleOnChange = async (type) => {
    try {
      const key = `${baseKey}${type}`;
      const params = {};
      params[key] = { value: (!state[type]).toString() };
      await api.patch(`/users/${user.id}/specifications`, params);
      notifier.showSuccess(
        intl.formatMessage({
          id: "user_profile.notifications_success_notification",
        }),
      );
      setState({ ...state, [type]: !state[type] });
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  return (
    <div id="notification-emails-group" className="notification-emails">
      <Heading
        id="user-profile-emails"
        level={3}
        title={intl.formatMessage({
          id: "user_profile.notification_emails_group",
        })}
      />
      <div id="notification-emails-activation-value">
        <input
          id="notification-value-emails"
          name="notification-value-emails"
          data-testid="notification-value-emails"
          type="checkbox"
          checked={state.value}
          onChange={() => handleOnChange("value")}
        />
        <label htmlFor="notification-value-emails">
          {intl.formatMessage({ id: "user_profile.notification_emails.value" })}
        </label>
      </div>
      <div id="notification-emails-activation-health">
        <input
          id="notification-health-emails"
          name="notification-health-emails"
          data-testid="notification-health-emails"
          type="checkbox"
          checked={state.health}
          onChange={() => handleOnChange("health")}
        />
        <label htmlFor="notification-health-emails">
          {intl.formatMessage({
            id: "user_profile.notification_emails.health",
          })}
        </label>
      </div>
    </div>
  );
}

NotificationEmailsHandler.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  user: userShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default withApi(
  withUser(withIntl(withNotifier(NotificationEmailsHandler))),
);
