import PropTypes from "prop-types";

export const documentCategoryShape = PropTypes.shape({
  id: PropTypes.number,
  code: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  parent: PropTypes.shape({
    code: PropTypes.string,
  }),
  tenant: PropTypes.shape({
    id: PropTypes.number,
    public: PropTypes.bool,
    name: PropTypes.string,
  }),
});

export const documentCategoriesShape = PropTypes.arrayOf(documentCategoryShape);
