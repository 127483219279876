import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import PopOver from "../PopOver";
import Colors from "../../constants/Colors";

export function ContextualHelp({ children, title, interactive }) {
  const content = (
    <div className="contextual-help">
      <h2>{title}</h2>
      {children}
    </div>
  );
  return (
    <PopOver content={content} interactive={interactive}>
      <div className="contextual-help-icon">
        <Icon name="lcm-iot-icon-help" color={Colors.EH_CYAN_3} />
      </div>
    </PopOver>
  );
}

ContextualHelp.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  interactive: PropTypes.bool,
};

export default ContextualHelp;
