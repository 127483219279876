import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import {
  SubmitButton,
  intlShape,
  apiShape,
  notifierShape,
  browserShape,
  withBrowser,
  withApi,
  withIntl,
  withNotifier,
  BadRequestError,
} from "lcm-iot-commons";

export function AttachmentOverrideModal({
  intl,
  values,
  onDecline,
  onClose,
  api,
  params,
  notifier,
  browser,
}) {
  const onAccept = async () => {
    const attachment = values.attachments.find(
      (item) =>
        item.languages.includes(values.language.code) &&
        item.content_version === values.version,
    );
    if (attachment) {
      try {
        const file = values.fileUpload.acceptedFiles[0];
        let uploadParams = {};
        if (values.contentDate) {
          uploadParams = { content_date: values.contentDate };
        }
        if (values.version) {
          uploadParams = { ...uploadParams, content_version: values.version };
        }
        uploadParams = {
          ...uploadParams,
          document_id: params.documentId,
          languages: [values.language.code],
          file,
        };
        await api.upload(`/attachments/${attachment.id}`, uploadParams);
        notifier.showSuccess(
          intl.formatMessage({ id: "attachment_override.override_successful" }),
        );
        browser.navigateTo(`/${params.model}/${params.id}`);
      } catch (error) {
        if (error instanceof BadRequestError) {
          onClose(error);
        } else {
          notifier.showError(api.translateError(error));
          onClose(null);
        }
      }
    } else {
      notifier.showError(
        intl.formatMessage({
          id: "attachment_override.not_found_corresponding_attachment",
        }),
      );
      browser.navigateTo(`/${params.model}/${params.id}`);
    }
  };

  return (
    <Modal
      id="attachment-override-modal"
      show
      onHide={onClose}
      restoreFocus={false}
      backdrop={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "attachment_override.modal_title" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {intl.formatMessage(
          { id: "attachment_override.modal_message" },
          { language: values.language?.name, version: values.version || "-" },
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="btn-group">
            <SubmitButton
              id="attachment-override-confirm"
              onClick={onAccept}
              text={intl.formatMessage({ id: "button.confirm" })}
            />
            <button
              id="attachment-override-cancel"
              type="button"
              className="btn btn-default"
              onClick={onDecline}
            >
              {intl.formatMessage({ id: "attachment_override.cancel" })}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

AttachmentOverrideModal.propTypes = {
  onDecline: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
    documentId: PropTypes.string,
    model: PropTypes.string,
  }).isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  api: apiShape.isRequired,
  values: PropTypes.shape({
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
    version: PropTypes.string,
    language: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
    contentDate: PropTypes.string,
    fileUpload: PropTypes.shape({
      acceptedFiles: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default withBrowser(
  withApi(withIntl(withNotifier(AttachmentOverrideModal))),
);
