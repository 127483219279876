export function extractClientApplication(rawClientApplication) {
  const clientApplication = {
    id: rawClientApplication.id,
    name: rawClientApplication.name,
    description: rawClientApplication.description,
    redirectURIs:
      (rawClientApplication.redirect_uris &&
        rawClientApplication.redirect_uris.join("\n")) ||
      "",
  };

  return clientApplication;
}
