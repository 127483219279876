import {
  lcmApiClient,
  // backendApiClient,
} from "lcm-iot-commons";

/* istanbul ignore next */
export async function resetTechnicalUserPassword(technicalUserId) {
  return lcmApiClient().post(
    `/technical_users/${technicalUserId}/password_reset`,
  );
}

/* istanbul ignore next */
export async function deleteTechnicalUser(technicalUserId) {
  return lcmApiClient().delete(`/technical_users/${technicalUserId}`);
}
