import PropTypes from "prop-types";

export const orderShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  customer_purchase_order_number: PropTypes.string,
  date: PropTypes.string,
});

export const ordersShape = PropTypes.arrayOf(orderShape);
