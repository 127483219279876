export function extractDataExport(rawDataExport) {
  const dataExport = {
    id: rawDataExport.id,
    status: rawDataExport.status,
    instrumentation: rawDataExport.instrumentation ?? null,
    batch: rawDataExport.batch ?? null,
    asset: rawDataExport.asset ?? null,
    node: rawDataExport.node ?? null,
    purchaseOrder: rawDataExport.purchase_order ?? null,
    type: rawDataExport.type,
    createdAt: rawDataExport.created_at,
    finishedAt: rawDataExport.finished_at,
    exportFileName: rawDataExport.export_file_name,
    exportContentType: rawDataExport.export_content_type,
    exportFileSize: rawDataExport.export_file_size,
    exportUpdatedAt: rawDataExport.export_updated_at,
    user: rawDataExport.user,
    downloadHref: rawDataExport.download_href,
    availableUntil: rawDataExport.available_until,
  };
  return dataExport;
}

export function extractDataExports(rawDataExports) {
  return rawDataExports.data_exports.map((dataExport) =>
    extractDataExport(dataExport),
  );
}
