/* eslint-disable no-use-before-define */
import { parseISO8601Date } from "lcm-iot-commons";

export function extractServiceSubscription(rawSubscription) {
  let interval = null;
  if (
    rawSubscription.specifications &&
    rawSubscription.specifications["smartsys-data-refresh-rate"]
  ) {
    interval =
      rawSubscription.specifications["smartsys-data-refresh-rate"].value;
  }
  return {
    id: rawSubscription.id,
    applicationName: rawSubscription.client_application.name,
    subscriptionName: rawSubscription.display_name,
    assetQuota: rawSubscription.asset_quota,
    storageQuota: rawSubscription.storage_quota,
    dataStorageQuota: rawSubscription.data_storage_quota,
    externalReference: rawSubscription.external_reference,
    externalPlanVariantReference:
      rawSubscription.external_plan_variant_reference,
    interval,
    appIconName: rawSubscription.external_plan_variant_reference
      ? appNameForIcon(rawSubscription.external_plan_variant_reference)
      : null,
    billedUntil: parseISO8601Date(rawSubscription.billed_until),
    status: rawSubscription.status,
    trialEndDate: rawSubscription.trial_end_date
      ? rawSubscription.trial_end_date
      : null,
    cancelledAt: rawSubscription.cancelled_at
      ? rawSubscription.cancelled_at
      : null,
    resellerLabel: rawSubscription.reseller_label
      ? rawSubscription.reseller_label
      : null,
    usable: rawSubscription.usable,
  };
}

export function appNameForIcon(externalPlanVariantReference) {
  let appName = externalPlanVariantReference.split("-")[0];
  if (appName === "iba") {
    appName = "analytics";
  }
  return appName;
}

export function extractServiceSubscriptions(rawSubscriptions) {
  return rawSubscriptions.subscriptions.map((subscription) =>
    extractServiceSubscription(subscription),
  );
}
