import React from "react";
import PropTypes from "prop-types";

import {
  Row,
  List,
  Column,
  withApi,
  withIntl,
  withNotifier,
  apiShape,
  intlShape,
  notifierShape,
} from "lcm-iot-commons";

import ListItemWithCheckBox from "./ListItemWithCheckBox";

export function EdgeDeviceApplicationsEdit(props) {
  const { api, intl, notifier, edgeDevice } = props;
  const { id } = edgeDevice;
  const [applications, setApplications] = React.useState();

  const loadData = async () => {
    try {
      const publicApplicationTypes = (
        await api.get("/edm/edge_device/application/types", { code: "public" })
      ).edge_device_application_types;
      const allApps = (
        await api.getAll(
          `/edm/edge_device/application/types/${publicApplicationTypes[0]?.id}/applications`,
          { edge_device_type_id: edgeDevice.type.id },
        )
      )?.edge_device_applications;
      const enabledApps = (
        await api.getAll(`/edm/edge_devices/${id}/applications`, {
          order_by: "name",
        })
      )?.edge_device_applications;
      const mergedApps = allApps.map((app) => ({
        ...app,
        checked: !!enabledApps.find((r) => r.id === app.id),
      }));
      setApplications(mergedApps);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  React.useEffect(() => {
    loadData();
  }, [id]);

  const updateCheckInState = (listItemId, isChecked) => {
    const newState = applications.map((app) => {
      if (app.id === listItemId) {
        return { ...app, checked: isChecked };
      }
      return app;
    });
    setApplications(newState);
  };

  const onAddCheck = async (listItemId) => {
    try {
      api.post(`/edm/edge_devices/${edgeDevice.id}/applications`, {
        edge_device_applications: [{ id: listItemId }],
      });
      notifier.showSuccess(
        intl.formatMessage({
          id: "edge_device_applications.application_successully_assigned_notification",
        }),
      );
      updateCheckInState(listItemId, true);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const onRemoveCheck = async (listItemId) => {
    try {
      api.delete(`/edm/edge_devices/${edgeDevice.id}/applications`, {
        edge_device_applications: [{ id: listItemId }],
      });
      notifier.showSuccess(
        intl.formatMessage({
          id: "edge_device_applications.application_successully_unassigned_notification",
        }),
      );
      updateCheckInState(listItemId, false);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  return (
    <Row>
      {applications && (
        <Column>
          <h2 id="edge-device-applications-header">
            {intl.formatMessage({ id: "edge_device_applications.header" })}
          </h2>
          <List id="edge-device-applications-list">
            {applications.map((app) => (
              <ListItemWithCheckBox
                key={app.id}
                checked={app.checked}
                listItem={app}
                icon="icon lcm-iot-icon-application"
                onRemoveCheck={onRemoveCheck}
                onAddCheck={onAddCheck}
              />
            ))}
          </List>
        </Column>
      )}
    </Row>
  );
}

EdgeDeviceApplicationsEdit.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  edgeDevice: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default withIntl(withApi(withNotifier(EdgeDeviceApplicationsEdit)));
