import PropTypes from "prop-types";

export const opcUaServerCertificateShape = PropTypes.shape({
  id: PropTypes.string,
  status: PropTypes.string,
  file_name: PropTypes.string,
});
export const opcUaServerCertificatesShape = PropTypes.arrayOf(
  opcUaServerCertificateShape,
);
