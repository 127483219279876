export function nameForCriticality(criticality, intl) {
  return intl.formatMessage({
    id: `insights.criticality.${criticality.toLowerCase()}`,
  });
}

export function intlAdjectiveForCriticality(criticality, intl) {
  return nameForCriticality(`${criticality.toLowerCase()}.adjective`, intl);
}

export function nameForSelections(selectionName, optionName, intl) {
  return intl.formatMessage({
    id: `${selectionName}.${optionName.toLowerCase()}`,
  });
}
