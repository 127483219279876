import React, { useState } from "react";
import {
  withApi,
  withIntl,
  withNotifier,
  apiShape,
  intlShape,
  notifierShape,
  Column,
  Row,
  ConfirmationModal,
  EditActionButton,
  BadRequestError,
  UnauthorizedError,
} from "lcm-iot-commons";
import RecoveryCodesModal from "./RecoveryCodesModal";
import MultiFactorAuthenticationRecoveryCodesForm from "./MultiFactorAuthenticationRecoveryCodesForm";

export function MultiFactorAuthenticationRecoveryCodes({
  api,
  intl,
  notifier,
}) {
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [showRecoveryCodesModal, setShowRecoveryCodesModal] = useState(false);
  const [showConfirmRecoveryCodesChange, setShowConfirmRecoveryCodesChange] =
    useState(false);

  const onConfirmModalConfirm = async (values, actions) => {
    try {
      const response = await api.patch(
        "/users/current/multi_factor_authentication/recovery_codes",
        { otp_code_token: values.otpCodeToken },
      );
      setRecoveryCodes(response.recovery_codes);
      setShowRecoveryCodesModal(true);
      setShowConfirmRecoveryCodesChange(false);
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        notifier.showError(intl.formatMessage({ id: "mfa.invalid_token" }));
      } else if (
        error instanceof BadRequestError &&
        error.contains("required_login_token")
      ) {
        notifier.showError(
          intl.formatMessage({ id: "mfa.error.required_login_token" }),
        );
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const onChangeRecoveryCodesModalClose = () => {
    setShowRecoveryCodesModal(false);
  };

  return (
    <Row className="mfa-details-item">
      <Column className="col-xs-8">
        <div className="mfa-message" id="mfa-recovery-codes">
          {intl.formatMessage({ id: "mfa.recovery_codes" })}
        </div>
      </Column>
      <Column className="mfa-config-button">
        <EditActionButton
          label={intl.formatMessage({ id: "mfa.recovery_codes.change" })}
          icon="icon-eh-export"
          id="edit-mfa"
          onClick={() => setShowConfirmRecoveryCodesChange(true)}
        />
      </Column>
      <ConfirmationModal
        show={showConfirmRecoveryCodesChange}
        titleText={intl.formatMessage({
          id: "mfra.recovery_codes.change.confirm_title",
        })}
        onClose={() => setShowConfirmRecoveryCodesChange(false)}
        showConfirm={false}
        showCancel={false}
      >
        <MultiFactorAuthenticationRecoveryCodesForm
          onSubmit={onConfirmModalConfirm}
          onCancel={() => setShowConfirmRecoveryCodesChange(false)}
        />
      </ConfirmationModal>
      <RecoveryCodesModal
        id="change-recovery-codes-modal"
        show={showRecoveryCodesModal}
        recoveryCodes={recoveryCodes}
        onClose={() => onChangeRecoveryCodesModalClose()}
      />
    </Row>
  );
}

MultiFactorAuthenticationRecoveryCodes.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default withNotifier(
  withApi(withIntl(MultiFactorAuthenticationRecoveryCodes)),
);
