import PropTypes from "prop-types";

export const permissionRequestShape = PropTypes.shape({
  status: PropTypes.string,
  requestUser: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  requestMessage: PropTypes.string,
  responseUser: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  responseMessage: PropTypes.string,
  permitable: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
});
