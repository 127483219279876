import React from "react";
import { sessionShape } from "../../shapes";

import TicketSearch from "./TicketSearch";
import { Container, Row, Column } from "../Grid";
import { withSession } from "../../context";
import NotFound from "../Errors/NotFound";

export function Tickets({ session }) {
  if (session.subscriptionType !== "paid") {
    return <NotFound />;
  }
  return (
    <Container>
      <Row>
        <Column>
          <TicketSearch />
        </Column>
      </Row>
    </Container>
  );
}

Tickets.propTypes = {
  session: sessionShape,
};
export default withSession(Tickets);
