import React, { useState } from "react";
import PropTypes from "prop-types";
import { handleFormikValueChange } from "../../utils";
import InputGroup from "./InputGroup";
import Select from "./Select";
import { backendShape, withBackend } from "../../context";
import { withConfiguration } from "../../context/ConfigurationContext";

export function CountriesSelectFormik(props) {
  const { backend, all, disabled, values, name, configuration } = props;

  const [state, setState] = useState({
    fetching: false,
    countries: undefined,
    selectedCountry: undefined,
  });

  const loadCountries = async () => {
    try {
      setState({
        fetching: true,
        countries: [],
        selectedCountry: null,
      });
      const response = await backend.get("/countries", {
        all,
        language: configuration.language,
      });
      setState({
        fetching: false,
        countries: response.countries,
        selectedCountry:
          values && values[name] ? values[name] : response.countries[0],
      });
    } catch (error) {
      setState({ fetching: false });
    }
  };

  React.useEffect(() => {
    loadCountries();
  }, []);

  return (
    <InputGroup {...props}>
      <Select
        id="countries-select"
        name="countries"
        valueKey="code"
        options={state.countries}
        selectedOption={state.selectedCountry}
        onChange={(value) => handleFormikValueChange(props, value)}
        disabled={disabled || state.fetching}
      />
    </InputGroup>
  );
}

CountriesSelectFormik.propTypes = {
  backend: backendShape,
  all: PropTypes.bool,
  disabled: PropTypes.bool,
  values: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string,
  configuration: PropTypes.shape({ language: PropTypes.string.isRequired })
    .isRequired,
};

export default withBackend(withConfiguration(CountriesSelectFormik));
