const CONFIGURATION = {
  BASE_URL: "/undefined",
  HELP_URL: "https://help.netilion.endress.com",
  HELP_URL_: "https://help.netilion.endress.com",
  MAIN_URL: "https://netilion.endress.com",
  HELP_URL_AVAILABILITY:
    "https://help.netilion.endress.com/hc/en-us/articles/360009713059",
  HELP_URL_DEMODATA:
    "https://help.netilion.endress.com/hc/en-us/articles/4407831766802",
  CONTACT_URL: "https://netilion.endress.com/contact/form",
  SIGN_UP_SURVEY: "https://www.surveymonkey.com/r/help-us-improve-netilion",
  SIGN_UP_VIDEO:
    "https://res.cloudinary.com/hpnfc0f7b/video/upload/v1633611742/FirstAsset_v2_.mp4",
  LEGAL_URL: "/legal",
  HELP_URL_DIGITAL_TWINS:
    "https://help.netilion.endress.com/hc/en-us/articles/13233308259740-Using-digital-twins-across-companies",
};

export function configureApplication(configuration) {
  Object.keys(configuration).forEach((key) => {
    CONFIGURATION[key] = configuration[key];
  });
}

export default CONFIGURATION;
