import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const WindowSizeContext = React.createContext();

const calculateWindowSize = () => {
  if (window.innerWidth >= 992) {
    return "desktop";
  }
  if (window.innerWidth >= 768) {
    return "tablet";
  }
  return "phone";
};

const getWindowSize = () => ({
  width: window.innerWidth || global.window.innerWidth,
  height: window.innerHeight || global.window.innerHeight,
  size: calculateWindowSize(),
});

function WindowSizeContextProvider({ children }) {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const timerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setWindowSize(getWindowSize());
      }, 100);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  );
}

WindowSizeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const withWindowSize = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <WindowSizeContext.Consumer>
        {(context = getWindowSize()) => (
          <ChildComponent
            {...props}
            windowWidth={context.width}
            windowHeight={context?.height}
            windowSize={context?.size}
          />
        )}
      </WindowSizeContext.Consumer>
    );
  }
  ConnectedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

const useWindowSize = () => React.useContext(WindowSizeContext);

export { WindowSizeContextProvider, withWindowSize, useWindowSize };
