import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { intlShape, handleUnknownErrors, showSuccess } from "lcm-iot-commons";
import { MenuItem } from "react-bootstrap";
import ItemMenu from "lcm-iot-commons/client/lib/components/ItemMenu";

import ConfirmationModal from "lcm-iot-commons/client/lib/components/ConfirmationModal";
import { technicalUserShape } from "../../shapes/technicalUsersShape";
import { accessRightsShape } from "../../shapes/accessRightsShape";
import TechnicalUserModal from "./TechnicalUserModal";
import {
  resetTechnicalUserPassword,
  deleteTechnicalUser,
} from "../../api/technicalUserApi";

class TechnicalUserItem extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnDeleteClick = this.handleOnDeleteClick.bind(this);
    this.handleOnResetClick = this.handleOnResetClick.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onResetConfirmationModal = this.onResetConfirmationModal.bind(this);
    this.onDeleteConfirmationModal = this.onDeleteConfirmationModal.bind(this);
    this.state = {
      deleting: false,
      resetting: false,
      showModal: false,
      newPassword: null,
    };
  }

  onCloseModal() {
    this.resetActionsState();
  }

  async onResetConfirmationModal() {
    const { intl, technicalUser } = this.props;
    const { resetting } = this.state;
    this.resetActionsState();
    if (resetting) {
      return resetTechnicalUserPassword(technicalUser.id)
        .then((newPassword) => {
          this.setState({ showModal: true, newPassword: newPassword.password });
        })
        .catch((apiErrors) => {
          handleUnknownErrors(
            apiErrors,
            intl.formatMessage({ id: "api.error.unknown" }),
          );
        });
    }
    return null;
  }

  onDeleteConfirmationModal() {
    const { intl, technicalUser, onTechnicalUserRemoved } = this.props;
    const { deleting } = this.state;
    this.resetActionsState();
    if (deleting) {
      deleteTechnicalUser(technicalUser.id)
        .then(() => {
          showSuccess(
            intl.formatMessage({
              id: "technical_user.actions.delete.notification",
            }),
          );
          onTechnicalUserRemoved(technicalUser);
        })
        .catch((apiErrors) => {
          handleUnknownErrors(
            apiErrors,
            intl.formatMessage({ id: "api.error.unknown" }),
          );
        });
    }
    return null;
  }

  resetActionsState() {
    this.setState({ deleting: false, resetting: false, showModal: false });
  }

  handleOnDeleteClick() {
    this.setState({ deleting: true });
  }

  handleOnResetClick() {
    this.setState({ resetting: true });
  }

  render() {
    const { technicalUser, accessRights, intl } = this.props;
    const { newPassword, showModal, resetting, deleting } = this.state;
    const canUpdateAndDelete = accessRights.canUpdate;
    const technicalUserModal =
      showModal && newPassword ? (
        <TechnicalUserModal
          id="modal-dialog"
          show={showModal}
          titleText={intl.formatMessage({
            id: "technical_user_modal.reset_password.modal_title",
          })}
          contextBodyText={intl.formatMessage({
            id: "technical_user_modal.reset_password.modal_message",
          })}
          onClose={this.onCloseModal}
          username={technicalUser.email}
          password={newPassword}
        />
      ) : null;
    const resetConfirmationModal = resetting ? (
      <ConfirmationModal
        id="reset-confirmation-modal"
        show
        titleText={intl.formatMessage({
          id: "password_reset.actions.modal_title",
        })}
        messageText={intl.formatMessage(
          { id: "password_reset.actions.modal_message" },
          { technicalUser: technicalUser.email },
        )}
        onConfirm={this.onResetConfirmationModal}
        onClose={this.onCloseModal}
      />
    ) : null;
    const deleteConfirmationModal = deleting ? (
      <ConfirmationModal
        id="delete-confirmation-modal"
        show
        titleText={intl.formatMessage({
          id: "technical_user_delete.actions.modal_title",
        })}
        messageText={intl.formatMessage(
          { id: "technical_user_delete.actions.modal_message" },
          { technicalUser: technicalUser.email },
        )}
        onConfirm={this.onDeleteConfirmationModal}
        onClose={this.onCloseModal}
      />
    ) : null;

    return (
      <li className="list-item">
        <div className="list-item-image">
          <span className="icon lcm-iot-icon-user" />
        </div>
        <div className="list-item-content">
          <div className="list-item-header">{technicalUser.email}</div>
          <div className="list-item-details">
            <FormattedMessage id="label.technical_user_id" />
            {": "}
            {technicalUser.id}
          </div>
        </div>
        <div className="list-item-actions">
          <ItemMenu>
            <MenuItem
              id="item-reset"
              onSelect={this.handleOnResetClick}
              className={!canUpdateAndDelete ? "disabled" : ""}
              disabled={!canUpdateAndDelete}
            >
              <FormattedMessage id="button.reset_password" />
            </MenuItem>
            <MenuItem
              id="item-delete"
              onSelect={this.handleOnDeleteClick}
              className={!canUpdateAndDelete ? "disabled" : ""}
              disabled={!canUpdateAndDelete}
            >
              <FormattedMessage id="button.delete" />
            </MenuItem>
          </ItemMenu>
        </div>
        {technicalUserModal}
        {resetConfirmationModal}
        {deleteConfirmationModal}
      </li>
    );
  }
}

TechnicalUserItem.propTypes = {
  technicalUser: technicalUserShape,
  accessRights: accessRightsShape,
  onTechnicalUserRemoved: PropTypes.func,
  intl: intlShape,
};

TechnicalUserItem.defaultProps = {
  technicalUser: undefined,
  accessRights: undefined,
  onTechnicalUserRemoved: undefined,
  intl: undefined,
};

export default injectIntl(TechnicalUserItem);
