import { combineReducers } from "redux";

import {
  accessRightsReducer,
  currentUserReducer,
  sessionReducer,
} from "lcm-iot-commons";

const lastAction = (state, action) => action;

const rootReducer = combineReducers({
  accessRights: accessRightsReducer,
  currentUser: currentUserReducer,
  lastAction,
  session: sessionReducer,
});

export default rootReducer;
