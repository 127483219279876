/* eslint-disable no-param-reassign */
import React, { useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { MenuItem } from "react-bootstrap";
import * as R from "ramda";

import {
  ActionBar,
  notifierShape,
  apiShape,
  withNotifier,
  withApi,
  ActionDropdownButton,
  intlShape,
  ActionBarButtons,
  withConfiguration,
  withFlipper,
  SearchBar,
  Loader,
} from "lcm-iot-commons";

import ServiceSubscriptionItem from "./ServiceSubscriptionItem";
import { extractServiceSubscriptions } from "../../extractors/serviceSubscriptionsExtractor";
import { extractAssets } from "../../extractors/assetsExtractor";

export function ServiceSubscriptions({
  resellerView,
  api,
  notifier,
  intl,
  configuration,
  flipper,
}) {
  const [fetching, setFetching] = useState(true);
  const [subscriptions, setSubscriptions] = useState();
  const [totalCount, setTotalCount] = useState();
  const [filteredSubscriptions, setFilteredSubscriptions] = useState();
  const { applications, netilionUrl } = configuration;

  const byStatusAndName = R.sortWith([
    R.ascend(R.propEq("cancelled", "status")),
    R.ascend(R.prop("applicationName")),
  ]);
  const subsRef = useRef(subscriptions);
  subsRef.current = subscriptions;
  const intervalRef = useRef();

  const addAssetsToSubscriptions = (subscriptionsParam) =>
    subscriptionsParam.map(async (subscription) => {
      if (subscription.appIconName === "smartsystems") {
        subscription.assets = extractAssets(
          await api.get(`/subscriptions/${subscription.id}/assets`, {
            include: "instrumentations",
          }),
        );
      }
      return subscription;
    });

  const loadData = async () => {
    try {
      setFetching(true);
      let subscriptionsCollection = [];

      if (resellerView) {
        // this view should only contain subscriptions that the reseller sold to others (in other words 'manage')
        subscriptionsCollection = extractServiceSubscriptions(
          await api.getAll("/subscriptions", { scope: "RESELLER" }),
        );

        subscriptionsCollection.forEach((subscription) => {
          subscription.pricingModel =
            subscription.externalReference && subscription.status !== "open"
              ? "paid"
              : "free";
          subscription.usable = false;
        });
      } else {
        // this view should contain subscriptions he can use (own + seats) but also readable ones through permissions
        subscriptionsCollection = extractServiceSubscriptions(
          await api.getAll("/subscriptions", {
            scope: "USER",
            status: "confirmed,payment_required,non_renewing,in_trial",
          }),
        );
        subscriptionsCollection.forEach((subscription) => {
          subscription.pricingModel =
            subscription.externalReference && subscription.status !== "open"
              ? "paid"
              : "free";
        });
      }

      subscriptionsCollection = addAssetsToSubscriptions(
        subscriptionsCollection,
      );
      await Promise.all(subscriptionsCollection).then((subs) => {
        setSubscriptions(subs);
        setTotalCount(subscriptionsCollection.length);
      });
    } catch (errors) {
      notifier.showError(api.translateError(errors));
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    loadData();

    return () => {
      clearInterval(intervalRef?.current);
    };
  }, []);

  const loader = <Loader id="subscriptions-list-loader" loading={fetching} />;

  const badge = fetching ? (
    <span className="loading">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  ) : (
    totalCount
  );

  const renderBadge =
    totalCount > 0 ? (
      <span id="subscriptions-count" className="badge">
        {badge}
      </span>
    ) : null;

  const noSubscriptionsFound =
    subscriptions && !fetching && subscriptions.length === 0 ? (
      <div id="no-subscription-found" className="clue">
        <div className="clue-header">
          <FormattedMessage id="subscription.no_service_found" />
        </div>
        <div className="clue-details">
          <FormattedMessage
            id={`subscription.no_service_found.${resellerView ? "reseller_" : ""}details`}
          />
        </div>
      </div>
    ) : null;

  const subscriptionsListItems =
    subscriptions && !fetching ? (
      <ul id="subscription-list" className="list">
        {byStatusAndName(filteredSubscriptions || subscriptions).map(
          (subscription) => (
            <ServiceSubscriptionItem
              subscription={subscription}
              key={subscription.id}
              usable={subscription.usable}
              resellerView={resellerView}
            />
          ),
        )}
      </ul>
    ) : null;

  const extendedApplications = applications;
  extendedApplications.netilion = netilionUrl;

  const excludedList = flipper?.oneSubscription
    ? ["id", "analytics", "health", "library", "value"]
    : ["id", "netilion"];

  const applicationItems = Object.keys(extendedApplications)
    .sort()
    .filter((key) => !excludedList.includes(key))
    .map((key) => {
      const enabled = resellerView
        ? key !== "smartsystems"
        : !subscriptions?.find(
            (sub) =>
              sub.applicationName.replace(/\s/g, "").toLowerCase() === key,
          ) &&
          (!flipper?.oneSubscription ||
            !["analytics", "health", "library", "value"].includes(key));

      const url = resellerView
        ? `${extendedApplications[key]}/reseller/subscriptions/create`
        : extendedApplications[key];

      return (
        <MenuItem
          id={`menu-item-${key}`}
          key={key}
          className="menu-item"
          href={url}
          disabled={!enabled}
        >
          <span>{intl.formatMessage({ id: `app.${key}` })}</span>
        </MenuItem>
      );
    });

  const title = resellerView ? (
    <FormattedMessage id="label.reseller_service_subscriptions" />
  ) : (
    <FormattedMessage id="label.service_subscriptions" />
  );

  const t = (key) => intl.formatMessage({ id: key }).toLowerCase();

  const translation = {
    [t`subscription_details.cancelled`]: "cancelled",
    [t`subscription_details.active`]: "confirmed",
    [t`subscription_details.in_trial`]: "in_trial",
    [t`subscription_details.non_renewing`]: "non_renewing",
    [t`subscription_details.payment_required`]: "payment_required",
  };

  // This function needs to be idempotent
  const onSearch = (searchedValue) => {
    // eslint-disable-next-line no-shadow
    const subscriptions = subsRef.current;
    if (!subscriptions && !intervalRef.current) {
      intervalRef.current = setInterval(() => onSearch(searchedValue), 1000);
    }
    if (!subscriptions) return null;
    if (!searchedValue) {
      setTotalCount(subscriptions.length);
      return setFilteredSubscriptions(null);
    }
    const query = searchedValue.toLowerCase();
    const match = Object.entries(translation)
      .find((trans) => trans[0].includes(query))
      ?.pop();

    const filteredSubs = match
      ? subscriptions.filter((sub) => sub.status === match)
      : subscriptions.filter((sub) =>
          Object.values(sub)
            .map((e) => String(e).toLocaleLowerCase())
            .find((e) => e.includes(query)),
        );

    setFilteredSubscriptions(filteredSubs);
    setTotalCount(filteredSubs.length);
    return filteredSubs;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ActionBar>
            <h1 id="subscriptions-header">
              {title}
              {renderBadge}
            </h1>
            <ActionBarButtons>
              <ActionDropdownButton
                id="create-button"
                icon="icon-eh-new"
                title={intl.formatMessage({ id: "button.create" })}
              >
                {applicationItems}
              </ActionDropdownButton>
            </ActionBarButtons>
          </ActionBar>
          <SearchBar
            onSearch={onSearch}
            placeholder={intl.formatMessage({
              id: "reseller_customer.subscription.search_placeholder",
            })}
          />
        </div>
      </div>
      {loader}
      {noSubscriptionsFound}
      {subscriptionsListItems}
    </div>
  );
}

ServiceSubscriptions.defaultProps = {
  resellerView: false,
};

ServiceSubscriptions.propTypes = {
  resellerView: PropTypes.bool,
  api: apiShape.isRequired,
  flipper: PropTypes.shape({
    oneSubscription: PropTypes.bool,
  }).isRequired,
  notifier: notifierShape.isRequired,
  intl: intlShape.isRequired,
  configuration: PropTypes.shape({
    applications: PropTypes.shape({}),
    netilionUrl: PropTypes.string,
  }).isRequired,
};

export default injectIntl(
  withFlipper(withConfiguration(withApi(withNotifier(ServiceSubscriptions)))),
);
