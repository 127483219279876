import React from "react";
import PropTypes from "prop-types";

import { withIntl } from "../../wrappers";
import { intlShape } from "../../shapes";
import DynamicFieldArray from "../Form/DynamicFieldArray";
import SpecificationKeyTypeahead from "./SpecificationKeyTypeahead";

export function SpecificationsInput(props) {
  const { intl, disabled, isSubmitting } = props;
  return (
    <>
      <label htmlFor="specifications" data-testid="specifications-input">
        {intl.formatMessage({ id: "specifications.title" })}
      </label>
      <DynamicFieldArray
        {...props}
        id="specifications"
        valueArrayName="specifications"
        placeholders={[
          intl.formatMessage({ id: "specifications.label" }),
          intl.formatMessage({ id: "specifications.value" }),
        ]}
        fieldNames={["key", "value"]}
        fieldComponents={[SpecificationKeyTypeahead]}
        disabled={disabled || isSubmitting}
      />
    </>
  );
}

SpecificationsInput.propTypes = {
  specificationKeysEndpoint: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default withIntl(SpecificationsInput);
