import PropTypes from "prop-types";
import React, { Component } from "react";
import { Formik } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import PermissionsEdit from "../Permissions/PermissionsEdit";
import { userGroupShape } from "../../shapes/userGroupsShape";
import { ActionBar } from "../ActionBar";
import Loader from "../Loader";
import { intlShape } from "../../shapes";

import {
  apiErrorsContain,
  updateUserGroup,
  loadUserGroup,
  isNotFoundError,
} from "../../api";

import { Form, CancelButton, SubmitButton, TextInput, TextArea } from "../Form";

import {
  handleUnknownErrors,
  isEmpty,
  navigateTo,
  showSuccess,
} from "../../utils";

import { withAccessRights, enforceAccessRightCanUpdate } from "../../wrappers";
import { Column, Container, Row } from "../Grid";
import BackButton from "../BackButton";

export class UserGroupEdit extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    fetchingPermissions: PropTypes.bool,
    userGroupLoading: PropTypes.bool,
    userGroup: userGroupShape,
    dirty: PropTypes.bool,
    match: PropTypes.shape({
      params: PropTypes.shape({ id: PropTypes.string }),
    }),
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      userGroupLoading: true,
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    this.loadData().catch((apiErrors) => {
      if (isNotFoundError(apiErrors)) {
        navigateTo("/404");
      } else {
        handleUnknownErrors(
          apiErrors,
          intl.formatMessage({ id: "api.error.unknown" }),
        );
      }
    });
  }

  onSubmit(values, actions) {
    const { intl, match } = this.props;

    updateUserGroup(match.params.id, values)
      .then(() => {
        actions.resetForm(values);
        showSuccess(
          intl.formatMessage({ id: "user_group_edit.success_notification" }),
        );
        navigateTo(`/usergroups/${match.params.id}`);
      })
      .catch((apiErrors) => {
        const formErrors = {};

        if (apiErrorsContain(apiErrors, "taken", "name")) {
          formErrors.name = intl.formatMessage({
            id: "api.error.user_group.taken",
          });
        }

        if (Object.keys(formErrors).length < apiErrors.length) {
          handleUnknownErrors(
            apiErrors,
            intl.formatMessage({ id: "api.error.unknown" }),
          );
        }

        actions.setErrors(formErrors);
        actions.setSubmitting(false);
      });
  }

  async loadData() {
    const { match } = this.props;
    const userGroup = await loadUserGroup(match.params.id);
    this.setState({ userGroup, userGroupLoading: false });
  }

  validateForm(values) {
    const { intl } = this.props;
    const errors = {};

    if (isEmpty(values.name) || isEmpty(values.name.trim())) {
      errors.name = intl.formatMessage({ id: "validation.name.mandatory" });
    } else if (values.name.length > 60) {
      errors.name = intl.formatMessage(
        { id: "validation.name.too_long" },
        { characters: 60 },
      );
    }

    return errors;
  }

  renderForm(props) {
    const { intl } = this.props;
    const { isSubmitting } = props;

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.form = props;
    return (
      <Form {...props}>
        <Row>
          <Column xs="12">
            <TextInput
              {...props}
              id="user-group-name"
              name="name"
              label={intl.formatMessage({ id: "label.name" })}
              required
            />
            <TextArea
              {...props}
              id="user-group-description"
              name="description"
              label={intl.formatMessage({ id: "label.description" })}
            />
          </Column>
        </Row>
        <div className="btn-group">
          <SubmitButton
            id="edit-user-group-submit"
            fetching={isSubmitting}
            disabled={!props.dirty}
          />
          <CancelButton id="edit-user-group-cancel" disabled={isSubmitting} />
        </div>
      </Form>
    );
  }

  render() {
    const { userGroupLoading, userGroup } = this.state;
    const { fetchingPermissions } = this.props;

    const editPermissions =
      userGroup && !userGroupLoading ? (
        <PermissionsEdit
          permitableType="Usergroup"
          permitableId={userGroup.id}
        />
      ) : null;

    const editUserGroup =
      userGroup && !userGroupLoading ? (
        <Formik
          validate={this.validateForm}
          onSubmit={this.onSubmit}
          render={this.renderForm}
          initialValues={{
            name: userGroup.name,
            description: userGroup.description,
          }}
        />
      ) : null;
    return (
      <Container>
        <Row>
          <Column>
            <BackButton />
          </Column>
        </Row>
        <Row>
          <Column>
            <ActionBar>
              <h1 id="edit-user-group-header">
                <FormattedMessage id="user_group_edit.header" />
              </h1>
            </ActionBar>
          </Column>
        </Row>
        <Row>
          <Column lg="7">
            <h2>
              <FormattedMessage id="user_group_edit.details" />
            </h2>
            {editUserGroup}
          </Column>
        </Row>
        <Row>
          <Column>{editPermissions}</Column>
        </Row>
        <Row>
          <Column>
            <Loader loading={fetchingPermissions || userGroupLoading} />
          </Column>
        </Row>
      </Container>
    );
  }
}

export default injectIntl(
  withAccessRights(UserGroupEdit, "Usergroup", enforceAccessRightCanUpdate),
);
