const BREADCRUMP_SEPARATOR = "-";

export function extractNode(rawNode) {
  return {
    id: rawNode.id,
    name: rawNode.name,
    description: rawNode.description,
    typeName: rawNode.type ? rawNode.type.name : undefined,
    typeCode: rawNode.type ? rawNode.type.code : undefined,
    typeParentCode: rawNode?.type?.parent?.code,
    worstStatusName: rawNode.worst_asset_status
      ? rawNode.worst_asset_status.name
      : null,
    worstStatusCode: rawNode.worst_asset_status
      ? rawNode.worst_asset_status.code
      : null,
    parentId: rawNode.parent ? rawNode.parent.id : undefined,
    parent:
      rawNode.parent && rawNode.parent.name
        ? extractNode(rawNode.parent)
        : undefined,
    specifications: rawNode.specifications ? rawNode.specifications : undefined,
    instrumentations: rawNode.instrumentations
      ? rawNode.instrumentations
      : undefined,
    // for now we want to extract only can_read parent objects, so there is a check for the name before extracting
    tenantId: rawNode.tenant ? rawNode.tenant.id : undefined,
    tenantName: rawNode.tenant ? rawNode.tenant.name : undefined,
    tenantPublic: rawNode.tenant ? rawNode.tenant.public : undefined,
    itemType: "node",
  };
}

function buildDisplayName(node) {
  if (node.parentId && node.parent) {
    // const parent = allNodes.find(t => t.id === node.parentId);
    const { parent } = node;
    if (parent.parentId) {
      return `${buildDisplayName(parent)} ${BREADCRUMP_SEPARATOR} ${node.name}`;
    }
    return `${parent.name} ${BREADCRUMP_SEPARATOR} ${node.name}`;
  }
  return node.name;
}

/* istanbul ignore next */
export function extractNodes(rawNodes, buildBreadcrumbNames = false) {
  const mappedNodes = rawNodes.nodes.map((node) => extractNode(node));
  if (buildBreadcrumbNames) {
    mappedNodes.forEach((node) => {
      /* eslint-disable no-param-reassign */
      node.displayName = buildDisplayName(node);
    });
  }
  return mappedNodes;
}
