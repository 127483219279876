import SpecificationKeys from "../constants/SpecificationKeys";

import { capitalizeWords, humanize } from "./langUtils";

// when unpacking specifications
export function pullUpdatedAtFromSpec(specifications, key) {
  if (!specifications || !key) {
    return null;
  }
  if (!specifications[key]) {
    return null;
  }
  return specifications[key].updated_at;
}

export function pullValueFromSpec(specifications, key) {
  if (!specifications || !key) {
    return null;
  }
  if (!specifications[key]) {
    return null;
  }
  return specifications[key].value;
}

export function pullBooleanFromSpec(specifications, key) {
  if (!specifications || !key) {
    return null;
  }
  if (!specifications[key]) {
    return null;
  }
  return specifications[key].value === "true";
}

function pickName(key) {
  const array = key.split(".");
  return array[array.length - 1];
}

// value mapping
export function mapSensitivity(specification) {
  if (!specification?.value) return undefined;
  switch (specification.value) {
    case "946":
      return "low";
    case "947":
      return "high";
    case "616":
      return undefined;
    default:
      return "medium";
  }
}

export function mapBlockDistance(specification) {
  if (!specification?.value) return undefined;
  if (specification.value === "-1") {
    return undefined;
  }
  return specification.value;
}

// function returns snake_case specification names for the specs provided in the whitelist
export function convertSpecsToAttributes(
  object,
  specifications,
  specsWhitelist,
) {
  if (!specifications) {
    return object;
  }
  const convertAllSpecifications = !specsWhitelist;

  /* eslint-disable no-param-reassign */
  Object.keys(specifications).forEach((key) => {
    if (convertAllSpecifications || specsWhitelist.indexOf(key) > -1) {
      const attributeName = pickName(key);
      switch (key) {
        case SpecificationKeys.FullEmptyConfiguration.SENSITIVITY:
          object.sensitivity = mapSensitivity(specifications[key]);
          break;
        case SpecificationKeys.FullEmptyConfiguration.BLOCK_DISTANCE:
          object.block_distance = mapBlockDistance(specifications[key]);
          break;
        default:
          object[attributeName] = specifications[key].value;
          break;
      }
    }
  });
  /* eslint-enabls no-param-reassign */
  return object;
}

export function convertSpecificationKeyToLabel(key) {
  const brackets = key.split(".");
  brackets[brackets.length - 1] = capitalizeWords(
    humanize(brackets[brackets.length - 1]),
  );
  return brackets.join(".");
}

export function convertLabelToSpecificationKey(label) {
  if (label === undefined || label === null) return undefined;
  return label
    .toLowerCase()
    .replace(/[^a-z0-9.]/gim, "_")
    .replace(/\s+/g, "_");
}
