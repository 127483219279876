import PropTypes from "prop-types";

export const searchResultShape = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  pictureUrl: PropTypes.string,
});

export const searchResultsShape = PropTypes.arrayOf(searchResultShape);
