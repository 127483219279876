import React, { Component } from "react";
import PropTypes from "prop-types";
import { navigateTo } from "../../utils";
import { SubmitButton } from "../Form";

class Clue extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    details: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    action: PropTypes.string,
    target: PropTypes.string,
    highlighted: PropTypes.bool,
    onClick: PropTypes.func,
    hero: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    hero: true,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnClick = this.handleOnClick.bind(this);
    this.state = { clicked: false };
  }

  handleOnClick() {
    const { onClick, target } = this.props;
    if (onClick) {
      onClick();
    } else {
      this.setState({ clicked: true });
      navigateTo(target);
    }
  }

  render() {
    const {
      id,
      title,
      details,
      action,
      highlighted,
      hero,
      onClick,
      target,
      children,
    } = this.props;
    const titleSection = title ? (
      <div className="clue-header">{title}</div>
    ) : null;
    const detailsSection = details ? (
      <div className="clue-details">{details}</div>
    ) : null;
    return (
      <div id={id} className={`clue${highlighted ? " highlighted" : ""}`}>
        {titleSection}
        {detailsSection}
        {children}
        {onClick || target ? (
          <SubmitButton
            onClick={this.handleOnClick}
            disabled={this.state.clicked}
            fetching={this.state.clicked}
            text={action}
            hero={hero}
          />
        ) : null}
      </div>
    );
  }
}

export default Clue;
