/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { FormattedMessage } from "react-intl";

import {
  withBrowser,
  withTracking,
  browserShape,
  trackingShape,
} from "../../context";

export function SessionExpired({ browser, tracking }) {
  React.useMemo(() => {
    tracking.reset();
  }, []);

  const onClick = (event) => {
    event.preventDefault();
    browser.redirectTo("/");
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <h1 id="session-expired-header">
            <FormattedMessage id="session_expired.header" />
          </h1>
          <p>
            <FormattedMessage id="session_expired.details" />
          </p>
          <a
            id="sign-in"
            className="btn btn-primary"
            type="button"
            onClick={onClick}
          >
            <FormattedMessage id="session_expired.button.signin" />
          </a>
        </div>
      </div>
    </div>
  );
}

SessionExpired.propTypes = {
  browser: browserShape.isRequired,
  tracking: trackingShape.isRequired,
};

export default withBrowser(withTracking(SessionExpired));
