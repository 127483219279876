/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from "prop-types";
import React from "react";
import { isNotEmpty } from "../../utils";

const defaultStyle = { background: "inherit" };
const highlightColor = "inherit";

export function Highlight(props) {
  const { string, match, path } = props;
  const chars = string?.split("");

  if (match && path === match?.key && chars && isNotEmpty(chars)) {
    return chars.map((char, index) => {
      const style = { ...defaultStyle };
      let classes = `character character-${index}`;

      // eslint-disable-next-line no-unused-expressions
      match?.indices?.forEach((pair) => {
        if (index >= pair[0] && index <= pair[1]) {
          style.background = highlightColor;
          classes += " highlighted";
        }
      });

      // Only use the index if items have no stable ID
      return (
        // eslint-disable-next-line react/no-array-index-key
        <span className={classes} style={style} key={index}>
          {char}
        </span>
      );
    });
  }
  return <>{string}</>;
}

Highlight.defaultProps = {
  string: "",
  match: {},
  path: "",
};

Highlight.propTypes = {
  string: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  path: PropTypes.string,
};

export default Highlight;
