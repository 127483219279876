import PropTypes from "prop-types";

export const tenantShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  public: PropTypes.bool,
});

export const tenantsShape = PropTypes.arrayOf(tenantShape);
