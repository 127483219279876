import PropTypes from "prop-types";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  withBackend,
  withBrowser,
  withIntl,
  withNotifier,
  backendShape,
  browserShape,
  intlShape,
  notifierShape,
  Container,
  SubmitButton,
  BadRequestError,
  Heading,
} from "lcm-iot-commons";
import { withSignUp, signUpShape } from "../../context/SignUpContext";
import SignUpBreadcrumb from "./SignUpBreadcrumb";

export function AccountConfirmation({
  location,
  backend,
  browser,
  intl,
  notifier,
  signUp,
}) {
  const [urlParameters, setUrlParameters] = useState({});

  const noop = () => null;

  const getUrlParameters = () => {
    const parameterObject = {};
    if (location?.search) {
      parameterObject.confirmation_token = new URLSearchParams(
        location.search,
      ).get("confirmation_token");
      parameterObject.redirect_uri = new URLSearchParams(location.search).get(
        "redirect_uri",
      );
      parameterObject.email_changed = new URLSearchParams(location.search).get(
        "email_changed",
      );
    }
    return parameterObject;
  };

  React.useEffect(() => {
    const parameters = getUrlParameters();
    if (parameters.confirmation_token) {
      setUrlParameters(parameters);
    } else {
      browser.navigateTo("/500");
    }
  }, [location]);

  const emailChanged = urlParameters.email_changed === "true";
  const accountConfirmationInfoMessage1 = emailChanged
    ? "account_confirmation.email_change.message_1"
    : "account_confirmation.new_user.message_1";
  const accountConfirmationInfoMessage2 = emailChanged
    ? "account_confirmation.email_change.message_2"
    : "account_confirmation.new_user.message_2";
  const accountConfirmationButton = emailChanged
    ? "account_confirmation.email_change.button"
    : "account_confirmation.new_user.button";
  const successMessage = emailChanged
    ? "account_confirmation.email_change.success"
    : "account_confirmation.new_user.success";

  const handleOnSubmit = async () => {
    try {
      const redirectUrl = urlParameters?.redirect_uri?.trim()
        ? urlParameters.redirect_uri
        : "/";
      const confirmationResponse = await backend.get(
        "/user_confirmation",
        urlParameters,
      );
      notifier.showSuccess(intl.formatMessage({ id: successMessage }));
      await signUp.updateSignUpDetails({
        email: confirmationResponse.email,
        firstName: confirmationResponse.first_name,
        lastName: confirmationResponse.last_name,
        redirectUrl,
      });
      if (emailChanged) {
        await backend.delete("/session").catch(noop);
        if (urlParameters?.redirect_uri?.trim()) {
          browser.redirectToExtern(urlParameters.redirect_uri);
        } else {
          browser.redirectTo("/");
        }
      } else {
        browser.navigateTo("/sign_up_complete");
      }
    } catch (error) {
      if (error instanceof BadRequestError) {
        if (error.contains("already_confirmed")) {
          notifier.showError(
            intl.formatMessage({
              id: "error.user_confirmation.already_confirmed",
            }),
          );
        } else if (error.contains("token_expired")) {
          notifier.showError(
            intl.formatMessage({ id: "error.user_confirmation.token_expired" }),
          );
        } else if (error.contains("token_invalid")) {
          notifier.showError(
            intl.formatMessage({ id: "error.user_confirmation.token_invalid" }),
          );
        } else if (error.contains("token_blank")) {
          notifier.showError(
            intl.formatMessage({ id: "error.user_confirmation.token_blank" }),
          );
        } else {
          notifier.showError(backend.translateError(error));
        }
      } else {
        notifier.showError(backend.translateError(error));
      }
    }
  };

  return (
    <Container>
      {!emailChanged && (
        <SignUpBreadcrumb current="verification" action="signup" />
      )}
      <Heading
        id="account-confirmation-header"
        title={intl.formatMessage({ id: "account_confirmation.header" })}
      />
      <p id="account-confirmation-message-1" className="no-space-after">
        {intl.formatMessage({ id: accountConfirmationInfoMessage1 })}
      </p>
      <p id="account-confirmation-message-2" className="space-after">
        {intl.formatMessage({ id: accountConfirmationInfoMessage2 })}
      </p>
      <SubmitButton
        id="account-confirmation-button"
        text={intl.formatMessage({ id: accountConfirmationButton })}
        onClick={handleOnSubmit}
      />
    </Container>
  );
}

AccountConfirmation.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  backend: backendShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  signUp: signUpShape.isRequired,
};

export default withRouter(
  withBackend(
    withBrowser(withIntl(withNotifier(withSignUp(AccountConfirmation)))),
  ),
);
