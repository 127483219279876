import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

function Template({
  children,
  cookieConsentVisible = false,
  notification = null,
}) {
  return (
    <span className="root-layout">
      <div
        className={
          cookieConsentVisible
            ? "cyan-stripe content-wrapper cookie"
            : "cyan-stripe content-wrapper"
        }
      >
        {notification}
        <div id="root-content">{children}</div>
      </div>
      <footer className={cookieConsentVisible ? "cookie" : ""}>
        <div className="container">
          <ul>
            <li>Copyright © Endress+Hauser Process Solutions AG</li>
            <li>
              <a href="/imprint">
                <FormattedMessage id="footer.imprint" />
              </a>
            </li>
            <li>
              <a href="/legal/terms-of-service">
                <FormattedMessage id="footer.terms" />
              </a>
            </li>
            <li>
              <a href="/legal/privacy-policy">
                <FormattedMessage id="footer.privacy" />
              </a>
            </li>
            <li>
              <a href="https://help.netilion.endress.com/">
                <FormattedMessage id="footer.help" />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </span>
  );
}

Template.propTypes = {
  children: PropTypes.node,
  cookieConsentVisible: PropTypes.bool,
  notification: PropTypes.node,
};

export default Template;
