/* eslint-disable no-console */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

export function ConsoleLogging({ children }) {
  const log = useMemo(
    () => ({
      setUser: () => true,
      error: (context, error) => {
        console.error(`${context}: ${error?.stack}`);
      },
    }),
    [],
  );

  return <>{children(log)}</>;
}

ConsoleLogging.propTypes = {
  children: PropTypes.func,
};

export default ConsoleLogging;
