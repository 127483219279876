import React from "react";
import PropTypes from "prop-types";

function NavigationBanner({
  id,
  iconClassName,
  className,
  message, // , onClick, { a: htmlButton(() => deactivate()) }
}) {
  return (
    <div id={id} className="cyan-stripe">
      <div className={className}>
        <div>
          <span className={iconClassName} />
          <p id="banner-message">{message}</p>
        </div>
      </div>
    </div>
  );
}

NavigationBanner.propTypes = {
  id: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};
NavigationBanner.defaultProps = {
  id: "banner",
  message: "",
  className: "navigation-banner",
  iconClassName: "lcm-iot-icon-info",
};

export default NavigationBanner;
