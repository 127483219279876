import React from "react";

import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import SearchOptionItem from "./SearchOptionItem";

export function SearchOptions({ options, onChange }) {
  const intl = useIntl();

  const handleOptionChange = (optionName, checked) => {
    if (checked) {
      onChange([...options, optionName]);
    }
    if (!checked) {
      onChange(options.filter((item) => item !== optionName));
    }
  };

  return (
    <ul className="list-inline">
      <li className="list-inline-item option-label">
        <span className="additional-info">
          <FormattedMessage id="search.options" />
        </span>
      </li>
      <SearchOptionItem
        label={intl.formatMessage({ id: "search.options.show_specifications" })}
        option="specifications"
        onChange={handleOptionChange}
        checked={options.indexOf("specifications") >= 0}
      />
      <SearchOptionItem
        label={intl.formatMessage({ id: "search.options.show_assigned_nodes" })}
        option="nodes"
        onChange={handleOptionChange}
        checked={options.indexOf("nodes") >= 0}
      />
    </ul>
  );
}

SearchOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchOptions;
