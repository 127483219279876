/* eslint-disable camelcase */
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import {
  Row,
  Column,
  Container,
  Loader,
  Heading,
  ActionBar,
  Details,
  DetailsItem,
  PictureGallery,
  DetailsHeaderItem,
  DetailsPicture,
  ActionBarButtons,
  EditActionButton,
  withApi,
  withUser,
  withAccessRights,
  apiShape,
  accessRightsShape,
  intlShape,
  withNotifier,
  notifierShape,
  parseISO8601Date,
} from "lcm-iot-commons";

import {
  networkStatusIconClassName,
  networkInterfaceIconClassName,
} from "../../utils/statusUtils";

export function extractInitialValues(response, accessRights, match, intl) {
  const initialValues = { ...response };
  initialValues.modus = intl.formatMessage({
    id: `enum.network_interface_modus.${response.modus.toLowerCase()}`,
  });
  initialValues.status = intl.formatMessage({
    id: `enum.network_interface_status.${response.status}`,
  });
  initialValues.http_proxy_auth_value =
    response.http_proxy_auth === false
      ? intl.formatMessage({ id: "enum.network_interface_status.inactive" })
      : intl.formatMessage({ id: "enum.network_interface_status.active" });
  initialValues.http_proxy_host = response.http_proxy_host || "-";
  initialValues.http_proxy_port = response.http_proxy_port || "-";
  initialValues.editable = accessRights.canUpdate && response.type === "LAN";
  initialValues.editEndpoint = `/edge_devices/${match.params?.id}/network_interfaces/${match.params?.networkInterfaceId}/edit`;
  initialValues.hasProxy = Boolean(
    response.http_proxy_host || response.http_proxy_port,
  );
  initialValues.isManualMode = response.modus === "manual";
  initialValues.icon = networkStatusIconClassName(response.status);
  initialValues.placeholder = networkInterfaceIconClassName();
  initialValues.apply_timestamp = response.apply_timestamp
    ? parseISO8601Date(response.apply_timestamp)
    : null;
  initialValues.type =
    response.number !== undefined
      ? `${response.type} ${response.number}`
      : response.type;
  return initialValues;
}

export const validateAccessRights = (accessRights) => accessRights.canRead;

export function NetworkInterfaceDetails(props) {
  const { api, accessRights, match, intl, notifier } = props;
  const { id, networkInterfaceId } = match.params;
  const [networkInterface, setNetworkInterface] = useState([]);

  const loadData = async () => {
    try {
      const response = await api.get(
        `/edm/edge_devices/${id}/network_interfaces/${networkInterfaceId}`,
      );
      setNetworkInterface(
        extractInitialValues(response, accessRights, match, intl),
      );
    } catch (error) {
      notifier.showError(api.translateError(error));
      setNetworkInterface({});
    }
  };
  React.useEffect(() => {
    loadData();
  }, [id]);

  const {
    dns1,
    dns2,
    name,
    type,
    status,
    modus,
    editable,
    editEndpoint,
    icon,
    placeholder,
    http_proxy_host,
    http_proxy_port,
    http_proxy_auth_value,
    ip_address,
    subnet_mask,
    default_gateway,
    hasProxy,
    isManualMode,
  } = networkInterface;

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading
              id="details-header"
              title={intl.formatMessage({
                id: "network_interface_details.header",
              })}
            />
            <ActionBarButtons>
              <EditActionButton
                id="edit-network-interface-button"
                disabled={!editable}
                target={editEndpoint}
              />
            </ActionBarButtons>
          </ActionBar>
          {networkInterface && (
            <Details id="network-interface-item">
              <DetailsPicture>
                <PictureGallery placeholder={placeholder} />
              </DetailsPicture>
              <DetailsHeaderItem
                id="network-interface-name"
                translationKey="label.name"
                value={name}
              />
              <DetailsHeaderItem
                id="network-interface-type"
                translationKey="label.type"
                value={type}
              />
              <DetailsHeaderItem
                id="network-interface-modus"
                translationKey="label.modus"
                value={modus}
              />
              <DetailsItem
                id="network-interface-status"
                icon={icon}
                translationKey="label.status"
                value={status}
              />
              {isManualMode && (
                <Details id="network-interface-manual-mode">
                  <h2>
                    <FormattedMessage id="network_interface_details.ip_configuration" />
                  </h2>
                  <DetailsItem
                    id="network-interface-ip-address"
                    translationKey="label.ip_address"
                    value={ip_address}
                  />
                  <DetailsItem
                    id="network-interface-subnet_mask"
                    translationKey="label.subnet_mask"
                    value={subnet_mask}
                  />
                  <DetailsItem
                    id="network-interface-default-gateway"
                    translationKey="label.default_gateway"
                    value={default_gateway}
                  />
                  <DetailsItem
                    id="network-interface-dns1"
                    translationKey="label.dns1"
                    value={dns1}
                  />
                  <DetailsItem
                    id="network-interface-dns2"
                    translationKey="label.dns2"
                    value={dns2}
                  />
                </Details>
              )}
              {hasProxy && (
                <Details id="network-interface-proxy-settings">
                  <h2>
                    <FormattedMessage id="network_interface_details.proxy" />
                  </h2>
                  <DetailsItem
                    id="network-interface-http-proxy-host"
                    translationKey="label.http_proxy_host"
                    value={http_proxy_host}
                  />
                  <DetailsItem
                    id="network-interface-http-proxy-port"
                    translationKey="label.http_proxy_port"
                    value={`${http_proxy_port}`}
                  />
                  <DetailsItem
                    id="network-interface-http-proxy-auth"
                    translationKey="label.http_proxy_auth"
                    value={http_proxy_auth_value}
                  />
                </Details>
              )}
            </Details>
          )}
          <Loader id="loader" loading={!networkInterface} />
        </Column>
      </Row>
    </Container>
  );
}

NetworkInterfaceDetails.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      networkInterfaceId: PropTypes.string,
    }),
  }).isRequired,
};

export default withNotifier(
  withApi(
    withUser(
      injectIntl(
        withAccessRights(
          NetworkInterfaceDetails,
          "EDM::EdgeDevice",
          validateAccessRights,
        ),
      ),
    ),
  ),
);
