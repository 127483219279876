import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import IconButton from "../IconButton";

const ICON_MAPPING = {
  error: "icon2-eh-debug",
  warning: "icon2-eh-info",
  success: "icon2-eh-good",
};

function NotificationAlert({ type, message, onClose }) {
  return (
    <div
      id={`notification-alert-${type}`}
      className={`notification-alert notification-alert-${type}`}
    >
      <div className="notification-container">
        <Icon name={ICON_MAPPING[type]} />
        <span>{message}</span>
        <IconButton
          id="notification-alert-close-btn"
          icon="ico2-eh-cancel"
          onClick={onClose}
        />
      </div>
    </div>
  );
}

NotificationAlert.propTypes = {
  type: PropTypes.oneOf(["error", "warning", "success"]).isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onClose: PropTypes.func.isRequired,
};

export default NotificationAlert;
