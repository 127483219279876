import PropTypes from "prop-types";

// Instrumentation
export const instrumentationShape = PropTypes.shape({
  id: PropTypes.number,
  tag: PropTypes.string,
  statusName: PropTypes.string,
  typeName: PropTypes.string,
  criticality: PropTypes.string,
  description: PropTypes.string,
  pictureUrls: PropTypes.arrayOf(PropTypes.string),
});
export const instrumentationsShape = PropTypes.arrayOf(instrumentationShape);

// Status
export const instrumentationStatusShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  tenantId: PropTypes.number,
  tenantName: PropTypes.string,
});
export const instrumentationStatusesShape = PropTypes.arrayOf(
  instrumentationStatusShape,
);

// Type
export const instrumentationTypeShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  tenantId: PropTypes.number,
  tenantName: PropTypes.string,
});
export const instrumentationTypesShape = PropTypes.arrayOf(
  instrumentationTypeShape,
);
