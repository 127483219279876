import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { MenuItem } from "react-bootstrap";
import {
  ItemMenu,
  ListItem,
  ConfirmationModal,
  withApi,
  withBrowser,
  withNotifier,
  apiShape,
  browserShape,
  tenantShape,
  intlShape,
  notifierShape,
} from "lcm-iot-commons";

export function TenantItem({
  tenant,
  api,
  browser,
  intl,
  notifier,
  removeTenant,
}) {
  const [deleting, setDeleting] = useState(false);
  const [accessRights, setAccessRights] = useState({
    can_delete: false,
    can_update: false,
  });

  const handleOnClick = () => {
    browser.navigateTo(`/tenants/${tenant.id}`);
  };

  const handleOnEditClick = () => {
    browser.navigateTo(`/tenants/${tenant.id}/edit`);
  };

  const handleOnDeleteClick = () => {
    setDeleting(true);
  };

  const handleClose = () => {
    setDeleting(false);
  };

  const handleOnItemMenuOpen = async () => {
    const response = await api.get("/users/current/access_rights", {
      permitable_type: "Tenant",
      permitable_id: tenant.id,
    });
    setAccessRights(response);
  };

  const handleConfirm = async () => {
    try {
      await api.delete(`/tenants/${tenant.id}`);
      setDeleting(false);
      if (removeTenant) await removeTenant(tenant.id);
      notifier.showSuccess(
        intl.formatMessage({ id: "tenants.actions.delete.notification" }),
      );
    } catch (error) {
      setDeleting(false);
      notifier.showError(api.translateError(error));
    }
  };

  const modalDeleting = deleting ? (
    <ConfirmationModal
      id="tenant-item-confirm"
      show
      titleText={intl.formatMessage({
        id: "tenants.actions.delete.modal_title",
      })}
      messageText={intl.formatMessage({
        id: "tenants.actions.delete.modal_message",
      })}
      onConfirm={() => handleConfirm()}
      onClose={() => handleClose()}
    />
  ) : null;
  const actions = (
    <div id="tenant-action-items">
      <ItemMenu onOpen={() => handleOnItemMenuOpen()}>
        <MenuItem
          id="item-edit"
          onSelect={() => handleOnEditClick()}
          disabled={!accessRights.can_update}
        >
          {intl.formatMessage({ id: "button.edit" })}
        </MenuItem>
        <MenuItem
          id="item-delete"
          onSelect={() => handleOnDeleteClick()}
          disabled={!accessRights.can_delete}
        >
          {intl.formatMessage({ id: "button.delete" })}
        </MenuItem>
      </ItemMenu>
      {modalDeleting}
    </div>
  );

  return (
    <ListItem
      id={`tenant-item-${tenant.id}`}
      icon="lcm-iot-icon-tenant"
      actions={actions}
      title={tenant.name}
      onClick={() => handleOnClick()}
      description={tenant.description}
    />
  );
}

TenantItem.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  removeTenant: PropTypes.func,
  tenant: tenantShape.isRequired,
};

TenantItem.defaultProps = {
  removeTenant: null,
};

export default injectIntl(withBrowser(withApi(withNotifier(TenantItem))));
