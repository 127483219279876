/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
  navigateTo,
  isSomething,
  ItemMenu,
  ListItem,
  Picture,
  FormattedRelativeTime,
  url,
  handleUnknownErrors,
  fetchAccessRights,
  ConfirmationModal,
  showSuccess,
  parseISO8601Date,
  withFlipper,
} from "lcm-iot-commons";
import PropTypes from "prop-types";
import { MenuItem } from "react-bootstrap";
import {
  edgeDeviceStatus,
  edgeDeviceIconClassName,
} from "../../utils/statusUtils";
import { edgeDeviceShape } from "../../shapes/edgeDevicesShape";
import { removeEdgeDevice } from "../../api";
import { getEdgeDeviceImageUrl } from "./PicturePoolUrl";

export class EdgeDeviceItem extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnEditClick = this.handleOnEditClick.bind(this);
    this.handleOnDeleteClick = this.handleOnDeleteClick.bind(this);
    this.handleOnConfirm = this.handleOnConfirm.bind(this);
    this.handleOnModalClose = this.handleOnModalClose.bind(this);
    this.handleOnItemMenuOpen = this.handleOnItemMenuOpen.bind(this);

    this.state = { accessRights: undefined, deleting: false };
  }

  async handleOnItemMenuOpen() {
    const { intl, edgeDevice } = this.props;
    const accessRightsED = await fetchAccessRights(
      "EDM::EdgeDevice",
      edgeDevice.id,
    ).catch((errors) =>
      handleUnknownErrors(
        errors,
        intl.formatMessage({ id: "api.error.unknown" }),
      ),
    );
    this.setState({
      accessRights: accessRightsED,
    });
  }

  handleOnEditClick() {
    const { edgeDevice } = this.props;
    navigateTo(`/edge_devices/${edgeDevice.id}/edit`);
  }

  handleOnDeleteClick() {
    this.setState({ deleting: true });
  }

  async handleOnConfirm() {
    const { edgeDevice, intl, onEdgeDeviceRemove } = this.props;
    this.resetActionsState();

    removeEdgeDevice(edgeDevice.id)
      .then(() => {
        onEdgeDeviceRemove(edgeDevice);
        showSuccess(
          intl.formatMessage({ id: "edge_device.delete.successful" }),
          true,
        );
      })
      .catch((errors) =>
        handleUnknownErrors(
          errors,
          intl.formatMessage({ id: "api.error.unknown" }),
        ),
      );
  }

  handleOnModalClose() {
    this.resetActionsState();
  }

  resetActionsState() {
    this.setState({
      deleting: false,
    });
  }

  getLastSeenTextColor = (edgeDevice) =>
    edgeDeviceStatus(
      edgeDevice.status.code,
      parseISO8601Date(edgeDevice.lastSeenAt),
    ) === "critical"
      ? "lastseen-offline"
      : "";

  getEdgeDeviceConnectityString = (edgeDevice) =>
    `Connectivity: ${this.removeParentheses(edgeDevice.addOn.name)}`;

  removeParentheses(str) {
    return str.replace(/\s*\(.*?\)\s*/g, "").trim();
  }

  formatDetails(edgeDevice) {
    if (
      edgeDevice.status.code === "registered" ||
      edgeDevice.status.code === "offline"
    ) {
      return (
        <div>
          {edgeDevice.addOn?.name
            ? this.getEdgeDeviceConnectityString(edgeDevice)
            : ""}
          {edgeDevice.addOn?.name ? " " : ""}
          {edgeDevice.addOn?.name ? <br /> : ""}
          <span>
            <FormattedMessage id="label.last_seen" />
            {": "}
            {edgeDevice.lastSeenAt ? (
              <span className={this.getLastSeenTextColor(edgeDevice)}>
                <FormattedRelativeTime
                  value={new Date(edgeDevice.lastSeenAt)}
                />
              </span>
            ) : /* istanbul ignore next */ null}
          </span>
        </div>
      );
    }
    return (
      <div>
        {edgeDevice.addOn?.name
          ? this.getEdgeDeviceConnectityString(edgeDevice)
          : ""}
        {edgeDevice.addOn?.name ? " " : ""}
        {edgeDevice.addOn?.name ? <br /> : ""}
        <span className={edgeDeviceStatus(edgeDevice.status.code)} />
        {edgeDevice.status.name}
      </div>
    );
  }

  render() {
    const { edgeDevice, showItemMenu, intl, flipper } = this.props;
    const { accessRights, deleting } = this.state;
    const canEdit = accessRights ? accessRights.canUpdate : false;
    const canDelete = accessRights ? accessRights.canPermit : false;
    const hasUplinkAddOn = edgeDevice.addOn?.product_code === "uplink";
    let headerText = edgeDevice.serialNumber
      .concat(" - ")
      .concat(edgeDevice.type.name);
    if (isSomething(edgeDevice.name)) {
      headerText = `${edgeDevice.name} - ${headerText}`;
    }

    return (
      <ListItem
        id={`ed-item-${edgeDevice.serialNumber}`}
        image={
          <Picture
            width={50}
            height={50}
            src={getEdgeDeviceImageUrl(edgeDevice.type.code, 50)}
            placeholder={edgeDeviceIconClassName(edgeDevice)}
          />
        }
        title={headerText}
        status={edgeDeviceStatus(
          edgeDevice.status.code,
          parseISO8601Date(edgeDevice.lastSeenAt),
        )}
        description={this.formatDetails(edgeDevice)}
        target={url(`/edge_devices/${edgeDevice.id}`)}
        actions={
          <>
            {showItemMenu ? (
              <ItemMenu id="item-menu" onOpen={this.handleOnItemMenuOpen}>
                <MenuItem
                  id="item-edit"
                  disabled={!canEdit}
                  onSelect={this.handleOnEditClick}
                >
                  <FormattedMessage id="button.edit" />
                </MenuItem>
                {hasUplinkAddOn && flipper.edgeDeviceLateBinding ? (
                  <MenuItem
                    id="item-remove"
                    disabled={!canDelete}
                    onSelect={this.handleOnDeleteClick}
                  >
                    <FormattedMessage id="edge_device.delete" />
                  </MenuItem>
                ) : null}
              </ItemMenu>
            ) : null}
            {deleting ? (
              <ConfirmationModal
                id="confirmation-modal"
                show
                intl={intl}
                titleText={intl.formatMessage({
                  id: "edge_device.delete.modal_header",
                })}
                messageText={intl.formatMessage({
                  id: "edge_device.delete.modal_message",
                })}
                onConfirm={this.handleOnConfirm}
                onClose={this.handleOnModalClose}
              />
            ) : null}
          </>
        }
      />
    );
  }
}

EdgeDeviceItem.propTypes = {
  edgeDevice: edgeDeviceShape,
  showItemMenu: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  onEdgeDeviceRemove: PropTypes.func,
  flipper: PropTypes.shape({
    edgeDeviceLateBinding: PropTypes.bool,
  }),
};

EdgeDeviceItem.defaultProps = {
  showItemMenu: false,
  edgeDevice: undefined,
  intl: undefined,
  onEdgeDeviceRemove: () => {},
  flipper: PropTypes.shape({
    edgeDeviceLateBinding: false,
  }),
};

export default withFlipper(EdgeDeviceItem);
