import React from "react";
import { injectIntl } from "react-intl";
import { intlShape } from "../../shapes";
import ErrorPage from "./ErrorPage";
import { errorTypes } from "./ErrorTypes";

export function Forbidden({ intl }) {
  return (
    <ErrorPage
      header={intl.formatMessage({ id: "forbidden.header" })}
      details={intl.formatMessage({ id: "forbidden.details" })}
      errorType={errorTypes.forbidden}
    />
  );
}

Forbidden.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Forbidden);
