const ConnectivitySettings = {
  FIRST_MEASUREMENT: "eh.user_config.first_push_timestamp",
  MEASURING_INTERVAL: "eh.user_config.data_collection_interval",
  TRANSMISSION_INTERVAL: "eh.user_config.data_send_interval",
};

const MultipleAssetValues = {
  PRIMARY_VALUE_KEYS: "eh.pcps.value.primary_value_keys",
};

const GeneralSettings = {
  ORDER_CODE: "eh.pcps.tmp.ordercode",
  MEDIUM_TYPE: "eh.user_config.medium_type",
  SENSOR_BUILD_NUMBER: "eh.pcps.sensor_module_build_number",
};

const PreferredUnits = {
  PREFERRED_UNITS: "preferred_units",
};

const ActivationSettings = {
  SIM_SHOULD_BE_ACTIVE: "eh.user_config.sim_should_be_active",
  SHOULD_BE_ACTIVE: "eh.user_config.device_should_be_active",
  DEVICE_ACTIVE: "eh.pcps.device_active",
};

const LocalizationSettings = {
  LOCALIZATION_TYPE: "eh.pcps.localization_type",
  LONGITUDE: "longitude",
  LATITUDE: "latitude",
};

export const FullEmptyConfiguration = {
  UNIT: "eh.user_config.unit",
  EMPTY: "eh.user_config.empty",
  FULL: "eh.user_config.full",
  BLOCK_DISTANCE: "eh.user_config.block_distance",
  SENSITIVITY: "eh.user_config.sensitivity",
};

const GPSSettings = {
  GPS_SEND_INTERVAL: "eh.user_config.gps_send_interval",
  GPS_SEND_LATEST_INTERVAL: "eh.user_config.latest_gps_send_interval",
  GPS_DEV_SETTINGS: "eh.user_config.dev_show_gps",
};

const ProductSpecs = {
  CONNECTED_ASSET: "eh.pcps.connected_asset",
  FERMENTATION: "eh.pcps.fermentation",
  ASSIGNABLE_ADD_ONS: "eh.pcps.assignable_add_ons",
};
const MebakActivatedSpecs = {
  Mebak_Activated: "eh.user_config.mebak",
};
const HeartbeatSpecs = {
  DEVICE_FIRMWARE_REVISION: "eh.configuration.std_enpdevicefirmwarerevision",
  FIELDBUS_TYPE: "eh.configuration.std_fieldbustype",
  LOCKING_STATE: "eh.configuration.std_lockingstate",
  OPTIONABLE_OVERVIEW: "eh.configuration.std_swoptionenabledoverview",
};

const ValueMappingKeys = {
  PRIMARY_VALUE: "eh.user_config.value_mapping.pv",
  SECONDARY_VALUE: "eh.user_config.value_mapping.sv",
  TERTIARY_VALUE: "eh.user_config.value_mapping.tv",
  QUATERNARY_VALUE: "eh.user_config.value_mapping.qv",
};

const CalibrationKeys = {
  CALIBRATION_MEASUREMENT_DENSITY:
    "eh.user_config.asset.calibration.measurement.density",
  CALIBRATION_MEASUREMENT_VISCOSITY:
    "eh.user_config.asset.calibration.measurement.viscosity",
  CALIBRATION_MEASUREMENT_TEMPERATURE:
    "eh.user_config.asset.calibration.measurement.temperature",
  CALIBRATION_MEASUREMENT_SPEED_OF_SOUND:
    "eh.user_config.asset.calibration.measurement.speed_of_sound",
  CALIBRATION_DELTA_DENSITY: "eh.user_config.asset.calibration.delta.density",
  CALIBRATION_DELTA_VISCOSITY:
    "eh.user_config.asset.calibration.delta.viscosity",
  CALIBRATION_DELTA_TEMPERATURE:
    "eh.user_config.asset.calibration.delta.temperature",
  CALIBRATION_DELTA_SPEED_OF_SOUND:
    "eh.user_config.asset.calibration.delta.speed_of_sound",
  CALIBRATION_STATUS: "eh.user_config.asset.calibration.status",
  CALIBRATION_TIMESTAMP: "eh.user_config.asset.calibration.timestamp",
};

export default {
  ConnectivitySettings,
  MultipleAssetValues,
  MebakActivatedSpecs,
  PreferredUnits,
  GeneralSettings,
  ActivationSettings,
  LocalizationSettings,
  FullEmptyConfiguration,
  GPSSettings,
  ProductSpecs,
  HeartbeatSpecs,
  ValueMappingKeys,
  CalibrationKeys,
};
