import PropTypes from "prop-types";

const treeItem = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

treeItem.children = PropTypes.arrayOf(PropTypes.shape(treeItem));

const treeItemShape = PropTypes.shape(treeItem);

export default treeItemShape;
