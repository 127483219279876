import React, { lazy, useMemo, forwardRef, Suspense } from "react";
import PropTypes from "prop-types";
import { withConfiguration } from "lcm-iot-commons";

export const Captcha = forwardRef((props, ref) => {
  const { configuration } = props;

  const CaptchaImplementation = useMemo(
    () =>
      lazy(() =>
        configuration.captchaImplementation === "alibaba"
          ? /* istanbul ignore next */
            import("./AliCaptcha")
          : import("./GoogleCaptcha"),
      ),
    [],
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CaptchaImplementation {...props} forwardedRef={ref} />
    </Suspense>
  );
});

Captcha.propTypes = {
  configuration: PropTypes.shape({
    captchaImplementation: PropTypes.oneOf(["google", "alibaba"]),
  }).isRequired,
};

Captcha.displayName = "Captcha";

export default withConfiguration(Captcha);
