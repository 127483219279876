import React, { Suspense } from "react";
import Loader from "../components/Loader";

export const withSuspense = (Component, componentName) =>
  function LazyComponent(props) {
    // eslint-disable-next-line
    const spinner = props.loader || <Loader loading />;
    LazyComponent.displayName = componentName;

    return (
      <Suspense fallback={spinner}>
        <Component {...props} />
      </Suspense>
    );
  };

export default withSuspense;
