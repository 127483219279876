import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { intlShape } from "../../shapes";
import ActionButton from "./ActionButton";
import ConfirmationModal from "../ConfirmationModal";

function ExportActionButton(props) {
  const [showExportModal, setShowModal] = useState(false);

  const onExportClick = () => {
    setShowModal(true);
    if (props.onClick) {
      props.onClick();
    }
  };

  const onConfirmClick = () => {
    setShowModal(false);
    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  const onCloseClick = () => {
    setShowModal(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const model = props.disabled ? null : (
    <ConfirmationModal
      id="export-modal"
      show={showExportModal}
      titleText={props.modalTitle}
      messageText={props.modalMessage}
      buttonConfirmText={props.buttonConfirmText}
      buttonCancelText={props.buttonCancelText}
      showCancel={props.showCancel}
      onConfirm={onConfirmClick}
      onClose={onCloseClick}
    />
  );

  return (
    <span>
      <ActionButton
        {...props}
        icon="icon-eh-save"
        defaultLabel={props.intl.formatMessage({ id: "button.export" })}
        onClick={onExportClick}
      />
      {model}
    </span>
  );
}

ExportActionButton.propTypes = {
  intl: intlShape.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalMessage: PropTypes.node,
  onClick: PropTypes.func,
  buttonConfirmText: PropTypes.string,
  buttonCancelText: PropTypes.string,
  showCancel: PropTypes.bool,
};

export default injectIntl(ExportActionButton);
