import { camelCase } from "lodash-es";
import { useApi } from "./useApi";

/**
 * @param {import('@tanstack/react-query').UseQueryOptions} options - Additional options for the query.
 * @returns The result of the query.
 */
export const useFlipperQuery = (options) => {
  const api = useApi();

  return api.get.useQuery("/../flipper/api/features", null, {
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    select: (data) =>
      data.features.reduce((features, feature) => {
        const newFeatures = { ...features };
        newFeatures[camelCase(feature.key)] = feature.state === "on";
        return newFeatures;
      }, {}),
    ...options,
  });
};
