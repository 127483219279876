import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { MenuItem } from "react-bootstrap";
import {
  ItemMenu,
  ListItem,
  ConfirmationModal,
  withApi,
  withBrowser,
  withNotifier,
  apiShape,
  browserShape,
  intlShape,
  notifierShape,
  concatValues,
} from "lcm-iot-commons";

export function ResellerCustomerItem({
  customer,
  api,
  browser,
  intl,
  notifier,
  removeCustomer,
}) {
  const [deleting, setDeleting] = useState(false);
  const [accessRights, setAccessRights] = useState();

  const handleConfirm = async () => {
    try {
      await api.delete(`/customers/${customer.id}`);
      setDeleting(false);
      if (removeCustomer) await removeCustomer(customer.id);
      notifier.showSuccess(
        intl.formatMessage({ id: "reseller_customer.delete.notification" }),
      );
    } catch (error) {
      setDeleting(false);
      notifier.showError(api.translateError(error));
    }
  };

  const modalDeleting = deleting ? (
    <ConfirmationModal
      id="customer-item-confirm"
      show
      titleText={intl.formatMessage({
        id: "reseller_customer.delete.modal_title",
      })}
      messageText={intl.formatMessage({
        id: "reseller_customer.delete.modal_message",
      })}
      onConfirm={() => handleConfirm()}
      onClose={() => setDeleting(false)}
    />
  ) : null;

  const itemMenuOpened = async () => {
    /* istanbul ignore next */
    if (!accessRights) {
      try {
        const apiResponse = await api.get("/users/current/access_rights", {
          permitable_type: "Customer",
          permitable_id: customer.id,
        });
        setAccessRights(apiResponse);
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    }
  };

  const actions = (
    <div id="customer-action-items">
      <ItemMenu onOpen={itemMenuOpened}>
        <>
          <MenuItem
            id="item-edit"
            onSelect={() =>
              browser.navigateTo(`/reseller/customers/${customer.id}/edit`)
            }
            disabled={!accessRights?.can_update}
          >
            {intl.formatMessage({ id: "button.edit" })}
          </MenuItem>
          <MenuItem
            id="item-delete"
            onSelect={() => setDeleting(true)}
            disabled={!accessRights?.can_delete}
          >
            {intl.formatMessage({ id: "button.delete" })}
          </MenuItem>
        </>
      </ItemMenu>
      {modalDeleting}
    </div>
  );

  const combineDescription = () => {
    let description = "";
    if (customer.address) {
      const contactName = concatValues(
        [customer.address?.first_name, customer.address?.last_name],
        " ",
      );
      if (contactName.length !== 0) {
        description = concatValues([contactName, customer.address.city], " - ");
      } else {
        description = customer.address.city;
      }
    }
    return description;
  };

  return (
    <ListItem
      id={`customer-item-${customer.id}`}
      icon="lcm-iot-icon-user"
      title={concatValues([`${customer.number}`, customer.name], " - ")}
      description={combineDescription()}
      onClick={() => browser.navigateTo(`/reseller/customers/${customer.id}`)}
      actions={actions}
      showItemMenu
    />
  );
}

ResellerCustomerItem.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  removeCustomer: PropTypes.func,
  customer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    number: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }).isRequired,
};

ResellerCustomerItem.defaultProps = {
  removeCustomer: null,
};

export default injectIntl(
  withBrowser(withApi(withNotifier(ResellerCustomerItem))),
);
