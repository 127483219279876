import React from "react";
import Tippy from "@tippy.js/react";
import PropTypes from "prop-types";
import { withWindowSize } from "../context/WindowSizeContext";

export function PopOver({
  content,
  children,
  windowSize,
  windowWidth,
  interactive,
}) {
  if (!windowSize) {
    return false;
  }

  return (
    <Tippy
      interactive={interactive}
      onTrigger={(_, event) => {
        event.stopPropagation();
      }}
      onUntrigger={
        /* istanbul ignore next */
        (_, event) => {
          event.stopPropagation();
        }
      }
      content={content}
      trigger="mouseenter click"
      placement={windowSize === "phone" ? "top" : "right"}
      className="pop-over"
      distance={windowSize === "phone" ? 12 : 16}
      offset={windowSize === "phone" ? "00,0" : "350,0"}
      maxWidth={windowSize === "phone" ? windowWidth - 20 : 500}
      appendTo={() => document.body}
    >
      {children}
    </Tippy>
  );
}

PopOver.propTypes = {
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  windowSize: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  interactive: PropTypes.bool,
};

export default withWindowSize(PopOver);
