import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useApi } from "../../../hooks";
import Loader from "../../Loader";

export default function ProductCodeDetailsTable({ productVariantId }) {
  const api = useApi();
  const { data, isLoading } = api.get.useQuery(
    `/product_variants/${productVariantId}/configurations`,
    { include_total_count: false },
    { staleTime: Infinity },
  );
  const filteredConfigurations = data?.configurations?.filter(
    (c) => c.options?.length > 0,
  );

  if (isLoading) return <Loader loading />;
  if (filteredConfigurations?.length === 0)
    return <FormattedMessage id="product_code_details.no_details" />;

  return (
    <div>
      {filteredConfigurations?.map((c) =>
        c.options.map((o) => (
          <div className="product-code-details-item" key={o.id}>
            <div className="product-code-details-item-title">
              {o.option_key}
            </div>
            <div className="product-code-details-item-content">
              <div className="product-option-item-wrapper">
                <div className="product-option-item-title">{c.description}</div>
                <div className="product-option-item-description">
                  {o.description}
                </div>
              </div>
            </div>
          </div>
        )),
      )}
    </div>
  );
}

ProductCodeDetailsTable.propTypes = {
  productVariantId: PropTypes.number.isRequired,
};
