import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik } from "formik";
import { extractNodes } from "../../extractors";
import { NotFoundError } from "../../api";
import { SubmitButton, ObjectsTypeahead } from "../Form";
import { ObjectsType } from "../../constants";
import { accessRightsShape, intlShape } from "../../shapes";
import { sortBy } from "../../utils";
import {
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  withRules,
  rulesShape,
} from "../../context";
import List from "../List/List";
import NodeItem from "../Nodes/NodeItem";
import Hint from "../Hint";

export function NodesAssignList(props) {
  const { intl, api, notifier, accessRights, rules, object } = props;

  const objectTypePlural = `${object?.itemType}s`;
  const objectId = object?.id;

  const [nodes, setNodes] = React.useState();

  const parentInstrumentation = objectTypePlural.includes("instrumentations")
    ? { id: objectId }
    : null;
  const parentAsset = objectTypePlural.includes("assets")
    ? { id: objectId }
    : null;

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const responseNodes = await api.get(
          `/${objectTypePlural}/${objectId}/nodes`,
          { include: "parent,type,type.parent,worst_asset_status" },
        );
        const loadedNodes = sortBy(extractNodes(responseNodes), "name");
        setNodes(loadedNodes);
      } catch (error) {
        if (!(error instanceof NotFoundError)) {
          notifier.showError(api.translateError(error));
        }
      }
    };
    if (object) {
      loadData();
    }
  }, [objectId]);

  const handleOnNodeRemoved = (node) => {
    setNodes(nodes.filter((n) => n.id !== node.id));
  };

  const assignNode = async (values, { resetForm }) => {
    const payload = { nodes: [{ id: values.node.id }] };
    try {
      await api.post(`/${objectTypePlural}/${objectId}/nodes`, payload);
      setNodes(sortBy(nodes.concat([{ ...values.node }]), "name"));
      notifier.showSuccess(
        intl.formatMessage({
          id: rules.node(values.node).get("assignNotificationKey"),
        }),
      );
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        notifier.showError(api.translateError(error));
      }
    }
    resetForm({});
  };

  const renderNodeAssignForm = (formProps) => {
    const { handleSubmit, isSubmitting, values } = formProps;
    return (
      <form onSubmit={handleSubmit} noValidate>
        <ObjectsTypeahead
          {...formProps}
          id="node-typeahead"
          name="node"
          label={intl.formatMessage({ id: "node.actions.assign" })}
          placeholder={intl.formatMessage({ id: "label.name" })}
          objectsToIgnore={nodes}
          objectsType={ObjectsType.Nodes}
        />
        <div className="btn-group">
          <SubmitButton
            id="assign-node-button"
            disabled={!values.node}
            fetching={isSubmitting}
            text={intl.formatMessage({ id: "button.assign" })}
          />
        </div>
      </form>
    );
  };

  return objectId && nodes ? (
    <div id="node-list">
      <h2>
        <FormattedMessage id="nodes_list.header" />
      </h2>
      {accessRights.canPermit ? (
        <Formik
          id="assign-node-formik"
          onSubmit={assignNode}
          render={renderNodeAssignForm}
        />
      ) : null}
      {nodes.length > 0 ? (
        <List id="nodes_assign_list">
          {nodes.map((node) => (
            <NodeItem
              key={node.id}
              node={node}
              parentNode={node}
              parentInstrumentation={parentInstrumentation}
              parentAsset={parentAsset}
              onNodeRemoved={handleOnNodeRemoved}
              showItemMenu
              fromDetailsPage
              assignedObjectAccessRights={accessRights}
            />
          ))}
        </List>
      ) : (
        <Hint
          id="hint-no-nodes-assigned"
          details={intl.formatMessage({ id: "node_list.no_nodes_assign" })}
        />
      )}
    </div>
  ) : null;
}

NodesAssignList.propTypes = {
  api: apiShape.isRequired,
  accessRights: accessRightsShape,
  intl: intlShape.isRequired,
  rules: rulesShape,
  object: PropTypes.shape({
    id: PropTypes.number,
    itemType: PropTypes.oneOf(["asset", "instrumentation"]),
  }), // .isRequired, throws an stupid error during loading..
  notifier: notifierShape.isRequired,
};
export default injectIntl(withNotifier(withApi(withRules(NodesAssignList))));
