import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useApi } from "../../../hooks";
import { Button, ButtonGroup } from "../../Form";
import { assetShape, documentsShape, nodeShape } from "../../../shapes";
import {
  addSortedSuccessorCategories,
  getDocumentLanguages,
} from "../../../utils";
import AllOrMultipleSelect from "../../AllOrMultipleSelect";

function CategoriesSelectionStep({
  setStepsState,
  stepsState,
  onClose,
  handleNext,
  includedDocuments = [],
  object,
}) {
  const api = useApi();
  const intl = useIntl();
  const { data: documentCategories } = api.getAll.useQuery(
    "/document/categories",
    { include: "tenant" },
  );
  let categories = documentCategories?.categories
    ? [...documentCategories.categories]
    : undefined;
  if (categories?.length > 0 && includedDocuments?.length > 0) {
    categories = addSortedSuccessorCategories(
      categories,
      includedDocuments,
      intl.formatMessage({ id: "export_files_dialog.no_categories" }),
    );
  }

  const handleSelectionSwitch = (value) =>
    setStepsState({
      ...stepsState,
      categoriesSelectionStep: {
        ...stepsState.categoriesSelectionStep,
        selectedSwitch: value,
      },
    });

  const handleSpecificSelectionChange = (newSelectedItems) => {
    const languages = getDocumentLanguages(
      includedDocuments.length ? includedDocuments : categories,
      newSelectedItems,
    );
    setStepsState((prevState) => ({
      ...prevState,
      categoriesSelectionStep: {
        ...stepsState.categoriesSelectionStep,
        selectedItems: newSelectedItems,
      },
      languagesSelectionStep: {
        ...stepsState.languagesSelectionStep,
        ...(object.asset && {
          selectedItems: stepsState.languagesSelectionStep.selectedItems.filter(
            (lang) => languages.includes(lang.id),
          ),
        }),
      },
    }));
  };

  return (
    <>
      <Modal.Body>
        <AllOrMultipleSelect
          title={intl.formatMessage({
            id: "export_files_dialog.file_categories.title",
          })}
          itemKey="categories"
          allItems={categories}
          handleSpecificSelectionChange={handleSpecificSelectionChange}
          handleSelectionSwitch={handleSelectionSwitch}
          values={stepsState.categoriesSelectionStep}
          withTreeView
          includeNoItems={includedDocuments.length === 0}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button
            text={intl.formatMessage({ id: "button.close" })}
            onClick={onClose}
          />
          <Button
            hero
            text={intl.formatMessage({ id: "button.next" })}
            onClick={handleNext}
            disabled={
              stepsState.categoriesSelectionStep.selectedItems.length === 0 &&
              stepsState.categoriesSelectionStep.selectedSwitch ===
                "specific_selection"
            }
          />
        </ButtonGroup>
      </Modal.Footer>
    </>
  );
}

CategoriesSelectionStep.propTypes = {
  stepsState: PropTypes.shape({
    categoriesSelectionStep: PropTypes.shape({
      selectedSwitch: PropTypes.string,
      selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
          ]).isRequired,
          name: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
        }),
      ),
    }),
    languagesSelectionStep: PropTypes.shape({
      selectedSwitch: PropTypes.string,
      selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
          ]).isRequired,
          name: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
        }),
      ),
    }),
  }).isRequired,
  setStepsState: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleNext: PropTypes.func,
  includedDocuments: documentsShape,
  object: PropTypes.oneOfType([
    PropTypes.shape({ asset: assetShape }),
    PropTypes.shape({ node: nodeShape }),
  ]).isRequired,
};

export default CategoriesSelectionStep;
