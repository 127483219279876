import { isNotEmpty } from "lcm-iot-commons/client/lib/utils/langUtils";
import { BadRequestError } from "lcm-iot-commons";

class DocumentFormLogic {
  #api;

  #match;

  #notifier;

  #intl;

  #browser;

  #addMode;

  #createMode;

  #initialValues;

  constructor(
    api,
    notifier,
    intl,
    browser,
    match,
    addMode,
    createMode,
    initialValues,
  ) {
    this.#match = match;
    this.#addMode = addMode;
    this.#createMode = createMode;
    this.#api = api;
    this.#notifier = notifier;
    this.#intl = intl;
    this.#browser = browser;
    this.#initialValues = initialValues;
  }

  #createDocument = async (parameters) => {
    const response = await this.#api.post("/documents", parameters);
    return response.id;
  };

  #deleteDocument = async (documentId) =>
    this.#api.delete(`/documents/${documentId}`);

  #addCategoryToDocument = async (categoryId, documentId) =>
    this.#api.post(`/documents/${documentId}/categories`, {
      categories: [{ id: categoryId }],
    });

  #addAttToDocument = async (
    file,
    documentId,
    language,
    version,
    contentDate,
  ) => {
    let date = {};
    let contentVersion = {};
    if (contentDate) {
      date = { content_date: contentDate };
    }
    if (version) {
      contentVersion = { content_version: version };
    }
    const params = {
      ...date,
      ...contentVersion,
      document_id: documentId,
      languages: [language.code],
      file,
    };
    return this.#api.upload("/attachments", params);
  };

  #addDocumentToModel = async (documentId) =>
    this.#api.post(
      `/${this.#match.params.model}/${this.#match.params.id}/documents`,
      {
        documents: [{ id: documentId }],
      },
    );

  #submitData = async (values, actions) => {
    const attachment = values.fileUpload.acceptedFiles[0];
    const { language, version, contentDate } = values;
    let { documentId } = this.#match.params;
    if (this.#createMode) {
      const payload = {
        name: values.name,
        valid_from: isNotEmpty(values.validFrom) ? values.validFrom : null,
        valid_until: isNotEmpty(values.validUntil) ? values.validUntil : null,
        document_version: values.version,
      };
      documentId = await this.#createDocument(payload);
      if (values.category?.id) {
        await this.#addCategoryToDocument(values.category.id, documentId);
      }
    }

    try {
      await this.#addAttToDocument(
        attachment,
        documentId,
        language,
        version,
        contentDate,
      );
      if (this.#createMode) {
        await this.#addDocumentToModel(documentId);
      }
    } catch (error) {
      if (this.#createMode) {
        await this.#deleteDocument(documentId);
      }
      throw error;
    }

    actions.setSubmitting(false);
    this.#notifier.showSuccess(
      this.#intl.formatMessage({ id: "document_create.success_notification" }),
    );
    this.#browser.navigateTo(
      `/${this.#match.params.model}/${this.#match.params.id}`,
    );
  };

  handleOnSubmit = async (values, actions) => {
    try {
      const { language, version } = values;

      if (
        this.#addMode &&
        this.#initialValues.attachments?.find(
          (item) =>
            item.languages?.includes(language.code) &&
            item.content_version === version,
        )
      ) {
        actions.setStatus("duplicated_attachment");
      } else {
        await this.#submitData(values, actions);
      }
    } catch (error) {
      const formErrors = {};
      if (
        error instanceof BadRequestError &&
        error.contains("spoofed_media_type", "file")
      ) {
        formErrors.fileUpload = this.#intl.formatMessage({
          id: "document_create.dropzone.rejection.illegal",
        });
      } else if (
        error instanceof BadRequestError &&
        error.contains("invalid_input")
      ) {
        formErrors.fileUpload = this.#intl.formatMessage({
          id: "document_create.dropzone.rejection.security",
        });
      } else {
        this.#notifier.showError(this.#api.translateError(error));
      }
      actions.setErrors(formErrors);
    }
    actions.setSubmitting(false);
  };
}

export default DocumentFormLogic;
