import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Row,
  Column,
  Container,
  Loader,
  ActionBar,
  DetailsItem,
  withApi,
  withUser,
  withBrowser,
  withNotifier,
  apiShape,
  browserShape,
  notifierShape,
  ForbiddenError,
  sortBy,
} from "lcm-iot-commons";

import AssignableUserList from "lcm-iot-commons/client/lib/components/Users/AssignableUserList";
import { capitalize } from "lodash-es";

export function UserRoleDetails(props) {
  const { api, browser, notifier } = props;
  const [userRole, setUserrole] = useState();
  const [users, setUsers] = useState();

  const loadData = async () => {
    try {
      const filter = { name: "reseller" };
      const response = await api.get("/userroles", filter);
      const role = response.userroles[0];
      setUserrole(role);

      const sortedUsers = await api.getAll(`/userroles/${role.id}/users`, {
        order_by: "last_name",
      });
      setUsers(sortedUsers.users);
    } catch (error) {
      if (error instanceof ForbiddenError) {
        browser.navigateTo("/404");
      } else {
        notifier.showError(api.translateError(error));
      }
    }
  };
  React.useEffect(() => {
    loadData();
  }, []);

  const assignUser = async (user) => {
    await api.post(`/userroles/${userRole.id}/users`, {
      users: [{ id: user.id }],
    });
    setUsers(sortBy(users.concat([{ ...{ ...user } }]), "first_name"));
  };

  const unassignUser = async (user) => {
    await api.delete(`/userroles/${userRole.id}/users`, {
      users: [{ id: user.id }],
    });
    setUsers(users.filter((u) => u.id !== user.id));
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <h1 id="details-header">
              <FormattedMessage id="user_role_details.header" />
            </h1>
          </ActionBar>
          {userRole && (
            <>
              <DetailsItem
                id="user-role-name"
                translationKey="label.name"
                value={capitalize(userRole.name)}
              />
              <DetailsItem
                id="user-role-description"
                translationKey="label.description"
                value={userRole.description}
              />
            </>
          )}
          {users && (
            <div id="user-list">
              <h2>
                <FormattedMessage
                  id="user_role_details.users"
                  values={{ role: userRole.name }}
                />
              </h2>
              <AssignableUserList
                showUserEmail
                users={users}
                canUpdate
                onAssignUser={assignUser}
                onUnassignUser={unassignUser}
              />
            </div>
          )}
          <Loader id="user-role-loader" loading={!userRole || !users} />
        </Column>
      </Row>
    </Container>
  );
}

UserRoleDetails.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default withApi(withNotifier(withUser(withBrowser(UserRoleDetails))));
