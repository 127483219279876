import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import {
  ActionBar,
  ActionBarButtons,
  ButtonGroup,
  Clue,
  CreateActionButton,
  Container,
  List,
  Loader,
  isNothing,
  url,
  withApi,
  withBrowser,
  withNotifier,
  withUser,
  apiShape,
  browserShape,
  intlShape,
  notifierShape,
  userShape,
} from "lcm-iot-commons";
import TaxItem from "./TaxItem";

export function Taxes({ api, browser, intl, notifier, user }) {
  const [state, setState] = useState({
    fetching: false,
    taxes: undefined,
    itemDeleted: false,
    nextPageUrl: undefined,
  });

  const loadTaxes = async () => {
    try {
      setState({
        fetching: true,
        taxes: [],
      });
      const response = await api.getAll("/taxes");
      setState({
        fetching: false,
        taxes: response.taxes,
      });
    } catch (error) {
      setState({ fetching: false });
      notifier.showError(api.translateError(error));
    }
  };

  React.useEffect(() => {
    loadTaxes();
  }, []);

  const controllingDirector = user?.userRoles?.find(
    (ur) => ur.name === "controlling_director",
  );
  if (user && isNothing(controllingDirector)) {
    browser.navigateTo("/403");
  }

  const taxesCount = state.taxes?.length > 0 ? state.taxes.length : null;

  const badge = state.fetching ? (
    <span className="loading">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  ) : (
    taxesCount
  );

  const taxesList =
    state.taxes?.length > 0 ? (
      <List id="taxes-list">
        <InfiniteScroll initialLoad loadMore={loadTaxes} hasMore={false}>
          {state.taxes.map((tax) => (
            <TaxItem key={tax.id} tax={tax} loadTaxes={loadTaxes} />
          ))}
        </InfiniteScroll>
      </List>
    ) : (
      <Clue
        id="no-taxes-found"
        title={intl.formatMessage({ id: "taxes.no_taxes_found" })}
        details={intl.formatMessage({ id: "taxes.no_taxes_found.ask_create" })}
        className="notification"
        highlighted
      >
        <ButtonGroup>
          <Link className="btn btn-primary" to={url("/taxes/create")}>
            {intl.formatMessage({ id: "taxes.create_tax" })}
          </Link>
        </ButtonGroup>
      </Clue>
    );

  return user ? (
    <Loader loading={state.fetching}>
      <Container>
        <ActionBar>
          <h1 id="taxes-header">
            {intl.formatMessage({ id: "taxes.header" })}
            <span id="taxes-count" className="badge">
              {badge}
            </span>
          </h1>
          <ActionBarButtons>
            <CreateActionButton
              id="create-tax-button"
              disabled={isNothing(controllingDirector)}
              target="/taxes/create"
            />
          </ActionBarButtons>
        </ActionBar>
        {!state.fetching ? taxesList : null}
      </Container>
    </Loader>
  ) : null;
}

Taxes.propTypes = {
  api: apiShape.isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  user: userShape,
};

Taxes.defaultProps = {
  user: undefined,
};

export default injectIntl(withUser(withApi(withBrowser(withNotifier(Taxes)))));
