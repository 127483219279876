/* eslint-disable no-param-reassign */
import PermissionType from "../constants/PermissionType";

export function createPermissionKey(
  permitableType,
  permitableId,
  assignableType,
  assignableId,
  permissionType,
) {
  return `${permitableType}_${permitableId}_${assignableType}_${assignableId}_${permissionType}`;
}

export function createPermissionsKey(assignableType, assignableId) {
  return `${assignableType}_${assignableId}`;
}

export function setUserOrUserGroupPermission(permissions, permission) {
  switch (permission.permissionType) {
    case PermissionType.READ:
      permissions.read = permission.id;
      break;
    case PermissionType.UPDATE:
      permissions.update = permission.id;
      break;
    case PermissionType.DELETE:
      permissions.delete = permission.id;
      break;
    case PermissionType.PERMIT:
      permissions.permit = permission.id;
      break;
    default:
      throw new Error();
  }
}

export function removeUserOrUserGroupPermission(permissions, permission) {
  switch (permission.permissionType) {
    case PermissionType.READ:
      permissions.read = null;
      break;
    case PermissionType.UPDATE:
      permissions.update = null;
      break;
    case PermissionType.DELETE:
      permissions.delete = null;
      break;
    case PermissionType.PERMIT:
      permissions.permit = null;
      break;
    default:
      throw new Error();
  }
}

export function hasReadPermission(permissions, assignableType, assignableId) {
  if (assignableType === "User") {
    const userPermissions = permissions.usersPermissions.find(
      (p) => p.id === assignableId,
    );
    return userPermissions && userPermissions.read > 0;
  } // if (permission.assignableType === 'Usergroup') {
  const userGroupPermissions = permissions.userGroupsPermissions.find(
    (p) => p.id === assignableId,
  );
  return userGroupPermissions && userGroupPermissions.read > 0;
}
