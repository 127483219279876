import PropTypes from "prop-types";
import React, { useState } from "react";
import { Formik } from "formik";

import {
  intlShape,
  apiShape,
  withApi,
  SwitchInput,
  Details,
  notifierShape,
  withNotifier,
  withIntl,
  userShape,
  withUser,
  ContextualHelp,
} from "lcm-iot-commons";
import { FormattedMessage } from "react-intl";

export function EdgeNotificationHandler({ notifier, api, intl, user, edgeId }) {
  const [state, setState] = useState({ offlineNotification: false });

  const baseKey = "eh.user_config.user_notifications.";
  const keySuffixes = {
    NOTIFICATION_VALUE: "status_offline",
  };

  const loadNotificationSpecifications = async () => {
    try {
      const notificationSpecifications = await api.get(
        `/edm/edge_devices/${edgeId}/specifications`,
        { key: `${baseKey}*` },
      );
      const currentState = {};

      if (
        !notificationSpecifications[
          `${baseKey}${keySuffixes.NOTIFICATION_VALUE}`
        ]
      ) {
        const params = {};
        params[`${baseKey}${keySuffixes.NOTIFICATION_VALUE}`] = { value: "" };
        await api.patch(`/edm/edge_devices/${edgeId}/specifications`, params);
        currentState.offlineNotification = false;
      } else {
        currentState.offlineNotification = notificationSpecifications[
          `${baseKey}${keySuffixes.NOTIFICATION_VALUE}`
        ]?.value
          .split(";")
          .includes(user.id.toString());
      }

      setState(currentState);
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  React.useEffect(() => {
    loadNotificationSpecifications();
  }, []);

  const saveDemoDataActivationState = async () => {
    try {
      const notificationSpecifications = await api.get(
        `/edm/edge_devices/${edgeId}/specifications`,
        { key: `${baseKey}*` },
      );
      const notifiedUsers =
        notificationSpecifications[
          `${baseKey}${keySuffixes.NOTIFICATION_VALUE}`
        ]?.value.split(";");
      const userId = user.id.toString();
      if (state.offlineNotification) {
        const userIndex = notifiedUsers.indexOf(userId);
        if (userIndex > -1) {
          const params = {};
          notifiedUsers.splice(userIndex, 1);
          params[`${baseKey}${keySuffixes.NOTIFICATION_VALUE}`] = {
            value: notifiedUsers.join(";"),
          };
          await api.patch(`/edm/edge_devices/${edgeId}/specifications`, params);
        }
      } else if (!notifiedUsers.includes(userId)) {
        const params = {};
        if (notifiedUsers[0] === "") {
          params[`${baseKey}${keySuffixes.NOTIFICATION_VALUE}`] = {
            value: userId,
          };
        } else {
          notifiedUsers.push(userId);
          params[`${baseKey}${keySuffixes.NOTIFICATION_VALUE}`] = {
            value: notifiedUsers.join(";"),
          };
        }
        await api.patch(`/edm/edge_devices/${edgeId}/specifications`, params);
      }
      setState({ ...state, offlineNotification: !state.offlineNotification });
      notifier.showSuccess(
        intl.formatMessage({
          id: "user_profile.notifications_success_notification",
        }),
      );
    } catch (apiErrors) {
      notifier.showError(api.translateError(apiErrors));
    }
  };
  const renderForm = (formProps) => (
    <>
      <h3 id="notification-switch-section">
        <FormattedMessage id="label.notifications" />
        <ContextualHelp
          title={intl.formatMessage({
            id: "edge_device_edit.user_notification.offline.hint_heading",
          })}
          interactive
        >
          <p>
            <FormattedMessage id="edge_device_edit.user_notification.offline.hint_text" />
            <FormattedMessage id="edge_device_edit.user_notification.offline.hint_text2" />
          </p>
        </ContextualHelp>
      </h3>
      <Details>
        <form id="demo-data-form" noValidate>
          <SwitchInput
            {...formProps}
            id="demo-data-switch-input"
            values={{ "demo-data-switch-input": state.offlineNotification }}
            handleChange={saveDemoDataActivationState}
            name="demo-data-switch-input"
          />
        </form>
      </Details>
    </>
  );

  return <Formik id="demo-data-formik" render={renderForm} />;
}

EdgeNotificationHandler.propTypes = {
  intl: intlShape.isRequired,
  user: userShape.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  edgeId: PropTypes.number.isRequired,
};

export default withNotifier(
  withUser(withApi(withIntl(EdgeNotificationHandler))),
);
