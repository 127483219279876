import React from "react";
import PropTypes from "prop-types";

import {
  List,
  ListItem,
  ActionBar,
  ActionBarButtons,
  CreateActionButton,
  Picture,
  concatValues,
  withBrowser,
  browserShape,
  intlShape,
  withAccessRights,
  accessRightsShape,
  withIntl,
} from "lcm-iot-commons";

import {
  gatewayIconClassName,
  statusBubbleFieldGateway,
} from "../../utils/statusUtils";
import { getFieldGatewayImageUrl } from "./PicturePoolUrl";

export function FieldGateways({
  intl,
  browser,
  accessRights,
  edgeDevice,
  fieldGateways,
}) {
  return (
    <div>
      <ActionBar>
        <h2 id="field-gateways-header">
          {intl.formatMessage({ id: "field_gateways.header" })}
        </h2>
        <ActionBarButtons>
          <CreateActionButton
            id="create-field-gateway-button"
            disabled={!accessRights.canUpdate}
            target={`/edge_devices/${edgeDevice.id}/field_gateways/create`}
          />
        </ActionBarButtons>
      </ActionBar>
      <List id="field-gateways-list">
        {fieldGateways.map((fieldGateway) => (
          <ListItem
            id={`field-gateway-list-item-${fieldGateway.id}`}
            key={fieldGateway.id}
            title={concatValues(
              [
                `${fieldGateway.name} ${fieldGateway.ip_address}`,
                fieldGateway.type.name,
              ],
              " - ",
            )}
            value={fieldGateway.description}
            image={
              <Picture
                width={50}
                height={50}
                src={getFieldGatewayImageUrl(fieldGateway.type.code, 50)}
                placeholder={gatewayIconClassName()}
              />
            }
            status={statusBubbleFieldGateway(
              fieldGateway.status.code,
              edgeDevice,
            )}
            onClick={() =>
              browser.navigateTo(
                `/edge_devices/${edgeDevice.id}/field_gateways/${fieldGateway.id}`,
              )
            }
            showItemMenu
          />
        ))}
      </List>
    </div>
  );
}

FieldGateways.propTypes = {
  fieldGateways: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      ip_address: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        name: PropTypes.string,
      }),
      status: PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  browser: browserShape.isRequired,
  intl: intlShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  edgeDevice: PropTypes.shape({
    id: PropTypes.number,
    lastSeenAt: PropTypes.number,
  }).isRequired,
};

export default withIntl(
  withBrowser(withAccessRights(FieldGateways, "EDM::EdgeDevice")),
);
