import ApiClientError from "./ApiClientError";

class ConnectionError extends ApiClientError {
  constructor(request, response, cause) {
    super(
      "ConnectionError",
      undefined,
      "Internet connection interrupted or server closed connection.",
      request,
      response,
      cause,
    );
  }
}

export default ConnectionError;
