import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik } from "formik";
import { Button, Form, SubmitButton, TextArea } from "../Form";

import { companyShape, intlShape } from "../../shapes";

import { isSomething, htmlFormat, htmlLink } from "../../utils";
import {
  apiShape,
  notifierShape,
  trackingShape,
  withApi,
  withNotifier,
  withTracking,
} from "../../context";
import { apiErrorsContain } from "../../api";
import { ContextualHelp } from "../Contextuals";
import CONFIGURATION from "../../configuration";

export function AssetPermissionModal({
  intl,
  api,
  serialNumber,
  notifier,
  manufacturer,
  onClose,
  tracking,
}) {
  const onSubmit = async (values, actions) => {
    const requestMessage = isSomething(values.message) ? values.message : null;
    tracking.trackEvent("PermissionRequest-AssetPermissionModal-send", {
      sn: serialNumber,
    });

    api
      .post("/permission_requests/", {
        asset: {
          serial_number: serialNumber,
          manufacturer: { id: manufacturer.id },
        },
        request_message: requestMessage,
      })
      .then(() => {
        actions.setSubmitting(false);
        notifier.showSuccess(
          intl.formatMessage({
            id: "permission_request_create.success_notification",
          }),
        );
        onClose();
      })
      .catch((apiErrors) => {
        actions.setSubmitting(false);
        if (apiErrorsContain(apiErrors.errors, "taken")) {
          notifier.showError(
            intl.formatMessage({ id: "api.error.permission_request.taken" }),
          );
          onClose();
        } else if (apiErrorsContain(apiErrors.errors, "no_owner")) {
          notifier.showError(
            intl.formatMessage({ id: "api.error.permission_request.no_owner" }),
          );
          onClose();
        } else if (
          apiErrorsContain(apiErrors.errors, "not_found_no_permission")
        ) {
          notifier.showWarning(
            intl.formatMessage({
              id: "api.error.permission_request.asset_gone",
            }),
          );
          onClose();
        } else {
          notifier.showError(api.translateError(apiErrors.errors));
        }
      });
  };

  const onShow = () => {
    tracking.trackEvent("PermissionRequest-AssetPermissionModal-seen", {
      sn: serialNumber,
    });
  };

  const cancelPermissionRequest = () => {
    tracking.trackEvent("PermissionRequest-AssetPermissionModal-cancel");
    onClose();
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;

    return (
      <Form {...formProps}>
        <FormattedMessage
          id="ask_permissions.asset.modal_message"
          values={{
            ...htmlFormat,
            serialNumber,
          }}
        />
        <div className="space-after" />
        <TextArea
          {...formProps}
          name="message"
          id="ask-message"
          label={intl.formatMessage({ id: "label.message" })}
          placeholder={intl.formatMessage({
            id: "asset.access.request.message",
          })}
          autoFocus
        />
        <div className="btn-group justify-right">
          <SubmitButton
            id="button-permision-request-send"
            fetching={isSubmitting}
            text={intl.formatMessage({ id: "button.send" })}
          />
          <Button
            id="button-permision-request-cancel"
            onClick={cancelPermissionRequest}
            disabled={isSubmitting}
            text={intl.formatMessage({ id: "button.cancel" })}
          />
        </div>
      </Form>
    );
  };

  return (
    <Modal
      show
      restoreFocus={false}
      onHide={onClose}
      onShow={onShow}
      bsSize="large"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title>
          {intl.formatMessage({ id: "ask_permissions.asset.modal_title" })}
          <ContextualHelp
            title={intl.formatMessage({ id: "info.asset.modal_title" })}
            interactive
          >
            <p>
              <FormattedMessage
                id="user_profile.demo_data.description"
                values={{
                  ...htmlFormat,
                  a: htmlLink({
                    href: CONFIGURATION.HELP_URL_DIGITAL_TWINS,
                    target: "_blank",
                  }),
                }}
              />
            </p>
          </ContextualHelp>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <Formik onSubmit={onSubmit} render={renderForm} />
      </Modal.Body>
    </Modal>
  );
}

AssetPermissionModal.propTypes = {
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  onClose: PropTypes.func,
  serialNumber: PropTypes.string,
  manufacturer: companyShape,
  tracking: trackingShape.isRequired,
};

export default injectIntl(
  withTracking(withNotifier(withApi(AssetPermissionModal))),
);
