import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { intlShape } from "../../shapes";

import ConfirmationModal from "../ConfirmationModal";

import ActionButton from "./ActionButton";

export class DeleteActionButton extends Component {
  static propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    modalTitle: PropTypes.string,
    modalMessage: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.onConfirm = this.onConfirm.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = {
      showModal: false,
    };
  }

  onClick() {
    this.setState({ showModal: true });
  }

  onConfirm() {
    this.setState({ showModal: false });
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  onClose() {
    this.setState({ showModal: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const { id, label, intl, modalTitle, modalMessage, disabled } = this.props;

    const modal = disabled ? null : (
      <ConfirmationModal
        id="delete-modal"
        show={this.state.showModal}
        titleText={modalTitle}
        messageText={modalMessage}
        onConfirm={this.onConfirm}
        onClose={this.onClose}
      />
    );

    return (
      <span>
        <ActionButton
          id={id}
          label={label}
          defaultLabel={intl.formatMessage({ id: "button.delete" })}
          icon="icon-eh-delete"
          onClick={this.onClick}
          disabled={disabled}
        />
        {modal}
      </span>
    );
  }
}

export default injectIntl(DeleteActionButton);
