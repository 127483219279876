import React from "react";
import { injectIntl } from "react-intl";
import { intlShape } from "lcm-iot-commons";
import PropTypes from "prop-types";

export function PasswordResetSuccess({ intl, redirectUri }) {
  return (
    <div className="row">
      <div className="col-sm-6">
        <h1>{intl.formatMessage({ id: "password_reset.success.header" })}</h1>
        <p>
          {intl.formatMessage({ id: "password_reset.success.description" })}
        </p>
        <a
          id="sign-in"
          data-turbolinks="false"
          href={redirectUri}
          className="btn btn-primary space-before"
          role="button"
        >
          {intl.formatMessage({ id: "password_reset.success.signin" })}
        </a>
      </div>
    </div>
  );
}

PasswordResetSuccess.propTypes = {
  intl: intlShape.isRequired,
  redirectUri: PropTypes.string,
};

PasswordResetSuccess.defaultProps = {
  redirectUri: undefined,
};

export default injectIntl(PasswordResetSuccess);
