import React, { Component } from "react";
import { injectIntl } from "react-intl";
import Clickable from "lcm-iot-commons/client/lib/components/Clickable";
import { intlShape, url } from "lcm-iot-commons";
import { opcUaServerShape } from "../../shapes/opcUaServersShape";
import {
  opcUaServerIconClassName,
  opcUaServerStatusIconClassName,
} from "../../utils/statusUtils";

export class OpcUaServerItem extends Component {
  displayOpcUaServerIcon() {
    return (
      <div className="details-item-value">
        <span className={opcUaServerIconClassName()} />
      </div>
    );
  }

  render() {
    const { opcUaServer, intl } = this.props;
    const status = opcUaServer.status || "undefined";
    const hostname = opcUaServer.hostname ? (
      <div className="list-item-details">
        <span>
          opc.tcp://
          {opcUaServer.hostname}
          :62541/Server
        </span>
      </div>
    ) : (
      "-"
    );

    return (
      <Clickable
        id="clickable-item"
        target={url(
          `/subscriptions/connect/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}`,
        )}
      >
        <li className="list-item">
          <div className="list-item-image">{this.displayOpcUaServerIcon()}</div>
          <div className="list-item-content">
            <div className="list-item-header">{hostname}</div>
            <div className="list-item-details">
              <span
                id="list-item-details-icon-icon"
                className={opcUaServerStatusIconClassName(status)}
              />
              <span id="list-item-details-icon-description">
                {intl.formatMessage({ id: `enum.opc_ua_server.${status}` })}
              </span>
            </div>
          </div>
        </li>
      </Clickable>
    );
  }
}

OpcUaServerItem.propTypes = {
  intl: intlShape.isRequired,
  opcUaServer: opcUaServerShape.isRequired,
};

export default injectIntl(OpcUaServerItem);
