import PropTypes from "prop-types";
import React from "react";
import uuid from "uuid";
import { AlertType } from "../../constants";
import InputAlert from "./InputAlert";
import { isNotEmpty, isSomething } from "../../utils";

function InputGroup(props) {
  const {
    id,
    name,
    help,
    info,
    label,
    objIdx,
    errors,
    status,
    touched,
    warning,
    success,
    required,
    children,
    hideLabel,
    fieldName,
    moreAlerts,
    isHelpInlineFlex,
    subLink,
  } = props;

  let type = {
    stateClass: "form-group",
    alertType: "",
    alertMessage: "",
  };

  let dirty = false;
  let error;

  // MULTI-DIMENSIONAL-FORMIK
  if (isSomething(objIdx) && isSomething(fieldName)) {
    if (
      isSomething(errors) &&
      isSomething(errors[name]) &&
      isSomething(errors[name][objIdx]) &&
      isSomething(errors[name][objIdx][fieldName])
    ) {
      error = errors[name][objIdx][fieldName];
    }
    if (
      touched &&
      touched[name] &&
      touched[name][objIdx] &&
      touched[name][objIdx][fieldName]
    ) {
      dirty = touched[name][objIdx][fieldName];
    }
    dirty = dirty || status === "submitted";
    // FORMIK
  } else {
    error = name.split(".").reduce((p, c) => p?.[c], errors);
    dirty =
      status === "submitted" ||
      name.split(".").reduce((p, c) => p?.[c], touched);
  }

  if (dirty && isNotEmpty(error)) {
    type = {
      stateClass: "form-group has-error",
      alertType: AlertType.ERROR,
      alertMessage: error,
    };
  } else if (isNotEmpty(warning)) {
    type = {
      stateClass: "form-group has-warning",
      alertType: AlertType.WARNING,
      alertMessage: warning,
    };
  } else if (isNotEmpty(info)) {
    type = {
      stateClass: "form-group has-info",
      alertType: AlertType.INFO,
      alertMessage: info,
    };
  } else if (isNotEmpty(success)) {
    type = {
      stateClass: "form-group has-success",
      alertType: AlertType.SUCCESS,
      alertMessage: success,
    };
  } else {
    type = {
      stateClass: "form-group",
      alertMessage: "",
      alertType: "",
    };
  }

  const labelText = required ? `${label}*` : label;

  const additionalInfos =
    moreAlerts && type.alertMessage !== moreAlerts[0] ? (
      <div>
        {moreAlerts.length > 0 ? (
          <div>
            {moreAlerts.map((message) => (
              <InputAlert
                message={message}
                type={AlertType.INFO}
                key={uuid()}
              />
            ))}
          </div>
        ) : null}
      </div>
    ) : null;

  const renderedLabel = hideLabel ? null : (
    <label
      className={isHelpInlineFlex ? "input-group-inline-flex-help" : ""}
      htmlFor={id}
    >
      {labelText}
      {help}
    </label>
  );

  return (
    <div id={id} className={type.stateClass} data-testid={`input-group-${id}`}>
      {renderedLabel}
      {children}
      <InputAlert message={type.alertMessage} type={type.alertType} />
      {additionalInfos}
      {subLink}
    </div>
  );
}

InputGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
  hideLabel: PropTypes.bool,
  moreAlerts: PropTypes.arrayOf(PropTypes.string),
  help: PropTypes.node,
  subLink: PropTypes.node,
  name: PropTypes.string,
  errors: PropTypes.shape({}),
  status: PropTypes.string,
  touched: PropTypes.shape({}),
  objIdx: PropTypes.number,
  fieldName: PropTypes.string,
  info: PropTypes.string,
  isHelpInlineFlex: PropTypes.bool,
  warning: PropTypes.string,
  success: PropTypes.string,
};

export default InputGroup;
