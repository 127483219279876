import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Urls } from "../../constants";
import SpecificationKeys from "../../constants/SpecificationKeys";
import {
  backendShape,
  browserShape,
  useNotifier,
  withBackend,
  withBrowser,
} from "../../context";
import { useApi } from "../../hooks";
import { htmlSpan, url } from "../../utils";
import ConfirmationModal from "../ConfirmationModal";
import { ControlledSwitchButton } from "../Form";

export function ConnectedAssetActivation({
  browser,
  deviceActive,
  deviceShouldBeActive,
  hasNoInstrumentation,
  assetId,
  serialNumber,
  backend,
}) {
  const [showModal, setShowModal] = useState(false);
  const [isDeviceActive, setIsDeviceActive] = useState(deviceActive);

  const api = useApi();
  const notifier = useNotifier();
  const intl = useIntl();

  const { mutate } = api.patch.useMutation(`/assets/${assetId}/specifications`);

  const updateAssetStatus = async (shouldBeActive) => {
    const shouldBeActiveString = shouldBeActive.toString();
    mutate(
      {
        [SpecificationKeys.ActivationSettings.SHOULD_BE_ACTIVE]: {
          value: shouldBeActiveString,
        },
        [SpecificationKeys.ActivationSettings.SIM_SHOULD_BE_ACTIVE]: {
          value: shouldBeActiveString,
        },
      },
      {
        onSuccess: () => {
          setShowModal(false);
          setIsDeviceActive(!isDeviceActive);
          notifier.showSuccess(
            intl.formatMessage({
              id: "iiot_radar.update_activation.success_notification",
            }),
          );
        },
      },
    );
    backend.patch(`/connected_assets_status/${assetId}`);
  };

  const handleOnModalConfirm = () => updateAssetStatus(!isDeviceActive);

  const onActiveToggleChange = () => {
    setShowModal(true);
  };

  const showActivationModal = () => {
    let modalActivationTitle = "iiot_radar.radar_activation_title";
    let modalActivationText = (
      <span id="modal-activation-text">
        <b>
          {intl.formatMessage({ id: "iiot_radar.radar_activation_text_1" })}
        </b>
        {intl.formatMessage({ id: "iiot_radar.radar_activation_text_2" })}
      </span>
    );
    let image = "FWR30.png";
    let secondConfirmLabel;

    if (isDeviceActive) {
      modalActivationTitle = "iiot_radar.radar_deactivation_title";
      modalActivationText = (
        <span id="modal-deactivation-text">
          <b>
            {intl.formatMessage({ id: "iiot_radar.radar_deactivation_text_1" })}
          </b>
          {intl.formatMessage({ id: "iiot_radar.radar_deactivation_text_2" })}
        </span>
      );
      image = "FWR30_Warning.png";
      secondConfirmLabel = (
        <FormattedMessage
          id="iiot_radar.reenter_serial_number_to_enable_confirm"
          values={{
            serial_number: serialNumber,
            span: htmlSpan({ className: "strong break-all" }),
          }}
        />
      );
    }

    return (
      <ConfirmationModal
        id="activation-modal-dialog"
        show={showModal}
        titleText={intl.formatMessage({ id: modalActivationTitle })}
        messageText={modalActivationText}
        image={url(`/images/${image}`)}
        buttonConfirmText={intl.formatMessage({ id: "button.confirm" })}
        onConfirm={() => handleOnModalConfirm()}
        onClose={() => setShowModal(false)}
        secondConfirmText={isDeviceActive ? serialNumber : undefined}
        secondConfirmLabel={secondConfirmLabel}
        showCancel
      />
    );
  };

  return (
    <div id="connected-asset-activation">
      <ControlledSwitchButton
        id="activation-status-switch"
        status={isDeviceActive}
        onChange={() => {
          if (!isDeviceActive && hasNoInstrumentation) {
            return updateAssetStatus(!isDeviceActive)
              .then(() => {
                notifier.showError(
                  intl.formatMessage({
                    id: "asset.actions.activation.no_instrument",
                  }),
                );
                const target = `/assets/${assetId}/instrumentations/create`;
                browser.navigateTo(target);
              })
              .catch(() => {
                notifier.showError(
                  intl.formatMessage({ id: "api.error.unknown" }),
                );
              });
          }
          return onActiveToggleChange();
        }}
      />
      {showActivationModal()}
      {deviceShouldBeActive && !deviceActive ? (
        <div className="help-text">
          <FormattedMessage id="asset_edit.sim.activation.hint" />{" "}
          <a href={Urls.SIM_ACTIVATION_HELP} target="_blank" rel="noreferrer">
            {intl.formatMessage({ id: "asset_edit.sim.activation.help_text" })}
          </a>
        </div>
      ) : null}
    </div>
  );
}

ConnectedAssetActivation.propTypes = {
  hasNoInstrumentation: PropTypes.bool,
  deviceActive: PropTypes.bool,
  deviceShouldBeActive: PropTypes.bool,
  assetId: PropTypes.string,
  serialNumber: PropTypes.string,
  browser: browserShape.isRequired,
  backend: backendShape.isRequired,
};

export default withBackend(withBrowser(ConnectedAssetActivation));
