import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import { isEmpty, get } from "lodash-es";

import {
  Form,
  CancelButton,
  SubmitButton,
  TextArea,
  TextInput,
  ButtonGroup,
  SelectBox,
} from "../Form";
import AssetStatusSelect from "./AssetStatusSelect";
import CompanyTypeahead from "../Companies/CompanyTypeahead";
import ProductTypeahead from "../Products/ProductTypeahead";
import ProductCategoryTypeahead from "../Products/ProductCategoryTypeahead";
import { intlShape, userShape } from "../../shapes";
import ProductRules from "../../rules/ProductRules";
import DateTimeInput from "../DateTimeInput";
import TenantTypeahead from "../Tenants/TenantTypeahead";
import {
  withApi,
  withUser,
  apiShape,
  withNotifier,
  notifierShape,
} from "../../context";

import AssetFormSynchronization from "./AssetFormSynchronization";
import { isSomething } from "../../utils";
import { SubClue } from "../Clue/SubClue";
import SwitchInput from "../Form/SwitchInput";
import AssetGPSSettingsHelp from "./AssetGPSSettingsHelp";
import SpecificationsInput from "../Specifications/SpecificationsInput";

import { validateSpecifications } from "../../validators";

export function AssetForm({
  onSubmit,
  intl,
  initialValues,
  showAssetStatus,
  user,
  api,
  notifier,
  onChange,
  canSubmit,
  gpsDevSettings,
}) {
  const [userAdminTenants, setUserAdminTenants] = useState();
  const [gpsError, setGPSError] = useState("");

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const response = await api.getAll("/tenants", {
          admin_user_id: user.id,
        });
        setUserAdminTenants(response.tenants);
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    };

    if (user) {
      loadData();
    }
  }, [user]);

  const validateForm = (values) => {
    /* this hack is needed to trigger the changes of values to the parent component of connected assets clue component (which check addon limits).
     * Alternatively these changes can be implemented: the clue component can be moved in the form and shown if needed */
    onChange(values);

    const errors = {};

    if (!values.manufacturer) {
      errors.manufacturer = intl.formatMessage({
        id: "validation.manufacturer.mandatory",
      });
    }

    if (
      values.manufacturer?.new &&
      !values.tenant &&
      userAdminTenants?.length > 1
    ) {
      errors.tenant = intl.formatMessage({
        id: "validation.manufacturer.no_tenant_selected",
      });
    }

    if (isEmpty(values.serial_number)) {
      errors.serial_number = intl.formatMessage({
        id: "validation.serial_number.mandatory",
      });
    } else if (values.serial_number.trim().length < 4) {
      errors.serial_number = intl.formatMessage({
        id: "validation.serial_number.too_short",
      });
    } else if (values.serial_number.length > 255) {
      errors.serial_number = intl.formatMessage({
        id: "validation.serial_number.too_long",
      });
    }

    if (!values.product || isEmpty(values.product.code)) {
      errors.product = intl.formatMessage({
        id: "validation.product_code.mandatory",
      });
    }

    if (
      !isSomething(values.gpsInterval) &&
      isSomething(gpsError) &&
      values.gpsActive
    ) {
      errors.gpsInterval = intl.formatMessage({ id: gpsError });
    } else if (!isSomething(values.gpsInterval) && values.gpsActive) {
      errors.gpsInterval = intl.formatMessage({
        id: "validation.gps_interval.undefined",
      });
    }

    if (values.production_date) {
      if (!/^[\d]{4}(-[01][\d](-[0-3][\d])?)?$/.test(values.production_date)) {
        errors.production_date = intl.formatMessage({
          id: "validation.production_date.invalid",
        });
      } else if (
        /^[\d]{4}-[01][\d]-[0-3][\d]$/.test(values.production_date) &&
        !Date.parse(values.production_date)
      ) {
        errors.production_date = intl.formatMessage({
          id: "validation.production_date.invalid",
        });
      } else if (
        /^[\d]{4}-[01][\d]$/.test(values.production_date) &&
        !Date.parse(`${values.production_date}-01`)
      ) {
        errors.production_date = intl.formatMessage({
          id: "validation.production_date.invalid",
        });
      }
    }

    const specificationErrors = validateSpecifications(intl, values);

    if (specificationErrors) {
      errors.specifications = specificationErrors;
    }

    return errors;
  };

  const loadGPSErrors = (errorValue) => {
    setGPSError(errorValue);
  };

  const loadGPS = (formProps, gpsIntervalOptions) => {
    const { values } = formProps;
    return (
      <div id="gps-settings-group" className="gps-settings-group-items">
        <div>
          <h3>
            {intl.formatMessage({ id: "label.gps_settings" })}
            <AssetGPSSettingsHelp intl={intl} />
          </h3>
        </div>
        <div>
          {gpsError === "validation.gps_interval.less" ? (
            <SubClue
              id="gps-clue"
              message={intl.formatMessage({
                id: "validation.gps_interval.less",
              })}
            />
          ) : null}
        </div>
        <div className="gps-settings-group-items-child">
          <SwitchInput
            {...formProps}
            id="gps-enabled"
            name="gpsActive"
            disabled={!gpsIntervalOptions}
          />
        </div>
        <div>
          <SelectBox
            {...formProps}
            id="gps-interval"
            name="gpsInterval"
            labelKey="name"
            label={intl.formatMessage({ id: "label.gps_interval" })}
            options={gpsIntervalOptions}
            disableOptionsSort
            disabled={!values.gpsActive || !gpsIntervalOptions}
            required
          />
        </div>
      </div>
    );
  };

  const renderForm = (formProps) => {
    const { isSubmitting, values, setFieldValue } = formProps;
    const manufacturerName = get(values, "manufacturer.name");
    const productCode = get(values, "product.code");
    const extendedOrderCode = get(values, "extendedOrderCode");
    const isPublicTenant = values.manufacturer?.tenantPublic;
    const productRules = ProductRules.find(
      manufacturerName,
      productCode,
      isPublicTenant,
    );
    const showMeasuringInterval = productRules?.get("needsMeasuringInterval");
    const showTransmissionInterval = productRules?.get(
      "needsTransmissionInterval",
    );
    const showTimeOfFirstMeasurement = productRules?.get(
      "needsTimeOfFirstMeasurement",
    );
    const needsInstrumentation = productRules?.get("needsInstrumentation");
    const needsGPSInterval = productRules?.get("needsGPSInterval");
    const editMode = values?.id > 0 && !values?.proceedWithTag;
    const showSaveButton = editMode || !needsInstrumentation;
    const showProceedToTagButton = !editMode;
    const measuringIntervalOptions = showMeasuringInterval
      ? productRules?.get("measuringIntervalOptions")
      : null;
    const transmissionIntervalOptions = showMeasuringInterval
      ? measuringIntervalOptions.find(
          (label) => label.id === values.measuringInterval?.id,
        )?.transmissionOptions
      : productRules?.get("transmissionIntervalOptions");
    const proceedWithTag = get(values, "proceedWithTag");
    const gpsAllIntervalOptions = productRules?.get("gpsIntervalOptions");
    const gpsIntervalOptions =
      gpsAllIntervalOptions &&
      gpsAllIntervalOptions.find(
        (label) => label.name === values.transmissionInterval?.name,
      )?.gpsOptions;
    // For asset edit only, if asset has an tenant assigned, we use the asset option endpoints, so the user can
    // also use master data from a tenant he has no direct access to.
    const useAssetOptionsWithAssetId =
      initialValues?.id &&
      initialValues.tenant?.id &&
      values.tenant?.id === initialValues.tenant?.id
        ? initialValues?.id
        : undefined;
    const basicSettings =
      showMeasuringInterval &&
      needsGPSInterval &&
      isSomething(values.transmissionInterval);
    const enableGPSSettings = productRules.get(
      "enableGPSForOrderCode",
      basicSettings,
      extendedOrderCode,
      gpsDevSettings,
    );

    const renderTagButton = () => {
      if (showProceedToTagButton) {
        return (
          <SubmitButton
            id="asset-form-proceed-with-tag-button"
            fetching={
              isSubmitting &&
              (proceedWithTag || productRules.get("needsInstrumentation"))
            }
            disabled={
              !formProps.dirty ||
              !canSubmit ||
              (isSubmitting && !proceedWithTag)
            }
            text={intl.formatMessage({ id: "button.save_create_tag" })}
            onClick={() => setFieldValue("proceedWithTag", true)}
          />
        );
      }
      return null;
    };

    return (
      <Form {...formProps}>
        <CompanyTypeahead
          {...formProps}
          id="manufacturer"
          name="manufacturer"
          label={intl.formatMessage({ id: "label.manufacturer" })}
          assetId={useAssetOptionsWithAssetId}
          disabled={!!initialValues?.ownership_claimed}
          required={!initialValues?.ownership_claimed}
          autoFocus
          canCreate
        />
        {values.manufacturer?.new && userAdminTenants?.length > 1 ? (
          <TenantTypeahead
            {...formProps}
            id="tenant"
            name="tenant"
            assetId={initialValues?.id}
            label={intl.formatMessage({ id: "label.tenant" })}
            canCreate
          />
        ) : null}
        <TextInput
          {...formProps}
          id="serial-number"
          name="serial_number"
          label={intl.formatMessage({ id: "label.serial_number" })}
          /* eslint-disable camelcase */
          disabled={!!initialValues?.ownership_claimed}
          required={!initialValues?.ownership_claimed}
        />
        <TextArea
          {...formProps}
          id="description"
          name="description"
          label={intl.formatMessage({ id: "label.description" })}
        />
        <ProductTypeahead
          {...formProps}
          id="product"
          name="product"
          manufacturerId={values.manufacturer?.id}
          assetId={useAssetOptionsWithAssetId}
          isEndress={values.manufacturer?.name === "Endress+Hauser"}
          canCreate
          disabled={!!initialValues?.ownership_claimed}
          required={!initialValues?.ownership_claimed}
        />
        {values.product?.new ? (
          <ProductCategoryTypeahead
            {...formProps}
            id="product-category"
            name="product_category"
            label={intl.formatMessage({ id: "label.product_category" })}
            tenantId={values.tenant?.id}
          />
        ) : null}

        <AssetFormSynchronization
          {...formProps}
          id="asset-form-sync"
          userAdminTenants={userAdminTenants}
          showMeasuringInterval={showMeasuringInterval}
          showTransmissionInterval={showTransmissionInterval}
          showTimeOfFirstMeasurement={showTimeOfFirstMeasurement}
          gpsEnabled={enableGPSSettings}
          gpsIntervalOptions={gpsIntervalOptions}
          loadGPSErrors={loadGPSErrors}
        />

        {showAssetStatus ? (
          <AssetStatusSelect
            {...formProps}
            id="asset-status"
            name="status"
            label={intl.formatMessage({ id: "label.asset_status" })}
            assetId={useAssetOptionsWithAssetId}
            required
          />
        ) : null}

        <TextInput
          {...formProps}
          id="production-date"
          name="production_date"
          placeholder={intl.formatMessage({
            id: "assets.production_date_placeholder",
          })}
          label={intl.formatMessage({ id: "label.asset_production_date" })}
        />
        <SpecificationsInput
          {...formProps}
          specificationKeysEndpoint="/asset/specification_keys"
        />
        {showMeasuringInterval ||
        showTransmissionInterval ||
        showTimeOfFirstMeasurement ? (
          <div
            id="connectivity-settings-group"
            className="connectivity-settings-group-items"
          >
            <div>
              <h3>
                {intl.formatMessage({ id: "label.connectivity_settings" })}
              </h3>
            </div>
            {showMeasuringInterval ? (
              <SelectBox
                {...formProps}
                id="measuring-interval"
                name="measuringInterval"
                labelKey="name"
                label={intl.formatMessage({ id: "label.measuring_interval" })}
                options={measuringIntervalOptions}
                disableOptionsSort
                required
              />
            ) : null}
            {showTransmissionInterval ? (
              <SelectBox
                {...formProps}
                id="transmission-interval"
                name="transmissionInterval"
                labelKey="name"
                label={intl.formatMessage({
                  id: "label.transmission_interval",
                })}
                options={transmissionIntervalOptions}
                disableOptionsSort
                required
              />
            ) : null}
            {showTimeOfFirstMeasurement ? (
              <DateTimeInput
                {...formProps}
                id="first-measurement"
                type="text"
                name="firstMeasurement"
                label={intl.formatMessage({ id: "label.first_measurement" })}
                showSelectTime
                showSelectDate
                required
              />
            ) : null}
            {enableGPSSettings ? loadGPS(formProps, gpsIntervalOptions) : null}
            <div />
          </div>
        ) : null}
        <ButtonGroup>
          {showSaveButton ? (
            <SubmitButton
              id="asset-form-submit-button"
              fetching={isSubmitting && !proceedWithTag}
              disabled={
                !formProps.dirty ||
                !canSubmit ||
                (isSubmitting && proceedWithTag)
              }
            />
          ) : null}
          {renderTagButton()}

          <CancelButton id="asset-form-cancel-button" fetching={isSubmitting} />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <Formik
      id="asset-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateForm}
      render={renderForm}
    />
  );
}

AssetForm.propTypes = {
  api: apiShape.isRequired,
  user: userShape,
  notifier: notifierShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  showAssetStatus: PropTypes.bool,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    gpsActive: PropTypes.bool,
    manufacturer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      tenantPublic: PropTypes.bool,
    }),
    tenant: PropTypes.shape({
      id: PropTypes.number,
    }),
    serial_number: PropTypes.string,
    ownership_claimed: PropTypes.bool,
    description: PropTypes.string,
    calibration_density: PropTypes.string,
    calibration_viscosity: PropTypes.string,
    calibration_temperature: PropTypes.string,
    calibration_time: PropTypes.instanceOf(Date),
    calibration_status: PropTypes.bool,
    calibration_speed_of_sound: PropTypes.string,
    production_date: PropTypes.string,
    transmissionInterval: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    product: PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
    }),
    measuringInterval: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      transmissionOptions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
    firstMeasurement: PropTypes.instanceOf(Date),
  }),
  gpsInterval: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  canSubmit: PropTypes.bool,
  gpsDevSettings: PropTypes.bool,
};

AssetForm.defaultProps = {
  initialValues: undefined,
  showAssetStatus: false,
  canSubmit: true,
};

export default injectIntl(withUser(withNotifier(withApi(AssetForm))));
