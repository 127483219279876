export function extractPermissionRequest(rawPermissionRequest, permitable) {
  return {
    id: rawPermissionRequest.id,
    status: rawPermissionRequest.status,
    requestUser: {
      id: rawPermissionRequest.request_user.id,
      email: rawPermissionRequest.request_user.email,
      firstName: rawPermissionRequest.request_user.first_name,
      lastName: rawPermissionRequest.request_user.last_name,
    },
    requestMessage: rawPermissionRequest.request_message,
    responseUser: rawPermissionRequest.response_user
      ? {
          id: rawPermissionRequest.response_user.id,
          email: rawPermissionRequest.response_user.email,
          firstName: rawPermissionRequest.response_user.first_name,
          lastName: rawPermissionRequest.response_user.last_name,
        }
      : undefined,
    responseMessage: rawPermissionRequest.response_message,
    permitable: { ...permitable, type: rawPermissionRequest.permitable.type },
  };
}
