import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropdownButton, MenuItem } from "react-bootstrap";
import Loader from "./Loader";

export function ItemMenu({ children, onOpen, handleDropdownRef, isLoading }) {
  return (
    <div className="list-item-menu">
      <DropdownButton
        onClick={onOpen}
        ref={handleDropdownRef}
        bsStyle="default"
        title="..."
        noCaret
        id="dropdown-menu"
        data-testid="dropdown-menu-button"
        pullRight
      >
        {children}
        <MenuItem className="close-item">
          <span className="icon icon-eh-show-less" />
        </MenuItem>
        <Loader id="item-menu-loader" loading={isLoading} withText={false} />
      </DropdownButton>
    </div>
  );
}

ItemMenu.propTypes = {
  children: PropTypes.node,
  onOpen: PropTypes.func,
  handleDropdownRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Component) }),
  ]),
  isLoading: PropTypes.bool,
};

export default ItemMenu;
