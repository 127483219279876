import React from "react";
import { useIntl } from "react-intl";

import { trackingShape, withTracking, withUser } from "../../context";
import { userShape } from "../../shapes";
import { htmlLink } from "../../utils";
import NavigationBanner from "./NavigationBanner";

function NavigationPermissionRequestsBanner({ user, tracking }) {
  const intl = useIntl();
  const message = intl.formatMessage(
    { id: "root.open_permission_requests" },
    { a: htmlLink({ href: "/app/id/permission_requests" }) },
  );
  tracking?.trackEvent("PermissionRequest-NavigationBanner-seen");

  return user?.openPermissionRequests ? (
    <NavigationBanner id="open-permission-banner" message={message} />
  ) : null;
}

NavigationPermissionRequestsBanner.propTypes = {
  user: userShape,
  tracking: trackingShape,
};

NavigationPermissionRequestsBanner.defaultProps = {
  user: undefined,
};

export default withUser(withTracking(NavigationPermissionRequestsBanner));
