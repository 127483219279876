import ActionType from "../constants/ActionType";
import backendApiClient from "../api/backendApiClient";

function expiresAt(timeout) {
  if (timeout) {
    let expireAtTime = Date.now();
    expireAtTime += timeout * 1000;
    // we can not be sure to have the right time because of network delays
    // therefore we sustract a little buffer time of 10 seconds
    expireAtTime -= 10000;
    return expireAtTime;
  }
  return undefined;
}

export function initializeSession(session) {
  return {
    type: ActionType.INITIALIZE_SESSION,
    token: session.token,
    tokenExpiresAt: expiresAt(session.token_expires_in),
    sessionExpiresAt: expiresAt(session.session_expires_in),
    captchaRequired: session.captcha_required,
    activeSubscription: session.active_subscription,
    subscriptionType: session.subscription_type,
    subscriptionStatus: session.subscription_status,
    subscriptionAssetQuota: session.subscription_asset_quota,
    subscriptionSeatQuota: session.subscription_seat_quota,
    subscriptionOwnerId: session.subscription_owner_id,
    subscriptionStorageQuota: session.subscription_storage_quota,
    startUrl: session.start_url,
    applicationSpecifics: session.application_specifics,
    reseller: session.reseller,
  };
}

export function refreshSessionFailed(errors) {
  return {
    type: ActionType.REFRESH_SESSION_FAILED,
    errors,
  };
}

export function createSessionFailed(errors) {
  return (dispatch) =>
    dispatch({
      type: ActionType.CREATE_SESSION_FAILED,
      errors,
    });
}

export function createSessionFetching() {
  return {
    type: ActionType.CREATE_SESSION_FETCHING,
  };
}

export function createSessionSuccessful(session) {
  return {
    type: ActionType.CREATE_SESSION_SUCCESSFUL,
    token: session.token,
    tokenExpiresAt: expiresAt(session.token_expires_in),
    sessionExpiresAt: expiresAt(session.session_expires_in),
    activeSubscription: session.active_subscription,
    subscriptionType: session.subscription_type,
    subscriptionStatus: session.subscription_status,
    subscriptionAssetQuota: session.subscription_asset_quota,
    subscriptionSeatQuota: session.subscription_seat_quota,
    subscriptionOwnerId: session.subscription_owner_id,
    subscriptionStorageQuota: session.subscription_storage_quota,
    startUrl: session.start_url,
    applicationSpecifics: session.application_specifics,
    reseller: session.reseller,
  };
}

export function refreshSessionSuccessful(session) {
  return {
    type: ActionType.REFRESH_SESSION_SUCCESSFUL,
    token: session.token,
    tokenExpiresAt: expiresAt(session.token_expires_in),
    sessionExpiresAt: expiresAt(session.session_expires_in),
    activeSubscription: session.active_subscription,
    subscriptionType: session.subscription_type,
    subscriptionStatus: session.subscription_status,
    subscriptionAssetQuota: session.subscription_asset_quota,
    subscriptionSeatQuota: session.subscription_seat_quota,
    subscriptionOwnerId: session.subscription_owner_id,
    subscriptionStorageQuota: session.subscription_storage_quota,
    startUrl: session.start_url,
    applicationSpecifics: session.application_specifics,
    reseller: session.reseller,
  };
}

export function createSession(email, password, captcha) {
  return (dispatch) => {
    dispatch(createSessionFetching());
    return backendApiClient()
      .post("/session", { email, password, captcha })
      .then((session) => dispatch(createSessionSuccessful(session)))
      .catch((errors) => dispatch(createSessionFailed(errors)));
  };
}

export function refreshSession() {
  // To use the real call, please set the TEST_ENABLE_SESSION_REFRESH to true inside the test
  if (
    process.env.NODE_ENV === "test" &&
    process.env.TEST_ENABLE_SESSION_REFRESH === undefined
  ) {
    const session = {
      token: "123",
      token_expires_in: 99999,
      active_subscription: true,
    };
    return (dispatch) =>
      Promise.resolve(dispatch(refreshSessionSuccessful(session)));
  }
  return (dispatch) =>
    backendApiClient()
      .get("/session", { force_refresh: true })
      .then((session) => {
        dispatch(refreshSessionSuccessful(session));
      })
      .catch((errors) => {
        // TODO: This block will be removed after bug NDT-1816 is investigated
        /* istanbul ignore next */
        if (typeof errors === "object" && errors !== null) {
          // eslint-disable-next-line no-console
          console.error(JSON.stringify(errors, null, 2));
        } else {
          // eslint-disable-next-line no-console
          console.error(errors);
        }
        dispatch(refreshSessionFailed(errors));
      });
}

export function deleteSessionSuccessful() {
  return {
    type: ActionType.DELETE_SESSION_SUCCESSFUL,
  };
}

export function deleteSession() {
  return (dispatch) =>
    backendApiClient()
      .delete("/session")
      .then(() => dispatch(deleteSessionSuccessful()))
      // eslint-disable-next-line no-console
      .catch((errors) => console.error(errors));
}

export function sessionExpiredSuccessful() {
  return {
    type: ActionType.SESSION_EXPIRED,
  };
}

export function sessionExpired() {
  return (dispatch) =>
    backendApiClient()
      .delete("/session")
      .then(() => dispatch(sessionExpiredSuccessful()))
      // eslint-disable-next-line no-console
      .catch((errors) => console.error(errors));
}
