import React from "react";
import { FormattedMessage } from "react-intl";

function SubscriptionSuccess() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h1 id="subscription-failed-header">
            <FormattedMessage id="subscription.failed.header" />
          </h1>
          <p>
            <FormattedMessage id="subscription.failed.description" />
          </p>
          <p>
            <FormattedMessage id="subscription.failed.details" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionSuccess;
