import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import PopOver from "../PopOver";
import Colors from "../../constants/Colors";

export function ContextualNotification({ children, title, interactive }) {
  const content = (
    <div className="contextual-notification">
      <h2>{title}</h2>
      {children}
    </div>
  );
  return (
    <PopOver content={content} interactive={interactive}>
      <div
        className="contextual-notification-icon"
        data-testid="contextual-notification-icon"
      >
        <Icon
          name="icon2-eh-notification nav-icon"
          color={Colors.EH_RED_ORANGE_3}
        />
      </div>
    </PopOver>
  );
}

ContextualNotification.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  interactive: PropTypes.bool,
};

export default ContextualNotification;
