import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
  Row,
  Column,
  Container,
  Heading,
  intlShape,
  ActionBar,
  withAccessRights,
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
} from "lcm-iot-commons";

import TenantForm from "./TenantForm";

export function TenantEdit({ api, notifier, intl, match }) {
  const [tenant, setTenant] = React.useState();
  const { id } = match.params;

  React.useEffect(() => {
    const loadData = async () => {
      const tenantResponse = await api.get(`/tenants/${id}`);
      setTenant(tenantResponse);
    };
    loadData().catch((error) => {
      notifier.showError(api.translateError(error));
    });
  }, []);

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading
              title={intl.formatMessage({ id: "tenant_edit.header" })}
              id="tenant-edit-header"
            />
          </ActionBar>
        </Column>
      </Row>
      <Row>
        <Column>
          <h2>{intl.formatMessage({ id: "tenant_edit.details" })}</h2>
          <TenantForm
            intl={intl}
            edit
            tenant={tenant}
            isLoadingTenant={!tenant}
          />
        </Column>
      </Row>
    </Container>
  );
}

TenantEdit.propTypes = {
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withNotifier(
  withApi(withAccessRights(injectIntl(TenantEdit), "Tenant")),
);
