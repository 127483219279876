import React from "react";
import PropTypes from "prop-types";
import { trackingShape, withTracking } from "../../context";

export function SearchFilterItem({
  label,
  disabled,
  onChange,
  checked,
  filter,
  tracking,
}) {
  const handleOnChange = (event) => {
    onChange(filter, event.target.checked);
    const eventProps = {
      filterItemName: label,
      actionType: event.target.checked ? "checked" : "unchecked",
    };
    tracking?.trackEvent("Search-Filter-Item-clicked", eventProps);
  };

  return (
    <li className="list-inline-item">
      <input
        id={`${label}-checkbox`}
        name={label}
        checked={checked}
        type="checkbox"
        disabled={disabled}
        onChange={handleOnChange}
      />
      <label htmlFor={`${label}-checkbox`}>{label}</label>
    </li>
  );
}

SearchFilterItem.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  filter: PropTypes.string.isRequired,
  tracking: trackingShape.isRequired,
};
export default withTracking(SearchFilterItem);
