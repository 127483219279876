import React from "react";
import PropTypes from "prop-types";
import { withAccessRights } from "lcm-iot-commons";
import ResellerCustomerCreateEdit from "./ResellerCustomerCreateEdit";

export const validateAccessRights = (accessRights) => accessRights.canUpdate;

export function ResellerCustomerEditAccessRights({ match }) {
  return <ResellerCustomerCreateEdit match={match} />;
}

ResellerCustomerEditAccessRights.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
export default withAccessRights(
  ResellerCustomerEditAccessRights,
  "Customer",
  validateAccessRights,
);
