/* eslint-disable no-param-reassign */
export function addCountryObject(address, countries) {
  if (address && address.countryCode) {
    address.country = countries.countries.find(
      (c) => c.code === address.countryCode,
    );
  } else if (address != null) {
    address.country = countries.countries.find(
      (c) => c.code === countries.userCountryCode,
    );
  }
  return address;
}
