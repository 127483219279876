/* eslint-disable jsx-a11y/interactive-supports-focus */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { isNotEmpty, isSomething, sortBy } from "../../utils";
import Loader from "../Loader";

class Typeahead extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    "data-testid": PropTypes.string,
    disabled: PropTypes.bool,
    fetching: PropTypes.bool,
    filterOption: PropTypes.func,
    valueKey: PropTypes.string,
    loadOptions: PropTypes.func,
    selectedOption: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    createOption: PropTypes.func,
    renderOption: PropTypes.func,
    renderSelectedOption: PropTypes.func,
    onChange: PropTypes.func,
    labelKey: PropTypes.string,
    canCreate: PropTypes.bool,
    loadOptionsOnMount: PropTypes.bool,
    optionsVisible: PropTypes.bool,
  };

  static defaultProps = {
    valueKey: "id",
    labelKey: "name",
    loadOptionsOnMount: true,
    optionsVisible: false,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputOnBlur = this.handleInputOnBlur.bind(this);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    this.handleRenderOptionsOptionOnMouseDown =
      this.handleRenderOptionsOptionOnMouseDown.bind(this);
    this.handleRenderArrowOnMousDown =
      this.handleRenderArrowOnMousDown.bind(this);
    this.handleMouseDownOnMenu = this.handleMouseDownOnMenu.bind(this);

    this.state = {
      optionsVisible: props.optionsVisible || false,
      focused: false,
      focusedOption: null,
      filteredOptions: null,
      selectedOption: null,
    };
  }

  componentDidMount() {
    const { options, loadOptions, selectedOption, loadOptionsOnMount } =
      this.props;

    let stateFilteredOptions = null;
    let stateFocusedOption = null;
    let stateSelectedOption = null;

    if (options) {
      stateFilteredOptions = this.filterOptions("", options);
    }

    if (selectedOption) {
      stateFocusedOption = selectedOption;
      stateSelectedOption = selectedOption;
    }

    this.setState({
      filteredOptions: stateFilteredOptions,
      focusedOption: stateFocusedOption,
      selectedOption: stateSelectedOption,
    });

    if (loadOptions && loadOptionsOnMount) {
      loadOptions("");
    }
  }

  /* eslint-disable camelcase */
  componentDidUpdate(prevProps) {
    const { focusedOption, selectedOption } = this.state;
    const { labelKey, valueKey } = this.props;

    if (this.props.options !== prevProps.options) {
      const filteredOptions = this.filterOptions("", this.props.options);

      let newFocusedOption = null;

      if (focusedOption) {
        newFocusedOption = this.findExactOption(
          focusedOption[labelKey],
          this.props.options,
          focusedOption[valueKey] ? focusedOption[valueKey].toString() : null,
        );
      }

      let newSelectedOption = null;

      if (selectedOption) {
        newSelectedOption = this.findExactOption(
          selectedOption[labelKey],
          this.props.options,
          selectedOption[valueKey] ? selectedOption[valueKey].toString() : null,
        );
      }

      this.setState({
        filteredOptions,
        focusedOption: newFocusedOption,
        selectedOption: newSelectedOption,
      });
    }

    if (this.props.selectedOption !== prevProps.selectedOption) {
      this.updateInputValue(
        this.props.selectedOption ? this.props.selectedOption[labelKey] : "",
      );
      //  this.loadOptions();
      this.setState({
        focusedOption: this.props.selectedOption,
        selectedOption: this.props.selectedOption,
      });
    }
  }

  handleSelection(focusedOption = this.state.focusedOption) {
    const { labelKey, canCreate } = this.props;

    let newFocusedOption = focusedOption;
    let newSelectedOption = focusedOption;

    if (!newSelectedOption && isNotEmpty(this.input.value)) {
      const filteredOptions = this.filterOptions(
        this.input.value,
        this.props.options,
      );
      newFocusedOption = this.findExactOption(
        this.input.value,
        filteredOptions,
      );

      /* istanbul ignore next */
      if (!newFocusedOption) {
        if (canCreate) {
          newFocusedOption = this.createOption(this.input.value);
        } else {
          newFocusedOption = null;
        }
      }

      newSelectedOption = newFocusedOption;
    }

    this.updateInputValue(newFocusedOption ? newFocusedOption[labelKey] : "");
    this.selectedValueChanged(newSelectedOption);
    this.setState({
      optionsVisible: false,
      focusedOption: newFocusedOption,
      selectedOption: newSelectedOption,
      focused: false,
    });
  }

  handleInputOnBlur() {
    this.handleSelection();
  }

  handleChange() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    const filteredOptions = this.filterOptions(
      this.input.value,
      this.props.options,
    );
    const focusedOption = isNotEmpty(this.input.value)
      ? this.findExactOption(this.input.value, filteredOptions)
      : null;

    this.loadOptions();

    this.setState({
      optionsVisible: true,
      filteredOptions,
      focusedOption,
    });
  }

  handleInputOnChange() {
    this.handleChange();
  }

  handleEnterKey(event) {
    if (this.state.optionsVisible) {
      event.preventDefault();
    }
    this.handleSelection();
  }

  handleEscapeKey() {
    const { labelKey } = this.props;
    const { selectedOption } = this.state;
    if (selectedOption) {
      this.setState({ focusedOption: selectedOption, optionsVisible: false });
    } else {
      this.setState({ focusedOption: null, optionsVisible: false });
    }
    this.updateInputValue(
      this.state.selectedOption ? this.state.selectedOption[labelKey] : "",
    );
  }

  handleKeyDown(event) {
    switch (event.keyCode) {
      case 13: // enter
        this.handleEnterKey(event);
        break;
      case 27: // escape
        this.handleEscapeKey();
        break;
      case 38: // up
        this.focusPreviousOption();
        break;
      case 40: // down
        this.focusNextOption();
        break;
      /* istanbul ignore next */
      default:
        /* istanbul ignore next */
        break;
    }
  }

  handleRenderArrowOnMousDown(event) {
    const { options } = this.props;
    const { optionsVisible } = this.state;
    if (this.props.disabled) {
      return;
    }

    if (optionsVisible) {
      this.setState({
        optionsVisible: false,
        filteredOptions: this.filterOptions("", options),
      });
    } else {
      this.setState({ optionsVisible: true });
    }
    this.input.focus();
    event.preventDefault();
  }

  handleRenderOptionsOptionOnMouseDown(event, option) {
    this.handleSelection(option);
    event.preventDefault();
  }

  handleMouseDownOnMenu(event) {
    // if the event was triggered by a mousedown and not the primary
    // button, or if the component is disabled, ignore it.
    if (
      this.props.disabled ||
      (event.type === "mousedown" && event.button !== 0)
    ) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
  }

  createOption(value) {
    const { labelKey, createOption } = this.props;
    if (createOption) {
      return createOption(value);
    }
    const option = { new: true };
    option[labelKey] = value;
    return option;
  }

  filterOption(searchString, option) {
    const { labelKey, filterOption } = this.props;
    if (filterOption) {
      return filterOption(searchString, option);
    }
    return (
      isSomething(option[labelKey]) &&
      option[labelKey].toLowerCase().includes(searchString.toLowerCase())
    );
  }

  filterOptions(searchString, options) {
    if (options) {
      const filteredOptions = options.filter((option) =>
        this.filterOption(searchString, option),
      );
      return sortBy(filteredOptions, this.props.labelKey);
    }
    return [];
  }

  selectedValueChanged(value) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  focusPreviousOption() {
    const { filteredOptions, focusedOption } = this.state;
    if (filteredOptions && focusedOption) {
      const index = filteredOptions.indexOf(focusedOption);
      if (index > 0) {
        this.setState({
          focusedOption: filteredOptions[index - 1],
          optionsVisible: true,
        });
      }
      this.scrollToFocusedOption(false);
    }
  }

  focusNextOption() {
    const { filteredOptions, focusedOption, optionsVisible } = this.state;
    let newFocusedOption = focusedOption;
    if (filteredOptions) {
      if (!optionsVisible) {
        if (!focusedOption) {
          [newFocusedOption] = filteredOptions;
        }
      } else {
        const index = filteredOptions.indexOf(focusedOption);
        if (index + 1 < filteredOptions.length) {
          newFocusedOption = filteredOptions[index + 1];
        }
      }

      this.setState({ focusedOption: newFocusedOption, optionsVisible: true });
      this.scrollToFocusedOption(true);
    }
  }

  findExactOption(searchString, options, valueString) {
    const { labelKey, valueKey } = this.props;
    if (options && searchString) {
      return (
        options.find((option) => {
          // defect 3581 (search based on the valueKey as well if valueString is given)
          if (
            isSomething(valueString) &&
            isSomething(option[valueKey]) &&
            option[valueKey].toString().toLowerCase() !==
              valueString.toLowerCase()
          ) {
            return false;
          }
          return (
            isSomething(option[labelKey]) &&
            option[labelKey].toLowerCase() === searchString.toLowerCase()
          );
        }) || null
      );
    }
    return null;
  }

  loadOptions() {
    const { loadOptions } = this.props;
    if (loadOptions) {
      this.timerId = setTimeout(() => {
        loadOptions(this.input?.value);
      }, 100);
    }
  }

  updateInputValue(value) {
    if (this.input.value !== value) {
      this.input.value = value;
    }
  }

  updateFocusedOptionRef(element, focused) {
    if (focused) {
      this.focusedOptionRef = element;
    }
  }

  updateInputRef(input) {
    const { focusedOption } = this.state;
    const { labelKey } = this.props;
    this.input = input;
    if (focusedOption && this.input) {
      this.updateInputValue(focusedOption[labelKey]);
    }
  }

  scrollToFocusedOption(top) {
    if (this.focusedOptionRef) {
      this.focusedOptionRef.scrollIntoView(top);
    }
  }

  renderArrow() {
    const { disabled } = this.props;
    const { filteredOptions, optionsVisible } = this.state;
    const arrowDisabled =
      disabled || !filteredOptions || filteredOptions.length === 0;
    const arrowClassName = arrowDisabled
      ? "select-arrow-disabled"
      : "select-arrow";
    const iconClassName =
      optionsVisible && !arrowDisabled
        ? "icon icon-eh-show-less"
        : "icon icon-eh-show-more";
    return (
      <div
        className={arrowClassName}
        onMouseDown={this.handleRenderArrowOnMousDown}
        role="button"
        data-testid={`${this.props["data-testid"]}-dropdown-button`}
      >
        <span className={iconClassName} />
      </div>
    );
  }

  renderLoader() {
    const { fetching } = this.props;
    return (
      <Loader
        id="select-loader"
        loading={!!(fetching && this.state.focused)}
        withText={false}
        className="select-loader"
      />
    );
  }

  renderOption(option) {
    const { renderOption, labelKey } = this.props;
    if (renderOption) {
      return renderOption(option);
    }
    return <span className="select-option-value">{option[labelKey]}</span>;
  }

  renderSelectedOption() {
    const { renderSelectedOption } = this.props;
    const { selectedOption, focusedOption } = this.state;
    if (
      focusedOption &&
      selectedOption &&
      focusedOption === selectedOption &&
      renderSelectedOption
    ) {
      return (
        <div className="typeahead-option-selected">
          {renderSelectedOption(selectedOption)}
        </div>
      );
    }
    return null;
  }

  renderOptionsOption(option, focused) {
    const { valueKey } = this.props;
    const className = focused ? "select-option-focused" : "select-option";

    return (
      <div
        role="button"
        key={`${this.props["data-testid"]}-${option[valueKey]}`}
        className={className}
        onMouseUp={(event) =>
          this.handleRenderOptionsOptionOnMouseDown(event, option)
        }
        ref={(element) => this.updateFocusedOptionRef(element, focused)}
      >
        {this.renderOption(option)}
      </div>
    );
  }

  renderOptions(options) {
    const { focusedOption } = this.state;
    const renderedOptions = options.map((option) =>
      this.renderOptionsOption(option, focusedOption === option),
    );
    return (
      <div
        onMouseDown={this.handleMouseDownOnMenu}
        className="select-options"
        role="button"
      >
        {renderedOptions}
      </div>
    );
  }

  render() {
    const { autoFocus, disabled, placeholder } = this.props;
    const { filteredOptions, optionsVisible } = this.state;
    const renderedSelectedOption = this.renderSelectedOption();
    const renderedArrow = this.renderArrow();
    const renderedLoader = this.renderLoader();
    const renderedOptions =
      optionsVisible && filteredOptions && filteredOptions.length > 0
        ? this.renderOptions(filteredOptions)
        : null;

    return (
      <div className="select" onKeyDown={this.handleKeyDown} role="button">
        {renderedLoader}
        {renderedArrow}
        <input
          autoFocus={autoFocus}
          data-testid={this.props["data-testid"]}
          className="form-control"
          onFocus={() => this.setState({ focused: true })}
          placeholder={placeholder}
          disabled={disabled}
          type="text"
          ref={(input) => this.updateInputRef(input)}
          onBlur={this.handleInputOnBlur}
          onChange={this.handleInputOnChange}
        />
        {renderedSelectedOption}
        {renderedOptions}
      </div>
    );
  }
}

export default Typeahead;
