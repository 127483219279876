import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Navbar, Nav, NavDropdown, MenuItem, NavItem } from "react-bootstrap";
import uuid from "uuid/v4";

import { intlShape, sessionShape, locationShape } from "../../shapes";
import CONFIGURATION from "../../configuration";
import SeatInvitationCheck from "../Seats/SeatInvitationCheck";

import {
  withConfiguration,
  withSession,
  withBrowser,
  browserShape,
} from "../../context";
import NavigationAppSwitcher from "./NavigationAppSwitcher";
import NavigationUserMenu from "./NavigationUserMenu";
import { NavigationItemExternalLink } from "./NavigationItemExternalLink";
import { withIntl } from "../../wrappers";
import HeaderSearch from "../HeaderSearch/HeaderSearch";
import { sentryUnmask } from "../../utils/sentryUtils";

const mapNavigationItem = (item, location, intl, browser) => {
  const {
    active,
    translationKey,
    target,
    className,
    isExternalLink,
    externalLinkTargetName,
  } = item.props;
  const isActive = location && active?.test(location.pathname);
  const label = translationKey
    ? intl.formatMessage({ id: translationKey })
    : "";
  if (isExternalLink) {
    return (
      <NavigationItemExternalLink
        key={uuid()}
        href={target}
        target={externalLinkTargetName}
        label={label}
        className={sentryUnmask(className)}
      />
    );
  }
  return (
    <NavItem
      className={sentryUnmask(className)}
      active={isActive}
      onClick={() => browser.navigateTo(target)}
      key={uuid()}
    >
      {label}
    </NavItem>
  );
};

const mapMenuItem = (menuItem, browser) => {
  const { active, target, children } = menuItem.props;
  const label = children.props.id;
  const onClick = () => browser.navigateTo(target);
  return (
    <MenuItem onClick={onClick} active={active} key={uuid()}>
      <FormattedMessage className="sentry-unblock" id={label} />
    </MenuItem>
  );
};

const mapNavigationDropdownItem = (item, location, intl, browser) => {
  const { children, translationKey, className } = item.props;
  const isActive = location && item.props.active.test(location.pathname);
  const label = intl.formatMessage({ id: translationKey });
  let menuItems = null;
  if (children) {
    menuItems = children.map
      ? children.map((child) => mapMenuItem(child, browser))
      : mapMenuItem(children, browser);
    return (
      <NavDropdown
        id="subscription-navigation-dropdown"
        title={label}
        active={isActive}
        className={sentryUnmask(className)}
        key={uuid()}
      >
        {menuItems}
      </NavDropdown>
    );
  }
  return null;
};

const renderChild = (child, location, intl, browser) => {
  if (child.type?.componentName === "NavigationItem") {
    return mapNavigationItem(child, location, intl, browser);
  }
  if (child.type?.componentName === "NavigationDropdownItem") {
    return mapNavigationDropdownItem(child, location, intl, browser);
  }
  if (child.key === "NavigationItemClickable") {
    return child;
  }

  return null;
};

const renderNavigationItems = (children, location, intl, browser) => {
  if (!children) {
    return null;
  }
  if (children.map) {
    return children
      .filter((child) => child && child.type)
      .map((child) => renderChild(child, location, intl, browser));
  }
  return renderChild(children, location, intl, browser);
};

export function NavigationBar({
  intl,
  name,
  browser,
  session,
  location,
  children,
  mobileName,
  showDemoData,
  configuration,
  showAppSwitcher,
  showSearchBar,
  showSupportItem,
  showNavigationItems,
}) {
  const navigationItems =
    session.authenticated && showNavigationItems
      ? renderNavigationItems(children, location, intl, browser)
      : null;
  const stripeShadowClassName = session.authenticated
    ? "cyan-stripe navigation-shadow"
    : "cyan-stripe";

  return (
    <div className="navigation">
      <div className={stripeShadowClassName}>
        <div className="container">
          <Navbar collapseOnSelect>
            <div className="row">
              <div className="navigation-header">
                <div className="navigation-toggle">
                  <Navbar.Toggle disabled={!session.authenticated} />
                </div>
                <div className="navigation-title">
                  <a href={`${CONFIGURATION.BASE_URL}`}>
                    <span className="hidden-xs hidden-sm">{name}</span>
                    {configuration.environment === "staging" && (
                      <span className="hidden-xs hidden-sm staging-hint">
                        {" "}
                        Staging
                      </span>
                    )}
                    <span className="hidden-md hidden-lg">{mobileName}</span>
                    {configuration.environment === "staging" && (
                      <span className="hidden-md hidden-lg staging-hint">
                        {" "}
                        Staging
                      </span>
                    )}
                  </a>
                </div>
                {session.authenticated && showSearchBar ? (
                  <div
                    className="mobile-search-overlay visible-xs"
                    id="mobile-search"
                  >
                    <HeaderSearch placeholder="Search in Netilion" />
                  </div>
                ) : null}
                <div className="navigation-logo" />
              </div>
            </div>
            <Navbar.Collapse>
              <div className="row">
                <Nav id="app-navigation">{navigationItems}</Nav>
                <Nav id="user-navigation" pullRight>
                  {session.authenticated && (
                    <NavigationUserMenu
                      showDemoData={showDemoData}
                      showSupportItem={showSupportItem}
                      location={location}
                    >
                      {children &&
                        children.map &&
                        children.filter(
                          (child) =>
                            child &&
                            child.key !== "NavigationItemClickable" &&
                            child.type.componentName !== "NavigationItem" &&
                            child.type.componentName !==
                              "NavigationDropdownItem",
                        )}
                    </NavigationUserMenu>
                  )}
                </Nav>
                {showAppSwitcher ? (
                  <Nav id="apps-navigation" pullRight>
                    {session.authenticated && (
                      <NavigationAppSwitcher location={location} />
                    )}
                  </Nav>
                ) : null}
                {session.authenticated && showSearchBar ? (
                  <Nav
                    id="header-search"
                    className="hidden-xs"
                    pullRight
                    style={{ height: "auto" }}
                  >
                    <HeaderSearch placeholder="Search in Netilion" />
                  </Nav>
                ) : null}
              </div>
            </Navbar.Collapse>
          </Navbar>
          <SeatInvitationCheck />
        </div>
      </div>
      <div className="navigation-separator" />
    </div>
  );
}

NavigationBar.propTypes = {
  name: PropTypes.string,
  mobileName: PropTypes.string,
  session: sessionShape,
  location: locationShape,
  children: PropTypes.node,
  intl: intlShape.isRequired,
  showAppSwitcher: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  showNavigationItems: PropTypes.bool,
  showSupportItem: PropTypes.bool,
  showDemoData: PropTypes.bool,
  browser: browserShape,
  configuration: PropTypes.shape({ environment: PropTypes.string }).isRequired,
};

NavigationBar.defaultProps = {
  showAppSwitcher: true,
  showSearchBar: true,
};

export default withConfiguration(
  withBrowser(withSession(withIntl(NavigationBar))),
);
