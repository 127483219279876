import PropTypes from "prop-types";

export const dataExportShape = PropTypes.shape({
  id: PropTypes.number,
  tag: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  finishedAt: PropTypes.string,
  exportFileName: PropTypes.string,
  exportContentType: PropTypes.string,
  exportFileSize: PropTypes.number,
  exportUpdatedAt: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.number,
    href: PropTypes.string,
  }),
  downloadHref: PropTypes.string,
  availableUntil: PropTypes.string,
});

export const dataExportsShape = PropTypes.arrayOf(dataExportShape);
