/* eslint-disable no-param-reassign */
import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";

import {
  ActionBar,
  BackButton,
  Column,
  Container,
  displayUserName,
  Heading,
  List,
  ListItem,
  Loader,
  Row,
  trackingShape,
  url,
  useApi,
  withTracking,
} from "lcm-iot-commons";

function PermissionRequests({ tracking }) {
  const intl = useIntl();
  const api = useApi();
  const { data, fetchNextPage, isFetchingNextPage, isLoading, hasNextPage } =
    api.get.useInfiniteQuery("/permission_requests", { status: "open" });

  const permissionRequestsCount = data?.pages?.reduce(
    (acc, page) => acc + page.permission_requests.length,
    0,
  );

  useEffect(() => {
    if (data?.pages?.length) {
      tracking.trackEvent("PermissionRequests-List", {
        page: Math.max(data.pages.map((page) => page.pagination?.page)),
      });
    }
  }, [data?.pages?.length]);

  return (
    <Container>
      <Row>
        <Column>
          <BackButton />
        </Column>
      </Row>
      <Row>
        <Column>
          <ActionBar>
            <Heading
              title={intl.formatMessage({
                id: "permission_request.header.list",
              })}
            />
          </ActionBar>
        </Column>
      </Row>
      <Row>
        <Column>
          {data && (
            <List>
              <InfiniteScroll
                initialLoad={false}
                loadMore={fetchNextPage}
                hasMore={hasNextPage && !isFetchingNextPage}
              >
                {data.pages.map((page) =>
                  page.permission_requests.map((permissionRequest) => (
                    <ListItem
                      key={permissionRequest.id}
                      id={`customer-item-${permissionRequest.id}`}
                      icon="lcm-iot-icon-user"
                      title={`${intl.formatMessage({ id: "permission_request.header" })}: ${displayUserName(permissionRequest.request_user, true, true)}`}
                      description={permissionRequest.request_message}
                      target={url(
                        `/permission_requests/${permissionRequest.id}`,
                      )}
                    />
                  )),
                )}
              </InfiniteScroll>
            </List>
          )}
          {!isLoading && permissionRequestsCount === 0 && (
            <FormattedMessage id="permission_request.no_requests" />
          )}
          <Loader loading={isFetchingNextPage || isLoading} />
        </Column>
      </Row>
    </Container>
  );
}

PermissionRequests.propTypes = {
  tracking: trackingShape.isRequired,
};

export default withTracking(PermissionRequests);
