import React from "react";
import PropTypes from "prop-types";
import {
  FormattedDate,
  FormattedTime,
  FormattedRelativeTime as OriginalFormattedRelativeTime,
} from "react-intl";
import Tooltip from "../Tooltip";

function FormattedRelativeTime(props) {
  const { value, customClass } = props;
  const { hideTooltip } = props;

  // display date if value older than 7 days
  const sevenDaysAgo = new Date(); // today!
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const renderDateTimeWithoutTooltip = () => {
    const spanProps = { "data-testid": "formatted-datetime" };
    if (customClass) {
      spanProps.className = customClass;
    }
    return (
      <span {...spanProps}>
        {value.getTime() < sevenDaysAgo.getTime() ||
        value.getTime() > Date.now() ? (
          <FormattedDate
            {...props}
            data-testid="formatted-date"
            year="numeric"
            month="2-digit"
            day="2-digit"
          />
        ) : (
          <OriginalFormattedRelativeTime
            {...props}
            data-testid="formatted-time"
            value={((Date.now() - value.getTime()) / 1000) * -1}
          />
        )}
      </span>
    );
  };

  const render = () => {
    if (hideTooltip) {
      return renderDateTimeWithoutTooltip();
    }

    return (
      <Tooltip
        data-testid="tooltip"
        content={
          <>
            <FormattedDate
              {...props}
              year="numeric"
              month="2-digit"
              day="2-digit"
            />
            <span> </span>
            <FormattedTime {...props} />
          </>
        }
      >
        {renderDateTimeWithoutTooltip()}
      </Tooltip>
    );
  };

  return value ? render() : null;
}

FormattedRelativeTime.propTypes = {
  value: PropTypes.instanceOf(Date),
  hideTooltip: PropTypes.bool,
  updateIntervalInSeconds: PropTypes.number,
  style: PropTypes.string,
  customClass: PropTypes.string,
  numeric: PropTypes.string,
};

FormattedRelativeTime.defaultProps = {
  value: undefined,
  hideTooltip: false,
  style: "long",
  updateIntervalInSeconds: 1,
  numeric: "auto",
};

export default FormattedRelativeTime;
