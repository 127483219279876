import React from "react";
import PropTypes from "prop-types";

function Tile({
  id,
  header,
  children,
  footer,
  selected,
  disabled,
  onClick,
  background,
}) {
  const content = (
    <>
      <div className="tile-background">{background}</div>
      <div className="tile-header">{header}</div>
      <div className="tile-body">{children}</div>
      <div className="tile-footer">{footer}</div>
    </>
  );

  const className = `tile${selected ? " selected" : ""}`;
  return onClick ? (
    <button
      id={id}
      className={className}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {content}
    </button>
  ) : (
    <div id={id} className={className}>
      {content}
    </div>
  );
}

const contentPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.node]);

Tile.propTypes = {
  id: PropTypes.string,
  background: PropTypes.node,
  header: contentPropType,
  children: contentPropType,
  footer: contentPropType,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Tile.defaultProps = {
  id: undefined,
  background: undefined,
  header: undefined,
  children: undefined,
  footer: undefined,
  selected: false,
  disabled: false,
  onClick: undefined,
};

export default Tile;
