/* eslint-disable */

import { Component } from "react";
import PropTypes from "prop-types";

class NavigationDropdownItem extends Component {
  static componentName = "NavigationDropdownItem";

  static propTypes = {
    active: PropTypes.object,
    target: PropTypes.string,
    translationKey: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };
}

export default NavigationDropdownItem;
