import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import Heading from "../Heading";
import { ActionBar } from "../ActionBar";
import { intlShape } from "../../shapes";
import { formatDateTime } from "../../utils";
import { Form, TextArea, SubmitButton, CancelButton } from "../Form";

export function Comments({ comments, intl, showCreateForm, onSubmit }) {
  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form id="ticket-form" {...formProps}>
        <TextArea
          {...formProps}
          id="ticket-comment"
          label={intl.formatMessage({ id: "label.new_comment" })}
          disabled={isSubmitting}
          name="comment"
          required
          cols={50}
          rows={5}
        />
        <div className="btn-group">
          <SubmitButton
            id="comment-submit"
            fetching={isSubmitting}
            disabled={!formProps.dirty}
            intl={intl}
          />
          <CancelButton
            id="comment-cancel"
            fetching={isSubmitting}
            intl={intl}
          />
        </div>
      </Form>
    );
  };

  return (
    <div>
      <ActionBar>
        <Heading
          level={2}
          id="ticket-comments-header"
          title={intl.formatMessage({ id: "support.ticket.comments.header" })}
        />
      </ActionBar>
      <ul id="ticket-comments-list" className="list">
        {comments?.map((comment) => (
          <li className="list-item" key={comment.id}>
            <div className="list-item-content support-wrap">
              <span className="strong">{comment.comment}</span>
              <div id={`ticket-comment-author-${comment.id}`}>
                {`${formatDateTime(comment.created_at, intl)} - ${comment.created_by}`}
              </div>
            </div>
          </li>
        ))}
      </ul>
      {showCreateForm && (
        <Formik id="comment-form" render={renderForm} onSubmit={onSubmit} />
      )}
    </div>
  );
}

Comments.propTypes = {
  intl: intlShape,
  showCreateForm: PropTypes.bool,
  onSubmit: PropTypes.func,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      comment: PropTypes.string,
      created_at: PropTypes.string,
      created_by: PropTypes.string,
    }),
  ),
};
export default injectIntl(Comments);
