import React from "react";
import PropTypes from "prop-types";
import ListItemSkeleton from "./ListItemSkeleton";

export default function ListSkeleton({ withChips = true }) {
  return (
    <div className="list-skeleton" data-testid="list-skeleton">
      <ListItemSkeleton withChips={withChips} />
      <ListItemSkeleton withChips={withChips} />
      <ListItemSkeleton withChips={withChips} />
    </div>
  );
}

ListSkeleton.propTypes = {
  withChips: PropTypes.bool,
};
