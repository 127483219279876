/* eslint-disable default-param-last */
import ActionType from "../constants/ActionType";

const initialState = {
  type: null,
  id: null,
  canRead: false,
  canUpdate: false,
  canDelete: false,
  canPermit: false,
  errors: null,
};

export default function accessRightsReducer(state = initialState, action) {
  const { type, accessRights, errors } = action;
  switch (type) {
    case ActionType.INIT_ACCESS_RIGHTS:
      return { ...initialState };
    case ActionType.LOAD_ACCESS_RIGHTS_SUCCESSFUL:
      return {
        ...state,
        type: accessRights.type,
        id: accessRights.id,
        canRead: accessRights.canRead,
        canUpdate: accessRights.canUpdate,
        canDelete: accessRights.canDelete,
        canPermit: accessRights.canPermit,
      };
    case ActionType.LOAD_ACCESS_RIGHTS_FAILED:
      return { ...initialState, errors };
    default:
      return state;
  }
}
