import React from "react";
import PropTypes from "prop-types";
import { withConfiguration } from "../../context/ConfigurationContext";
import { withSession } from "../../context";
import { url } from "../../utils";
import { sessionShape } from "../../shapes";

export function ListItemImage({ pictureUrl, session, configuration }) {
  const { lcmApiUrl } = configuration;
  const { token } = session;

  const imageUrl =
    pictureUrl && pictureUrl.startsWith(lcmApiUrl)
      ? `${pictureUrl}?access_token=${token}`
      : pictureUrl;

  return (
    <div
      className="list-item-image-image"
      style={{
        backgroundImage: `url(${imageUrl}), url(${url("/images/no-picture.png")}`,
      }}
    />
  );
}

ListItemImage.propTypes = {
  pictureUrl: PropTypes.string,
  session: sessionShape.isRequired,
  configuration: PropTypes.shape({ lcmApiUrl: PropTypes.string }).isRequired,
};

export default withConfiguration(withSession(ListItemImage));
