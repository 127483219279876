import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import TypeaheadBox from "../Form/TypeaheadBox";
import intlShape from "../../shapes/intlShape";
import {
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  withUser,
} from "../../context";
import { userShape } from "../../shapes";

export function TenantTypeahead(props) {
  const [tenants, setTenants] = useState();
  const [fetching, setFetching] = useState();

  const { api, notifier, intl, values, name, user, assetId } = props;
  const loadOptions = async (searchText = "") => {
    try {
      setFetching(true);

      const endpoint = assetId
        ? `/assets/${assetId}/tenant-options`
        : "/tenants";

      const response = await api.get(endpoint, {
        admin_user_id: user.id,
        name: `${searchText}*`,
      });
      setTenants(
        response.tenants.map((tenant) => ({
          id: tenant.id,
          name: tenant.name,
        })),
      );
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  const selectedTenant = values[name];

  return user ? (
    <TypeaheadBox
      {...props}
      info={
        selectedTenant?.new
          ? intl.formatMessage({ id: "tenant_typeahead.new_info" })
          : null
      }
      options={tenants}
      fetching={fetching}
      loadOptions={loadOptions}
    />
  ) : null;
}

TenantTypeahead.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  user: userShape,
  notifier: notifierShape.isRequired,
  assetId: PropTypes.number,
  values: PropTypes.shape({
    manufacturer: PropTypes.shape({}),
  }),
};

TenantTypeahead.defaultProps = {
  user: undefined,
};

export default injectIntl(withUser(withNotifier(withApi(TenantTypeahead))));
