import React, { useState } from "react";
import {
  backendShape,
  withBackend,
  Container,
  notifierShape,
  withNotifier,
  isSomething,
} from "lcm-iot-commons";
import RequestPasswordResetForm from "./RequestPasswordResetForm";
import RequestPasswordResetResponse from "./RequestPasswordResetResponse";

export function RequestPasswordReset({ backend, notifier }) {
  const [responseType, setResponseType] = useState();

  const handleOnSubmit = async (values, actions) => {
    try {
      const resetPasswordRequest = {
        email: values.email,
        captcha: values.captcha,
      };
      const response = await backend.post(
        "/users/request_password_reset",
        resetPasswordRequest,
      );
      setResponseType(response.type);
    } catch (error) {
      notifier.showError(backend.translateError(error));
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Container>
      {isSomething(responseType) ? (
        <RequestPasswordResetResponse
          id="request-password-response"
          type={responseType}
        />
      ) : (
        <RequestPasswordResetForm
          id="request-password-form"
          onSubmit={handleOnSubmit}
        />
      )}
    </Container>
  );
}

RequestPasswordReset.propTypes = {
  backend: backendShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default withNotifier(withBackend(RequestPasswordReset));
