import React from "react";
import PropTypes from "prop-types";
import { DetailsHeaderItem, DetailsPicture } from "../Details";
import PictureGallery from "../Pictures/PictureGallery";
import { picturesShape } from "../../shapes";

export default function AssetDetailsBasicInfo({
  productPictureUrl,
  pictures,
  serialNumber,
  productName,
  manufacturerName,
}) {
  return (
    <>
      <DetailsPicture>
        <PictureGallery
          additionalPictureUrls={productPictureUrl ? [productPictureUrl] : null}
          pictures={pictures}
          placeholder="icon-eh-device"
        />
      </DetailsPicture>
      <DetailsHeaderItem
        id="asset-serialnumber"
        translationKey="label.serial_number"
        value={serialNumber}
        breakValue
      />
      <DetailsHeaderItem
        id="asset-product-name"
        translationKey="label.product_name"
        value={productName}
      />
      <DetailsHeaderItem
        id="asset-manufacturer"
        translationKey="label.manufacturer"
        value={manufacturerName}
      />
    </>
  );
}

AssetDetailsBasicInfo.propTypes = {
  productPictureUrl: PropTypes.string,
  pictures: picturesShape,
  serialNumber: PropTypes.string.isRequired,
  productName: PropTypes.string,
  manufacturerName: PropTypes.string.isRequired,
};
