/* eslint-disable react/require-default-props */
import { FormattedMessage, injectIntl } from "react-intl";
import {
  ActionBar,
  ActionBarButtons,
  CreateActionButton,
  handleUnknownErrors,
  htmlFormat,
  htmlSpan,
  isNotFoundError,
  isSomething,
  isTouchDevice,
  List,
  Loader,
  navigateTo,
  SearchBar,
  withFlipper,
} from "lcm-iot-commons";

import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";

import PropTypes from "prop-types";

import { EdgeDeviceItem } from "./EdgeDeviceItem";
import {
  loadEdgeDevices,
  loadEdgeDeviceUplinkAddons,
  loadNextEdgeDevices,
} from "../../api/edgeDeviceApi";

export class EdgeDevices extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnSearch = this.handleOnSearch.bind(this);
    this.loadEdgeDevices = this.loadEdgeDevices.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.onEdgeDeviceRemove = this.onEdgeDeviceRemove.bind(this);
    this.doHandleLoadMoreEdgeDevices =
      this.doHandleLoadMoreEdgeDevices.bind(this);

    this.state = {
      fetching: true,
      edgeDevices: [],
      initialized: false,
      totalCount: 0,
      nextPageUrl: undefined,
      numberFreeAddons: 0,
    };
  }

  componentDidMount() {
    this.loadEdgeDevices();
  }

  onEdgeDeviceRemove(edgeDevice) {
    const { edgeDevices } = this.state;
    this.setState({});
    const count = edgeDevices.length - 1;
    this.loadUplinkAddOn();
    this.setState({
      edgeDevices: edgeDevices.filter((ed) => ed.id !== edgeDevice.id),
      totalCount: count,
      fetching: false,
      initialized: true,
    });
  }

  async handleOnSearch(value) {
    const edgeDevicesResult = await loadEdgeDevices({
      serial_number: `${value}*`,
    });
    this.setState({
      edgeDevices: edgeDevicesResult.edgeDevices,
      nextPageUrl: edgeDevicesResult.nextPageUrl,
      totalCount: edgeDevicesResult.totalCount,
      fetching: false,
      initialized: true,
    });
  }

  async doHandleLoadMoreEdgeDevices() {
    const { edgeDevices, nextPageUrl } = this.state;
    this.setState({ fetching: true });

    let edgeDevicesResult;

    if (nextPageUrl) {
      edgeDevicesResult = await loadNextEdgeDevices(nextPageUrl);
      edgeDevicesResult.edgeDevices = edgeDevices.concat(
        edgeDevicesResult.edgeDevices,
      );
    } else {
      const filter = { order_by: "name" };
      edgeDevicesResult = await loadEdgeDevices(filter);
    }

    await this.loadUplinkAddOn();

    this.setState({
      edgeDevices: edgeDevicesResult.edgeDevices,
      nextPageUrl: edgeDevicesResult.nextPageUrl,
      totalCount: edgeDevicesResult.totalCount,
      fetching: false,
      initialized: true,
    });
  }

  async loadUplinkAddOn() {
    const addonsResponse = await loadEdgeDeviceUplinkAddons();
    let foundFreeAddons = 0;
    addonsResponse?.add_ons?.forEach((addon) => {
      foundFreeAddons += addon.quantity - addon.assigned_edge_devices_count;
    });
    this.setState({
      numberFreeAddons: foundFreeAddons,
    });
  }

  async loadEdgeDevices() {
    const { intl } = this.props;
    this.doHandleLoadMoreEdgeDevices().catch((apiErrors) => {
      if (isNotFoundError(apiErrors)) {
        navigateTo("/404");
      } else {
        handleUnknownErrors(
          apiErrors,
          intl.formatMessage({ id: "api.error.unknown" }),
        );
      }
    });
  }

  handleLoadMore() {
    const { intl } = this.props;
    this.doHandleLoadMoreEdgeDevices().catch((apiErrors) => {
      handleUnknownErrors(
        apiErrors,
        intl.formatMessage({ id: "api.error.unknown" }),
      );
    });
  }

  renderEdgeDevices(edgeDevices) {
    const { intl, flipper } = this.props;
    const { initialized } = this.state;
    return initialized && edgeDevices
      ? edgeDevices.map((edgeDevice) => (
          <EdgeDeviceItem
            key={edgeDevice.id}
            edgeDevice={edgeDevice}
            intl={intl}
            showItemMenu
            flipper={flipper}
            onEdgeDeviceRemove={this.onEdgeDeviceRemove}
          />
        ))
      : [];
  }

  render() {
    const { intl, flipper } = this.props;
    const {
      initialized,
      edgeDevices,
      nextPageUrl,
      fetching,
      totalCount,
      numberFreeAddons,
    } = this.state;

    const noEdgeDevicesFound =
      initialized && totalCount === 0 && !fetching ? (
        <div id="no-edge_devices-found" className="clue">
          <div className="clue-header">
            <FormattedMessage id="edge_devices.no_edge_devices_found" />
          </div>
        </div>
      ) : null;

    const badge = fetching ? (
      <span className="loading">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>
    ) : (
      totalCount
    );

    const actionBarButtons = (
      <ActionBarButtons>
        <div>
          <CreateActionButton
            disabled={!initialized || numberFreeAddons === 0}
            id="create-edge-device-button"
            target="/edge_devices/create"
            label={intl.formatMessage({ id: "button.create" })}
          />
        </div>
      </ActionBarButtons>
    );

    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div>
              <ActionBar>
                <h1 id="edge-devices-header">
                  <FormattedMessage id="edge_devices.header" />
                  <span id="edge-devices-count" className="badge">
                    {badge}
                  </span>
                </h1>
                {flipper.edgeDeviceLateBinding ? actionBarButtons : null}
              </ActionBar>

              {initialized && flipper.edgeDeviceLateBinding ? (
                <div className="edge-free-uplink-addons-align">
                  <FormattedMessage
                    id="edge_device.number_free_addons"
                    values={{
                      ...htmlFormat,
                      numberOfUplinks: numberFreeAddons,
                      span: htmlSpan({ className: "edge-free-uplink-addons" }),
                    }}
                  />
                </div>
              ) : null}

              <SearchBar
                autoFocus={!isTouchDevice()}
                onSearch={this.handleOnSearch}
                placeholder={intl.formatMessage({
                  id: "edge_devices.serial_number",
                })}
              />
              <List id="edge-device-list">
                <InfiniteScroll
                  initialLoad={false}
                  loadMore={this.handleLoadMore}
                  hasMore={!fetching && isSomething(nextPageUrl)}
                >
                  {this.renderEdgeDevices(edgeDevices)}
                </InfiniteScroll>
              </List>
              {noEdgeDevicesFound}
              <Loader loading={fetching} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EdgeDevices.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  flipper: PropTypes.shape({
    edgeDeviceLateBinding: PropTypes.bool,
  }),
};

EdgeDeviceItem.defaultProps = {
  intl: undefined,
  flipper: PropTypes.shape({
    edgeDeviceLateBinding: false,
  }),
};

export default injectIntl(withFlipper(EdgeDevices));
