import React, { useState } from "react";
import {
  withApi,
  withIntl,
  withNotifier,
  withUser,
  apiShape,
  intlShape,
  notifierShape,
  userShape,
  Heading,
} from "lcm-iot-commons";

export function NewsletterHandler({ api, intl, notifier, user }) {
  const [newsletterEnabled, setNewsletterEnabled] = useState(false);
  const [eloquaConsentStatus, setEloquaConsentStatus] = useState();

  const loadNewsletterSpecification = async () => {
    try {
      const key = "eh.pcps.user.newsletter_recipient";
      const newsletterSpec = await api.get(`/users/${user.id}/specifications`, {
        key,
      });
      setEloquaConsentStatus(newsletterSpec[key]?.value);
      if (
        newsletterSpec[key]?.value === "true" ||
        newsletterSpec[key]?.value === "confirmation_pending"
      ) {
        setNewsletterEnabled(true);
      }
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  React.useEffect(() => {
    loadNewsletterSpecification();
  }, []);

  const handleOnChange = async () => {
    try {
      const specificationValue = newsletterEnabled
        ? "false"
        : "confirmation_pending";
      const params = {
        "eh.pcps.user.newsletter_recipient": { value: specificationValue },
      };
      await api.patch(`/users/${user.id}/specifications`, params);
      setNewsletterEnabled(!newsletterEnabled);
      setEloquaConsentStatus(specificationValue);
      notifier.showSuccess(
        intl.formatMessage({
          id: "user_profile.newsletter_success_notification",
        }),
      );
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  const renderConfirmationHint =
    eloquaConsentStatus === "confirmation_pending" ? (
      <div id="newsletter-hint" className="newsletter-hint">
        <span className="lcm-iot-icon-info info-icon" />
        <div id="eloqua-confirmation-hint">
          {intl.formatMessage({
            id: "user_profile.newsletter.confirmation_pending",
          })}
        </div>
      </div>
    ) : null;

  return (
    <div id="email-newsletter-group" className="newsletter">
      <Heading
        id="user-profile-emails"
        level={3}
        title={intl.formatMessage({
          id: "user_profile.newsletter_emails_group",
        })}
      />
      <div id="newsletter-activation">
        <input
          id="email-newsletter"
          name="email-newsletter"
          type="checkbox"
          checked={newsletterEnabled}
          onChange={handleOnChange}
        />
        <label htmlFor="email-newsletter">
          {intl.formatMessage({ id: "user_profile.newsletter" })}
        </label>
      </div>
      {renderConfirmationHint}
    </div>
  );
}

NewsletterHandler.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  user: userShape.isRequired,
};

export default withApi(withUser(withNotifier(withIntl(NewsletterHandler))));
