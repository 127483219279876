import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import {
  Row,
  Loader,
  sortBy,
  Column,
  withApi,
  Container,
  ActionBar,
  withBrowser,
  withNotifier,
  withAccessRights,
  ActionBarButtons,
  apiShape,
  DetailsItem,
  browserShape,
  notifierShape,
  EditActionButton,
  accessRightsShape,
  DeleteActionButton,
  AssignableUserList,
  BadRequestError,
} from "lcm-iot-commons";

function TenantDetails({ api, notifier, browser, accessRights, match }) {
  const [tenant, setTenant] = React.useState();
  const [users, setUsers] = React.useState();
  const [admins, setAdmins] = React.useState();
  const { id } = match.params;
  const intl = useIntl();

  React.useEffect(() => {
    const loadData = async () => {
      const tenantResponse = await api.get(`/tenants/${id}`);
      const usersResponse = await api.getAll(`/tenants/${id}/users`, {
        include: "technical_connect_users",
      });
      const adminsResponse = await api.getAll(`/tenants/${id}/admins`, {
        include: "technical_connect_users",
      });
      setTenant(tenantResponse);
      setUsers(usersResponse.users);
      setAdmins(adminsResponse.users);
    };
    loadData().catch((error) => {
      notifier.showError(api.translateError(error));
    });
  }, []);

  const assignUser = async (user) => {
    await api.post(`/tenants/${id}/users`, { users: [{ id: user.id }] });
    setUsers(sortBy(users.concat([{ ...{ ...user } }]), "first_name"));
  };

  const unassignUser = async (user) => {
    await api.delete(`/tenants/${id}/users`, { users: [{ id: user.id }] });
    setUsers(users.filter((u) => u.id !== user.id));
  };

  const assignAdmin = async (admin) => {
    await api.post(`/tenants/${id}/admins`, { admins: [{ id: admin.id }] });
    setAdmins(sortBy(admins.concat([{ ...{ ...admin } }]), "first_name"));
  };

  const unassignAdmin = async (admin) => {
    await api.delete(`/tenants/${id}/admins`, { admins: [{ id: admin.id }] });
    setAdmins(admins.filter((u) => u.id !== admin.id));
  };

  const deleteTenant = async () => {
    try {
      await api.delete(`/tenants/${id}`);
      notifier.showSuccess(
        intl.formatMessage({ id: "tenants.actions.delete.notification" }),
      );
      browser.navigateTo("/tenants");
    } catch (error) {
      if (
        error instanceof BadRequestError &&
        error.contains("assigned_restriction")
      ) {
        notifier.showError(
          intl.formatMessage({ id: "api.error.tenant.assigned_restriction" }),
        );
      } else {
        notifier.showError(api.translateError(error));
      }
    }
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <h1 id="tenant-details-header">
              {intl.formatMessage({ id: "tenant_details.header" })}
            </h1>
            <ActionBarButtons>
              <EditActionButton
                id="edit-tenant-button"
                target={`/tenants/${id}/edit`}
                disabled={!accessRights.canUpdate}
              />
              <DeleteActionButton
                id="delete-tenant-button"
                disabled={!accessRights.canDelete}
                modalTitle={intl.formatMessage({
                  id: "tenants.actions.delete.modal_title",
                })}
                modalMessage={intl.formatMessage({
                  id: "tenants.actions.delete.modal_message",
                })}
                onConfirm={deleteTenant}
              />
            </ActionBarButtons>
          </ActionBar>
          {!(tenant || users) && <Loader id="tenant-details-loader" />}
        </Column>
      </Row>
      <Row>
        {tenant && (
          <Column>
            <div>
              <DetailsItem
                id="tenant-name"
                translationKey="label.name"
                value={tenant.name}
              />
              <DetailsItem
                id="tenant-description"
                translationKey="label.description"
                value={tenant.description}
              />
            </div>
            {users && (
              <div id="user-list">
                <h2>{intl.formatMessage({ id: "users.header" })}</h2>
                <AssignableUserList
                  users={users}
                  onAssignUser={assignUser}
                  onUnassignUser={unassignUser}
                  canUpdate={accessRights.canUpdate}
                  acceptConnectUsers
                />
              </div>
            )}
            {admins && (
              <div id="admin-list">
                <h2>
                  {intl.formatMessage({ id: "tenant_details.admins_header" })}
                </h2>
                <AssignableUserList
                  users={admins}
                  onAssignUser={assignAdmin}
                  onUnassignUser={unassignAdmin}
                  canUpdate={accessRights.canUpdate}
                  disableUnassignForLastUser
                  acceptConnectUsers
                />
              </div>
            )}
          </Column>
        )}
      </Row>
    </Container>
  );
}

TenantDetails.propTypes = {
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withNotifier(
  withBrowser(withApi(withAccessRights(TenantDetails, "Tenant"))),
);
