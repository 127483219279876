/* eslint-disable func-names */
import React from "react";
import uuid from "uuid";

export const htmlLinebreak = () =>
  function () {
    return <br />;
  };
export const htmlLink = ({ href, target }) =>
  function (...chunks) {
    return (
      <a
        key={uuid()}
        href={href}
        rel="noopener noreferrer"
        target={target}
        onClick={(e) => e.stopPropagation()}
      >
        {chunks}
      </a>
    );
  };
export const htmlParagraph = (prop = {}) =>
  function (...chunks) {
    return (
      <p key={uuid()} className={prop.className}>
        {chunks}
      </p>
    );
  };
export const htmlSpan = (prop = {}) =>
  function (...chunks) {
    return (
      <span key={uuid()} className={prop.className}>
        {chunks}
      </span>
    );
  };
export const htmlStrong = () =>
  function (...chunks) {
    return <strong key={uuid()}>{chunks}</strong>;
  };
export const htmlButton = (onClick, prop = {}) =>
  function (...chunks) {
    return (
      <button
        type="button"
        className={prop.className}
        key={uuid()}
        onClick={onClick}
      >
        {chunks}
      </button>
    );
  };

export const htmlH1 = (prop = {}) =>
  function (...chunks) {
    return (
      <h1 key={uuid()} className={prop.className}>
        {chunks}
      </h1>
    );
  };
export const htmlH2 = (prop = {}) =>
  function (...chunks) {
    return (
      <h2 key={uuid()} className={prop.className}>
        {chunks}
      </h2>
    );
  };
export const htmlH3 = (prop = {}) =>
  function (...chunks) {
    return (
      <h3 key={uuid()} className={prop.className}>
        {chunks}
      </h3>
    );
  };
export const htmlUl = (prop = {}) =>
  function (...chunks) {
    return (
      <ul key={uuid()} className={prop.className}>
        {chunks}
      </ul>
    );
  };
export const htmlLi = (prop = {}) =>
  function (...chunks) {
    return (
      <li key={uuid()} className={prop.className}>
        {chunks}
      </li>
    );
  };

export default {
  br: htmlLinebreak(),
  p: htmlParagraph(),
  span: htmlSpan(),
  strong: htmlStrong(),
  button: htmlButton(),
  h1: htmlH1(),
  h2: htmlH2(),
  h3: htmlH3(),
  ul: htmlUl(),
  li: htmlLi(),
};
