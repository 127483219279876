import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import NotFound from "../components/Errors/NotFound";
import Forbidden from "../components/Errors/Forbidden";
import Unknown from "../components/Errors/Unknown";
import { useAccessRightsQuery } from "../hooks";

export function enforceAccessRightCanRead(accessRights) {
  return accessRights.canRead;
}

export function enforceAccessRightCanUpdate(accessRights) {
  return accessRights.canUpdate;
}

const withAccessRights = (
  ChildComponent,
  type = undefined,
  validate = undefined,
) => {
  const WrappedComponent = withRouter((props) => {
    const { match, testAccessRights } = props;

    const getPermitableType = () => {
      if (type !== undefined) return type;
      const { objects } = match.params;
      if (objects !== undefined)
        return objects.substring(0, objects.length - 1);
      return undefined;
    };
    const neededType = getPermitableType();

    const { data, isError } = useAccessRightsQuery(neededType, {
      enabled: !testAccessRights && !!match.params.id,
    });

    const accessRights = testAccessRights || data;

    if (!match.params.id) return <ChildComponent {...props} />;
    if (isError) return <Unknown />;
    if (!accessRights) return null;
    if (!validate || validate(accessRights))
      return <ChildComponent {...props} accessRights={accessRights} />;
    if (accessRights.canRead) return <Forbidden />;
    return <NotFound />;
  });

  WrappedComponent.propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    testAccessRights: PropTypes.shape({}),
  };

  WrappedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;

  return WrappedComponent;
};

export default withAccessRights;
