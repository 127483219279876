import React from "react";
import PropTypes from "prop-types";
import { DetailsItem } from "../Details";
import ExpirableDate from "../ExpirableDate";
import AssetConnectivityIssuesNotification from "./AssetConnectivityIssuesNotification";
import { Timeouts } from "../../constants";

function AssetLastSeen({ asset }) {
  return (
    <DetailsItem id="asset-last-seen" translationKey="label.last_seen">
      {asset.last_seen_at ? (
        <ExpirableDate
          id="asset-last-seen-date"
          date={new Date(asset.last_seen_at)}
          hoursAfterLatestUpdateToExpire={Timeouts.ASSET_OFFLINE_WARNING_HOURS}
          infoPopup={
            <AssetConnectivityIssuesNotification
              type="last-seen"
              lastConnectionDate={asset.last_seen_at}
              hoursAfterLatestUpdateToExpire={
                Timeouts.ASSET_OFFLINE_WARNING_HOURS
              }
            />
          }
        />
      ) : null}
    </DetailsItem>
  );
}

AssetLastSeen.propTypes = {
  asset: PropTypes.shape({
    last_seen_at: PropTypes.string,
  }).isRequired,
};

export default AssetLastSeen;
