import React from "react";
import { intlShape } from "../../shapes";
import { withIntl } from "../../wrappers";
import { htmlLink, url } from "../../utils";
import ContextualNotification from "../Contextuals/ContextualNotification";

export function FileStorageLimitHelper({ intl }) {
  const content = (
    <div id="file-storage-helper">
      <p>
        {intl.formatMessage({
          id: "file-storage-reached.contextual-description",
        })}
      </p>
      <p>
        {intl.formatMessage(
          { id: "file-storage-reached.contextual-upgrade" },
          { a: htmlLink({ href: url("/upgrade") }) },
        )}
      </p>
    </div>
  );

  return (
    <ContextualNotification
      title={intl.formatMessage({
        id: "file-storage-reached.contextual-title",
      })}
      interactive
    >
      {content}
    </ContextualNotification>
  );
}

FileStorageLimitHelper.propTypes = {
  intl: intlShape.isRequired,
};

export default withIntl(FileStorageLimitHelper);
