import PropTypes from "prop-types";

export const invoicePaymentDetailsShape = PropTypes.arrayOf(
  PropTypes.shape({
    status: PropTypes.string,
    currency: PropTypes.string,
    dueDate: PropTypes.string,
    amountDue: PropTypes.string,
  }),
);
