import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber as OriginalFormattedNumber } from "react-intl";
import { isSomething } from "../../utils";
import { rulesShape, withRules } from "../../context";

export function FormattedNumber(props) {
  const { minimumFractionDigits, maximumFractionDigits, value, rules } = props;

  const min =
    minimumFractionDigits || rules.application().get("minDecimalDigits");
  const max =
    maximumFractionDigits || rules.application().get("maxDecimalDigits");

  return isSomething(value) ? (
    <OriginalFormattedNumber
      {...props}
      minimumFractionDigits={min}
      maximumFractionDigits={max}
      value={value}
    />
  ) : (
    <span>-</span>
  );
}

FormattedNumber.propTypes = {
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
  value: PropTypes.number,
  rules: rulesShape.isRequired,
};

export default withRules(FormattedNumber);
