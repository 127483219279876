import React from "react";
import { withIntl } from "../../wrappers";
import { intlShape } from "../../shapes";
import ActionButton from "./ActionButton";

export function DetailsActionButton(props) {
  return (
    <ActionButton
      {...props}
      icon="lcm-iot-icon-info"
      defaultLabel={props.intl.formatMessage({ id: "global.show_details" })}
      id="details-action-button"
    />
  );
}

DetailsActionButton.propTypes = {
  intl: intlShape.isRequired,
};

export default withIntl(DetailsActionButton);
