/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { MenuItem } from "react-bootstrap";
import ItemMenu from "lcm-iot-commons/client/lib/components/ItemMenu";
import ConfirmationModal from "lcm-iot-commons/client/lib/components/ConfirmationModal";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  intlShape,
  navigateTo,
  userGroupShape,
  showSuccess,
  handleUnknownErrors,
  fetchAccessRights,
  deleteUserGroup,
} from "lcm-iot-commons";

class UserGroupItem extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnItemMenuOpen = this.handleOnItemMenuOpen.bind(this);
    this.handleOnEditClick = this.handleOnEditClick.bind(this);
    this.handleOnDeleteClick = this.handleOnDeleteClick.bind(this);
    this.handleOnModalClose = this.handleOnModalClose.bind(this);
    this.handleOnConfirm = this.handleOnConfirm.bind(this);
    this.handleOnItemMenuOpen = this.handleOnItemMenuOpen.bind(this);
    this.state = {
      accessRights: {
        canUpdate: false,
        canDelete: false,
        showDeleteModal: false,
      },
      deleting: false,
    };
  }

  handleOnClick() {
    const { usergroup } = this.props;
    navigateTo(`/usergroups/${usergroup.id}`);
  }

  handleOnEditClick() {
    const { usergroup } = this.props;
    navigateTo(`/usergroups/${usergroup.id}/edit`);
  }

  handleOnConfirm() {
    const { intl, usergroup, onUserGroupRemoved } = this.props;
    const { deleting } = this.state;
    this.resetActionsState();

    if (deleting) {
      return deleteUserGroup(usergroup.id)
        .then(() => {
          showSuccess(
            intl.formatMessage({
              id: "usergroups.actions.delete.notification",
            }),
          );
          onUserGroupRemoved(usergroup);
        })
        .catch((apiErrors) => {
          handleUnknownErrors(
            apiErrors,
            intl.formatMessage({ id: "api.error.unknown" }),
          );
        });
    }
    return null;
  }

  resetActionsState() {
    this.setState({ deleting: false });
  }

  handleOnModalClose() {
    this.resetActionsState();
  }

  handleOnDeleteClick() {
    this.setState({ deleting: true });
  }

  handleOnItemMenuOpen() {
    const { usergroup, intl } = this.props;
    return fetchAccessRights("Usergroup", usergroup.id)
      .then((response) =>
        this.setState({
          accessRights: {
            canUpdate: response.canUpdate,
            canDelete: response.canDelete,
          },
        }),
      )
      .catch((errors) =>
        handleUnknownErrors(
          errors,
          intl.formatMessage({ id: "api.error.unknown" }),
        ),
      );
  }

  isSingleRow = ({ description, name }) => !description || !name;

  render() {
    const { usergroup, intl } = this.props;
    const { deleting, accessRights } = this.state;
    const { canUpdate, canDelete } = accessRights;
    return (
      <li className="list-item clickable usergroup-item">
        <div className="list-item-image" onClick={this.handleOnClick}>
          <span className="icon lcm-iot-icon-team" />
        </div>
        <div
          className="list-item-content"
          onClick={this.handleOnClick}
          style={this.isSingleRow(usergroup) ? { verticalAlign: "middle" } : {}}
        >
          <div className="list-item-header">{usergroup.name}</div>
          {usergroup.description && (
            <div className="list-item-details">{usergroup.description}</div>
          )}
        </div>
        <div className="list-item-actions" style={{ verticalAlign: "unset" }}>
          <ItemMenu onOpen={this.handleOnItemMenuOpen}>
            <MenuItem
              id="item-edit"
              onSelect={this.handleOnEditClick}
              disabled={!canUpdate}
            >
              <FormattedMessage id="button.edit" />
            </MenuItem>
            <MenuItem
              id="item-delete"
              onSelect={this.handleOnDeleteClick}
              disabled={!canDelete}
            >
              <FormattedMessage id="button.delete" />
            </MenuItem>
          </ItemMenu>
        </div>
        {deleting ? (
          <ConfirmationModal
            id="confirmation-modal"
            show
            titleText={intl.formatMessage({
              id: "usergroups.actions.delete.modal_title",
            })}
            messageText={intl.formatMessage({
              id: "usergroups.actions.delete.modal_message",
            })}
            onConfirm={this.handleOnConfirm}
            onClose={this.handleOnModalClose}
          />
        ) : null}
      </li>
    );
  }
}

UserGroupItem.propTypes = {
  usergroup: userGroupShape,
  onUserGroupRemoved: PropTypes.func,
  intl: intlShape,
};

UserGroupItem.defaultProps = {
  usergroup: undefined,
  onUserGroupRemoved: undefined,
  intl: undefined,
};

export default injectIntl(UserGroupItem);
