import React from "react";
import PropTypes from "prop-types";
import Clickable from "../Clickable";
import Icon from "../Icon";
import NamurIcon from "../NamurIcon";
import StatusBadge from "../StatusBadge";
import { Chips } from "../Chips";

function ListItem({
  actions,
  description,
  disabled,
  icon,
  iconPrimary,
  id,
  image,
  imageUrl,
  namur,
  onClick,
  showDisclosureIndicator,
  status,
  target,
  title,
  value,
  specifications,
  nodes,
  extraContent,
  onAuxClick,
}) {
  const classSubstring = icon ? "icon" : "image";
  const statusElements = (
    <>
      {status && (
        <div className={`list-item-${classSubstring}-status`}>
          <StatusBadge name={status} />
        </div>
      )}
      {namur && (
        <div className={`list-item-${classSubstring}-namur`}>
          <NamurIcon type={namur} size={15} />
        </div>
      )}
    </>
  );

  const statusElementFromUrl = imageUrl ? (
    <div className="list-item-image">
      <div
        className="list-item-image-image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      {statusElements}
    </div>
  ) : null;

  const statusElementFromImage = image ? (
    <div className="list-item-image">
      {image}
      {statusElements}
    </div>
  ) : null;

  const statusElementFromIcon = icon ? (
    <div className={`list-item-icon${iconPrimary ? " primary" : ""}`}>
      <Icon name={icon} />
      {statusElements}
    </div>
  ) : null;

  const content = (
    <div className="list-item-content">
      <div className="list-item-content-wrapper">
        <div className="list-item-content-title">{title}</div>
        <div className="list-item-content-description">{description}</div>
        {specifications && (
          <div className="list-item-chip">
            <Icon name="icon3-eh-list chip-icon" />
            <Chips items={specifications} withTooltip />
          </div>
        )}
        {nodes && (
          <div className="list-item-chip">
            <Icon name="icon3-eh-folder chip-icon" />
            <Chips items={nodes} withTooltip />
          </div>
        )}
      </div>
      {value ? <div className="list-item-content-value">{value}</div> : null}
    </div>
  );

  return (
    <div
      data-testid={id}
      id={id}
      className={`list-item${disabled ? " disabled" : ""}`}
    >
      <Clickable
        target={target}
        onClick={!disabled ? onClick : null}
        onAuxClick={!disabled ? onAuxClick : null}
        outside
      >
        {statusElementFromUrl}
        {statusElementFromImage}
        {statusElementFromIcon}
        {content}
      </Clickable>
      {actions || showDisclosureIndicator ? (
        <div className="list-item-actions">
          {showDisclosureIndicator ? <Icon name="icon-eh-arrow-right" /> : null}
          {actions}
        </div>
      ) : null}
      {extraContent}
    </div>
  );
}

ListItem.propTypes = {
  actions: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.string,
  iconPrimary: PropTypes.bool,
  image: PropTypes.node,
  imageUrl: PropTypes.string,
  namur: PropTypes.string,
  onClick: PropTypes.func,
  showDisclosureIndicator: PropTypes.bool,
  status: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  specifications: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ),
  nodes: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ),
  extraContent: PropTypes.node,
  onAuxClick: PropTypes.func,
};

ListItem.defaultProps = {
  actions: undefined,
  description: undefined,
  disabled: false,
  icon: undefined,
  iconPrimary: false,
  image: undefined,
  imageUrl: undefined,
  namur: undefined,
  onClick: undefined,
  showDisclosureIndicator: false,
  status: undefined,
  target: undefined,
  title: undefined,
  value: undefined,
};

export default ListItem;
