import React from "react";

import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import SearchFilterItem from "./SearchFilterItem";

export function SearchFilters({ filters, onChange }) {
  const intl = useIntl();

  const handleFilterChange = (filterName, checked) => {
    if (checked) {
      onChange([...filters, filterName]);
    }
    if (!checked) {
      onChange(filters.filter((item) => item !== filterName));
    }
  };

  return (
    <ul className="list-inline">
      <li className="list-inline-item filter-label">
        <span className="additional-info">
          <FormattedMessage id="search.filters" />
        </span>
      </li>
      <SearchFilterItem
        label={intl.formatMessage({ id: "search.filters.assets" })}
        filter="assets"
        onChange={handleFilterChange}
        checked={filters.indexOf("assets") >= 0}
      />
      <SearchFilterItem
        label={intl.formatMessage({ id: "search.filters.nodes" })}
        filter="nodes"
        onChange={handleFilterChange}
        checked={filters.indexOf("nodes") >= 0}
      />
      <SearchFilterItem
        label={intl.formatMessage({ id: "search.filters.instrumentations" })}
        filter="instrumentations"
        onChange={handleFilterChange}
        checked={filters.indexOf("instrumentations") >= 0}
      />
    </ul>
  );
}

SearchFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchFilters;
