import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import FilesUpload from "../Files/FilesUpload";
import { intlShape } from "../../shapes";
import {
  withBackend,
  backendShape,
  notifierShape,
  withNotifier,
} from "../../context";

export function TicketAttachmentsForm({
  ticketId,
  backend,
  notifier,
  intl,
  returnResponse,
}) {
  async function uploadFiles(files, index = 0) {
    try {
      const response = await backend.upload(
        `/support/tickets/${ticketId}/attachments`,
        { name: files[index].name, file: files[index] },
      );
      if (returnResponse) {
        returnResponse(response);
      }
      if (files.length > index + 1) {
        await uploadFiles(files, index + 1);
      }
      notifier.showSuccess(
        intl.formatMessage({
          id: "support.ticket.attachments.uploaded_notification",
        }),
      );
    } catch (error) {
      notifier.showError(
        intl.formatMessage({
          id: "support.ticket.attachments.error_notification",
        }),
      );
    }
  }

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });
    uploadFiles(acceptedFiles);
  };

  return (
    <FilesUpload
      onDrop={onDrop}
      autofocus
      id="attachment-upload"
      name="filesUpload"
      multiple
      buildInWhiteList="image/*, video/*, .pdf, .zip"
      fileSizeLimit={1024 * 1024 * 10}
      dropzoneInfoPrefix="add_attachment"
    />
  );
}

TicketAttachmentsForm.propTypes = {
  ticketId: PropTypes.string,
  intl: intlShape,
  backend: backendShape,
  notifier: notifierShape,
  returnResponse: PropTypes.func,
};

export default withBackend(withNotifier(injectIntl(TicketAttachmentsForm)));
