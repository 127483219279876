import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
  ActionBar,
  Details,
  DetailsHeaderItem,
  DetailsItem,
  DetailsPicture,
  PictureGallery,
  Heading,
  Container,
  Column,
  Row,
  Loader,
  backendShape,
  intlShape,
  notifierShape,
  ContextualHelp,
  withBackend,
  withApi,
  apiShape,
  withNotifier,
  ActionBarButtons,
  CreateActionButton,
} from "lcm-iot-commons";
import { OpcUaServerCertificatesList } from "./OpcUaServerCertificatesList";
import {
  opcUaServerIconClassName,
  opcUaServerStatusIconClassName,
} from "../../utils/statusUtils";
import OpcUaServerWhitelist from "./OpcUaServerWhitelist";

export function OpcUaServerDetails({ intl, match, notifier, api, backend }) {
  const [opcUaServer, setOpcUaServer] = useState();
  const [accessRights, setAccessRights] = useState();

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const subscription = await api.get(
          `/api_subscriptions/${match.params.subscription_id}`,
        );
        // eslint-disable-next-line camelcase
        const accessRightsResponse = await api.get(
          "/users/current/access_rights",
          {
            permitable_type: "ClientApplication",
            permitable_id: subscription?.client_application.id,
          },
        );
        setAccessRights({
          canUpdate: accessRightsResponse.can_update,
        });
        setOpcUaServer(
          await backend.get(
            `/api_subscriptions/${match.params.subscription_id}/opc_ua_servers/${match.params.id}`,
          ),
        );
      } catch (error) {
        notifier.showError(backend.translateError(error));
      }
    };
    loadData();
  }, [match]);

  return (
    <Loader loading={!opcUaServer}>
      <Container>
        <Row>
          <Column>
            <ActionBar>
              <Heading
                id="details-heading"
                title={intl.formatMessage({
                  id: "opc_ua_server_details.header",
                })}
              />
            </ActionBar>
          </Column>
        </Row>
        {opcUaServer ? (
          <Details>
            <Row>
              <Column>
                <DetailsPicture>
                  <PictureGallery placeholder={opcUaServerIconClassName()} />
                </DetailsPicture>
                <DetailsHeaderItem
                  intl={intl}
                  id="opc-ua-server-hostname"
                  translationKey="label.opc_ua_connection_url"
                  value={`opc.tcp://${opcUaServer.hostname}:62541/Server`}
                  breakValue
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <DetailsItem
                  intl={intl}
                  id="opc-ua-server-status"
                  icon={opcUaServerStatusIconClassName(opcUaServer.status)}
                  translationKey="label.status"
                  value={intl.formatMessage({
                    id: `enum.opc_ua_server.${opcUaServer.status}`,
                  })}
                />
              </Column>
            </Row>
            <div
              id="client_certificates-list"
              className="row space-before space-after"
            >
              <div className="col-md-12">
                <ActionBar>
                  <Heading
                    id="client-certificates"
                    title={intl.formatMessage({
                      id: "opc_ua_server_details.client_certificates",
                    })}
                    level={2}
                  >
                    <ContextualHelp
                      title={intl.formatMessage({
                        id: "opc_ua_server_details.client_certificates",
                      })}
                    >
                      <p>
                        {intl.formatMessage({
                          id: "client_certificate.help_1",
                        })}
                      </p>
                      <p>
                        {intl.formatMessage({
                          id: "client_certificate.help_2",
                        })}
                      </p>
                      <p>
                        {intl.formatMessage({
                          id: "client_certificate.help_3",
                        })}
                      </p>
                    </ContextualHelp>
                  </Heading>
                </ActionBar>
                <OpcUaServerCertificatesList
                  type="client"
                  notifier={notifier}
                  intl={intl}
                  backend={backend}
                  opcUaServer={opcUaServer}
                  accessRights={accessRights}
                />
              </div>
            </div>
            <div
              id="ca_certificates-list"
              className="row space-before space-after"
            >
              <div className="col-md-12">
                <ActionBar>
                  <Heading
                    id="ca-certificates"
                    title={intl.formatMessage({
                      id: "opc_ua_server_details.ca_certificates",
                    })}
                    level={2}
                  >
                    <ContextualHelp
                      title={intl.formatMessage({
                        id: "opc_ua_server_details.ca_certificates",
                      })}
                    >
                      <p>
                        {intl.formatMessage({ id: "ca_certificate.help_1" })}
                      </p>
                      <p>
                        {intl.formatMessage({ id: "ca_certificate.help_2" })}
                      </p>
                      <p>
                        {intl.formatMessage({ id: "ca_certificate.help_3" })}
                      </p>
                    </ContextualHelp>
                  </Heading>
                  <ActionBarButtons>
                    <div>
                      <CreateActionButton
                        id="create-ca-certificate"
                        disabled={!accessRights.canUpdate}
                        target={`/subscriptions/connect/${opcUaServer.subscription_id}/opc_ua_servers/${opcUaServer.id}/upload_ca_certificate`}
                      />
                    </div>
                  </ActionBarButtons>
                </ActionBar>
                <OpcUaServerCertificatesList
                  type="ca"
                  notifier={notifier}
                  intl={intl}
                  backend={backend}
                  opcUaServer={opcUaServer}
                  accessRights={accessRights}
                />
              </div>
            </div>
            <div id="whitelist" className="row space-before space-after">
              <div className="col-md-12">
                <ActionBar>
                  <Heading
                    id="whitelist-header"
                    title={intl.formatMessage({
                      id: "opc_ua_server_details.whitelist.header",
                    })}
                    level={2}
                  >
                    <ContextualHelp
                      title={intl.formatMessage({
                        id: "opc_ua_server_details.whitelist.header",
                      })}
                    >
                      <p>{intl.formatMessage({ id: "whitelist.help_1" })}</p>
                      <p>{intl.formatMessage({ id: "whitelist.help_2" })}</p>
                      <p>{intl.formatMessage({ id: "whitelist.help_3" })}</p>
                    </ContextualHelp>
                  </Heading>
                </ActionBar>
                <OpcUaServerWhitelist
                  opcUaServer={opcUaServer}
                  accessRights={accessRights}
                />
              </div>
            </div>
          </Details>
        ) : null}
      </Container>
    </Loader>
  );
}

OpcUaServerDetails.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      subscription_id: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
  backend: backendShape.isRequired,
};

export default withNotifier(
  withApi(withBackend(injectIntl(OpcUaServerDetails))),
);
