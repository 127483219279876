import React from "react";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";

import {
  ActionBar,
  Column,
  Container,
  Details,
  DetailsItem,
  Heading,
  Row,
  useApi,
  useNotifier,
  Loader,
  Form,
  TextArea,
  SelectBox,
  ButtonGroup,
  SubmitButton,
  CancelButton,
} from "lcm-iot-commons";

import AuthenticationTypes from "../../constants/AuthenticationTypes";

export default function ApiKeyCreate() {
  const api = useApi();
  const intl = useIntl();
  const notifier = useNotifier();
  const navigate = useNavigate();
  const { id } = useParams();

  const authenticationTypes = [
    {
      id: AuthenticationTypes.OAUTH,
      name: intl.formatMessage({
        id: `enum.authentication_type.${AuthenticationTypes.OAUTH}`,
      }),
    },
    {
      id: AuthenticationTypes.BASIC,
      name: intl.formatMessage({
        id: `enum.authentication_type.${AuthenticationTypes.BASIC}`,
      }),
    },
  ];

  const { data: subscription, isLoading: isLoadingSubscription } =
    api.get.useQuery(`/api_subscriptions/${id}`);
  const { mutate: createApiKey } = api.post.useMutation("/api_keys");

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form id="api-key-create-form" {...formProps}>
        <SelectBox
          {...formProps}
          id="api-key-authentication-type"
          name="authenticationType"
          label={intl.formatMessage({ id: "label.authentication_type" })}
          options={authenticationTypes}
          required
        />
        <TextArea
          {...formProps}
          id="api-key-description"
          name="description"
          label={intl.formatMessage({ id: "label.description" })}
        />
        <ButtonGroup>
          <SubmitButton id="create-api-key-submit" fetching={isSubmitting} />
          <CancelButton id="create-api-key-cancel" disabled={isSubmitting} />
        </ButtonGroup>
      </Form>
    );
  };

  const onSubmit = async (values, actions) => {
    const parameters = {
      client_application: { id: subscription.client_application.id },
      authentication_type: values.authenticationType.id,
    };
    if (values.description) {
      parameters.description = values.description;
    }

    const successfullyCreated = () => {
      notifier.showSuccess(
        intl.formatMessage({ id: "api_key_create.success_notification" }),
      );
      actions.setSubmitting(false);
      navigate(-1);
    };
    createApiKey(parameters, {
      onSuccess: successfullyCreated,
      onError: () => actions.setSubmitting(false),
    });
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading
              title={intl.formatMessage({ id: "api_key_create.header" })}
            />
          </ActionBar>
          {isLoadingSubscription ? (
            <Loader />
          ) : (
            <>
              <Details>
                <DetailsItem
                  className="no-bottom-padding"
                  translationKey="label.client_application"
                  value={subscription.client_application.name}
                />
              </Details>
              <Formik
                render={renderForm}
                onSubmit={onSubmit}
                initialValues={{ authenticationType: authenticationTypes[0] }}
              />
            </>
          )}
        </Column>
      </Row>
    </Container>
  );
}
