import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik } from "formik";
import { extractAssets } from "../../extractors";
import { NotFoundError } from "../../api";
import { SubmitButton, ObjectsTypeahead } from "../Form";
import { ObjectsType } from "../../constants";
import { accessRightsShape, intlShape } from "../../shapes";
import { sortBy } from "../../utils";
import {
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  withRules,
} from "../../context";
import List from "../List/List";
import AssetItem from "../Assets/AssetItem";
import Hint from "../Hint";

export function AssetsAssignList(props) {
  const { intl, api, notifier, accessRights, object } = props;

  const objectTypePlural = `${object?.itemType}s`;
  const objectId = object?.id;

  const [assets, setAssets] = React.useState();
  const parentInstrumentation = objectTypePlural.includes("instrumentation")
    ? { id: parseInt(objectId, 10) }
    : null;
  const parentNode = objectTypePlural.includes("node")
    ? { id: parseInt(objectId, 10) }
    : null;

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const responseAssets = await api.get(
          `/${objectTypePlural}/${objectId}/assets`,
          {
            include:
              "specifications,product.manufacturer,product.tenant,product.pictures,status,product.specifications",
          },
        );
        const loadedAssets = sortBy(
          extractAssets(responseAssets),
          "serialNumber",
        );
        setAssets(loadedAssets);
      } catch (error) {
        if (!(error instanceof NotFoundError)) {
          notifier.showError(api.translateError(error));
        }
      }
    };
    if (object) {
      loadData();
    }
  }, [objectId]);

  const handleOnAssetRemoved = (asset) => {
    setAssets(assets.filter((a) => a.id !== asset.id));
  };

  const assignAsset = async (values, { resetForm }) => {
    const payload = { assets: [{ id: values.asset.id }] };
    try {
      await api.post(`/${objectTypePlural}/${objectId}/assets`, payload);
      setAssets(sortBy(assets.concat([{ ...values.asset }]), "serialNumber"));
      notifier.showSuccess(
        intl.formatMessage({ id: "asset.actions.assign.notification" }),
      );
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        notifier.showError(api.translateError(error));
      }
    }
    resetForm({});
  };

  const renderAssetAssignForm = (formProps) => {
    const { handleSubmit, isSubmitting, values } = formProps;
    return (
      <form onSubmit={handleSubmit} noValidate>
        <ObjectsTypeahead
          {...formProps}
          id="asset-typeahead"
          name="asset"
          label={intl.formatMessage({ id: "asset.actions.assign" })}
          placeholder={intl.formatMessage({ id: "label.serial_number" })}
          objectsToIgnore={assets}
          objectsType={ObjectsType.Assets}
        />
        <div className="btn-group">
          <SubmitButton
            id="assign-asset-button"
            disabled={!values.asset}
            fetching={isSubmitting}
            text={intl.formatMessage({ id: "button.assign" })}
          />
        </div>
      </form>
    );
  };

  return objectId && assets ? (
    <div id="asset-list">
      <h2>
        <FormattedMessage id="assets_list.header" />
      </h2>
      {accessRights.canPermit ? (
        <Formik
          id="assign-asset-formik"
          onSubmit={assignAsset}
          render={renderAssetAssignForm}
        />
      ) : null}
      {assets.length > 0 ? (
        <List id="assets_assign_list">
          {assets.map((asset) => (
            <AssetItem
              key={asset.id}
              asset={asset}
              parentNode={parentNode}
              parentInstrumentation={parentInstrumentation}
              onAssetRemoved={handleOnAssetRemoved}
              showItemMenu
              parentAccessRights={accessRights}
            />
          ))}
        </List>
      ) : (
        <Hint
          id="hint-no-assets-assigned"
          details={intl.formatMessage({ id: "asset_list.no_assets_assign" })}
        />
      )}
    </div>
  ) : null;
}

AssetsAssignList.propTypes = {
  api: apiShape.isRequired,
  accessRights: accessRightsShape,
  intl: intlShape.isRequired,
  object: PropTypes.shape({
    id: PropTypes.number,
    itemType: PropTypes.oneOf(["node", "instrumentation"]),
  }), // .isRequired, throws an stupid error during loading..
  notifier: notifierShape.isRequired,
};
export default injectIntl(withNotifier(withApi(withRules(AssetsAssignList))));
