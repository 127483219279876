import React from "react";
import PropTypes from "prop-types";
import { handleFormikValueChange, TextInput } from "lcm-iot-commons";

export function FieldGatewayModbusValueTextInput(props) {
  const { valueArrayName, objIdx, values } = props;

  return (
    <TextInput
      {...props}
      hideLabel
      value={values[valueArrayName][objIdx].valueName}
      validateOnChange
      validateOnBlur
      errors={{}}
      handleChange={(value) => {
        handleFormikValueChange(props, value.target.value);
      }}
    />
  );
}

FieldGatewayModbusValueTextInput.propTypes = {
  valueArrayName: PropTypes.string.isRequired,
  objIdx: PropTypes.number.isRequired,
  values: PropTypes.shape({
    modbusConfig: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default FieldGatewayModbusValueTextInput;
