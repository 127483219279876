import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";
import { Formik } from "formik";
import {
  url,
  Row,
  Form,
  useApi,
  Button,
  Column,
  Checkbox,
  TextArea,
  ActionBar,
  AlertType,
  InputAlert,
  withNotifier,
  SubmitButton,
  withTracking,
  trackingShape,
  notifierShape,
  PermissionType,
} from "lcm-iot-commons";
import { permissionRequestShape } from "../../shapes/permissionRequestsShape";

function PermissionRequestEdit({ permissionRequest, notifier, tracking }) {
  const api = useApi();
  const intl = useIntl();
  const navigate = useNavigate();
  const { mutate: createPermissions } = api.post.useMutation("/permissions");
  const { mutate: updatePermissionRequest } = api.patch.useMutation(
    `/permission_requests/${permissionRequest.id}`,
  );
  const [isSubmitting, setIsSubmiting] = React.useState(false);

  /* istanbul ignore next */
  const preparePermissions = (values) => {
    const permissionTypes = [];
    if (values.canRead) {
      permissionTypes.push(PermissionType.READ);
    }
    if (values.canUpdate) {
      permissionTypes.push(PermissionType.UPDATE);
    }
    if (values.canDelete) {
      permissionTypes.push(PermissionType.DELETE);
    }
    if (values.canPermit) {
      permissionTypes.push(PermissionType.PERMIT);
    }
    return permissionTypes;
  };

  const handleSuccess = () => {
    notifier.showSuccess(
      intl.formatMessage({
        id: "permission_request.accepted_success_notification",
      }),
    );
    navigate(url("/permission_requests"));
  };

  const onSubmit = async (formProps) => {
    const { values } = formProps;
    setIsSubmiting(true);
    tracking?.trackEvent("PermissionRequestEdit-accept", {
      read: !!values.canRead,
      update: !!values.canUpdate,
      delete: !!values.canDelete,
      permit: !!values.canPermit,
      haveMessage: !!values.responseMessage,
    });

    const permissionTypes = preparePermissions(values);
    const permissions = {
      permission_type: permissionTypes,
      assignable: {
        id: permissionRequest.requestUser.id,
        type: "User",
      },
      permitable: {
        id: permissionRequest.permitable.id,
        type: permissionRequest.permitable.type,
      },
    };
    createPermissions(permissions, {
      onSuccess: () => {
        updatePermissionRequest(
          { status: "accepted", response_message: values.responseMessage },
          {
            onSuccess: handleSuccess,
          },
        );
      },
    });
    setIsSubmiting(false);
  };

  const onReject = async (formProps) => {
    const { values } = formProps;
    setIsSubmiting(true);
    tracking?.trackEvent("PermissionRequestEdit-reject", {
      haveMessage: !!formProps.values.responseMessage,
    });
    updatePermissionRequest(
      { status: "rejected", response_message: values.responseMessage },
      {
        onSuccess: () => {
          notifier.showSuccess(
            intl.formatMessage({
              id: "permission_request.rejected_success_notification",
            }),
          );
          navigate(url("/permission_requests"));
        },
      },
    );
  };

  const validate = (values) => {
    const errors = {};
    if (
      !values.canRead &&
      !values.canUpdate &&
      !values.canDelete &&
      !values.canPermit
    ) {
      errors.permissions = intl.formatMessage({
        id: "validation.permissions.mandatory",
      });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { errors } = formProps;
    return (
      <Form {...formProps}>
        <Row>
          <Column>
            <h3>
              <FormattedMessage id="label.permissions" />
            </h3>
            <div className="form-inline space-after">
              <Checkbox
                {...formProps}
                id="checkbox-canRead"
                name="canRead"
                label={intl.formatMessage({ id: "permission_type.can_read" })}
              />
              <Checkbox
                {...formProps}
                name="canUpdate"
                label={intl.formatMessage({ id: "permission_type.can_update" })}
              />
              <Checkbox
                {...formProps}
                name="canDelete"
                label={intl.formatMessage({ id: "permission_type.can_delete" })}
              />
              <Checkbox
                {...formProps}
                name="canPermit"
                label={intl.formatMessage({ id: "permission_type.can_permit" })}
              />
              <InputAlert message={errors.permissions} type={AlertType.ERROR} />
            </div>
            <TextArea
              {...formProps}
              name="responseMessage"
              label={intl.formatMessage({ id: "label.message" })}
            />
            <div className="btn-group">
              <SubmitButton
                id="edit-permission-request-submit"
                disabled={isSubmitting}
                fetching={isSubmitting}
                onClick={() => {
                  onSubmit(formProps);
                }}
              />
              <Button
                id="edit-permission-request-reject"
                disabled={isSubmitting}
                text={intl.formatMessage({ id: "button.reject" })}
                onClick={() => {
                  onReject(formProps);
                }}
              />
            </div>
          </Column>
        </Row>
      </Form>
    );
  };

  return (
    <div className="permission-request-edit">
      <ActionBar>
        <h2>
          <FormattedMessage id="permission_request_edit.header" />
        </h2>
      </ActionBar>
      <Formik
        onSubmit={() => {}}
        validate={validate}
        render={renderForm}
        initialValues={{ canRead: true, canUpdate: true }}
      />
    </div>
  );
}

PermissionRequestEdit.propTypes = {
  tracking: trackingShape.isRequired,
  notifier: notifierShape.isRequired,
  permissionRequest: permissionRequestShape.isRequired,
};

export default withNotifier(withTracking(PermissionRequestEdit));
