import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";
import Icon from "./Icon";

function BackButton() {
  const navigate = useNavigate();
  return (
    <button className="back-button" onClick={() => navigate(-1)} type="button">
      <Icon name="icon-eh-arrow-left" />
      <span>
        <FormattedMessage id="label.go_back" />
      </span>
    </button>
  );
}

export default BackButton;
