import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  accessRightsShape,
  ActionBar,
  ActionBarButtons,
  apiErrorsContain,
  apiShape,
  backendShape,
  Clue,
  ConfirmationModal,
  convertBytesToGB,
  CreateActionButton,
  Details,
  DetailsItem,
  EditActionButton,
  formatDate,
  formatNumber,
  handleUnknownErrors,
  htmlFormat,
  htmlSpan,
  intlShape,
  isNotEmpty,
  isNotFoundError,
  isSomething,
  List,
  Loader,
  navigateTo,
  ProgressBar,
  redirectTo,
  showError,
  showSuccess,
  url,
  withAccessRights,
  withApi,
  withBackend,
  withFlipper,
} from "lcm-iot-commons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import SubscriptionBillingDetails from "lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionBillingDetails";
import SubscriptionPaymentDetails from "lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionPaymentDetails";
import SubscriptionPaymentEditModal from "lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionPaymentEditModal";
import SubscriptionAssignedAssetsList from "lcm-iot-commons/client/lib/components/Subscriptions/SubscriptionAssignedAssetsList";
import TechnicalUserModal from "../TechnicalUsers/TechnicalUserModal";
import {
  loadClientApplicationAccessRights,
  loadClientApplicationApiKeys,
  loadConnectSubscription,
  loadCurrentSubscription,
  loadSubscriptionEdgeDevices,
} from "../../api";

import EdgeDeviceItem from "../EdgeDevice/EdgeDeviceItem";
import OpcUaServerItem from "../OpcUaServer/OpcUaServerItem";
import OpcUaServerHelp from "../OpcUaServer/OpcUaServerHelp";
import ApiKeyItem from "../ApiKeys/ApiKeyItem";
import TechnicalUserItem from "../TechnicalUsers/TechnicalUserItem";

export class ConnectSubscriptionDetails extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.loadData = this.loadData.bind(this);
    this.renderApiKeys = this.renderApiKeys.bind(this);
    this.renderOpcUaServer = this.renderOpcUaServer.bind(this);
    this.renderTechnicalUsers = this.renderTechnicalUsers.bind(this);
    this.createTechnicalUser = this.createTechnicalUser.bind(this);
    this.handleOnTechnicalUserRemoved =
      this.handleOnTechnicalUserRemoved.bind(this);
    this.onCloseTechnicalUserModal = this.onCloseTechnicalUserModal.bind(this);
    this.onPaymentEditAccept = this.onPaymentEditAccept.bind(this);
    this.onPaymentEditDecline = this.onPaymentEditDecline.bind(this);
    this.onChangeToPaymentPerInvoiceAccept =
      this.onChangeToPaymentPerInvoiceAccept.bind(this);
    this.onChangeToPaymentPerInvoiceDecline =
      this.onChangeToPaymentPerInvoiceDecline.bind(this);
    this.onPaymentUpdateToInvoice = this.onPaymentUpdateToInvoice.bind(this);
    this.state = {
      subscription: {},
      fetching: true,
      showUpdatePaymentModal: false,
      apiKeys: undefined,
      showTechnicalUserModal: false,
      technicalUser: undefined,
      technicalUsers: [],
      technicalUserCreating: false,
      showPaymentEditModal: false,
      clientApplicationAccessRights: {},
      assetAssignmentEnabled: false,
      connectedAssetAddonQuantity: undefined,
      numberOfFreeUplinks: 0,
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    return this.loadData().catch((apiErrors) => {
      if (isNotFoundError(apiErrors)) {
        navigateTo("/404");
      } else {
        handleUnknownErrors(
          apiErrors,
          intl.formatMessage({ id: "api.error.unknown" }),
        );
      }
    });
  }

  onChangeToPaymentPerInvoiceAccept() {
    const { intl } = this.props;
    this.onChangeToPaymentPerInvoice().catch((apiErrors) => {
      this.setState({ fetching: false });
      handleUnknownErrors(
        apiErrors,
        intl.formatMessage({ id: "api.error.unknown" }),
      );
    });
  }

  async onChangeToPaymentPerInvoice() {
    const { intl, match, backend } = this.props;
    this.setState({
      showUpdatePaymentModal: false,
      fetching: true,
    });
    await backend.patch(
      `/api_subscriptions/${match.params.id}/payment/to_invoice`,
    );
    const subscription = await loadConnectSubscription(match.params.id);
    const subscriptionFromBackend = await loadCurrentSubscription(
      match.params.id,
      true,
    );
    this.setState({ subscriptionFromBackend, subscription, fetching: false });
    showSuccess(
      intl.formatMessage({
        id: "payment_details_edit.change_to_invoice.success_message",
      }),
    );
  }

  onChangeToPaymentPerInvoiceDecline() {
    this.setState({ showUpdatePaymentModal: false });
  }

  onPaymentUpdateToInvoice() {
    this.setState({
      showPaymentEditModal: false,
      showUpdatePaymentModal: true,
    });
  }

  onPaymentEditDecline() {
    this.setState({ showPaymentEditModal: false });
  }

  onPaymentEditAccept() {
    const { match } = this.props;
    this.setState({ showPaymentEditModal: false });
    redirectTo(`/payment/update/subscription/${match.params.id}`);
  }

  onCloseTechnicalUserModal() {
    this.setState({
      showTechnicalUserModal: false,
      technicalUserCreating: false,
    });
  }

  getSubscriptionStatusText(subscription) {
    const { intl } = this.props;
    switch (subscription.status) {
      case "confirmed":
      case "scheduled_update":
        return intl.formatMessage({ id: "subscription_details.active" });
      case "non_renewing":
      case "cancelled":
        return intl.formatMessage({ id: "subscription_details.cancelled" });
      case "payment_required":
        return intl.formatMessage({
          id: "subscription_details.payment_required",
        });
      case "open":
        return intl.formatMessage({ id: "subscription_details.open" });
      default:
        return intl.formatMessage({ id: "subscription_details.active" });
    }
  }

  getSubscriptionEndingOrRenewingOrPaymentRequired(
    subscription,
    subscriptionFromBackend,
  ) {
    const { intl } = this.props;
    const invoiceDetailsList = isSomething(
      subscriptionFromBackend?.invoicePaymentDetails,
    )
      ? subscriptionFromBackend?.invoicePaymentDetails
      : null;

    switch (subscription.status) {
      case "payment_required":
        if (
          invoiceDetailsList &&
          invoiceDetailsList.filter(
            (i) =>
              i.status && (i.status === "posted" || i.status === "payment_due"),
          ).length > 0
        ) {
          const invoices = invoiceDetailsList
            .filter(
              (invoiceDetails) =>
                invoiceDetails.status === "posted" ||
                invoiceDetails.status === "payment_due",
            )
            .map((invoiceDetails, index) => {
              const invoice =
                `${intl.formatMessage({ id: "subscription_details.amount_label" })} ` +
                `${intl.formatNumber(invoiceDetails.amountDue, { minimumFractionDigits: 2 })} ${invoiceDetails.currency} ` +
                `${intl.formatMessage({ id: "subscription_details.due_by" })} ${formatDate(invoiceDetails.dueDate, intl)}`;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>{invoice}</li>
              );
            });
          return <ul>{invoices}</ul>;
        }
        return `(${intl.formatMessage({ id: "subscription_details.renewing_on" })} ${formatDate(subscription.billedUntil, intl)})`;
      case "confirmed":
      case "scheduled_update":
      case "open":
        return `(${intl.formatMessage({ id: "subscription_details.renewing_on" })} ${formatDate(subscription.billedUntil, intl)})`;
      case "non_renewing":
      case "cancelled":
        return `(${intl.formatMessage({ id: "subscription_details.ending_on" })} ${formatDate(subscription.billedUntil, intl)})`;
      default:
        return `(${intl.formatMessage({ id: "subscription_details.renewing_on" })} ${formatDate(subscription.billedUntil, intl)})`;
    }
  }

  async loadData() {
    const { match, accessRights, api, backend, flipper } = this.props;
    const subscription = await loadConnectSubscription(match.params.id);
    /* istanbul ignore next */
    if (flipper.edgeDeviceCreateNni) {
      const apiSubscriptionAddOnsResponse = await api.get(
        `/api_subscriptions/${match.params.id}/add_ons?add_on_type=connectivity&product_code=uplink`,
      );
      if (apiSubscriptionAddOnsResponse?.add_ons?.length > 0) {
        const addOn = apiSubscriptionAddOnsResponse?.add_ons[0];
        const numberOfFreeUplinks =
          addOn.quantity - addOn.assigned_edge_devices_count;
        this.setState({
          numberOfFreeUplinks,
        });
      }
    }
    const assetAssignmentEnabled = flipper.assetAssignmentActive;
    const subscriptionFromBackend = accessRights.canUpdate
      ? await loadCurrentSubscription(match.params.id, true)
      : undefined;
    const opcUaServerAddon = isNotEmpty(
      subscriptionFromBackend?.addons?.filter((addon) =>
        addon.id.includes("opc-ua-server"),
      ),
    );
    const connectedAssetAddonQuantity = subscriptionFromBackend?.addons?.filter(
      (addon) => addon.id.includes("connect-connected-asset"),
    )[0]?.quantity;

    const response = await backend.get(
      `/api_subscriptions/${subscription.id}/opc_ua_servers`,
    );
    // eslint-disable-next-line camelcase
    const opcUaServerData =
      response.opc_ua_servers.length > 0
        ? {
            id: response.opc_ua_servers[0].id,
            status: response.opc_ua_servers[0].status,
            hostname: response.opc_ua_servers[0].hostname,
            subscription_id: response.opc_ua_servers[0].subscription_id,
          }
        : null;

    const clientApplicationAccessRights =
      await loadClientApplicationAccessRights(subscription.clientApplicationId);
    const clientApplicationResponse = await api.get(
      `/client_applications/${subscription.clientApplicationId}`,
      { include: "technical_users" },
      false,
    );
    const clientApplication = {
      id: clientApplicationResponse.id,
      name: clientApplicationResponse.name,
      description: clientApplicationResponse.description,
      redirectURIs:
        (clientApplicationResponse.redirect_uris &&
          clientApplicationResponse.redirect_uris.join("\n")) ||
        "",
    };
    let technicalUsers = [];
    if (clientApplicationResponse.technical_users) {
      technicalUsers = clientApplicationResponse.technical_users.map(
        (technicalUser) => ({
          id: technicalUser.id,
          email: technicalUser.email,
        }),
      );
    }
    const filter = { order_by: "name" };
    const edgeDevices = await loadSubscriptionEdgeDevices(
      subscription.id,
      filter,
    );
    const apiKeys = await loadClientApplicationApiKeys(
      subscription.clientApplicationId,
    );
    this.setState({
      subscription,
      subscriptionFromBackend,
      opcUaServerData,
      clientApplication,
      technicalUsers,
      edgeDevices,
      apiKeys,
      fetching: false,
      clientApplicationAccessRights,
      opcUaServerAddon,
      connectedAssetAddonQuantity,
      assetAssignmentEnabled,
    });
  }

  async createTechnicalUser() {
    const { intl, api } = this.props;
    const { clientApplication, technicalUsers } = this.state;
    this.setState({ technicalUserCreating: true });
    try {
      const technicalUser = await api.post(
        `/client_applications/${clientApplication.id}/technical_users`,
      );
      const technicalUserListItem = {
        id: technicalUser.id,
        email: technicalUser.email,
      };
      technicalUsers.push(technicalUserListItem);
      this.setState({
        showTechnicalUserModal: true,
        technicalUser,
        technicalUsers,
      });
    } catch (apiErrors) {
      if (isNotFoundError(apiErrors)) {
        showError(
          intl.formatMessage({
            id: "api.error.technical_user_create.not_found",
          }),
        );
      } else if (apiErrorsContain(apiErrors, "conflict")) {
        showError(
          intl.formatMessage({
            id: "api.error.technical_user_create.conflict",
          }),
        );
      } else {
        handleUnknownErrors(
          apiErrors,
          intl.formatMessage({ id: "api.error.unknown" }),
        );
      }
    }
  }

  handleOnPermissionsClick() {
    redirectTo("/nodes");
  }

  handleOnTechnicalUserRemoved(technicalUser) {
    const { technicalUsers } = this.state;
    this.setState({
      technicalUsers: technicalUsers.filter((i) => i !== technicalUser),
    });
  }

  renderTechnicalUsers() {
    const { intl } = this.props;
    const {
      technicalUsers,
      technicalUserCreating,
      clientApplicationAccessRights,
    } = this.state;
    const createDisabled =
      technicalUserCreating || !clientApplicationAccessRights.canUpdate;
    const editPermissionsDisabled = !clientApplicationAccessRights.canUpdate;

    const renderBadge =
      technicalUsers?.length > 0 ? (
        <span id="technical-user-count" className="badge">
          {technicalUsers.length}
        </span>
      ) : null;

    return (
      <div id="subscription-technical-users" className="row space-before">
        <div className="col-md-12">
          <ActionBar>
            <h2>
              <FormattedMessage id="subscription_details.technical_users.header" />
              {renderBadge}
            </h2>
            <ActionBarButtons>
              <CreateActionButton
                id="create-technical-user-button"
                onClick={this.createTechnicalUser}
                disabled={createDisabled}
              />
              <EditActionButton
                id="edit-permissions-button"
                onClick={this.handleOnPermissionsClick}
                disabled={editPermissionsDisabled}
                label={intl.formatMessage({ id: "button.permissions" })}
              />
            </ActionBarButtons>
          </ActionBar>
          {this.renderTechnicalUserList(technicalUsers)}
        </div>
      </div>
    );
  }

  renderTechnicalUserList(technicalUsers) {
    const { clientApplicationAccessRights } = this.state;
    return technicalUsers && technicalUsers.length > 0 ? (
      // eslint-disable-next-line react/no-unknown-property
      <ul id="technical-user-item-list" className="list" badge={5}>
        {technicalUsers.map((item) => (
          <TechnicalUserItem
            key={item.id}
            technicalUser={item}
            onTechnicalUserRemoved={this.handleOnTechnicalUserRemoved}
            accessRights={clientApplicationAccessRights}
          />
        ))}
      </ul>
    ) : (
      <div id="no-technical-users-found">
        <FormattedMessage id="subscription_details.no_technical_users_assigned" />
      </div>
    );
  }

  renderApiKeys() {
    const { match } = this.props;
    const { clientApplicationAccessRights } = this.state;
    const targetUrl = `/subscriptions/connect/${match.params.id}/api_keys/create`;

    return (
      <div id="subscription-api-keys" className="row space-before">
        <div className="col-md-12">
          <ActionBar>
            <h2>
              <FormattedMessage id="subscription_details.api_keys.header" />
            </h2>
            <ActionBarButtons>
              <CreateActionButton
                id="create-api-key-button"
                target={targetUrl}
                disabled={!clientApplicationAccessRights.canUpdate}
              />
            </ActionBarButtons>
          </ActionBar>
          {this.renderApiKeyList()}
        </div>
      </div>
    );
  }

  renderApiKeyList() {
    const { apiKeys } = this.state;
    return apiKeys && apiKeys.length > 0 ? (
      <ul id="api-key-item-list" className="list">
        {apiKeys.map((apiKey) => (
          <ApiKeyItem key={apiKey.id} apiKey={apiKey} />
        ))}
      </ul>
    ) : (
      <div id="no-api_keys-found">
        <FormattedMessage id="subscription_details.no_api_keys_assigned" />
      </div>
    );
  }

  renderOpcUaServer() {
    return (
      <div id="subscription-opc-ua-server" className="row space-before">
        <div className="col-md-12">
          <ActionBar>
            <h2>
              <FormattedMessage id="subscription_details.opc_ua_server.header" />
              <OpcUaServerHelp id="opc-ua-server-help" />
            </h2>
          </ActionBar>
          {this.renderOpcUaServerList()}
        </div>
      </div>
    );
  }

  renderOpcUaServerList() {
    const { opcUaServerData } = this.state;
    return opcUaServerData ? (
      <ul id="opc-ua-server-item-list" className="list">
        <OpcUaServerItem
          id="opc-ua-server-item"
          opcUaServer={opcUaServerData}
        />
      </ul>
    ) : (
      <div id="no-opc-ua-server-found">
        <FormattedMessage id="subscription_details.no_opc_ua_server_found" />
      </div>
    );
  }

  renderAssignedEdgeDevices() {
    const { flipper, match } = this.props;
    const { clientApplicationAccessRights, numberOfFreeUplinks } = this.state;
    const targetUrl = `/edge_devices/create?subscription_id=${match.params.id}`;

    const actionBarButtons = (
      <ActionBarButtons>
        <CreateActionButton
          id="create-edge-device-button"
          target={targetUrl}
          disabled={
            !clientApplicationAccessRights.canUpdate ||
            numberOfFreeUplinks === 0
          }
        />
      </ActionBarButtons>
    );

    const informationFreeAddons = (
      <div className="edge-free-uplink-addons-align">
        <FormattedMessage
          id="edge_device.number_free_addons"
          values={{
            ...htmlFormat,
            numberOfUplinks: numberOfFreeUplinks,
            span: htmlSpan({ className: "edge-free-uplink-addons" }),
          }}
        />
      </div>
    );

    /* istanbul ignore next */
    return (
      <div id="edge-device-list" className="row space-before">
        <div className="col-md-12">
          <ActionBar>
            <h2>
              <FormattedMessage id="edge_devices.header" />
            </h2>
            {flipper.edgeDeviceCreateNni ? actionBarButtons : null}
          </ActionBar>
          {flipper.edgeDeviceCreateNni ? informationFreeAddons : null}
          {this.renderAssignedEdgeDeviceList()}
        </div>
      </div>
    );
  }

  renderAssignedEdgeDeviceList() {
    const { subscription, edgeDevices } = this.state;
    return subscription && edgeDevices?.length > 0 ? (
      <List id="edge-device-item-list" className="list">
        {edgeDevices.map((edgeDevice) => (
          <EdgeDeviceItem key={edgeDevice.id} edgeDevice={edgeDevice} />
        ))}
      </List>
    ) : (
      <FormattedMessage id="subscription.no_edgedevices_assign" />
    );
  }

  render() {
    const { match, accessRights, intl } = this.props;
    const {
      subscription,
      subscriptionFromBackend,
      clientApplication,
      showUpdatePaymentModal,
      fetching,
      connectedAssetAddonQuantity,
      technicalUser,
      showTechnicalUserModal,
      showPaymentEditModal,
      clientApplicationAccessRights,
      opcUaServerData,
      opcUaServerAddon,
      assetAssignmentEnabled,
    } = this.state;
    const subscriptionStorageQuotaInGB =
      subscription && subscription.storageQuota
        ? formatNumber(convertBytesToGB(subscription.storageQuota))
        : "0";
    const subscriptionStorageUsedInGB =
      subscription && subscription.storageUsed
        ? formatNumber(convertBytesToGB(subscription.storageUsed))
        : "0";
    const subscriptionUploadDownloadQuotaInGB =
      subscription && subscription.uploadDownloadQuota
        ? formatNumber(convertBytesToGB(subscription.uploadDownloadQuota))
        : "0";
    const subscriptionUploadDownloadUsedInGB =
      subscription && subscription.uploadDownloadUsed
        ? formatNumber(convertBytesToGB(subscription.uploadDownloadUsed))
        : "0";
    const subscriptionApiCallsQuota =
      subscription && subscription.apiCallQuota
        ? formatNumber(subscription.apiCallQuota)
        : "0";
    const subscriptionApiCallsUsed =
      subscription && subscription.apiCallsUsed
        ? formatNumber(subscription.apiCallsUsed)
        : "0";

    let subscriptionDetails = null;
    let paymentEditModal = null;
    let redirectUris;
    if (
      clientApplication &&
      clientApplication.redirectURIs &&
      isNotEmpty(clientApplication.redirectURIs)
    ) {
      redirectUris = clientApplication.redirectURIs.split(/\n/g);
    }

    const perInvoice =
      subscriptionFromBackend &&
      isSomething(subscriptionFromBackend?.invoicePaymentDetails);
    const paymentRequired =
      subscription && subscription.status === "payment_required";
    const editDisabled = fetching || !accessRights?.canUpdate;
    let canUpgrade = null;

    if (subscription) {
      canUpgrade =
        subscription.status !== "non_renewing" &&
        subscription.status !== "cancelled" &&
        accessRights?.canUpdate;
    }

    const displayUris =
      redirectUris?.length > 0 ? (
        <ul id="redirectUri-list" className="address">
          {redirectUris.map((uri) => (
            <li key={uri}>{uri}</li>
          ))}
        </ul>
      ) : (
        <span>-</span>
      );

    const technicalUserModal =
      showTechnicalUserModal &&
      technicalUser.email &&
      technicalUser.password ? (
        <TechnicalUserModal
          id="modal-dialog"
          show={showTechnicalUserModal}
          titleText={intl.formatMessage({
            id: "technical_user_modal.create.modal_title",
          })}
          contextBodyText={intl.formatMessage({
            id: "technical_user_modal.create.modal_message",
          })}
          onClose={this.onCloseTechnicalUserModal}
          username={technicalUser.email}
          password={technicalUser.password}
        />
      ) : null;

    const paymentRequiredClue =
      accessRights?.canUpdate && paymentRequired ? (
        <Clue
          id="payment-required-notification"
          title={intl.formatMessage({
            id: "subscription.payment_required.title",
          })}
          className="notification"
          highlighted
        >
          <div className="clue-details">
            <FormattedMessage id="subscription.payment_required.details-1" />
            <br />
            <FormattedMessage id="subscription.payment_required.details-2" />
          </div>
        </Clue>
      ) : null;

    const modalChangeToInvoice = showUpdatePaymentModal ? (
      <ConfirmationModal
        id="modal-dialog-invoice"
        show={showUpdatePaymentModal}
        titleText={intl.formatMessage({
          id: "payment_details_edit.change_credit_to_invoice",
        })}
        messageText={intl.formatMessage({
          id: "payment_details_edit.change_to_invoice_description",
        })}
        onConfirm={this.onChangeToPaymentPerInvoiceAccept}
        onClose={this.onChangeToPaymentPerInvoiceDecline}
        intl={intl}
      />
    ) : null;

    const canChangeToInvoice =
      subscription &&
      (subscription.status === "confirmed" ||
        subscription.status === "scheduled_update" ||
        subscription.status === "open");

    if (subscription && clientApplication && !fetching) {
      const paid = subscription.externalReference;
      const connectedAssetsInPercent = connectedAssetAddonQuantity
        ? Math.round(
            (subscription.connectedAssetsUsed / connectedAssetAddonQuantity) *
              100,
          )
        : 0;
      const apiCallsInPercent = subscription.apiCallQuota
        ? Math.round(
            (subscription.apiCallsUsed / subscription.apiCallQuota) * 100,
          )
        : 0;
      const uploadDownloadInPercent = subscription.uploadDownloadQuota
        ? Math.round(
            (subscription.uploadDownloadUsed /
              subscription.uploadDownloadQuota) *
              100,
          )
        : 0;
      const storageInPercent = subscription.storageQuota
        ? Math.round(
            (subscription.storageUsed / subscription.storageQuota) * 100,
          )
        : 0;

      subscriptionDetails = (
        <div>
          <div id="subscription-plan-details" className="row">
            <div className="col-xs-12">
              <Details>
                <DetailsItem
                  id="subscription-client-application-name"
                  translationKey="label.client_application"
                  value={clientApplication.name}
                  intl={intl}
                />
                <DetailsItem
                  id="subscription-client-application-description"
                  translationKey="label.description"
                  value={clientApplication.description}
                  intl={intl}
                />
                <div
                  id="subscription-client-application-redirect-uris"
                  className="details-item"
                >
                  <div className="details-item-name">
                    {intl.formatMessage({ id: "label.redirect_uris" })}
                  </div>
                  {displayUris}
                </div>
                <DetailsItem
                  id="subscription-plan-name"
                  translationKey="subscription_details.current_plan"
                  value={subscription.subscriptionName}
                  intl={intl}
                />
                {paid ? (
                  <DetailsItem
                    id="subscription-status"
                    translationKey="subscription_details.status"
                    intl={intl}
                  >
                    <div>
                      <span>
                        <div>
                          {this.getSubscriptionStatusText(subscription, intl)}
                        </div>
                        {this.getSubscriptionEndingOrRenewingOrPaymentRequired(
                          subscription,
                          subscriptionFromBackend,
                        )}
                      </span>
                    </div>
                  </DetailsItem>
                ) : null}
                <DetailsItem
                  id="subscription-upload-download-quota"
                  translationKey="subscription_details.upload_download"
                  intl={intl}
                >
                  <div id="subscription-upload-download-progressbar">
                    <span>
                      {`${subscriptionUploadDownloadUsedInGB} GB / ${subscriptionUploadDownloadQuotaInGB} GB`}
                    </span>
                    <ProgressBar percent={uploadDownloadInPercent} />
                  </div>
                </DetailsItem>
                <DetailsItem
                  id="subscription-storage-quota"
                  translationKey="subscription_details.storage"
                  intl={intl}
                >
                  <div id="subscription-storage-progressbar">
                    <span>
                      {`${subscriptionStorageUsedInGB} GB / ${subscriptionStorageQuotaInGB} GB`}
                    </span>
                    <ProgressBar percent={storageInPercent} />
                  </div>
                </DetailsItem>
                <DetailsItem
                  id="subscription-apicalls-quota"
                  translationKey="subscription_details.api_calls"
                  intl={intl}
                >
                  <div id="subscription-apicalls-progressbar">
                    <span>
                      {`${subscriptionApiCallsUsed} / ${subscriptionApiCallsQuota}`}
                    </span>
                    <ProgressBar percent={apiCallsInPercent} />
                  </div>
                </DetailsItem>
                {connectedAssetAddonQuantity ? (
                  <DetailsItem
                    id="subscription-connected-assets-fraction"
                    translationKey="subscription_details.connected_assets"
                    intl={intl}
                  >
                    <div id="subscription-connected-assets-progressbar">
                      <span>
                        {`${formatNumber(subscription.connectedAssetsUsed)} / ${formatNumber(connectedAssetAddonQuantity)}`}
                      </span>
                      <ProgressBar percent={connectedAssetsInPercent} />
                    </div>
                  </DetailsItem>
                ) : null}
              </Details>
              <div className="btn-group">
                {canUpgrade ? (
                  <Link
                    id="upgrade-button"
                    className="btn btn-primary"
                    to={url(
                      `/subscriptions/connect/${subscription.id}/upgrade`,
                    )}
                  >
                    <FormattedMessage id="subscription_details.upgrade_button" />
                  </Link>
                ) : null}
              </div>
              {this.renderAssignedEdgeDevices()}
              {opcUaServerAddon || opcUaServerData
                ? this.renderOpcUaServer()
                : null}
              {this.renderApiKeys()}
              {this.renderTechnicalUsers()}
            </div>
          </div>
          {paid && accessRights.canUpdate ? (
            <div id="subscription-billing-details" className="row space-before">
              <div className="col-md-12">
                <ActionBar>
                  <h2>
                    <FormattedMessage id="subscription_details.billing_details_header" />
                  </h2>
                  <ActionBarButtons>
                    <EditActionButton
                      id="edit-billing-details-button"
                      target={`/subscriptions/connect/${subscription.id}/billing_details/edit`}
                      disabled={
                        editDisabled ||
                        (perInvoice && subscription.status !== "confirmed")
                      }
                    />
                  </ActionBarButtons>
                </ActionBar>
                <SubscriptionBillingDetails
                  billingAddress={subscription.billingAddress}
                  shippingAddress={subscription.shippingAddress}
                  customerPurchaseOrder={subscription.customerPurchaseOrder}
                />
              </div>
            </div>
          ) : null}
        </div>
      );

      paymentEditModal =
        accessRights?.canUpdate && showPaymentEditModal ? (
          <SubscriptionPaymentEditModal
            onAccept={this.onPaymentEditAccept}
            onDecline={this.onPaymentEditDecline}
            isCredit={!perInvoice}
            onChange={this.onPaymentUpdateToInvoice}
            canChangeToInvoice={canChangeToInvoice}
          />
        ) : null;
    }

    const paymentDetails =
      accessRights?.canUpdate &&
      subscriptionFromBackend &&
      subscription &&
      subscription.externalReference &&
      !fetching ? (
        <div id="subscription-payment-details" className="row">
          <div className="col-md-12">
            <ActionBar>
              <h2>
                <FormattedMessage id="subscription_checkout.payment_details_header" />
              </h2>
              <ActionBarButtons>
                <EditActionButton
                  id="edit-payment-details-button"
                  onClick={() => this.setState({ showPaymentEditModal: true })}
                  disabled={
                    editDisabled ||
                    (perInvoice && subscription.status !== "confirmed")
                  }
                />
              </ActionBarButtons>
            </ActionBar>
            <SubscriptionPaymentDetails
              cardPaymentDetails={subscriptionFromBackend?.cardPaymentDetails}
              invoicePaymentDetails={
                subscriptionFromBackend?.invoicePaymentDetails
              }
              source="subscription"
              onChangeToPaymentPerInvoice={
                this.onChangeToPaymentPerInvoiceAccept
              }
              canChangeToInvoice={canChangeToInvoice}
              intl={intl}
            />
          </div>
        </div>
      ) : null;

    const assignedAssetList =
      subscription && assetAssignmentEnabled ? (
        <div id="subscription-assigned-assets-list" className="row">
          <div className="col-md-12">
            <ActionBar>
              <h2 id="asset_list_header">
                <FormattedMessage id="subscription_details.asset_list_edit_header" />
                {subscription.numberAssignedAssets > 0
                  ? ` (${formatNumber(subscription.numberAssignedAssets)})`
                  : null}
              </h2>
              <ActionBarButtons>
                <EditActionButton
                  id="asset_list_edit_button"
                  target={`/subscriptions/connect/${match.params.id}/assets/assign`}
                />
              </ActionBarButtons>
            </ActionBar>
            <SubscriptionAssignedAssetsList
              subscriptionId={subscription.id}
              subscriptionRoute="api_subscriptions"
            />
          </div>
        </div>
      ) : null;

    return (
      <div id="subscription-details" className="container">
        {paymentRequiredClue}
        <ActionBar>
          <h1>
            <FormattedMessage id="subscription_details.header" />
          </h1>
          <ActionBarButtons>
            <EditActionButton
              id="edit-client-application-button"
              target={`/subscriptions/connect/${match.params.id}/edit`}
              disabled={!clientApplicationAccessRights.canUpdate}
            />
          </ActionBarButtons>
        </ActionBar>
        <Loader loading={fetching} />
        {technicalUserModal}
        {subscriptionDetails}
        {paymentDetails}
        {modalChangeToInvoice}
        {paymentEditModal}
        {assignedAssetList}
      </div>
    );
  }
}

ConnectSubscriptionDetails.propTypes = {
  backend: backendShape.isRequired,
  api: apiShape.isRequired,
  accessRights: accessRightsShape,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  intl: intlShape,
  flipper: PropTypes.shape({
    assetAssignmentActive: PropTypes.bool,
    edgeDeviceCreateNni: PropTypes.bool,
  }).isRequired,
};

ConnectSubscriptionDetails.defaultProps = {
  accessRights: undefined,
  match: undefined,
  intl: undefined,
};

export default withFlipper(
  withBackend(
    withApi(
      withAccessRights(
        injectIntl(ConnectSubscriptionDetails),
        "APISubscription",
      ),
    ),
  ),
);
