import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  backendShape,
  browserShape,
  isEmpty,
  Container,
  withBrowser,
  withBackend,
  removeApiAttributes,
} from "lcm-iot-commons";
import PasswordResetForm from "./PasswordResetForm";
import PasswordResetSuccess from "./PasswordResetSuccess";
import PasswordResetError from "./PasswordResetError";

export function PasswordReset({ backend, location, browser }) {
  const [isPasswordResetPage, setIsPasswordResetPage] = useState("MainPage");
  const [shouldRender, setShouldRender] = useState(true);
  const [redirectUri, setRedirectUri] = useState(undefined);

  const queryParameter = (loc, param) =>
    loc && loc.search ? new URLSearchParams(loc.search).get(param) : undefined;

  React.useEffect(() => {
    if (isEmpty(queryParameter(location, "reset_password_token"))) {
      setShouldRender(false);
      browser.navigateTo("");
    }
  }, [location]);

  const handleOnSubmit = async (values, actions) => {
    try {
      const updateObject = {
        reset_password_token: queryParameter(location, "reset_password_token"),
        password: values.password,
      };
      await backend.patch("/users/reset_password", updateObject);
      setRedirectUri(queryParameter(location, "redirect_uri"));
      setIsPasswordResetPage("SuccessPage");
    } catch (error) {
      removeApiAttributes(error, "reset_password_token");
      setIsPasswordResetPage("ErrorPage");
      actions.setSubmitting(false);
    }
  };

  return (
    <Container>
      {shouldRender && isPasswordResetPage === "MainPage" ? (
        <PasswordResetForm onSubmit={handleOnSubmit} />
      ) : null}
      {shouldRender && isPasswordResetPage === "SuccessPage" ? (
        <PasswordResetSuccess redirectUri={redirectUri} />
      ) : null}
      {shouldRender && isPasswordResetPage === "ErrorPage" ? (
        <PasswordResetError />
      ) : null}
    </Container>
  );
}

PasswordReset.propTypes = {
  backend: backendShape.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  browser: browserShape.isRequired,
};

export default withRouter(withBrowser(withBackend(PasswordReset)));
