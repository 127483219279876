import React from "react";
import PropTypes from "prop-types";

function ActionBar({ children }) {
  return <div className="actionbar-wrapper">{children}</div>;
}

ActionBar.propTypes = {
  children: PropTypes.node,
};

export default ActionBar;
