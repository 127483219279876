import PropTypes from "prop-types";
import React, { Component } from "react";
import InputGroup from "./InputGroup";
import Typeahead from "./Typeahead";
import { handleFormikValueChange } from "../../utils";

class TypeaheadBox extends Component {
  static propTypes = {
    name: PropTypes.string,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    labelKey: PropTypes.string,
    /* eslint-disable react/forbid-prop-types */
    values: PropTypes.object,
    renderOption: PropTypes.func,
    renderSelectedOption: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    labelKey: "name",
  };

  constructor() {
    super();
    /* istanbul ignore next */
    this.handleOnChange = this.handleOnChange.bind(this);
    this.renderOption = this.renderOption.bind(this);
  }

  handleOnChange(value) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    } else {
      handleFormikValueChange(this.props, value);
    }
  }

  renderOption(option) {
    const { labelKey } = this.props;
    const verified =
      option.tenantName && option.tenantPublic ? (
        <span className="select-option-icon">
          <span className="icon icon3-eh-radio-button-checked" />
        </span>
      ) : null;
    const tenant =
      option.tenantName && !option.tenantPublic
        ? ` (${option.tenantName})`
        : null;
    return (
      <div>
        <span className="select-option-value">{option[labelKey]}</span>
        {tenant}
        {verified}
      </div>
    );
  }

  render() {
    const {
      name,
      values,
      disabled,
      isSubmitting,
      renderOption,
      renderSelectedOption,
    } = this.props;
    return (
      <InputGroup {...this.props}>
        <Typeahead
          {...this.props}
          disabled={disabled || isSubmitting}
          onChange={this.handleOnChange}
          selectedOption={values && values[name]}
          renderOption={renderOption || this.renderOption}
          renderSelectedOption={renderSelectedOption || this.renderOption}
        />
      </InputGroup>
    );
  }
}

export default TypeaheadBox;
