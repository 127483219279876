import React from "react";
import { injectIntl } from "react-intl";
import { intlShape } from "../../shapes";
import ErrorPage from "./ErrorPage";
import { errorTypes } from "./ErrorTypes";

export function NotFound({ intl }) {
  return (
    <ErrorPage
      header={intl.formatMessage({ id: "not_found.header" })}
      details={intl.formatMessage({ id: "not_found.details" })}
      errorType={errorTypes.not_found}
    />
  );
}

NotFound.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(NotFound);
