import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { intlShape } from "../../shapes";
import { browserShape, withBrowser } from "../../context";
import { formatDateTime } from "../../utils";

export function TicketItem({ intl, browser, ticket }) {
  const onClick = () => {
    browser.navigateTo(`/support/tickets/${ticket.id}`);
  };

  const updatedAt = `${intl.formatMessage({ id: "label.updated_at" })}: ${formatDateTime(ticket.updated_at, intl)}`;
  const status = `${intl.formatMessage({ id: "label.status" })}: ${intl.formatMessage({ id: `support.status.${ticket.status}` })}`;

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <li id="ticket-item" className="list-item clickable" onClick={onClick}>
      <div className="list-item-content">
        <div className="list-item-header">
          {`${ticket.number} - ${ticket.subject}`}
        </div>
        <div id="ticket-item-status" className="list-item-details">
          {status}
        </div>
        <div id="ticket-item-updated-at" className="list-item-details">
          {updatedAt}
        </div>
      </div>
    </li>
  );
}

TicketItem.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    number: PropTypes.string,
    subject: PropTypes.string,
    updated_at: PropTypes.string,
  }),
  intl: intlShape,
  browser: browserShape,
};
export default withBrowser(injectIntl(TicketItem));
