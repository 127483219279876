import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useApi } from "../../hooks";
import { assetShape, nodeShape, orderShape } from "../../shapes";
import StepperModal from "../StepperModal";
import CategoriesSelectionStep from "./DocumentExport/CategoriesSelectionStep";
import ExportInitiatedStep from "./DocumentExport/ExportInitiatedStep";
import LanguagesSelectionStep from "./DocumentExport/LanguagesSelectionStep";
import DataExportExistsStep from "./DocumentExport/DataExportExistsStep";
import ConfirmationModal from "../ConfirmationModal";

function DocumentExportModal({ object, disabled, children }) {
  const api = useApi();
  const intl = useIntl();

  const INITIAL_STEPS_STATE = {
    categoriesSelectionStep: {
      selectedSwitch: "all",
      selectedItems: [],
    },
    languagesSelectionStep: {
      selectedSwitch: "all",
      selectedItems: [],
    },
  };

  const [showExportModal, setShowExportModal] = useState(false);
  const [stepsState, setStepsState] = useState(INITIAL_STEPS_STATE);

  const getExportTypeAndIdFilter = (type) => {
    let exportType;
    let idFilter;

    if (type.asset) {
      exportType = "asset_documents";
      idFilter = { asset_id: type.asset.id };
    } else if (type.node) {
      exportType = "node_documents_recursive";
      idFilter = { node_id: type.node.id };
    } else {
      exportType = "purchase_order_documents";
      idFilter = { purchase_order_id: type.order.id };
    }

    return { idFilter, exportType };
  };

  const { idFilter, exportType } = getExportTypeAndIdFilter(object);
  const {
    data: dataExports,
    refetch,
    isFetching,
  } = api.get.useQuery(
    "/users/current/data_exports",
    { ...idFilter, type: exportType },
    { enabled: false },
  );
  const {
    data: documents,
    refetch: refetchDocuments,
    isFetching: isDocumentFetching,
  } = api.get.useQuery(
    `/assets/${object.asset?.id}/documents`,
    {
      include:
        "attachments,categories,categories.parent,categories.tenant,eventdocuments,productdocuments",
    },
    { enabled: false },
  );

  useEffect(() => {
    if (showExportModal) {
      refetch();
      if (object.asset) {
        refetchDocuments();
      }
    }
  }, [showExportModal]);

  const currentDataExport = dataExports?.data_exports[0];
  const isCurrentDataExportInProgress =
    currentDataExport?.status === "in_progress";
  const dataExportNotInProgress =
    currentDataExport && !isCurrentDataExportInProgress;

  const handleShowModal = async () => {
    setShowExportModal(true);
  };

  const handleCloseClick = () => {
    setShowExportModal(false);
    setTimeout(() => {
      setStepsState(INITIAL_STEPS_STATE);
    }, 200);
  };

  const steps = [
    ...(currentDataExport
      ? [intl.formatMessage({ id: "stepper.delete_existing_export" })]
      : []),
    intl.formatMessage({ id: "stepper.categories_selection" }),
    intl.formatMessage({ id: "stepper.language_selection" }),
    intl.formatMessage({ id: "stepper.export" }),
  ];

  const modal =
    currentDataExport && isCurrentDataExportInProgress ? (
      <ConfirmationModal
        show={showExportModal}
        titleText={intl.formatMessage({
          id: "export_files_dialog_in_progress.title",
        })}
        messageText={intl.formatMessage({
          id: "export_files_dialog_in_progress.text",
        })}
        onConfirm={handleCloseClick}
        buttonConfirmText={intl.formatMessage({ id: "button.close" })}
        showCancel={false}
      />
    ) : (
      <StepperModal
        id="export-modal"
        steps={steps}
        show={showExportModal}
        titleText={intl.formatMessage({
          id: "export_files_dialog_replace.stepper_title",
        })}
        onClose={handleCloseClick}
        disableBackStepAt={
          dataExportNotInProgress
            ? [
                { stepIndex: 1, disabled: [0] },
                { stepIndex: 2, disabled: [0] },
                { stepIndex: 3, disabled: [0, 1, 2] },
              ]
            : [{ stepIndex: 2, disabled: [0, 1] }]
        }
      >
        {dataExportNotInProgress ? (
          <DataExportExistsStep
            onClose={handleCloseClick}
            existingDataExport={currentDataExport}
          />
        ) : null}
        <CategoriesSelectionStep
          stepsState={stepsState}
          setStepsState={setStepsState}
          object={object}
          onClose={handleCloseClick}
          includedDocuments={documents?.documents || []}
        />
        <LanguagesSelectionStep
          stepsState={stepsState}
          setStepsState={setStepsState}
          object={object}
          exportType={exportType}
          includedDocuments={documents?.documents || []}
        />
        <ExportInitiatedStep onClose={handleCloseClick} />
      </StepperModal>
    );

  return (
    <>
      {children({ showModal: handleShowModal })}
      {!disabled &&
        !!dataExports &&
        !isFetching &&
        !isDocumentFetching &&
        modal}
    </>
  );
}

DocumentExportModal.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.func,
  object: PropTypes.oneOfType([
    PropTypes.shape({ asset: assetShape }),
    PropTypes.shape({ node: nodeShape }),
    PropTypes.shape({ order: orderShape }),
  ]),
};

DocumentExportModal.defaultProps = {
  disabled: false,
};

export default DocumentExportModal;
