import React from "react";
import PropTypes from "prop-types";
import { convertSpecificationKeyToLabel } from "../../utils";
import { DetailsItem } from "../Details";

export function SpecificationDetails({ specifications }) {
  return specifications
    ? Object.keys(specifications)
        .filter((key) => specifications[key].ui_visible)
        .sort()
        .map((key) => (
          <DetailsItem
            id={key}
            key={key}
            label={convertSpecificationKeyToLabel(key)}
            value={specifications[key].value}
          />
        ))
    : null;
}

SpecificationDetails.propTypes = {
  specifications: PropTypes.shape({}),
};

SpecificationDetails.defaultProps = {
  specifications: undefined,
};

export default SpecificationDetails;
