import { extractPictures } from "./picturesExtractor";
import { InstrumentationLabels } from "../constants/Instrumentation";
import { extractInstrumentationTypeTree } from "./instrumentationTypesExtractor";
import { extractPrimaryValue } from "./valuesExtractor";
import SpecificationKeys from "../constants/SpecificationKeys";
import { mapBlockDistance, mapSensitivity } from "../utils/specificationUtils";

export function extractInstrumentation(rawInstrumentation) {
  const instrumentation = {
    id: rawInstrumentation.id,
    tag: rawInstrumentation.tag,
    statusName: rawInstrumentation.status
      ? rawInstrumentation.status.name
      : null,
    statusCode: rawInstrumentation.status
      ? rawInstrumentation.status.code
      : null,
    worstStatusName: rawInstrumentation.worst_asset_status
      ? rawInstrumentation.worst_asset_status.name
      : null,
    worstStatusCode: rawInstrumentation.worst_asset_status
      ? rawInstrumentation.worst_asset_status.code
      : null,
    typeName: rawInstrumentation.type ? rawInstrumentation.type.name : null,
    typeCode: rawInstrumentation.type ? rawInstrumentation.type.code : null,
    type: rawInstrumentation.type
      ? extractInstrumentationTypeTree(rawInstrumentation.type)
      : null,
    criticality: rawInstrumentation.criticality,
    accessibility: rawInstrumentation.accessibility,
    description: rawInstrumentation.description,
    pictures: rawInstrumentation.pictures
      ? extractPictures(rawInstrumentation)
      : null,
    tenantId: rawInstrumentation.tenant ? rawInstrumentation.tenant.id : null,
    tenantName: rawInstrumentation.tenant
      ? rawInstrumentation.tenant.name
      : null,
    tenantPublic: rawInstrumentation.tenant
      ? rawInstrumentation.tenant.public
      : null,
    itemType: "instrumentation",
    value: extractPrimaryValue(rawInstrumentation.values),
    specifications: rawInstrumentation.specifications,
    nodes: rawInstrumentation.nodes?.items,
  };
  const specs = rawInstrumentation.specifications;

  instrumentation.criticalityQuality = undefined;
  instrumentation.criticalityEnvironment = undefined;
  instrumentation.criticalitySafety = undefined;
  instrumentation.specialDemands = undefined;
  instrumentation.environmentalCondition = undefined;
  instrumentation.unit = undefined;
  instrumentation.empty = undefined;
  instrumentation.full = undefined;
  instrumentation.blockDistance = undefined;
  instrumentation.sensitivity = undefined;
  instrumentation.mediumType = undefined;

  if (specs) {
    instrumentation.criticalityQuality =
      specs[[InstrumentationLabels.CRITICALITY_QUALITY]] &&
      specs[[InstrumentationLabels.CRITICALITY_QUALITY]].value === "true";
    instrumentation.criticalityEnvironment =
      specs[[InstrumentationLabels.CRITICALITY_ENVIRONMENT]] &&
      specs[[InstrumentationLabels.CRITICALITY_ENVIRONMENT]].value === "true";
    instrumentation.criticalitySafety =
      specs[[InstrumentationLabels.CRITICALITY_SAFETY]] &&
      specs[[InstrumentationLabels.CRITICALITY_SAFETY]].value === "true";
    instrumentation.specialDemands =
      specs[[InstrumentationLabels.SPECIAL_DEMANDS]] &&
      specs[[InstrumentationLabels.SPECIAL_DEMANDS]].value === "true";
    instrumentation.environmentalCondition =
      specs[[InstrumentationLabels.ENVIRONMENTAL_CONDITION]] &&
      specs[[InstrumentationLabels.ENVIRONMENTAL_CONDITION]].value === "heavy";
    instrumentation.unit =
      specs[[SpecificationKeys.FullEmptyConfiguration.UNIT]] &&
      specs[[SpecificationKeys.FullEmptyConfiguration.UNIT]].value;
    instrumentation.empty =
      specs[[SpecificationKeys.FullEmptyConfiguration.EMPTY]] &&
      specs[[SpecificationKeys.FullEmptyConfiguration.EMPTY]].value;
    instrumentation.full =
      specs[[SpecificationKeys.FullEmptyConfiguration.FULL]] &&
      specs[[SpecificationKeys.FullEmptyConfiguration.FULL]].value;
    const blockDistanceSpec =
      specs[[SpecificationKeys.FullEmptyConfiguration.BLOCK_DISTANCE]];
    instrumentation.blockDistance = mapBlockDistance(blockDistanceSpec);
    const sensitivitySpec =
      specs[[SpecificationKeys.FullEmptyConfiguration.SENSITIVITY]];
    instrumentation.sensitivity = mapSensitivity(sensitivitySpec);
    instrumentation.mediumType =
      specs[[SpecificationKeys.GeneralSettings.MEDIUM_TYPE]] &&
      specs[[SpecificationKeys.GeneralSettings.MEDIUM_TYPE]].value;
  }
  return instrumentation;
}

export function extractInstrumentations(
  rawInstrumentations,
  instrumentationRules,
) {
  if (!rawInstrumentations) {
    return [];
  }
  if (rawInstrumentations.items) {
    return rawInstrumentations.items.map((instrumentation) =>
      extractInstrumentation(instrumentation, instrumentationRules),
    );
  }
  return rawInstrumentations.instrumentations.map((instrumentation) =>
    extractInstrumentation(instrumentation, instrumentationRules),
  );
}
