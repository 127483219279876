import React from "react";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import PropTypes from "prop-types";
import SubmitButton from "../Form/SubmitButton";
import intlShape from "../../shapes/intlShape";
import { Button, ObjectsTypeahead, Form } from "../Form";
import { ObjectsType } from "../../constants";

export function InstrumentationDuplicateMoreTags({
  intl,
  onCloseDuplicate,
  onCreateDuplicate,
  duplicateTags,
  onAssignDuplicate,
}) {
  const validateForm = (values) => {
    const errors = {};

    if (!values.tag) {
      errors.tag = intl.formatMessage({
        id: "validation.tag_selection.mandatory",
      });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form id="duplicate-form" {...formProps}>
        <span id="modal-text-1" className="space-after">
          {intl.formatMessage(
            { id: "instrumentation_assign.more_duplicates_modal_text1" },
            { tag: duplicateTags[0].tag },
          )}
        </span>
        <ObjectsTypeahead
          {...formProps}
          id="tag-typeahead"
          name="tag"
          placeholder={intl.formatMessage({ id: "label.tag" })}
          values={{}}
          filteredObjects={duplicateTags}
          objectsType={ObjectsType.FilteredInstrumentations}
        />
        <div className="btn-group space-before">
          <SubmitButton
            fetching={isSubmitting}
            id="assign-button"
            disabled={!formProps.values.tag}
            text={intl.formatMessage({ id: "button.assign" })}
          />
        </div>
        <span id="modal-text-2" className="space-after">
          {intl.formatMessage({
            id: "instrumentation_assign.more_duplicates_modal_text2",
          })}
        </span>
        <div className="btn-group space-before">
          <Button
            id="create-button"
            fetching={isSubmitting}
            hero
            onClick={onCreateDuplicate}
            text={intl.formatMessage({ id: "button.create" })}
          />
          <Button
            id="cancel-button"
            onClick={onCloseDuplicate}
            text={intl.formatMessage({ id: "button.cancel" })}
          />
        </div>
      </Form>
    );
  };

  return duplicateTags.length > 0 ? (
    <Formik
      id="duplicate-formik"
      onSubmit={onAssignDuplicate}
      validate={validateForm}
      render={renderForm}
      initialValues={duplicateTags}
    />
  ) : null;
}

InstrumentationDuplicateMoreTags.propTypes = {
  intl: intlShape.isRequired,
  onCloseDuplicate: PropTypes.func,
  onCreateDuplicate: PropTypes.func,
  duplicateTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      tag: PropTypes.string,
    }),
  ).isRequired,
  onAssignDuplicate: PropTypes.func,
};

export default injectIntl(InstrumentationDuplicateMoreTags);
