import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";

import InstrumentationRules from "../rules/InstrumentationRules";
import AssetRules from "../rules/AssetRules";
import NodeRules from "../rules/NodeRules";
import { ApplicationRulesFactory } from "../rules/ApplicationRules";
import { SystemRules } from "../rules";

const RulesContext = React.createContext();

const rulesShape = PropTypes.shape({
  instrumentation: PropTypes.func,
  asset: PropTypes.func,
  node: PropTypes.func,
  application: PropTypes.func,
  system: PropTypes.func,
});

function RulesContextProvider({ children, testRules = {} }) {
  const applicationRules = new ApplicationRulesFactory();

  /* istanbul ignore next */
  const createSystemRules = (system, assets) => new SystemRules(system, assets);

  /* istanbul ignore next */
  const createInstrumentationRules = (instrumentation, assets) =>
    new InstrumentationRules(instrumentation, assets);

  /* istanbul ignore next */
  const createAssetRules = (asset) => new AssetRules(asset);

  /* istanbul ignore next */
  const createNodeRules = (node) => new NodeRules(node);

  /* istanbul ignore next */
  const createApplicationRules = () => applicationRules;

  const context = useMemo(
    () => ({
      rules: {
        system: createSystemRules,
        node: createNodeRules,
        instrumentation: createInstrumentationRules,
        asset: createAssetRules,
        application: createApplicationRules,
        ...testRules,
      },
    }),
    [],
  );

  return (
    <RulesContext.Provider value={context}>{children}</RulesContext.Provider>
  );
}

RulesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  testRules: PropTypes.shape({}),
};

const withRules = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <RulesContext.Consumer>
        {(context) => <ChildComponent {...props} rules={context.rules} />}
      </RulesContext.Consumer>
    );
  }
  ConnectedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

const useRules = () => useContext(RulesContext).rules;

export { rulesShape, RulesContextProvider, withRules, useRules };
