import React, { useState, useEffect } from "react";
import { intlShape } from "../shapes";

import Markdown from "./Markdown";
import { withIntl } from "../wrappers";
import { apiShape, notifierShape, withApi, withNotifier } from "../context";
import { isSomething } from "../utils";

export function Notification({ api, intl, notifier }) {
  const [notification, setNotification] = useState();

  useEffect(() => {
    async function loadNotification() {
      if (!notification) {
        try {
          const response = await api.get("/users/current/notifications", {
            per_page: 1,
          });

          if (isSomething(response?.notifications[0])) {
            setNotification({
              id: response?.notifications[0].id,
              content: response?.notifications[0].content,
            });
          }
        } catch (error) {
          notifier.showError(api.translateError(error));
        }
      }
    }
    loadNotification();
  }, [notification]);

  const hide = async () => {
    const { id } = notification;
    if (isSomething(id)) {
      try {
        await api.delete(`/users/current/notifications/${id}`);
        setNotification(undefined);
      } catch (error) {
        notifier.showError(api.translateError(error));
      }
    }
  };

  return notification?.content ? (
    <div className="notification" id="notification-message">
      <Markdown content={notification.content} />
      <button
        type="button"
        className="btn hide-button"
        id="notification-hide-button"
        onClick={hide}
      >
        {intl.formatMessage({ id: "button.hide" })}
      </button>
    </div>
  ) : null;
}

Notification.propTypes = {
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default withIntl(withApi(withNotifier(Notification)));
