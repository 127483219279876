export function packSubscription(
  customerPurchaseOrder,
  billingAddress,
  shippingAddress,
) {
  const newSubscription = {};
  newSubscription.customer_purchase_order = customerPurchaseOrder;
  newSubscription.billing_address = billingAddress
    ? {
        street: billingAddress.streetName,
        street_number: billingAddress.streetNumber,
        zip_code: billingAddress.zipCode,
        city: billingAddress.city,
        region_code: billingAddress.region
          ? billingAddress.region.code
          : billingAddress.regionCode,
        country_code: billingAddress.country
          ? billingAddress.country.code
          : null,
        phone: billingAddress.phone,
        company_name: billingAddress.companyName,
        first_name: billingAddress.firstName,
        last_name: billingAddress.lastName,
        email: billingAddress.email,
        vat_number: billingAddress.vatNumber,
      }
    : null;
  newSubscription.shipping_address = shippingAddress
    ? {
        street: shippingAddress.streetName,
        street_number: shippingAddress.streetNumber,
        zip_code: shippingAddress.zipCode,
        city: shippingAddress.city,
        region_code: shippingAddress.region
          ? shippingAddress.region.code
          : shippingAddress.regionCode,
        country_code: shippingAddress.country
          ? shippingAddress.country.code
          : null,
        phone: shippingAddress.phone,
        company_name: shippingAddress.companyName,
        first_name: shippingAddress.firstName,
        last_name: shippingAddress.lastName,
      }
    : null;
  return newSubscription;
}
