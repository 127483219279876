import PropTypes from "prop-types";

export const attachmentShape = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  file_name: PropTypes.string,
  file_size: PropTypes.number,
  content_author: PropTypes.string,
  remarks: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string),
  content_date: PropTypes.string,
  created_at: PropTypes.string,
  download_href: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
});
export const attachmentsShape = PropTypes.arrayOf(attachmentShape);
