import React from "react";
import { injectIntl } from "react-intl";
import validator from "validator";
import { Formik } from "formik";
import {
  intlShape,
  apiShape,
  withApi,
  notifierShape,
  withNotifier,
  Form,
  Row,
  Column,
  TextInput,
  ButtonGroup,
  CancelButton,
  SubmitButton,
  isEmpty,
} from "lcm-iot-commons";

export function UserEmailEditForm({ intl, api, notifier }) {
  const handleOnSubmit = async (values, actions) => {
    try {
      const updateObject = {
        current_password: values.password,
        new_email: values.email,
      };
      await api.patch("/users/current/email", updateObject);
      notifier.showSuccess(
        intl.formatMessage({ id: "edit_email.success_notification" }),
      );
    } catch (error) {
      if (error.contains("invalid_password")) {
        notifier.showError(
          intl.formatMessage({ id: "api.error.invalid_password" }),
        );
      } else if (error.contains("taken", "email")) {
        notifier.showError(intl.formatMessage({ id: "api.error.email.taken" }));
      } else {
        notifier.showError(api.translateError(error));
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (isEmpty(values.password)) {
      errors.password = intl.formatMessage({
        id: "validation.password.mandatory",
      });
    }
    if (isEmpty(values.email)) {
      errors.email = intl.formatMessage({
        id: "validation.new_email.mandatory",
      });
    } else if (
      !validator.isEmail(values.email, { allow_utf8_local_part: false })
    ) {
      errors.email = intl.formatMessage({ id: "validation.new_email.invalid" });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <Row id="user-profile-password">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="password"
              component={TextInput}
              label={intl.formatMessage({ id: "label.current_password" })}
              intl={intl}
              name="password"
              type="password"
              disabled={isSubmitting}
              required
            />
          </Column>
        </Row>
        <Row id="user-profile-new-email">
          <Column md={8}>
            <TextInput
              {...formProps}
              id="new-mail"
              component={TextInput}
              label={intl.formatMessage({ id: "label.new_email" })}
              name="email"
              disabled={isSubmitting}
              required
            />
          </Column>
        </Row>
        <ButtonGroup className="btn-group">
          <SubmitButton
            id="edit-email-submit"
            fetching={isSubmitting}
            disabled={!formProps.dirty}
            text={intl.formatMessage({ id: "change_current_email.submit" })}
          />
          <CancelButton
            id="edit-email-cancel"
            disabled={isSubmitting}
            intl={intl}
            target="/profile"
          />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <div className="form-group">
      <Formik
        id="edit-mail-form"
        onSubmit={handleOnSubmit}
        validate={validateForm}
        render={renderForm}
      />
    </div>
  );
}

UserEmailEditForm.propTypes = {
  intl: intlShape.isRequired,
  api: apiShape.isRequired,
  notifier: notifierShape.isRequired,
};

export default injectIntl(withNotifier(withApi(UserEmailEditForm)));
