import React from "react";
import PropTypes from "prop-types";

function Hint({ title, details, id }) {
  return (
    <span className="hint" id={id} data-testid={id}>
      <p>{title}</p>
      {details ? <small>{details}</small> : null}
    </span>
  );
}

Hint.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  details: PropTypes.string,
};

Hint.defaultProps = {
  title: undefined,
  details: undefined,
};

export default Hint;
