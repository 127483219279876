import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import uuid from "uuid";
import {
  formatNumberInText,
  firstToUpperCase,
  readLocalStorage,
} from "../../utils";
import {
  browserShape,
  withBrowser,
  withConfiguration,
  withSession,
} from "../../context";
import { intlShape, sessionShape } from "../../shapes";

export function PlanVariantEnterprise({
  browser,
  configuration,
  intl,
  session,
  planVariant,
}) {
  const handleMouseDown = () => {
    let successUrl;
    if (configuration.appName === "netilion") {
      successUrl = `${readLocalStorage("rootBackUrl")}/subscription/enterprise-quotation-request/success`;
    } else {
      successUrl = session.activeSubscription
        ? configuration.applications[configuration.appName]
        : `${configuration.applications[configuration.appName]}/subscription/enterprise-quotation-request/success`;
    }
    browser.redirectToExtern(
      `/enterprise-quotation-request/form?App=${firstToUpperCase(configuration.appName)}&clientAppUrl=${successUrl}`,
    );
  };

  const features = planVariant.features
    ? planVariant.features.map((feature) => (
        <li className="list-item-checked" key={uuid()}>
          {formatNumberInText(feature)}
        </li>
      ))
    : null;

  const shortDescription = planVariant.short_description ? (
    <div id="short-description" className="space-after short-description">
      {planVariant.short_description}
    </div>
  ) : null;

  return (
    <div id="plan-variant-enterprise" className="panel panel-defaul">
      <div className="panel-heading">
        <div className="title">
          {planVariant.name}
          <div className="panel-subtitle">
            {intl.formatMessage({ id: "plan_variant.enterprise.sub_title" })}
          </div>
        </div>
      </div>
      <div className="panel-body">
        {shortDescription}
        <ul className="list-checked">{features}</ul>
      </div>
      <div className="panel-footer">
        <button
          type="button"
          className="btn select-button"
          onMouseDown={handleMouseDown}
        >
          {planVariant.button}
        </button>
      </div>
    </div>
  );
}

PlanVariantEnterprise.propTypes = {
  browser: browserShape.isRequired,
  configuration: PropTypes.shape({
    appName: PropTypes.string,
    applications: PropTypes.shape({}),
  }).isRequired,
  intl: intlShape.isRequired,
  session: sessionShape.isRequired,
  planVariant: PropTypes.shape({
    id: PropTypes.string,
    currency: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    type: PropTypes.string,
    sort: PropTypes.number,
    popular: PropTypes.bool,
    button: PropTypes.string,
    assets: PropTypes.number,
    storage: PropTypes.number,
    short_description: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    // eslint-disable-next-line react/forbid-prop-types
    addons: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
};
export default injectIntl(
  withBrowser(withConfiguration(withSession(PlanVariantEnterprise))),
);
