/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-numberformat/locale-data/de";
import { isObject } from "lodash-es";

import { locale } from "./browserUtils";

export function numberFormat() {
  return new Intl.NumberFormat(locale());
}

export function formatNumber(number) {
  return numberFormat().format(number);
}

export function numberFormatter(options) {
  return new Intl.NumberFormat(locale(), options);
}

export function isSomething(value) {
  return value !== null && value !== undefined;
}

export function isNothing(value) {
  return !isSomething(value);
}

export function isNumber(value) {
  return (
    value !== null &&
    value !== undefined &&
    (typeof value === "number" || value instanceof Number)
  );
}

export function isString(value) {
  return (
    value !== null &&
    value !== undefined &&
    (typeof value === "string" || value instanceof String)
  );
}

export function isArray(value) {
  return (
    value !== null &&
    value !== undefined &&
    (value instanceof Array || Array.isArray(value))
  );
}

export function isNotEmpty(value) {
  return (isString(value) || isArray(value)) && value.length > 0;
}

export function isNullOrEmpty(value) {
  return !value || !value.trim();
}

export function isEmpty(value) {
  return !isNotEmpty(value);
}

export function isFunction(value) {
  return !!(value && value.constructor && value.call && value.apply);
}

export function camel(value) {
  return value.replace(/[-_]([a-z])/g, (g) => g[1].toUpperCase());
}

export function random(min, max) {
  return (
    Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) +
    Math.ceil(min)
  );
}

export function isValidEmail(email) {
  /* eslint-disable no-useless-escape */
  // https://emailregex.com/#crayon-5c5b38b704153632751579
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!reg.test(email)) return false;
  return true;
}

const inRange = (min, number, max) =>
  isNumber(number) && number >= min && number <= max;

export function isValidCoordinates(numberLat, numberLng) {
  return (
    inRange(-90, Number(numberLat), 90) && inRange(-180, Number(numberLng), 180)
  );
}

export function isObjectNullOrUndefined(object) {
  return object == null;
}

export function extractFirstIdFromIdArray(idArray) {
  if (/^[0-9][0-9,]*$/.test(idArray)) {
    return idArray.split(",")[0];
  }
  return null;
}

export function convertIdsArrayToHirarchicalIdsArray(idsArray) {
  if (/^[0-9][0-9,]*$/.test(idsArray)) {
    return `${idsArray.split(",").join("+,")}+`;
  }
  return null;
}

export function singularPlural(count) {
  return count === 1 ? "singular" : "plural";
}

export function formatTime(seconds) {
  if (seconds >= 0) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return [h, m > 9 ? m : `0${m}`, s > 9 ? s : `0${s}`]
      .filter((se) => se)
      .join(":");
  }
  return "";
}

export function parseDecimal(value) {
  return isSomething(value) ? parseFloat(value) : undefined;
}

export function convertBytesToGB(storage, digits = 2) {
  return (storage / 1024 / 1024 / 1024).toFixed(digits);
}

export function equalsByAttribute(a, b, attribute) {
  if (!a && !b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return a[attribute] === b[attribute];
}

export function prettifyIpAddress(ip) {
  return ip
    .split(".")
    .map((t) => parseInt(t, 10))
    .join(".");
}

export function concatValues(values, separator) {
  return values
    .filter((a) => a)
    .join(separator)
    .trim();
}

export function firstToUpperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function firstToLowerCase(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function prefixObjectKeys(object, prefix) {
  if (isEmpty(prefix) || isNothing(object)) {
    return object;
  }
  const prefixedObject = {};
  Object.keys(object).forEach((key) => {
    prefixedObject[`${prefix}${firstToUpperCase(key)}`] = object[key];
  });
  return prefixedObject;
}

export function unprefixObjectKeys(prefixedObject, prefix) {
  if (isEmpty(prefix) || isNothing(prefixedObject)) {
    return prefixedObject;
  }
  const object = {};
  Object.keys(prefixedObject).forEach((key) => {
    object[firstToLowerCase(key.slice(prefix.length))] = prefixedObject[key];
  });
  return object;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWords(str) {
  return str ? str.replace(/\w+/g, (m) => capitalizeFirstLetter(m)) : "";
}

export function humanize(str, capitalize = true) {
  let result = str;

  if (result) {
    result = result
      .replace(/^[\s_]+|[\s_]+$/g, "")
      .replace(/^[\s\.]+|[\s\.]+$/g, "")
      .replace(/^[\s\-]+|[\s\-]+$/g, "")
      .replace(/[_\s]+/g, " ")
      .replace(/[\.\s]+/g, " ")
      .replace(/[\-\s]+/g, " ")
      .replace(/[a-z]{1}[A-Z]{1}/g, (s) => `${s[0]} ${s[1].toLowerCase()}`);

    if (capitalize) {
      result = capitalizeFirstLetter(result);
    }

    return result;
  }

  return "";
}

export function concatObjectValues(object, separator = ",") {
  return concatValues(
    Object.keys(object).map((key) => object[key]),
    separator,
  );
}

export function sortBy(objectsArray, attribute, direction) {
  let clone = objectsArray.slice(0);
  clone = clone.sort((a, b) => {
    const sortDirection = direction === "ASC" ? -1 : 1; // -1 = ASCENDING, 1 = DESCENDING

    if (
      isNothing(a[attribute]) ||
      (isEmpty(a[attribute]) && typeof a[attribute] === "string")
    ) {
      return -1 * sortDirection;
    }
    if (
      isNothing(b[attribute]) ||
      (isEmpty(b[attribute]) && typeof b[attribute] === "string")
    ) {
      return 1 * sortDirection;
    }

    const attributeType = typeof a[attribute];
    let compareValue = 0;

    if (attributeType === "boolean" || attributeType === "number") {
      compareValue = (a[attribute] - b[attribute]) * -1;
    } else {
      compareValue = a[attribute]
        .toString()
        .localeCompare(b[attribute].toString());
    }
    return compareValue * sortDirection;
  });
  return clone;
}

// eslint-disable-next-line default-param-last
export function sortByNestedAttribute(array, nestedAttributes = [], direction) {
  if (array.length === 0) {
    return [];
  }
  let clone = array.slice(0);

  const getObjectAttribute = (object, attributeArray) => {
    let nestedObject = object;
    for (let i = 0; i <= attributeArray.length - 1; i += 1) {
      if (
        nestedObject &&
        hasOwnProperty.call(nestedObject, attributeArray[i])
      ) {
        nestedObject = nestedObject[attributeArray[i]];
      } else {
        nestedObject = undefined;
        break;
      }
    }
    return nestedObject;
  };

  if (nestedAttributes.length > 1) {
    clone = clone.sort((a, b) => {
      const sortDirection = direction === "ASC" ? -1 : 1; // -1 = ASCENDING, 1 = DESCENDING
      const aAttribute = getObjectAttribute(a, nestedAttributes);
      const bAttribute = getObjectAttribute(b, nestedAttributes);

      if (
        isNothing(aAttribute) ||
        (isEmpty(aAttribute) && typeof aAttribute === "string")
      ) {
        return -1 * sortDirection;
      }
      if (
        isNothing(bAttribute) ||
        (isEmpty(bAttribute) && typeof bAttribute === "string")
      ) {
        return 1 * sortDirection;
      }

      const attributeType = typeof getObjectAttribute(a, nestedAttributes);
      let compareValue = 0;

      if (attributeType === "boolean" || attributeType === "number") {
        compareValue =
          (getObjectAttribute(a, nestedAttributes) -
            getObjectAttribute(b, nestedAttributes)) *
          -1;
      } else {
        compareValue = getObjectAttribute(a, nestedAttributes)
          .toString()
          .localeCompare(getObjectAttribute(b, nestedAttributes).toString());
      }
      return compareValue * sortDirection;
    });
  }
  return clone;
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    /* eslint-disable no-await-in-loop */
    await callback(array[index], index, array);
  }
}

// can be used to set empty values to null before sending them to the hub.
export function setEmptyOrUndefinedToNull(obj) {
  if (!isObject(obj)) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "" || obj[key] === undefined) {
      obj[key] = null;
    }
  });
  return obj;
}

export function snakeToCamel(value) {
  return value.replace(/_(\w)/g, (m) => m[1].toUpperCase());
}

export function formatNumberInText(value) {
  return value.replace(/([\d\.]+)/g, (m) => formatNumber(parseFloat(m)));
}

class NumberParser {
  /*
    Full implementation including arab etc.: https://observablehq.com/@mbostock/localized-number-parsing
  */
  constructor() {
    const parts = numberFormat().formatToParts(12345.6);
    this.decimal = new RegExp(
      `[${parts.find((d) => d.type === "decimal").value}]`,
    );
  }

  parse(string) {
    if (!isSomething(string)) {
      return NaN;
    }
    return parseFloat(string.trim().replace(this.decimal, "."));
  }
}

export function parseIntlNumber(value) {
  return new NumberParser().parse(value);
}

export function isValidDecimalSeparator(value) {
  const parts = numberFormat().formatToParts(1.2);
  const decimalSeparator = parts.find((d) => d.type === "decimal").value;
  let isValid = false;

  if (decimalSeparator === ".") {
    const dotTestComma = value.split("").filter((c) => c === ",").length;
    const dotTestDot = value
      .split("")
      .filter((c) => c === decimalSeparator).length;
    isValid = dotTestComma === 0 && dotTestDot <= 1;
  } else {
    const commaTestComma = value
      .split("")
      .filter((c) => c === decimalSeparator).length;
    const commaTestDot = value.split("").filter((c) => c === ".").length;
    isValid = commaTestDot === 0 && commaTestComma <= 1;
  }
  return isValid;
}

export function isValidFloat(value) {
  return /^-?\d+([\.\,]\d+)?$/.test(value);
}

export function arraysEqual(_arr1, _arr2) {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  )
    return false;

  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}
