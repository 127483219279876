import Status from "../constants/Status";
import DataExportStatus from "../constants/DataExportStatus";

export function statusIconClassName(status) {
  switch (status) {
    case Status.CRITICAL:
      return "icon lcm-iot-icon-circle text-danger";
    case Status.WARNING:
      return "icon lcm-iot-icon-circle text-warning";
    case Status.SUCCESS:
      return "icon lcm-iot-icon-circle text-success";
    default:
      return "icon lcm-iot-icon-circle text-muted";
  }
}

export function statusForProductStatus(status) {
  switch (status) {
    case "order_stop":
      return Status.CRITICAL;
    case "phase_out":
      return Status.WARNING;
    case "available":
      return Status.SUCCESS;
    default:
      return Status.UNDEFINED;
  }
}

export function productStatusIconClassName(status) {
  return statusIconClassName(statusForProductStatus(status));
}

export function statusForCriticality(criticality) {
  switch (criticality) {
    case "high":
      return Status.CRITICAL;
    case "medium":
      return Status.WARNING;
    case "low":
      return Status.SUCCESS;
    default:
      return Status.UNDEFINED;
  }
}

export function criticalityIconClassName(status) {
  return statusIconClassName(statusForCriticality(status));
}

export function statusForAccessibility(accessibility) {
  switch (accessibility) {
    case "difficult":
      return Status.CRITICAL;
    case "moderate":
      return Status.WARNING;
    case "easy":
      return Status.SUCCESS;
    default:
      return Status.UNDEFINED;
  }
}

export function accessibilityIconClassName(status) {
  return statusIconClassName(statusForAccessibility(status));
}

export function statusForInstrumentationStatus(status) {
  switch (status) {
    case "inactive":
      return Status.WARNING;
    case "active":
      return Status.SUCCESS;
    default:
      return Status.UNDEFINED;
  }
}

export function instrumentationStatusIconClassName(status) {
  return statusIconClassName(statusForInstrumentationStatus(status));
}

export function statusForAssetStatus(status) {
  switch (status) {
    case "defective":
      return Status.CRITICAL;
    case "failure":
      return Status.WARNING;
    case "inactive":
      return Status.WARNING;
    case "active":
      return Status.SUCCESS;
    default:
      return Status.UNDEFINED;
  }
}

export function assetSatusIconClassName(status) {
  return statusIconClassName(statusForAssetStatus(status));
}

export function exportDataStatusIconClassName(status) {
  switch (status) {
    case DataExportStatus.FAILED:
      return "lcm-iot-icon-circle status-error icon";
    case DataExportStatus.IN_PROGRESS:
      return "lcm-iot-icon-circle status-warning icon";
    case DataExportStatus.DONE:
      return "lcm-iot-icon-circle status-success icon";
    default:
      return "lcm-iot-icon-circle status-undefined icon";
  }
}
