import SpecificationKeys from "../constants/SpecificationKeys";

export function setGPSInterval(values) {
  const specifications = {};
  if (values.gpsActive && values.gpsInterval) {
    specifications[[SpecificationKeys.GPSSettings.GPS_SEND_INTERVAL]] = {
      value: values.gpsInterval.id,
    };
    specifications[[SpecificationKeys.GPSSettings.GPS_SEND_LATEST_INTERVAL]] = {
      value: values.gpsInterval.id,
    };
  } else if (
    (values.gpsActive && !values.gpsInterval) ||
    (!values.gpsActive && !values.gpsInterval)
  ) {
    specifications[[SpecificationKeys.GPSSettings.GPS_SEND_INTERVAL]] = {
      value: "0",
    };
    specifications[[SpecificationKeys.GPSSettings.GPS_SEND_LATEST_INTERVAL]] = {
      value: "0",
    };
  } else {
    specifications[[SpecificationKeys.GPSSettings.GPS_SEND_INTERVAL]] = {
      value: "0",
    };
    specifications[[SpecificationKeys.GPSSettings.GPS_SEND_LATEST_INTERVAL]] = {
      value: values.gpsInterval.id,
    };
  }
  return specifications;
}
