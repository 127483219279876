import PropTypes from "prop-types";
import React from "react";
import { userShape } from "../../shapes";

function UserItem({ user, actions, showUserEmail }) {
  const { first_name: firstName, last_name: lastName, email } = user;
  const name = `${firstName || ""} ${lastName || ""}`.trim();

  const showName = () => {
    const regex = /@connect$/;
    if (regex.test(email)) {
      return email;
    }

    return name || "No name";
  };

  return (
    <li className="list-item">
      <div className="list-item-image">
        <span className="icon lcm-iot-icon-user" />
      </div>
      <div className="list-item-header-only">
        {showName()}
        {showUserEmail && (
          <>
            <br />
            <span className="list-item-header-email">{email}</span>
          </>
        )}
      </div>
      {actions && <div className="list-item-actions">{actions}</div>}
    </li>
  );
}

UserItem.propTypes = {
  user: userShape,
  actions: PropTypes.node,
  showUserEmail: PropTypes.bool,
};

export default UserItem;
