import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import * as browserUtils from "../utils/browserUtils";

const BrowserContext = React.createContext();

const browserShape = PropTypes.shape({
  redirectTo: PropTypes.func,
  navigateTo: PropTypes.func,
  goBack: PropTypes.func,
  redirectToExtern: PropTypes.func,
  redirectToExternNewWindow: PropTypes.func,
  currentUrl: PropTypes.func,
});

const BrowserContextProvider = withRouter(
  ({ children, history, testBrowser }) => {
    // FIXME this a backward compatibility hack. One day we should have the browserUtils methods
    // only in this context and call them always via the browser context.
    window.app.browserHistory = history;

    const context = useMemo(() => {
      let browser = browserUtils;
      if (testBrowser) {
        browser = testBrowser;
      }
      return { browser };
    }, []);

    return (
      <BrowserContext.Provider value={context}>
        {children}
      </BrowserContext.Provider>
    );
  },
);

BrowserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  testBrowser: PropTypes.shape({}),
};

BrowserContextProvider.defaultProps = {
  testBrowser: undefined,
};

const withBrowser = (ChildComponent) => {
  function ConnectedComponent(props) {
    return (
      <BrowserContext.Consumer>
        {(context) => <ChildComponent {...props} browser={context.browser} />}
      </BrowserContext.Consumer>
    );
  }
  ConnectedComponent.displayName =
    ChildComponent.displayName || ChildComponent.name;
  return ConnectedComponent;
};

export { browserShape, BrowserContextProvider, withBrowser, BrowserContext };
