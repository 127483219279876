import React from "react";
import { injectIntl } from "react-intl";
import validator from "validator";
import { Formik } from "formik";
import PropTypes from "prop-types";
import {
  intlShape,
  Form,
  isTouchDevice,
  TextInput,
  SubmitButton,
  isEmpty,
} from "lcm-iot-commons";
import Captcha from "../Captcha/Captcha";

export function RequestPasswordResetForm({ intl, onSubmit }) {
  const validateForm = (values) => {
    const errors = {};
    if (isEmpty(values.email)) {
      errors.email = intl.formatMessage({ id: "validation.email.mandatory" });
    } else if (!validator.isEmail(values.email)) {
      errors.email = intl.formatMessage({ id: "validation.email.invalid" });
    }
    if (!values.captcha) {
      errors.captcha = intl.formatMessage({
        id: "validation.captcha.mandatory",
      });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;

    return (
      <Form {...formProps}>
        <div className="form-group">
          <TextInput
            {...formProps}
            name="email"
            id="email"
            component={TextInput}
            label={intl.formatMessage({ id: "password_reset.email" })}
            disabled={isSubmitting}
            autoFocus={!isTouchDevice()}
            required
          />
        </div>
        <Captcha {...formProps} name="captcha" />
        <SubmitButton
          id="send-verification-email"
          name="send-verification-email"
          disabled={!formProps.dirty}
          fetching={isSubmitting}
          text={intl.formatMessage({
            id: "password_reset.send_verification_email",
          })}
        />
      </Form>
    );
  };

  return (
    <div className="row">
      <div className="col-md-6" id="request-password-reset-init">
        <h1>{intl.formatMessage({ id: "password_reset.header" })}</h1>
        <p>{intl.formatMessage({ id: "password_reset.provide_username" })}</p>
        <p>
          {intl.formatMessage({ id: "password_reset.we_send_you_an_email" })}
        </p>
        <div className="form-group">
          <Formik
            id="edit-mail-form"
            onSubmit={onSubmit}
            validate={validateForm}
            render={renderForm}
          />
        </div>
      </div>
    </div>
  );
}

RequestPasswordResetForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func,
};

RequestPasswordResetForm.defaultProps = {
  onSubmit: undefined,
};

export default injectIntl(RequestPasswordResetForm);
