/* eslint-disable max-classes-per-file */
import Rules from "./Rules";

class ApplicationRules extends Rules {
  static delegate;

  nodesIncludes() {
    return "type,type.parent";
  }

  minDecimalDigits() {
    return 0;
  }

  editValueSettingsButtonExists() {
    return false;
  }

  maxDecimalDigits() {
    return 3;
  }

  searchScope() {
    return ["assets", "nodes", "instrumentations"];
  }

  userSpecifications() {
    return ["eh.pcps.user.newsletter_recipient"];
  }

  allowedTrialApp() {
    return false;
  }

  showDeleteMeasurementValuesButton() {
    return false;
  }

  hasConnectedAssetAddon() {
    return false;
  }

  showHealthNotifications() {
    return false;
  }

  withOneSubscription() {
    return false;
  }

  showFilesInNodeDetails() {
    return false;
  }

  showFilesExportInContextMenu() {
    return false;
  }

  usePreferredValueSettings() {
    return false;
  }
}

export class ApplicationRulesFactory {
  get(rule, a, b, c) {
    return ApplicationRules.delegate.get(rule, a, b, c);
  }
}

ApplicationRules.delegate = new ApplicationRules();

ApplicationRules.register = (Clazz) => {
  if (Clazz) {
    ApplicationRules.delegate = new Clazz();
  } else {
    ApplicationRules.delegate = new ApplicationRules();
  }
};

export default ApplicationRules;
