import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";
import { useQueryClient } from "@tanstack/react-query";
import { SubmitButton } from "../Form";
import { withNotifier, withConfiguration, useNotifier } from "../../context";
import { capitalizeFirstLetter } from "../../utils";
import { useApi, useSubscriptionQuery } from "../../hooks";

export function AssignAssetToSubscriptionModal({ asset, configuration }) {
  const api = useApi();
  const intl = useIntl();
  const notifier = useNotifier();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const { data: subscription } = useSubscriptionQuery();

  const { appName } = configuration;
  const capitalizedAppName = capitalizeFirstLetter(appName);
  const assetsLeftInSubscription =
    !subscription ||
    subscription.asset_quota === -1 ||
    subscription.asset_quota > subscription.number_assigned_assets;

  const { mutate, isLoading } = api.post.useMutation(
    `/subscriptions/${subscription?.id}/assets`,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["/subscription"] });
        setIsRedirecting(true);
        notifier.showSuccess(
          intl.formatMessage({
            id: "subscription.asset_assigned_success_message",
          }),
        );
        navigate(0);
      },
    },
  );

  const onAccept = async () => {
    if (!assetsLeftInSubscription) {
      navigate(`${configuration.baseUrl}/upgrade`);
      return;
    }

    mutate({ assets: [{ id: asset.id }] });
  };

  const onDecline = async () => {
    navigate(-1);
  };

  return (
    <Modal
      dialogClassName="asset-subscription-assignment-modal"
      show
      restoreFocus={false}
      size="sm"
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
          {intl.formatMessage(
            { id: "subscription.asset_assignment_modal.title" },
            { appName: capitalizedAppName },
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="center">
          <div className="apps-menu">
            <div className="menu-item">
              <div className="menu-app-icon">
                <span
                  className={`icon lcm-iot-icon-${appName.toLowerCase()}`}
                />
              </div>
              <span className="title">
                <span>{capitalizedAppName}</span>
              </span>
            </div>
          </div>
        </div>
        <p>
          {intl.formatMessage(
            {
              id: !assetsLeftInSubscription
                ? "subscription.asset_assignment_modal.message.subscription_full"
                : "subscription.asset_assignment_modal.message",
            },
            { appName: capitalizedAppName, serialNumber: asset.serial_number },
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-group justify-middle">
          <SubmitButton
            fetching={isLoading || isRedirecting}
            id="asset-subscription-assign-accept"
            onClick={onAccept}
            text={intl.formatMessage({
              id: !assetsLeftInSubscription
                ? "subscription.asset_assignment_modal.upgrade"
                : "subscription.asset_assignment_modal.accept",
            })}
            disabled={isLoading || isRedirecting}
          />
          <button
            id="asset-subscription-assign-decline"
            type="button"
            className="btn btn-default"
            onClick={onDecline}
            disabled={isLoading || isRedirecting}
          >
            {intl.formatMessage({
              id: "subscription.asset_assignment_modal.cancel",
            })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

AssignAssetToSubscriptionModal.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.number,
    serial_number: PropTypes.string,
  }),
  configuration: PropTypes.shape({
    appName: PropTypes.string,
    baseUrl: PropTypes.string,
  }).isRequired,
};

export default withConfiguration(withNotifier(AssignAssetToSubscriptionModal));
