import PropTypes from "prop-types";
import React, { Component } from "react";
import { navigateTo } from "../../utils";

class ActionButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    defaultLabel: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    target: PropTypes.string,
  };

  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { disabled, onClick, target } = this.props;
    if (!disabled) {
      if (onClick) {
        onClick();
      } else if (target) {
        navigateTo(target);
      }
    }
  }

  render() {
    const { id, disabled, icon, label, defaultLabel } = this.props;
    const displayLabel = label || defaultLabel;
    const className = `btn-link btn-action ${icon}`;
    return (
      <button
        id={id}
        data-testid={`test-${id}`}
        className={className}
        disabled={disabled}
        onClick={this.onClick}
        type="button"
      >
        <span className="action-button-label hidden-xs">{displayLabel}</span>
      </button>
    );
  }
}

export default ActionButton;
