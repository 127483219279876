/* eslint-disable no-param-reassign */

import { isObject } from "lodash-es";
import { isString } from "./langUtils";

export function couldBeAnEndressHauserSerialNumber(serialNumber) {
  return (
    serialNumber &&
    isString(serialNumber) &&
    /^[0-9a-zA-Z\-_]{4,18}$/.test(serialNumber)
  );
}

// can be used to clean errors at the end of validateForm before returning them to the form
// errors object must be empty, null attributes need to be deleted.
export function removeNullAttributes(obj) {
  if (!isObject(obj)) {
    return obj;
  }
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
  return obj;
}

export function luhnCheck(num) {
  const arr = `${num}`
    .split("")
    .reverse()
    .map((x) => parseInt(x, 10));
  const lastDigit = arr.splice(0, 1)[0];
  let sum = arr.reduce(
    (acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9),
    0,
  );
  sum += lastDigit;
  return sum % 10 === 0;
}
