import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { intlShape } from "../../shapes";
import { url } from "../../utils";
import ContextualHelp from "../Contextuals/ContextualHelp";

export function InstrumentationFullEmptyCalibrationHelp({ intl }) {
  return (
    <ContextualHelp
      title={intl.formatMessage({ id: "label.full_empty_calibration" })}
    >
      <p className="space-after">
        <FormattedMessage id="instrumentation_full_empty_calibration_help.description" />
      </p>
      <img
        className="img-responsive"
        src={url("/images/full_empty_calibration_help.png")}
        alt={intl.formatMessage({ id: "label.full_empty_calibration" })}
      />
      <h3>{`${intl.formatMessage({ id: "label.full" })} (F)`}</h3>
      <p>
        <FormattedMessage id="instrumentation_full_empty_calibration_help.full" />
      </p>
      <h3>{`${intl.formatMessage({ id: "label.empty" })} (E)`}</h3>
      <p>
        <FormattedMessage id="instrumentation_full_empty_calibration_help.empty" />
      </p>
      <h3>{`${intl.formatMessage({ id: "label.block_distance" })} (BD)`}</h3>
      <p>
        <FormattedMessage id="instrumentation_full_empty_calibration_help.block_distance" />
      </p>
      <h3>{`${intl.formatMessage({ id: "label.sensitivity" })}`}</h3>
      <p>
        <FormattedMessage id="instrumentation_full_empty_calibration_help.sensitivity" />
      </p>
    </ContextualHelp>
  );
}

InstrumentationFullEmptyCalibrationHelp.propTypes = {
  intl: intlShape,
};

export default injectIntl(InstrumentationFullEmptyCalibrationHelp);
