import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

export default function ChipsSkeleton({ count = 2 }) {
  return (
    <div className="chips-skeleton" data-testid="chips-skeleton">
      {Array.from({ length: count }, (_, i) => (
        <Skeleton key={`chips-skeleton-${i}`} width={80} />
      ))}
    </div>
  );
}

ChipsSkeleton.propTypes = {
  count: PropTypes.number,
};
