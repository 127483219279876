/* eslint-disable camelcase */
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { isEmpty } from "lodash-es";

import {
  Row,
  Column,
  Container,
  Loader,
  ActionBar,
  Heading,
  Details,
  DetailsItem,
  PictureGallery,
  DetailsHeaderItem,
  DetailsPicture,
  ActionBarButtons,
  EditActionButton,
  DeleteActionButton,
  withApi,
  withUser,
  withBrowser,
  withAccessRights,
  apiShape,
  accessRightsShape,
  intlShape,
  browserShape,
  withNotifier,
  notifierShape,
  formatAddress,
  removeNullAttributes,
} from "lcm-iot-commons";

export function extractInitialValues(response) {
  const initialValues = { ...response };
  const address = {
    firstName: response.address?.first_name,
    lastName: response.address?.last_name,
    streetName: response.address?.street,
    streetNumber: response.address?.street_number,
    zipCode: response.address?.zip_code,
    regionCode: response.address?.region_code,
    city: response.address?.city,
    phone: response.address?.phone,
    countryCode: response.address?.country_code,
    countryName: response.address?.country_name,
  };
  initialValues.address = removeNullAttributes(address);
  return initialValues;
}

export function ResellerCustomerDetails(props) {
  const { api, browser, accessRights, match, intl, notifier } = props;
  const { id } = match.params;
  const [customer, setCustomer] = useState([]);

  const loadData = async () => {
    try {
      const response = await api.get(
        `/customers/${id}`,
        { include: "address" },
        false,
      );
      setCustomer(extractInitialValues(response, accessRights));
    } catch (error) {
      notifier.showError(api.translateError(error));
      setCustomer({});
    }
  };
  React.useEffect(() => {
    loadData();
  }, [id]);

  const onConfirmDelete = async () => {
    try {
      await api.delete(`/customers/${id}`);
      notifier.showSuccess(
        intl.formatMessage({ id: "reseller_customer.delete.notification" }),
      );
      browser.navigateTo("/reseller/customers");
    } catch (error) {
      notifier.showError(api.translateError(error));
    }
  };

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <Heading
              title={intl.formatMessage({
                id: "reseller_customer.details.header",
              })}
            />
            <ActionBarButtons>
              <EditActionButton
                id="edit-customer-button"
                disabled={!accessRights.canUpdate}
                target={`/reseller/customers/${id}/edit`}
              />
              <DeleteActionButton
                id="delete-customer-button"
                disabled={!accessRights.canDelete}
                modalTitle={intl.formatMessage({
                  id: "reseller_customer.delete.modal_title",
                })}
                modalMessage={intl.formatMessage({
                  id: "reseller_customer.delete.modal_message",
                })}
                onConfirm={onConfirmDelete}
              />
            </ActionBarButtons>
          </ActionBar>
          {customer && (
            <Details>
              <DetailsPicture>
                <PictureGallery
                  placeholder="lcm-iot-icon-user icon"
                  id="placeholder"
                />
              </DetailsPicture>
              <DetailsHeaderItem
                id="company"
                translationKey="label.account"
                value={customer.name}
              />
              <DetailsHeaderItem
                id="number"
                translationKey="label.number"
                value={customer.number}
              />
              {!isEmpty(customer.address) && (
                <Details>
                  <DetailsItem
                    id="customer-address"
                    translationKey="label.address"
                  >
                    <ul className="shipping-billing-address">
                      {formatAddress(customer.address).map((line) => (
                        <li className="shipping-billing-line" key={uuid()}>
                          {line}
                        </li>
                      ))}
                    </ul>
                  </DetailsItem>
                </Details>
              )}
            </Details>
          )}
          <Loader id="loader" loading={!customer} />
        </Column>
      </Row>
    </Container>
  );
}

ResellerCustomerDetails.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  accessRights: accessRightsShape.isRequired,
  notifier: notifierShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      resellerCustomerId: PropTypes.string,
    }),
  }).isRequired,
};

export default withBrowser(
  withNotifier(
    withApi(
      withUser(
        injectIntl(withAccessRights(ResellerCustomerDetails, "Customer")),
      ),
    ),
  ),
);
