import React from "react";
import { FormattedMessage } from "react-intl";
import { ActionBar, withUser, isSomething, userShape } from "lcm-iot-commons";
import AdministrationsItem from "./AdministrationItem";

export function Administration({ user }) {
  const administrations = [
    {
      titleId: "usergroups.header",
      descriptionId: "usergroups.description",
      type: "team",
      href: "/usergroups",
    },
    {
      titleId: "tenants.header",
      descriptionId: "tenants.description",
      type: "tenant",
      href: "/tenants",
    },
    {
      titleId: "products.header",
      descriptionId: "products.description",
      type: "product",
      href: "/products",
    },
  ];
  const controllingDirector = user?.userRoles.find(
    (ur) => ur.name === "controlling_director",
  );

  if (isSomething(controllingDirector)) {
    administrations.push({
      titleId: "taxes.header",
      descriptionId: "taxes.description",
      type: "tax-rounded",
      href: "/taxes",
    });
  }

  administrations.push({
    titleId: "administration.fus.title",
    descriptionId: "administration.fus.description",
    type: "fus",
    href: "/app/fus",
    external: true,
  });

  if (
    isSomething(
      user?.userRoles.find((ur) => ur.name === "firmware_update_service_admin"),
    )
  ) {
    administrations.push({
      titleId: "administration.fum.title",
      descriptionId: "administration.fum.description",
      type: "fum",
      href: "/app/fum",
      external: true,
    });
  }

  if (
    isSomething(user?.userRoles.find((role) => role.name === "reseller_admin"))
  ) {
    administrations.push({
      titleId: "administration.userroles.title",
      descriptionId: "administration.userroles.description",
      type: "team",
      href: "/userrole",
    });
  }

  if (isSomething(user?.userRoles.find((role) => role.name === "reseller"))) {
    administrations.push({
      titleId: "administration.reseller.title",
      descriptionId: "administration.reseller.description",
      type: "team",
      href: "/reseller/subscriptions",
    });
    administrations.push({
      titleId: "administration.reseller.customers",
      descriptionId: "administration.reseller.customers.description",
      type: "user",
      href: "/reseller/customers",
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <ActionBar>
            <h1 id="administration-header">
              <FormattedMessage id="label.administration" />
            </h1>
          </ActionBar>
        </div>
      </div>
      <ul id="administration-list" className="list">
        {administrations.map((administration) => (
          <AdministrationsItem
            administration={administration}
            key={administration.titleId}
          />
        ))}
      </ul>
    </div>
  );
}

Administration.propTypes = {
  user: userShape,
};

Administration.defaultProps = {
  user: undefined,
};

export default withUser(Administration);
