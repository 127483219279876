import React from "react";
import PropTypes from "prop-types";
import FormattedRelativeTime from "./Format/FormattedRelativeTime";

function ExpirableDate({
  date,
  id,
  infoPopup,
  hoursAfterLatestUpdateToExpire,
}) {
  const isDateOlderThanOneDay =
    new Date() - date > hoursAfterLatestUpdateToExpire * 60 * 60 * 1000;
  return (
    <div data-testid="expirable-date" className="expirable-date">
      <FormattedRelativeTime
        id={id}
        customClass={isDateOlderThanOneDay ? "offline" : ""}
        value={date}
      />
      {isDateOlderThanOneDay ? infoPopup : null}
    </div>
  );
}

ExpirableDate.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  id: PropTypes.string,
  infoPopup: PropTypes.node,
  hoursAfterLatestUpdateToExpire: PropTypes.number,
};

export default ExpirableDate;
