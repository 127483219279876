import React from "react";
import { injectIntl } from "react-intl";
import {
  withApi,
  apiShape,
  withBackend,
  backendShape,
  notifierShape,
  withNotifier,
} from "../../context";

import { Clue } from "../Clue";
import { SearchBar } from "../Search";
import { intlShape } from "../../shapes";
import { isTouchDevice } from "../../utils";
import { ActionBar, ActionBarButtons, CreateActionButton } from "../ActionBar";
import TicketItem from "./TicketItem";
import Loader from "../Loader";

export function TicketSearch({ api, backend, intl, notifier }) {
  const [tickets, setTickets] = React.useState();
  const [totalCount, setTotalCount] = React.useState();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const response = await backend.getAll("/support/tickets", {
        term: `${searchTerm}`,
      });
      setTotalCount(response.pagination.total_count);
      setTickets(response.tickets);
      setLoading(false);
    };
    loadData().catch((error) => {
      notifier.showError(api.translateError(error));
    });
  }, [searchTerm]);

  const onSearch = (input) => {
    setSearchTerm(input);
  };

  const badge = loading ? (
    <span className="loading">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  ) : (
    totalCount
  );

  return (
    <div>
      <ActionBar>
        <h1>
          {intl.formatMessage({ id: "support.tickets.header" })}
          <span id="support-tickets-count" className="badge">
            {badge}
          </span>
        </h1>
        <ActionBarButtons>
          <CreateActionButton
            id="create-support-ticket-button"
            target="/support/tickets/create"
          />
        </ActionBarButtons>
      </ActionBar>
      <SearchBar
        autoFocus={!isTouchDevice()}
        onSearch={onSearch}
        placeholder={intl.formatMessage({ id: "label.support_ticket" })}
      />
      <ul id="support-tickets-list" className="list">
        {tickets &&
          tickets.map((ticket) => (
            <TicketItem key={ticket.id} ticket={ticket} />
          ))}
        {totalCount === 0 && (
          <Clue
            details={intl.formatMessage({ id: "support.tickets.not_found" })}
          />
        )}
      </ul>
      {!tickets && <Loader loading />}
    </div>
  );
}

TicketSearch.propTypes = {
  api: apiShape,
  intl: intlShape,
  backend: backendShape,
  notifier: notifierShape,
};

export default withBackend(withNotifier(withApi(injectIntl(TicketSearch))));
