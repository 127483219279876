import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  ActionBar,
  ActionBarButtons,
  DeleteActionButton,
  EditActionButton,
} from "../ActionBar";
import Loader from "../Loader";
import More from "../More";
import NodesAssignList from "../ObjectAssignList/NodesAssignList";
import AssetsAssignList from "../ObjectAssignList/AssetsAssignList";
import SpecificationDetails from "../Specifications/SpecificationDetails";
import InstrumentationFullEmptyCalibrationHelp from "./InstrumentationFullEmptyCalibrationHelp";
import { addUnitToValue } from "../../utils/valueUtils";
import { Details, DetailsItem } from "../Details";
import {
  extractAssets,
  extractInstrumentationTypeTree,
  extractPictures,
  extractPrimaryValue,
} from "../../extractors";
import { convertSpecsToAttributes } from "../../utils/specificationUtils";
import { NotFoundError, ConflictError } from "../../api";
import { ToggleLabel } from "../Form";
import { Column, Container, Row } from "../Grid";
import { accessRightsShape, intlShape } from "../../shapes";
import { withAccessRights, enforceAccessRightCanRead } from "../../wrappers";
import InstrumentationDetailsBasicInfo from "./InstrumentationDetailsBasicInfo";

import {
  accessibilityIconClassName,
  criticalityIconClassName,
  nameForCriticality,
  nameForSelections,
  sortBy,
} from "../../utils";
import {
  withApi,
  apiShape,
  withRules,
  rulesShape,
  withBrowser,
  withNotifier,
  browserShape,
  notifierShape,
} from "../../context";

export function InstrumentationDetails(props) {
  const {
    api,
    accessRights,
    browser,
    notifier,
    intl,
    match,
    rules,
    shouldCollapse,
    appSpecificMore,
    appShowsFullEmptyCalibration,
    appShowsMediumType,
    children,
  } = props;

  const { id } = match.params;
  const [assets, setAssets] = useState();
  const [instrumentation, setInstrumentation] = React.useState();
  const [firmwareVersion, setFirmwareVersion] = useState();
  const [sensorBuildNr, setSensorBuildNr] = useState();
  const [fetching, setFetching] = useState(true);

  const stringToBool = (string) => string === "true";
  const currentRules = rules.instrumentation(instrumentation, assets);
  const showHealthNotifications =
    rules?.application().get("showHealthNotifications") &&
    accessRights.canUpdate;
  const prepareInstrumentationForDisplay = (apiResponse) => {
    let newInstrumentation = apiResponse;
    const specsWhitelist = [
      "eh.iba.environmental_condition",
      "eh.iba.special_demands",
      "eh.iba.criticality_quality",
      "eh.iba.criticality_environment",
      "eh.iba.criticality_safety",
      // The following specs are shared by value & inventory.
      // They get displayed by appShowsMediumType & appShowsFullEmptyCalibration (bools in props)
      "eh.user_config.medium_type",
      "eh.user_config.unit",
      "eh.user_config.empty",
      "eh.user_config.full",
      "eh.user_config.sensitivity",
      "eh.user_config.block_distance",
    ];
    newInstrumentation.itemType = "instrumentation";
    newInstrumentation.type = extractInstrumentationTypeTree(apiResponse.type);
    newInstrumentation.pictures = extractPictures(apiResponse);
    newInstrumentation.value = extractPrimaryValue(apiResponse.values);
    newInstrumentation = convertSpecsToAttributes(
      newInstrumentation,
      apiResponse.specifications,
      specsWhitelist,
    );
    newInstrumentation.worst_asset_status = apiResponse.worst_asset_status;
    return newInstrumentation;
  };

  const loadData = async () => {
    try {
      const apiResponse = await api.get(
        `/instrumentations/${id}`,
        {
          include:
            "type,status,pictures,specifications,type.parent,type.tenant,worst_asset_status",
        },
        false,
      );
      const responseAssets = await api.get(`/instrumentations/${id}/assets`, {
        include:
          "specifications,product.manufacturer,product.tenant,product.pictures,status,product.specifications",
      });
      const loadedAssets = sortBy(
        extractAssets(responseAssets),
        "serialNumber",
      );
      if (loadedAssets.length) {
        setSensorBuildNr(loadedAssets[0].sensorModuleBuildNumber);
        const responseSoftwares = await api.get(
          `/assets/${loadedAssets[0].id}/softwares`,
          { software_type_id: 1 },
          false,
        );
        if (responseSoftwares.softwares?.length) {
          setFirmwareVersion(responseSoftwares.softwares.version_number);
        }
      }
      const instrumentationForDisplay =
        prepareInstrumentationForDisplay(apiResponse);
      const instrumentationRules = rules.instrumentation(
        instrumentationForDisplay,
        loadedAssets,
      );
      instrumentationForDisplay.unit = instrumentationRules
        ?.get("fullEmptyCalibrationUnitOptions")
        ?.filter((unit) => unit.id === instrumentationForDisplay.unit)[0];

      setInstrumentation(instrumentationForDisplay);
      setAssets(loadedAssets);
      setFetching(false);
    } catch (error) {
      if (error instanceof NotFoundError) {
        browser.navigateTo("/404");
      } else {
        notifier.showError(api.translateError(error));
        setFetching(false);
      }
    }
  };

  React.useEffect(() => {
    loadData();
  }, [id]);

  const onConfirmDelete = async () => {
    try {
      await api.delete(`/instrumentations/${id}`);
      notifier.showSuccess(
        intl.formatMessage({
          id: "instrumentation.actions.delete.notification",
        }),
      );
      browser.goBack();
    } catch (error) {
      if (error instanceof ConflictError) {
        notifier.showError(
          intl.formatMessage({
            id: "api.error.instrumentation.assigned_restriction",
          }),
        );
      } else {
        notifier.showError(intl.formatMessage({ id: "api.error.unknown" }));
      }
    }
  };

  const renderConfigurations = () => {
    const sensitivityValue = () =>
      instrumentation.sensitivity
        ? intl.formatMessage({
            id: `enum.sensitivity.${instrumentation.sensitivity}`,
          })
        : intl.formatMessage({ id: "enum.sensitivity.medium" });
    return (
      <>
        <h2 id="full_empty_calibration">
          <FormattedMessage id="label.full_empty_calibration" />
          <InstrumentationFullEmptyCalibrationHelp />
        </h2>
        <DetailsItem
          id="instrumentation-empty"
          translationKey="label.empty"
          value={addUnitToValue(
            instrumentation.empty,
            instrumentation.unit,
            "-",
          )}
        />
        <DetailsItem
          id="instrumentation-full"
          translationKey="label.full"
          value={addUnitToValue(
            instrumentation.full,
            instrumentation.unit,
            "-",
          )}
        />
        <DetailsItem
          id="instrumentation-block-distance"
          translationKey="label.block_distance"
          value={addUnitToValue(
            instrumentation.block_distance,
            instrumentation.unit,
            intl.formatMessage({ id: "label.automatic" }),
          )}
        />
        <DetailsItem
          id="instrumentation-sensitivity"
          translationKey="label.sensitivity"
          value={sensitivityValue()}
        />
      </>
    );
  };

  const renderMediumType = () => {
    const returnDetail = instrumentation.medium_type ? (
      <DetailsItem
        intl={intl}
        id="instrumentation-medium-type"
        translationKey="label.medium_type"
        value={intl.formatMessage({
          id: `enum.medium_type.${instrumentation.medium_type}`,
        })}
      />
    ) : null;
    return returnDetail;
  };

  const renderMoreContent = () => {
    const detailItems = instrumentation ? (
      <>
        <DetailsItem
          id="instrumentation-description"
          translationKey="label.description"
          value={instrumentation.description}
        />
        {appShowsMediumType &&
        currentRules.get("needsMediumType") &&
        currentRules?.get(
          "isCapableOfMeasuringSolids",
          firmwareVersion,
          sensorBuildNr,
        )
          ? renderMediumType(instrumentation)
          : null}
        {appShowsFullEmptyCalibration &&
        currentRules.get("needsFullEmptySettings")
          ? renderConfigurations(instrumentation)
          : null}
        <DetailsItem
          id="instrumentation-accessibility"
          icon={accessibilityIconClassName(instrumentation.accessibility)}
          translationKey="label.accessibility"
          value={nameForSelections(
            "enum.accessibility",
            instrumentation.accessibility,
            intl,
          )}
        />
        <DetailsItem
          id="instrumentation-criticality"
          icon={criticalityIconClassName(instrumentation.criticality)}
          translationKey="label.criticality"
          value={nameForCriticality(instrumentation.criticality, intl)}
        />
        <div className="space-after" />
        <DetailsItem
          id="instrumentation-labels"
          translationKey="label.tag_labels"
        >
          <ToggleLabel
            id="criticality-environment"
            text={intl.formatMessage({ id: "label.criticality_env" })}
            initial={stringToBool(instrumentation.criticality_environment)}
          />
          <ToggleLabel
            id="criticality-quality"
            text={intl.formatMessage({ id: "label.criticality_quality" })}
            initial={stringToBool(instrumentation.criticality_quality)}
          />
          <ToggleLabel
            id="criticality-safety"
            text={intl.formatMessage({ id: "label.criticality_safety" })}
            initial={stringToBool(instrumentation.criticality_safety)}
          />
          <ToggleLabel
            id="special-demands"
            disabled
            text={intl.formatMessage({ id: "label.special_demands" })}
            initial={stringToBool(instrumentation.special_demands)}
          />
          <ToggleLabel
            id="environmental-condition"
            text={intl.formatMessage({ id: "label.environmental_condition" })}
            initial={instrumentation.environmental_condition === "heavy"}
          />
        </DetailsItem>
        {appSpecificMore}
      </>
    ) : null;

    return (
      <Row>
        <Column>
          {shouldCollapse && !fetching ? (
            <div className="space-before">
              <More>{detailItems}</More>
            </div>
          ) : (
            detailItems
          )}
        </Column>
      </Row>
    );
  };

  const handleChildComponent = (child) =>
    child
      ? React.cloneElement(child, {
          parentInstrumentation: instrumentation,
          parentAssets: assets,
        })
      : /* istanbul ignore next */ child;

  const renderWorstAssetStatus = () =>
    instrumentation.worst_asset_status ? (
      <DetailsItem
        id="instrumentation-worst-asset-status"
        translationKey="label.status"
        icon={`icon lcm-iot-icon-circle status-${instrumentation.worst_asset_status.code} ${instrumentation.status.code === "inactive" ? "inactive" : ""} `}
        headerSuffix={
          showHealthNotifications ? (
            <button
              id="edit-notification-link"
              className="btn-link header-side-button icon2-eh-mail"
              type="button"
              onClick={() =>
                browser.navigateTo(
                  `/instrumentations/${id}/edit_health_notifications`,
                )
              }
            />
          ) : null
        }
        value={instrumentation.worst_asset_status.name}
      />
    ) : null;

  const renderInstrumentationDetails = () =>
    instrumentation && assets ? (
      <>
        <InstrumentationDetailsBasicInfo
          pictures={instrumentation.pictures}
          tag={instrumentation.tag}
          typeDisplayName={instrumentation.type.displayName}
        />
        <DetailsItem
          id="instrumentation-status"
          translationKey="label.operation_mode"
          value={instrumentation.status.name}
        />
        {renderWorstAssetStatus()}
        <SpecificationDetails specifications={instrumentation.specifications} />
      </>
    ) : null;

  const appSpecificChildren =
    instrumentation && assets && children
      ? React.Children.map(children, (child) => handleChildComponent(child))
      : null;

  return (
    <Container>
      <Row>
        <Column>
          <ActionBar>
            <h1>
              <FormattedMessage id="instrumentation_details.header" />
            </h1>
            <ActionBarButtons>
              <EditActionButton
                id="edit-instrumentation-button"
                disabled={!accessRights.canUpdate}
                target={`/instrumentations/${match.params.id}/edit`}
              />
              <DeleteActionButton
                id="delete-instrumentation"
                disabled={!accessRights.canDelete || fetching}
                modalTitle={intl.formatMessage({
                  id: "instrumentation.actions.delete.modal_title",
                })}
                modalMessage={intl.formatMessage({
                  id: "instrumentation.actions.delete.modal_message",
                })}
                onConfirm={onConfirmDelete}
              />
            </ActionBarButtons>
          </ActionBar>
          <Details>
            {renderInstrumentationDetails()}
            {renderMoreContent()}
          </Details>
          {appSpecificChildren}
          <AssetsAssignList
            object={instrumentation}
            accessRights={accessRights}
          />
          <NodesAssignList
            object={instrumentation}
            accessRights={accessRights}
          />
          <Loader id="instrumentation-details-loader" loading={fetching} />
        </Column>
      </Row>
    </Container>
  );
}

InstrumentationDetails.propTypes = {
  api: apiShape.isRequired,
  accessRights: accessRightsShape,
  intl: intlShape.isRequired,
  notifier: notifierShape.isRequired,
  browser: browserShape,
  children: PropTypes.node,
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
  shouldCollapse: PropTypes.bool,
  appSpecificMore: PropTypes.node,
  rules: rulesShape,
  appShowsFullEmptyCalibration: PropTypes.bool,
  appShowsMediumType: PropTypes.bool,
};

export default injectIntl(
  withApi(
    withNotifier(
      withBrowser(
        withRules(
          withAccessRights(
            InstrumentationDetails,
            "Instrumentation",
            enforceAccessRightCanRead,
          ),
        ),
      ),
    ),
  ),
);
