import React from "react";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import {
  intlShape,
  browserShape,
  backendShape,
  sessionShape,
  isEmpty,
  withSession,
  withIntl,
  withBackend,
  withBrowser,
  ContextualHelp,
  Column,
  Form,
  Row,
  Checkbox,
  RadioSelect,
  Container,
  SubmitButton,
  withNotifier,
  notifierShape,
  Heading,
} from "lcm-iot-commons";

import SignUpBreadcrumb from "./SignUpBreadcrumb";
import { SignUpTileSlider } from "./SignUpTileSlider";

export function SignInTermsConfirmation({ intl, browser, backend, notifier }) {
  const onSubmit = async (values, actions) => {
    try {
      const response = await backend.patch("/users/terms", {
        newsletter: values.newsletter,
      });
      browser.redirectToExtern(response.redirect_uri);
    } catch (apiErrors) {
      notifier.showError(backend.translateError(apiErrors));
    }
    actions.setSubmitting(false);
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.terms) {
      errors.terms = intl.formatMessage({ id: "validation.terms.mandatory" });
    }

    if (isEmpty(values.newsletter)) {
      errors.newsletter = intl.formatMessage({
        id: "validation.option.invalid",
      });
    }

    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;

    const newletterHelp = (
      <ContextualHelp
        title={intl.formatMessage({ id: "signup.newsletter.title" })}
      >
        <p>{intl.formatMessage({ id: "signup.newsletter.help_info_1" })}</p>
        <p>{intl.formatMessage({ id: "signup.newsletter.help_info_2" })}</p>
      </ContextualHelp>
    );

    const newsletterOptions = [
      {
        value: "true",
        label: intl.formatMessage({ id: "signup.newsletter.yes" }),
        contextualHelp: newletterHelp,
      },
      {
        value: "false",
        label: intl.formatMessage({ id: "signup.newsletter.no" }),
      },
    ];

    const termsLabel = (
      <span>
        <FormattedMessage id="signup.accept_terms" />
        &nbsp;
        <a href="/legal/terms-of-service" target="_new" id="signin_tos_url">
          <FormattedMessage id="signup.terms" />
        </a>
        &nbsp;
        <FormattedMessage id="signup.and" />
        &nbsp;
        <a href="/legal/privacy-policy" target="_new" id="signin_privacy_url">
          <FormattedMessage id="signup.privacy_policy" />
        </a>
      </span>
    );

    return (
      <Form {...formProps}>
        <RadioSelect
          {...formProps}
          id="newsletter"
          name="newsletter"
          label="Radio Select"
          options={newsletterOptions}
        />
        <br />
        <Checkbox
          {...formProps}
          id="terms"
          name="terms"
          labelNode={termsLabel}
        />
        <SubmitButton
          id="sign-in"
          text={intl.formatMessage({ id: "signin.button.signin" })}
          fetching={isSubmitting}
        />
      </Form>
    );
  };

  return (
    <Container>
      <Row>
        <div className="col-md-12">
          <SignUpBreadcrumb current="confirmation" action="signinterms" />
        </div>
        <Column md="7">
          <Heading
            id="header"
            title={intl.formatMessage(
              { id: "signinterms.header" },
              { username: "" },
            )}
          />
          <p className="space-after">
            {intl.formatMessage({ id: "signinterms.introduction" })}
          </p>
          <br />
          <Formik
            validate={validateForm}
            onSubmit={onSubmit}
            render={renderForm}
          />
        </Column>
        <Column md="4">
          <div
            id="signin_customer_feedback"
            className="sign-up-customer-feedback-block"
          >
            <SignUpTileSlider intl={intl} />
          </div>
        </Column>
      </Row>
    </Container>
  );
}

SignInTermsConfirmation.propTypes = {
  intl: intlShape.isRequired,
  browser: browserShape.isRequired,
  backend: backendShape.isRequired,
  session: sessionShape,
  notifier: notifierShape.isRequired,
};

SignInTermsConfirmation.defaultProps = {
  session: {
    authenticated: false,
    startUrl: "",
  },
};

export default withNotifier(
  withIntl(withBackend(withBrowser(withSession(SignInTermsConfirmation)))),
);
