import React from "react";
import PropTypes from "prop-types";
import ReactInputSwitch from "react-input-switch";
import Colors from "../constants/Colors";

const style = {
  container: {
    width: 50,
    height: 28,
  },
  track: {
    borderRadius: 14,
    backgroundColor: Colors.EH_RED_ORANGE_3,
  },
  trackChecked: {
    backgroundColor: Colors.EH_MAY_GREEN_2,
  },
  button: {
    borderRadius: 18,
    right: 24,
    left: 2,
    top: 3,
    bottom: 3,
  },
  buttonChecked: {
    left: 24,
    right: 2,
    top: 3,
    bottom: 3,
  },
};

export function Switch({ id, value, disabled, onChange, label }) {
  return (
    <div className="switch">
      <ReactInputSwitch
        disabled={disabled}
        id={id}
        off={false}
        on
        onChange={onChange}
        styles={style}
        value={value}
      />
      <div className="switch" id="switch-label">
        {label}
      </div>
    </div>
  );
}

Switch.propTypes = {
  id: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

Switch.defaultProps = {
  id: undefined,
  value: false,
  disabled: false,
  onChange: undefined,
  label: undefined,
};

export default Switch;
