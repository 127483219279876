import lcmApiClient from "./lcmApiClient";
import { extractInstrumentations } from "../extractors/instrumentationsExtractor";

/* istanbul ignore next */
export async function loadInstrumentations(parameters) {
  const response = await lcmApiClient().get("/instrumentations", parameters);
  return {
    instrumentations: extractInstrumentations(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}

/* istanbul ignore next */
export async function loadNextInstrumentations(nextPageUrl) {
  const response = await lcmApiClient().get(nextPageUrl);
  return {
    instrumentations: extractInstrumentations(response),
    totalCount: response.pagination.total_count,
    nextPageUrl: response.pagination.next,
  };
}
