import {
  parseISO8601Date,
  extractAddress,
  extractCardPaymentDetails,
  extractInvoicePaymentDetails,
} from "lcm-iot-commons";
import { appNameForIcon } from "./serviceSubscriptionsExtractor";

export function extractConnectSubscription(rawSubscription) {
  return {
    id: rawSubscription.id,
    clientApplicationId: rawSubscription.client_application.id,
    clientApplicationName: rawSubscription.client_application.name,
    subscriptionName: rawSubscription.display_name,
    apiCallQuota: rawSubscription.api_call_quota,
    storageQuota: rawSubscription.storage_quota,
    connectedAssetQuota: rawSubscription.connected_asset_quota,
    uploadDownloadQuota: rawSubscription.upload_download_quota,
    numberAssignedAssets: rawSubscription.number_assigned_assets,
    connectedAssetsUsed: rawSubscription.connected_assets_used,
    apiCallsUsed: rawSubscription.api_calls_used,
    storageUsed: rawSubscription.storage_used,
    uploadDownloadUsed: rawSubscription.upload_download_used,
    billingAddress: rawSubscription.billing_address
      ? extractAddress(rawSubscription.billing_address)
      : null,
    shippingAddress: rawSubscription.shipping_address
      ? extractAddress(rawSubscription.shipping_address)
      : null,
    customerPurchaseOrder: rawSubscription.customer_purchase_order,
    externalReference: rawSubscription.external_reference,
    externalPlanVariantReference:
      rawSubscription.external_plan_variant_reference,
    appIconName: rawSubscription.external_plan_variant_reference
      ? appNameForIcon(rawSubscription.external_plan_variant_reference)
      : null,
    billedUntil: rawSubscription.billed_until
      ? parseISO8601Date(rawSubscription.billed_until)
      : null,
    status: rawSubscription.status,
  };
}

export function extractConnectSubscriptions(rawSubscriptions) {
  return rawSubscriptions.api_subscriptions.map((subscription) =>
    extractConnectSubscription(subscription),
  );
}

export function extractClientApplication(rawClientApplication) {
  return {
    id: rawClientApplication.id,
    name: rawClientApplication.name,
    description: rawClientApplication.description,
    redirectUris: rawClientApplication.redirect_uris,
  };
}

function extractCurrentSubscriptionAddons(addons) {
  return addons.map((addon) => ({
    id: addon.id,
    quantity: addon.quantity,
  }));
}

export function extractCurrentSubscription(rawSubscription) {
  return {
    id: rawSubscription.id,
    type: rawSubscription.type,
    planVariant: rawSubscription.plan_variant,
    displayName: rawSubscription.display_name,
    apiCallQuota: rawSubscription.api_call_quota,
    storageQuota: rawSubscription.storage_quota,
    uploadDownloadQuota: rawSubscription.upload_download_quota,
    status: rawSubscription.status,
    billedUntil: rawSubscription.billed_until
      ? parseISO8601Date(rawSubscription.billed_until)
      : null,
    addons: rawSubscription.addons
      ? extractCurrentSubscriptionAddons(rawSubscription.addons)
      : null,
    customerPurchaseOrder: rawSubscription.customer_purchase_order,
    billingAddress: rawSubscription.billing_address
      ? extractAddress(rawSubscription.billing_address)
      : null,
    shippingAddress: rawSubscription.shipping_address
      ? extractAddress(rawSubscription.shipping_address)
      : null,
    cardPaymentDetails: rawSubscription.card_payment_details
      ? extractCardPaymentDetails(rawSubscription.card_payment_details)
      : null,
    invoicePaymentDetails: rawSubscription.invoice_payment_details
      ? extractInvoicePaymentDetails(rawSubscription.invoice_payment_details)
      : null,
    canCancel: rawSubscription.can_cancel,
  };
}
