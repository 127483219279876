import ApiClient from "./ApiClient";
import { redirectTo } from "../utils/browserUtils";

export class PrivateBackendApiClient extends ApiClient {
  constructor(
    baseUrl,
    options,
    redirectToSessionExpiredOnAuthError = true,
    useNewErrors = false,
  ) /* istanbul ignore next */ {
    super(baseUrl, options);
    this.redirectToSessionExpiredOnAuthError =
      redirectToSessionExpiredOnAuthError;
    this.useNewErrors = useNewErrors;
  }

  handleError(error, request) {
    if (
      error.response &&
      error.response.status === 401 &&
      this.redirectToSessionExpiredOnAuthError
    ) {
      redirectTo("/session_expired");
      return new Promise((resolve, reject) => reject());
    }
    /* istanbul ignore next */
    return super.handleError(error, request);
  }
}

export default function backendApiClient(
  redirectToSessionExpiredOnAuthError = true,
  useNewErrors = false,
  responseType = null,
) /* istanbul ignore next */ {
  const baseUrl = `${document.location.protocol}//${document.location.host}${global.configuration.backendApiUrl}`;
  const csrfToken = document.head.querySelector("[name=csrf-token]");
  const headers = { "Content-Type": "application/json" };

  //  test needed for capybara tests where no csrf token is present
  if (csrfToken) {
    headers["X-CSRF-Token"] = csrfToken.content;
  }

  const options = { withCredentials: true, headers };
  if (responseType) {
    options.responseType = responseType;
  }

  return new PrivateBackendApiClient(
    baseUrl,
    options,
    redirectToSessionExpiredOnAuthError,
    useNewErrors,
  );
}
