import React from "react";
import PropTypes from "prop-types";

function Row({ id, className, style, children }) {
  return (
    <div
      id={id}
      className={["row", className].filter((c) => c).join(" ")}
      style={style}
    >
      {children}
    </div>
  );
}

Row.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node,
};

Row.defaultProps = {
  id: undefined,
  className: undefined,
  style: undefined,
  children: undefined,
};

export default Row;
