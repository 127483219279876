import React from "react";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import {
  intlShape,
  Form,
  CancelButton,
  SubmitButton,
  TextInput,
  ButtonGroup,
  SelectBox,
  DateInput,
  isValidDecimalSeparator,
  isValidFloat,
  parseIntlNumber,
  CountriesSelectFormik,
} from "lcm-iot-commons";
import PropTypes from "prop-types";

export function TaxForm({ intl, onSubmit, initialValues }) {
  const validateForm = (values) => {
    const errors = {};
    if (!values.startDate) {
      errors.startDate = intl.formatMessage({
        id: "validation.taxes.start_date_mandatory",
      });
    }
    if (
      values.startDate &&
      values.endDate &&
      values.startDate.getTime() > values.endDate.getTime()
    ) {
      errors.endDate = intl.formatMessage({
        id: "validation.taxes.start_date_later_than_end_date",
      });
    }
    if (!values.rate) {
      errors.rate = intl.formatMessage({
        id: "validation.taxes.rate_mandatory",
      });
    } else if (
      !isValidDecimalSeparator(values.rate) ||
      !isValidFloat(values.rate)
    ) {
      errors.rate = intl.formatMessage({ id: "validation.number.invalid" });
    } else {
      const rateValue = parseIntlNumber(values.rate);
      if (rateValue < 0 || rateValue > 100) {
        errors.rate = intl.formatMessage({
          id: "validation.tax.must_be_between_0_and_100",
        });
      }
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        <DateInput
          {...formProps}
          id="tax-start-date"
          name="startDate"
          label={intl.formatMessage({ id: "label.tax.start_date" })}
          required
        />
        <DateInput
          {...formProps}
          id="tax-end-date"
          name="endDate"
          placeholder={intl.formatMessage({
            id: "taxes.never_expires_when_empty",
          })}
          label={intl.formatMessage({ id: "label.tax.end_date" })}
        />
        <CountriesSelectFormik
          {...formProps}
          id="tax-country"
          name="country"
          label={intl.formatMessage({ id: "label.tax.country" })}
          all
        />
        <SelectBox
          {...formProps}
          id="tax-type"
          name="type"
          label={intl.formatMessage({ id: "label.tax.type" })}
          options={[
            {
              id: "withholding",
              name: intl.formatMessage({ id: "enum.taxes.withholding" }),
            },
          ]}
        />
        <TextInput
          {...formProps}
          id="tax-rate"
          name="rate"
          label={intl.formatMessage({ id: "label.tax.rate" })}
          required
        />
        <ButtonGroup className="btn-group">
          <SubmitButton
            id="tax-submit"
            fetching={isSubmitting}
            intl={intl}
            disabled={!formProps.dirty}
          />
          <CancelButton id="tax-cancel" disabled={isSubmitting} intl={intl} />
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <Formik
      id="tax-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateForm}
      render={renderForm}
    />
  );
}

TaxForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    country: PropTypes.objectOf(PropTypes.string),
    rate: PropTypes.string,
    type: PropTypes.objectOf(PropTypes.string),
  }),
};

TaxForm.defaultProps = {
  onSubmit: undefined,
  initialValues: {
    startDate: new Date(),
    country: { code: "AF" },
    rate: undefined,
    type: { id: "withholding" },
  },
};

export default injectIntl(TaxForm);
