import React, { useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import TypeaheadBox from "../Form/TypeaheadBox";
import intlShape from "../../shapes/intlShape";
import {
  withApi,
  apiShape,
  withNotifier,
  notifierShape,
  withUser,
} from "../../context";
import { Column, Row } from "../Grid";

export function UserGroupTypeahead(props) {
  const [userGroups, setuserGroups] = useState();
  const [fetching, setFetching] = useState();

  const { api, notifier, intl, values, name, excludables } = props;

  const loadOptions = async (searchText = "") => {
    try {
      setFetching(true);
      const response = await api.get("/usergroups", {
        name: `${searchText}*`,
      });
      const allGroups = response.usergroups.map((userGroup) => ({
        id: userGroup.id,
        name: userGroup.name,
      }));
      let validSelection = allGroups;
      if (excludables?.length > 0) {
        const idsToExclude = excludables.map((a) => a.id);
        validSelection = allGroups.filter(
          (record) => !idsToExclude.includes(record.id),
        );
      }
      setuserGroups(validSelection);
    } catch (error) {
      notifier.showError(api.translateError(error));
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    loadOptions();
  }, [JSON.stringify(excludables)]);

  const selectedGroup = values[name];

  return (
    <Row>
      <Column>
        <TypeaheadBox
          {...props}
          info={
            selectedGroup?.new
              ? intl.formatMessage({ id: "usergroup_typeahead.new_info" })
              : null
          }
          options={userGroups}
          fetching={fetching}
          loadOptions={loadOptions}
        />
      </Column>
    </Row>
  );
}

UserGroupTypeahead.propTypes = {
  api: apiShape.isRequired,
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  notifier: notifierShape.isRequired,
  values: PropTypes.shape({
    usergroup: PropTypes.shape({}),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  excludables: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(withUser(withNotifier(withApi(UserGroupTypeahead))));
