import React from "react";
import QRCode from "qrcode.react";
import PropTypes from "prop-types";
import {
  Row,
  Column,
  ButtonGroup,
  CancelButton,
  Clue,
  Form,
  intlShape,
  SubmitButton,
  TextInput,
  withIntl,
} from "lcm-iot-commons";
import { Formik } from "formik";

const allow6Numbers = /^\d{6}$/;

export function MultiFactorAuthenticationForm({
  provisioningUri,
  onSubmit,
  intl,
  initialValues,
}) {
  const validateForm = (values) => {
    const errors = {};
    if (!values.otpCodeToken) {
      errors.otpCodeToken = intl.formatMessage({ id: "mfa.code_mandatory" });
    } else if (!allow6Numbers.test(values.otpCodeToken)) {
      errors.otpCodeToken = intl.formatMessage({ id: "mfa.code_format" });
    }
    return errors;
  };

  const renderForm = (formProps) => {
    const { isSubmitting } = formProps;
    return (
      <Form {...formProps}>
        {provisioningUri ? (
          <Row>
            <Column md={4}>
              <QRCode value={provisioningUri} level="H" size={256} />
            </Column>
            <Column md={4}>
              <Clue
                id="mfg-qr-hint"
                details={intl.formatMessage({ id: "mfa.clue_details" })}
                highlighted
              />
            </Column>
          </Row>
        ) : null}
        <Row>
          <Column md={8}>
            <TextInput
              {...formProps}
              id="mfa-code"
              name="otpCodeToken"
              otpCodeToken
              label={intl.formatMessage({ id: "mfa.code" })}
              placeholder={intl.formatMessage({ id: "mfa.code_placeholder" })}
              required
            />
          </Column>
        </Row>
        <Row>
          <Column md={8}>
            <ButtonGroup className="btn-group">
              <SubmitButton
                id="mfa-change-submit"
                text={
                  provisioningUri
                    ? intl.formatMessage({ id: "mfa.button_enable_action" })
                    : intl.formatMessage({ id: "mfa.button_disable_action" })
                }
                fetching={isSubmitting}
                intl={intl}
                disabled={!formProps.dirty}
              />
              <CancelButton
                id="mfa-change-cancel"
                disabled={isSubmitting}
                intl={intl}
              />
            </ButtonGroup>
          </Column>
        </Row>
      </Form>
    );
  };

  return (
    <Formik
      id="change-mfa-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateForm}
      render={renderForm}
    />
  );
}

MultiFactorAuthenticationForm.propTypes = {
  provisioningUri: PropTypes.string,
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }),
};

MultiFactorAuthenticationForm.defaultProps = {
  provisioningUri: undefined,
  initialValues: undefined,
};

export default withIntl(MultiFactorAuthenticationForm);
