/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  convertBytesToGB,
  formatDate,
  formatNumber,
  redirectToExtern,
  intlShape,
  showError,
} from "lcm-iot-commons";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import PropTypes from "prop-types";
import { serviceSubscriptionShape } from "../../shapes/serviceSubscriptionsShape";

class ServiceSubscriptionItem extends Component {
  constructor(props) {
    super(props);
    /* istanbul ignore next */
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  getIconForApp(appIconName) {
    if (
      [
        "health",
        "library",
        "analytics",
        "smartsystems",
        "value",
        "netilion",
        "inventory",
      ].includes(appIconName)
    ) {
      return `icon lcm-iot-icon-${appIconName}-rounded`;
    }
    if (appIconName === "fermentation_monitor") {
      return "icon icon3-eh-netilion-fermentation icon-style-fermentation";
    }
    return "icon lcm-iot-icon-circle";
  }

  getSubscriptionStatusLabel(subscription) {
    switch (subscription.status) {
      case "confirmed":
        return "subscription_details.active";
      case "non_renewing":
        return "subscription_details.non_renewing";
      case "cancelled":
        return "subscription_details.cancelled";
      case "payment_required":
        return "subscription_details.payment_required";
      case "in_trial":
        if (subscription.cancelledAt) {
          return "subscription_details.non_renewing";
        }
        return "subscription_details.in_trial";
      default:
        return "subscription_details.active";
    }
  }

  redirectToSmartSystemsUrl(subscription) {
    const { intl } = this.props;
    if (subscription.assets?.length > 0) {
      const transmitterId = subscription.assets[0].id;
      redirectToExtern(`/app/smartsystems/assets/${transmitterId}`);
    } else {
      showError(
        intl.formatMessage({ id: "error.subscription_no_asset_assigned" }),
      );
    }
  }

  redirectToGeneralUrl(subscription) {
    const { resellerView } = this.props;
    const appSpecificUrl =
      subscription.appIconName === "fermentation_monitor"
        ? "fermentation"
        : subscription.appIconName;
    // normal case
    let url = `/app/${appSpecificUrl}/subscription`;
    if (resellerView) {
      // reseller
      url = `/app/${appSpecificUrl}/reseller/subscriptions/${subscription.id}`;
    } else if (!subscription.usable) {
      url = `/app/${appSpecificUrl}/subscriptions/${subscription.id}`;
    }
    redirectToExtern(url);
  }

  handleOnClick() {
    const { subscription } = this.props;
    if (subscription.appIconName === "smartsystems") {
      this.redirectToSmartSystemsUrl(subscription);
    } else {
      this.redirectToGeneralUrl(subscription);
    }
  }

  render() {
    const { subscription, intl, resellerView } = this.props;
    const {
      applicationName,
      status,
      subscriptionName,
      billedUntil,
      appIconName,
      interval,
      pricingModel,
      assets,
      trialEndDate,
      resellerLabel,
      usable,
    } = subscription;
    const externalRef = `ID: ${subscription.id}`;
    const storageQuota = formatNumber(
      convertBytesToGB(subscription.storageQuota),
    );
    const dataStorageQuota = subscription.dataStorageQuota
      ? formatNumber(convertBytesToGB(subscription.dataStorageQuota))
      : undefined;
    let assetQuota = formatNumber(subscription.assetQuota);
    if (assetQuota === "-1") {
      assetQuota = intl.formatMessage({ id: "label.unlimited" });
    }
    const iconClassname = this.getIconForApp(appIconName);
    const intervalAndTag =
      assets &&
      assets.length > 0 &&
      assets[0].instrumentations &&
      assets[0].instrumentations.length > 0 ? (
        <div>
          <FormattedMessage id="label.tag" />:
          {assets[0].instrumentations[0].tag},{interval}{" "}
          <FormattedMessage id="label.minutes" />{" "}
          <FormattedMessage id="label.interval" />
        </div>
      ) : null;

    const assetAndStorageQuota =
      subscription.assetQuota && storageQuota ? (
        <div>
          <FormattedMessage
            id="label.subscription_service_quota"
            values={{ assetQuota, storageQuota }}
          />
          {dataStorageQuota ? (
            <FormattedMessage
              id="label.subscription_service_data_quota"
              values={{ dataStorageQuota }}
            />
          ) : null}
        </div>
      ) : null;

    const subscriptionStatus = (
      <span>
        <FormattedMessage id="label.status" />:{" "}
        <FormattedMessage id={this.getSubscriptionStatusLabel(subscription)} />{" "}
      </span>
    );

    let currentTermDetails;
    if (!(pricingModel === "free")) {
      // paid subscription
      let statusDetails;
      if (status === "in_trial") {
        statusDetails = (
          <FormattedMessage
            id="label.ending_on"
            values={{ date: formatDate(trialEndDate, intl) }}
          />
        );
      } else {
        statusDetails =
          status !== "payment_required" ? (
            <FormattedMessage
              id={
                status === "confirmed" ? "label.renewing_on" : "label.ending_on"
              }
              values={{ date: formatDate(billedUntil, intl) }}
            />
          ) : null;
      }

      currentTermDetails = (
        <div>
          {subscriptionStatus}
          {statusDetails}
        </div>
      );
    } else {
      // free subscription
      currentTermDetails = (
        <div>
          {subscriptionStatus}
          <FormattedMessage id="label.free_subscription" />
        </div>
      );
    }

    let resellerLabelElement;
    if (resellerView && resellerLabel) {
      resellerLabelElement = ` - ${resellerLabel}`;
    }

    let usableElement;
    if (!resellerView && usable === false) {
      usableElement = ` - ${intl.formatMessage({ id: "label.can_read_subscription" })}`;
    }

    const isCancelled = subscription.status?.toLowerCase() === "cancelled";

    return (
      <li
        data-testid={`item-${applicationName}`}
        className={isCancelled ? "list-item disabled" : "list-item clickable"}
        onClick={isCancelled ? () => {} : this.handleOnClick}
      >
        <div className="list-item-image">
          <span className={iconClassname} id="application-icon" />
        </div>
        <div className="list-item-content">
          <div className="list-item-header">
            {applicationName}
            {" - "}
            {subscriptionName}
            {resellerLabelElement}
            {usableElement}
          </div>
          <div>
            {assetAndStorageQuota}
            {intervalAndTag}
            {currentTermDetails}
            {externalRef}
          </div>
        </div>
      </li>
    );
  }
}

ServiceSubscriptionItem.propTypes = {
  intl: intlShape,
  subscription: serviceSubscriptionShape,
  resellerView: PropTypes.bool,
};

ServiceSubscriptionItem.defaultProps = {
  intl: undefined,
  subscription: undefined,
  resellerView: false,
};

export default injectIntl(ServiceSubscriptionItem);
