import NodeTypeRules from "./NodeTypeRules";

class NodeRules {
  constructor(node) {
    this.node = node;
    this.nodeTypeRules = NodeTypeRules.find(node);
  }

  get(rule, a, b, c) {
    return this.nodeTypeRules.get(rule, a, b, c);
  }
}

export default NodeRules;
