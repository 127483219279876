const Colors = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  LIGHT_GRAY: "#f3f6f8",

  EH_GRAY: "#333333",

  EH_CYAN_1: "#00AEEF",
  EH_CYAN_2: "#AFD3E8",
  EH_CYAN_3: "#007CAA",
  EH_CYAN_4: "#00597A",

  EH_MAGENTA_1: "#FF0099",
  EH_MAGENTA_2: "#A8005C",
  EH_MAGENTA_3: "#7B0040",

  EH_WINTER_GRAY_1: "#E6ECF0",
  EH_WINTER_GRAY_2: "#C3CED5",
  EH_WINTER_GRAY_3: "#8FA2AC",
  EH_WINTER_GRAY_4: "#506671",

  EH_SUN_YELLOW_1: "#FFE596",
  EH_SUN_YELLOW_2: "#FFCC00",

  EH_RED_ORANGE_1: "#FBCDAB",
  EH_RED_ORANGE_2: "#E94C0A",
  EH_RED_ORANGE_3: "#D50C2F",
  EH_RED_ORANGE_4: "#652E2B",

  EH_MAY_GREEN_1: "#C2DDAF",
  EH_MAY_GREEN_2: "#54A931",
  EH_MAY_GREEN_3: "#486F4F",
};

export default Colors;
