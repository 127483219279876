import lcmApiClient from "./lcmApiClient";
import { extractUserGroup } from "../extractors/userGroupsExtractor";

/* istanbul ignore next */
export async function createUserGroup(userGroup) {
  const newUserGroup = {
    name: userGroup.name,
    description: userGroup.description,
  };
  const response = await lcmApiClient().post("/usergroups", newUserGroup);
  return response.id;
}

/* istanbul ignore next */
export async function updateUserGroup(id, userGroup) {
  const updatedUserGroup = {
    name: userGroup.name,
    description: userGroup.description,
  };
  await lcmApiClient().patch(`/usergroups/${id}`, updatedUserGroup);
}

/* istanbul ignore next */
export async function loadUserGroup(id, parameters) {
  const response = await lcmApiClient(false).get(
    `/usergroups/${id}`,
    parameters,
  );
  return extractUserGroup(response);
}

/* istanbul ignore next */
export async function deleteUserGroup(id) {
  return lcmApiClient().delete(`/usergroups/${id}`);
}
