import React from "react";
import PropTypes from "prop-types";
import Switch from "react-input-switch";
import { injectIntl } from "react-intl";
import { intlShape } from "../../shapes";
import Colors from "../../constants/Colors";

function ControlledSwitchButton(
  {
    id,
    onChange,
    activeKey,
    inactiveKey,
    intl,
    disabled,
    status,
    headerKey,
    isMainHeaderActive = true,
  },
  isEventPropagate,
) {
  const labelKey = status ? activeKey : inactiveKey;
  return (
    <div>
      <h2 hidden={!isMainHeaderActive} id="switch-title">
        {intl.formatMessage({ id: headerKey })}
      </h2>
      <div hidden={isMainHeaderActive}>
        <label
          style={{
            fontWeight: 600,
            fontSize: 20,
          }}
          id="switch-title"
        >
          {intl.formatMessage({ id: headerKey })}
        </label>
        <br />
      </div>
      <Switch
        id={id}
        role="switch" // To identify the element for test cases
        aria-checked="false"
        value={status}
        on
        off={false}
        disabled={disabled}
        styles={{
          container: {
            width: 50,
            height: 28,
          },
          track: {
            borderRadius: 14,
            backgroundColor: Colors.EH_RED_ORANGE_3,
          },
          trackChecked: {
            backgroundColor: Colors.EH_MAY_GREEN_2,
          },
          button: {
            borderRadius: 18,
            right: 24,
            left: 2,
            top: 3,
            bottom: 3,
          },
          buttonChecked: {
            left: 24,
            right: 2,
            top: 3,
            bottom: 3,
          },
        }}
        onChange={() => {
          const value = status === true ? false : true; // eslint-disable-line no-unneeded-ternary
          if (!isEventPropagate && window.document.documentMode) {
            onChange(value);
          }

          // Normal configuration
          if (!window.document.documentMode) {
            onChange(value);
          }
          // added this property to fix the library issue wIth IE
          // Need to avoid propagating event in IE
          // isEventPropagate : added a lint exception, since this parameter value needs not to be reload when assigning from a local variable
          // eslint-disable-next-line no-param-reassign
          isEventPropagate = !isEventPropagate;
        }}
      />
      <div className="controlled-switch-label" id="switch-label">
        {intl.formatMessage({ id: labelKey })}
      </div>
    </div>
  );
}

ControlledSwitchButton.propTypes = {
  id: PropTypes.string.isRequired,
  activeKey: PropTypes.string,
  inactiveKey: PropTypes.string,
  intl: intlShape,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  status: PropTypes.bool.isRequired,
  headerKey: PropTypes.string,
  isMainHeaderActive: PropTypes.bool,
};

ControlledSwitchButton.defaultProps = {
  activeKey: "label.active",
  inactiveKey: "label.inactive",
  headerKey: "label.activation_status",
  disabled: false,
};

export default injectIntl(ControlledSwitchButton);
